import React, { createContext, useState, useContext } from "react";

export const MainContext = createContext();

const MainContextProvider = ({ children }) => {
    const [countryCode, setCountryCode] = useState("");
    const [mob, setMob] = useState("");
    const [formData, setFormData] = useState({
        country_code: '',
        fullName: '',
        phone: '',
        email: '',
        country: '',
        state: '',
        city: '',
        zipCode: '',
        instaLink: '',
        fbLink: '',
        youtubeLink: '',
        twitterLink: ''
    });
    const [buttonName, setButtonName] = useState('Default Button');
    const [buttonValue, setButtonValue] = useState('Default Value');
    const [isLanding, setisLanding] = useState(true);
    const [isSignIn, setisSignIn] = useState(false);
    const [isSignUp, setisSignUp] = useState(false);
    const [isAuthenticated, setisAuthenticated] = useState(false);
    const [contentType, setContentType] = useState('audio');
    const [file, setFile] = useState(null);

    const updateButtonInfo = (name, value) => {
        setButtonName(name);
        setButtonValue(value);
    };

    const handleFormData = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    console.log('formdata', formData);

    return <MainContext.Provider value={{
        countryCode, setCountryCode,
        mob, setMob,
        formData, handleFormData,
        buttonName, buttonValue, updateButtonInfo,
        isLanding, setisLanding,
        isSignIn, setisSignIn,
        isSignUp, setisSignUp,
        isAuthenticated, setisAuthenticated,
        contentType, setContentType, 
        file, setFile
    }}>
        {children}
    </MainContext.Provider>
}
export const useContent = () => {
    return useContext(MainContext);
  };
  

export default MainContextProvider;