import { Navigate, Outlet } from "react-router-dom";
import React from 'react'
import Footer from "../components/Footer";
import Navbar2 from '../components/Navbar2';
import { publicRouteLink } from "../constant/Routes";

const PrivateRoutes = () => {
    const isloggedIn = !localStorage.getItem("login")
    // const isloggedIn = true;

    console.log("isloggedIn", isloggedIn)
    return (
        isloggedIn ? <>
            <Navbar2 />
            <Outlet />
            <Footer />
        </> : <Navigate to={publicRouteLink.SIGNIN} replace />)
}

export default PrivateRoutes
