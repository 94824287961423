import React from "react";

import { Link } from "react-router-dom";
import { FiUpload } from "react-icons/fi";
import { useState, useEffect } from "react";
import ProfilePicture from "./ProfilePicture";
import axios from "axios";

export default function BankDetails() {
  const [imageURL1, setImageURL1] = useState(null);
  const [imageURL2, setImageURL2] = useState(null);
  const [imageURL3, setImageURL3] = useState(null);
  const [imageURL4, setImageURL4] = useState(null);
  const [imageName1, setImageName1] = useState(null);
  const [imageName2, setImageName2] = useState(null);
  const [imageName3, setImageName3] = useState(null);
  const [imageName4, setImageName4] = useState(null);
  const [bankNameValue, setBankNameValue] = useState("");
  const [bankNameError, setBankNameError] = useState("");
  const [ifscValue, setIfscValue] = useState("");
  const [ifscError, setIfscError] = useState("");
  const [accNumberValue, setAccNumberValue] = useState("");
  const [accNumberError, setAccNumberError] = useState("");
  const [confirmAccNumberValue, setConfirmAccNumberValue] = useState("");
  const [confirmAccNumberError, setConfirmAccNumberError] = useState("");
  const [branchValue, setBranchValue] = useState("");
  const [branchError, setBranchError] = useState("");
  const [beneficiaryValue, setBeneficiaryValue] = useState("");
  const [beneficiaryError, setBeneficiaryError] = useState("");
  const [imageError, setImageError] = useState("");
  const [fullName, setFullName] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({});
  const [originalData, setOriginalData] = useState({ ...formData });

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    setOriginalData({ ...formData });
  };

  const handleCancelClick = () => {
    setFormData({ ...originalData });
    setIsEditing(false);
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  const validateImageFile = (file) => {
    const validTypes = ["image/jpeg", "image/jpg", "application/pdf"];
    return validTypes.includes(file.type);
  };

  const handleImageUpload1 = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (validateImageFile(file)) {
        const objectURL = URL.createObjectURL(file);
        setImageURL1(objectURL);
        setImageName1(file.name); // Save the file name
        setImageError(""); // Clear any previous error message
      } else {
        setImageError("Only .jpeg, .jpg, and .pdf files are allowed.");
      }
    }
  };

  const handleImageUpload2 = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (validateImageFile(file)) {
        const objectURL = URL.createObjectURL(file);
        setImageURL2(objectURL);
        setImageName2(file.name); // Save the file name
        setImageError(""); // Clear any previous error message
      } else {
        setImageError("Only .jpeg, .jpg, and .pdf files are allowed.");
      }
    }
  };

  const handleImageUpload3 = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (validateImageFile(file)) {
        const objectURL = URL.createObjectURL(file);
        setImageURL3(objectURL);
        setImageName3(file.name); // Save the file name
        setImageError(""); // Clear any previous error message
      } else {
        setImageError("Only .jpeg, .jpg, and .pdf files are allowed.");
      }
    }
  };

  const handleImageUpload4 = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (validateImageFile(file)) {
        const objectURL = URL.createObjectURL(file);
        setImageURL4(objectURL);
        setImageName4(file.name); // Save the file name
        setImageError(""); // Clear any previous error message
      } else {
        setImageError("Only .jpeg, .jpg, and .pdf files are allowed.");
      }
    }
  };
  //----------validation for input fields----------
  const handleBankNameChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z\s]{0,50}$/;

    if (regex.test(value)) {
      setBankNameValue(value);
      setBankNameError("");
    } else {
      setBankNameValue("");
      setBankNameError("Please Enter a valid Bank name");
    }
  };

  const handleIfscChange = (e) => {
    const value = e.target.value.toUpperCase();
    const regex = /^[A-Z0-9]*$/;
    if (regex.test(value) && value.length <= 11) {
      setIfscValue(value);
      setIfscError("");
    } else {
      setIfscError(
        "Please Enter a valid IFSC code (11 characters, format: AAAA0AAAAAA)"
      );
    }
  };

  const handleAccountNumber = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]*$/;

    if (regex.test(value) && value.length <= 16) {
      setAccNumberValue(value);
      setAccNumberError("");
      console.log(accNumberValue);
    } else {
      setAccNumberError("Please Enter Account Number of 16 Digits");
    }
  };

  const handleConfirmAccNumber = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]{0,16}$/;

    if (regex.test(value)) {
      setConfirmAccNumberValue(value);
      if (value === accNumberValue) {
        setConfirmAccNumberError("");
      } else {
        setConfirmAccNumberError("Account numbers do not match");
      }
    } else {
      // setConfirmAccNumberValue("");
    }
  };

  const handleBranchChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z\s]{0,30}$/;

    if (regex.test(value)) {
      setBranchValue(value);
      setBranchError("");
    } else {
      setBranchError("Please Enter a valid Branch name");
    }
  };

  const handleBeneficiaryChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z\s]{0,50}$/;

    if (regex.test(value)) {
      setBeneficiaryValue(value);
      setBeneficiaryError("");
    } else {
      setBeneficiaryError("Please Enter a valid Branch name");
    }
  };

  //------API Integration------
  const getUserInfo = async () => {
    try {
      const response = await axios.get(
        "https://haartistaloud-backend.kollywoodhungama.com/v1/userById/400f6036b07146eab9d2eab150fa5f"
      );
      const userData = response.data.data[0];

      setFullName(userData.full_name || "");

      if (userData.pan_image) {
        setImageURL1(userData.pan_image);
        setImageName1("PAN Image");
      }
      if (userData.cancelled_cheque_image) {
        setImageURL2(userData.cancelled_cheque_image);
        setImageName2("Cancelled Cheque Image");
      }
      if (userData.gst_certificate_image) {
        setImageURL3(userData.gst_certificate_image);
        setImageName3("GST Certificate Image");
      }
      if (userData.msme_certificate_image) {
        setImageURL4(userData.msme_certificate_image);
        setImageName4("MSME Certificate Image");
      }

      setFormData(userData);
      setOriginalData(userData);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  // const saveData = async () => {
  //   try {
  //     const formData = new FormData();
  //     formData.append("bankName", bankNameValue);
  //     formData.append("ifsc", ifscValue);
  //     formData.append("accountNumber", accNumberValue);
  //     formData.append("branch", branchValue);
  //     formData.append("beneficiary", beneficiaryValue);

  //     // Append files if they exist
  //     if (imageURL1) {
  //       formData.append("pan_image", imageURL1);
  //     }
  //     if (imageURL2) {
  //       formData.append("cancelled_cheque_image", imageURL2);
  //     }
  //     if (imageURL3) {
  //       formData.append("gst_certificate_image", imageURL3);
  //     }
  //     if (imageURL4) {
  //       formData.append("msme_certificate_image", imageURL4);
  //     }

  //     await axios.post(" https://haartistaloud-backend.kollywoodhungama.com/aa/upload", formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });

  //     alert("Data saved successfully");
  //   } catch (error) {
  //     console.error("Error saving data:", error);
  //   }
  // };
  return (
    <>
      <div className="flex">
        <button
          className="my-5 text-[#FEC961] text-[1.6rem] w-auto h-[5rem] ml-[12.5%]"
          style={{ fontFamily: "Poppins, sanSerif" }}
        >
          <Link to="/account-details">Account Details</Link>
        </button>
        <button
          className="my-5 text-[#FEC961] text-[1.6rem] w-auto h-[5rem] ml-[4%]"
          style={{ fontFamily: "Poppins, sanSerif" }}
        >
          <Link to="/social-media">Social Media</Link>
        </button>
        <button
          className="my-5 text-[#FF6C2F] text-[1.6rem] w-auto h-[5rem] ml-[4%]"
          style={{ fontFamily: "Poppins, sanSerif" }}
        >
          <Link to="/bank-details">Bank Details</Link>
          <hr />
        </button>
      </div>
      <div className="container1">
        <div className="profileBar w-[28%]  bg-[#ffffff0d] rounded-md">
          <ProfilePicture artistName={fullName} />
        </div>
        <div className="formDetails">
          <div>
            <label
              htmlFor=""
              className="flex font-[Poppins] text-[#FFFFFF] mt-[1%] mx-0 w-[80%] flex-row"
            >
              Name of the Bank
            </label>
            <input
              type=""
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins]"
              placeholder=""
              required
              value={bankNameValue}
              onChange={handleBankNameChange}
              readOnly={!isEditing}
            />
          </div>
          {bankNameError && (
            <span className="error-text" style={{ color: "red" }}>
              {bankNameError}
            </span>
          )}
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              IFSC
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins]"
              placeholder=""
              required
              value={ifscValue}
              onChange={handleIfscChange}
              readOnly={!isEditing}
            />
          </div>
          {ifscError && (
            <span className="error-text" style={{ color: "red" }}>
              {ifscError}
            </span>
          )}
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Account Number
            </label>
            <input
              type=""
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins]"
              placeholder=""
              required
              value={accNumberValue}
              onChange={handleAccountNumber}
              readOnly={!isEditing}
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Confirm Account Number
            </label>
            <input
              type=""
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins]"
              placeholder=""
              required
              value={confirmAccNumberValue}
              onChange={handleConfirmAccNumber}
              readOnly={!isEditing}
            />
          </div>
          {confirmAccNumberError && (
            <span className="error-text" style={{ color: "red" }}>
              {confirmAccNumberError}
            </span>
          )}

          <div className="flex flex-row items-center">
            <div className="mt-[4%] grow-[0.58]">
              <label htmlFor="" className="font-[Poppins] text-[#FFFFFF]">
                Branch
              </label>
            </div>
            <div className="mt-[4%]">
              <label htmlFor="" className="font-[Poppins] text-[#FFFFFF]">
                Account Type
              </label>
            </div>
          </div>
          <div>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[17rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins]"
              placeholder=""
              required
              value={branchValue}
              onChange={handleBranchChange}
              readOnly={!isEditing}
            />
            <select
              className="rounded-[5px] bg-[#4c4c4c] w-[17rem] h-[2.8rem] ml-3 text-[#FFFFFF] font-[Poppins] pl-[2%] outline-none"
              // disabled={!isEditing}
            >
              <option value="Savings">Savings Account</option>
              <option value="Current">Current Account</option>
              <option value="Salary">Salary Account</option>
              <option value="NRI">NRI Account</option>
            </select>
          </div>
          <div>
            <label
              htmlFor=""
              className="flex font-[Poppins] text-[#FFFFFF] mt-[4%] mx-0 w-[80%] flex-row"
            >
              Beneficiary Name
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins]"
              placeholder="Artist Aloud"
              required
              value={beneficiaryValue}
              onChange={handleBeneficiaryChange}
              readOnly={!isEditing}
            />
          </div>
          <div className="font-[Poppins] text-[#FFFFFF] w-full mt-5">
            {/* First row: PAN & Cancelled Cheque */}
            <div className="flex justify-between items-center w-full mb-2">
              <label htmlFor="" className="font-[Poppins]">
                Upload copy of PAN
              </label>
              <label htmlFor="" className="font-[Poppins]">
                Upload copy of Cancelled Cheque
              </label>
            </div>

            <div className="flex items-center w-full">
              {/* Upload PAN */}
              <div className="w-[18rem] flex items-center">
                <div className=" w-[7rem]">
                  <input
                    type="file"
                    id="upload_file1"
                    accept=".jpeg,.jpg,.pdf"
                    hidden
                    onChange={handleImageUpload1}
                    disabled={!isEditing}
                  />
                  <label
                    htmlFor="upload_file1"
                    className="flex items-center bg-[#4c4c4c] rounded-md w-full px-4 py-2 text-[#e2e2e2] cursor-pointer"
                  >
                    <span className="flex-grow">Upload</span>
                    <FiUpload className="text-[#FF6C2F]" />
                  </label>
                </div>
                <div className="w-[3rem] pl-2 max-h-[2.8rem] overflow-hidden">
                  {imageName1 && (
                    <a
                      href={imageURL1}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm text-[#FF6C2F]"
                      style={{
                        display: "inline-block",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                      }}
                    >
                      {imageName1}
                    </a>
                  )}
                </div>
              </div>

              {/* Upload Cancelled Cheque */}
              <div className="flex items-center">
                <div className=" w-[7rem]">
                  <input
                    type="file"
                    id="upload_file2"
                    accept=".jpeg,.jpg,.pdf"
                    hidden
                    onChange={handleImageUpload2}
                    disabled={!isEditing}
                  />
                  <label
                    htmlFor="upload_file2"
                    className="flex items-center bg-[#4c4c4c] rounded-md w-full px-4 py-2 text-[#e2e2e2] cursor-pointer"
                  >
                    <span className="flex-grow">Upload</span>
                    <FiUpload className="text-[#FF6C2F]" />
                  </label>
                </div>
                <div className="w-[3rem] pl-2 max-h-[2.8rem] overflow-hidden">
                  {imageName2 && (
                    <a
                      href={imageURL2}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm text-[#FF6C2F]"
                      style={{
                        display: "inline-block",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                      }}
                    >
                      {imageName2}
                    </a>
                  )}
                </div>
              </div>
            </div>

            {/* Error message for first row */}
            {imageError && (
              <span className="block mt-2 text-red-500 text-sm">
                {imageError}
              </span>
            )}

            {/* Second row: GST Certificate & MSME Certificate */}
            <div className="flex justify- items-center w-full mt-7 mb-2 ">
              <label htmlFor="" className="font-[Poppins]">
                Upload copy of GST Certificate
              </label>
              <label htmlFor="" className="font-[Poppins] ml-[7.5%]">
                Upload copy of MSME Certificate
              </label>
            </div>

            <div className="flex items-center w-full">
              {/* Upload GST Certificate */}
              <div className="w-[18rem] flex items-center">
                <div className=" w-[7rem]">
                  <input
                    type="file"
                    id="upload_file3"
                    accept=".jpeg,.jpg,.pdf"
                    hidden
                    onChange={handleImageUpload3}
                    disabled={!isEditing}
                  />
                  <label
                    htmlFor="upload_file3"
                    className="flex items-center bg-[#4c4c4c] rounded-md w-full px-4 py-2 text-[#e2e2e2] cursor-pointer"
                  >
                    <span className="flex-grow">Upload</span>
                    <FiUpload className="text-[#FF6C2F]" />
                  </label>
                </div>
                <div className="w-[3rem] pl-2 max-h-[2.8rem] overflow-hidden">
                  {imageName3 && (
                    <a
                      href={imageURL3}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm text-[#FF6C2F]"
                      style={{
                        display: "inline-block",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                      }}
                    >
                      {imageName3}
                    </a>
                  )}
                </div>
              </div>

              {/* Upload MSME Certificate */}
              <div className="flex items-center">
                <div className=" w-[7rem]">
                  <input
                    type="file"
                    id="upload_file4"
                    accept=".jpeg,.jpg,.pdf"
                    hidden
                    onChange={handleImageUpload4}
                    disabled={!isEditing}
                  />
                  <label
                    htmlFor="upload_file4"
                    className="flex items-center bg-[#4c4c4c] rounded-md w-full px-4 py-2 text-[#e2e2e2] cursor-pointer"
                  >
                    <span className="flex-grow">Upload</span>
                    <FiUpload className="text-[#FF6C2F]" />
                  </label>
                </div>
                <div className="w-[3rem] pl-2 max-h-[2.8rem] overflow-hidden">
                  {imageName4 && (
                    <a
                      href={imageURL4}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm text-[#FF6C2F]"
                      style={{
                        display: "inline-block",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                      }}
                    >
                      {imageName4}
                    </a>
                  )}
                </div>
              </div>
            </div>

            {/* Error message for second row */}
            {imageError && (
              <span className="block mt-2 text-red-500 text-sm">
                {imageError}
              </span>
            )}
          </div>

          <div>
            {isEditing ? (
              <div className="flex justify-end mt-[14%]">
                <button
                  className="text-[#FFFFFF] text-[100%] w-[6rem] h-[3rem] bg-[#6E6E6E] rounded-md hover:bg-[#484848] active:bg-[#484848]"
                  style={{
                    fontFamily: "Poppins, sanSerif",
                    padding: "8px 16px",
                    marginBottom: "40px",
                  }}
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>
                <button
                  className="mb-[10%] text-[#FFFFFF] text-[100%] w-[6rem] h-[3rem] bg-[#FF6C2F] rounded-md ml-[1%] hover:bg-[#d95a25] active:bg-[#c15122]"
                  style={{
                    fontFamily: "Poppins, sanSerif",
                    padding: "8px 16px",
                  }}
                  onClick={handleSaveClick}
                >
                  Save
                </button>
              </div>
            ) : (
              <div className="flex justify-end mt-[14%]">
                <button
                  className="text-[#FFFFFF] text-[100%] w-[6rem] h-[3rem] bg-[#FF6C2F] rounded-md mb-[10%] hover:bg-[#d95a25] active:bg-[#c15122]"
                  style={{
                    fontFamily: "Poppins, sanSerif",
                    padding: "8px 16px",
                  }}
                  onClick={handleEditClick}
                >
                  Edit
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
