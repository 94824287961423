import React, { useState } from 'react';
import { publicRouteLink } from '../constant/Routes';
import Popover from '@mui/material/Popover';
import Notifications from './notifications';
import ProfileDropdown from './profileDropdown';

const Navbar2 = () => {
    let Links = [
        { name: "Marketing Plans", link: publicRouteLink.MARKETING_PLANS },
        { name: "Releases", link: publicRouteLink.RELEASES },
        { name: "Royalty Reports", link: publicRouteLink.ROYALTY_REPORT },
        { name: "Uploads", link: publicRouteLink.UPLOADS },
    ];

    let [open, setOpen] = useState(false);
    const [anchorElNotification, setAnchorElNotification] = useState(null);
    const openNotification = Boolean(anchorElNotification);
    const [anchorElProfile, setAnchorElProfile] = useState(null);
    const openProfile = Boolean(anchorElProfile);

    const handleClose = () => {
        setAnchorElNotification(null);
    };

    const handleClickNotification = (event) => {
        setAnchorElNotification(event.currentTarget);
    }

    const handleProfileClick = (event) => {
        setAnchorElProfile(event.currentTarget);
    }

    return (
        <div className='shadow-md w-full sticky top-0 left-0'>
            <div className='md:flex items-center justify-between bg-black text-white py-4 md:px-10 px-7'>
                <div className='font-bold text-2xl cursor-pointer flex items-center font-[Poppins] text-gray-800'>
                    <span className=' mr-1 pt-2'>
                        <img src='\assets\HungamaLogo.png' alt='hungama-logo' />
                    </span>
                </div>

                <div onClick={() => setOpen(!open)} className='text-3xl absolute right-8 top-6 cursor-pointer md:hidden'>
                    <ion-icon name={open ? 'close' : 'menu'}></ion-icon>
                </div>

                <ul className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-black text-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-20 ' : 'top-[-490px]'}`}>
                    {
                        Links.map((link) => (
                            <li key={link.name} className='md:ml-8 text-[16.67px] font-[500] md:my-0 my-7'>
                                <a href={link.link} className='text-white duration-500'>{link.name}</a>
                            </li>
                        ))
                    }

                    <div className='md:ml-5 w-70 p-1 bg-black text-white overflow-hidden inline-flex justify-center items-center'>
                        <div className='w-110 h-110'>
                            <button onClick={handleClickNotification} className='w-6 h-6'>
                                <img src='assets\Doorbell.png' alt='notification-icon'></img>
                                <Popover
                                    open={openNotification}
                                    anchorEl={anchorElNotification}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    sx={{ marginTop: '2%' }}
                                >
                                    <Notifications></Notifications>
                                </Popover>
                            </button>
                        </div>
                    </div>
                    <div className='md:ml-2 w-70 p-1 bg-black text-[#FEC961] overflow-hidden inline-flex justify-center items-center'>
                        <div className='w-110 h-110'>
                            <button onClick={handleProfileClick} className='w-6 h-6'>
                                <ion-icon className='w-6 h-6' name="person-circle-sharp"></ion-icon>
                                {/* <img src='assets\user profile.jpg' alt='profile-icon'></img> */}
                                <Popover
                                    open={openProfile}
                                    anchorEl={anchorElProfile}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    sx={{ marginTop: '2%' }}
                                >
                                    <ProfileDropdown></ProfileDropdown>
                                </Popover>
                            </button>

                        </div>
                    </div>
                </ul>
            </div>
        </div>
    )
}

export default Navbar2;
