import React, { useState } from "react";

const Notifications = () => {
  const [open, setOpen] = useState(true);
  const notifications = [
    {
      title: 'Your release "Starlight" just got approve!',
      details: "Details about the notification",
      bgColor: "bg-lime-500",
    },
    {
      title: "Royalties for the quarter is here!",
      details: "Details about the notification",
      bgColor: "bg-zinc-400",
    },
    {
      title: "Your invoice has been generated",
      details: "Details about the notification",
      bgColor: "bg-zinc-400",
    },
    {
      title: 'Your release "Flow" got rejected',
      details: "Details about the notification",
      bgColor: "bg-red-600",
    },
  ];

  const handleClose = () => {
    // onClose && onClose();
    // setOpen(false);
  };

  return (
    <>
      {open && (
        <section className="flex flex-col justify-center text-lg text-white max-w-[584px]">
          <div className="flex flex-col px-6 pt-1 pb-5 w-full bg-black rounded-lg max-md:px-5 max-md:max-w-full">
            {notifications.map((item, index) => (
              <div
                key={index}
                className="flex flex-col w-full items-start py-2 mt-4 ${bgColor} bg-opacity-0 max-md:pr-5 max-md:max-w-full"
              >
                <p>{item.title}</p>
                <p>{item.details}</p>
              </div>
            ))}
            <div className="flex ">
              <button className="bg-[#FF6C2F] text-black w-24 mt-6 rounded-md h-10">
                View All
              </button>
              <button
                className="bg-[#FF6C2F] text-black w-24 mt-6 rounded-md h-10 ml-auto"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Notifications;
