import React, { useState } from "react";
import { useContent } from "../context/main.js";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function CoverImage() {
  const { contentType } = useContent();
  const navigate = useNavigate();
  const { file, setFile } = useContent();
  const [preview, setPreview] = useState("");

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const validImageTypes = ["image/jpeg", "image/png"];
      if (!validImageTypes.includes(selectedFile.type)) {
        alert("Invalid file type. Please select a JPG or PNG image.");
        setPreview("");
        setFile(null);
        return;
      }

      const img = new Image();
      const reader = new FileReader();

      reader.onloadend = () => {
        img.src = reader.result;
        img.onload = () => {
          if (img.width === 3000 && img.height === 3000) {
            setPreview(reader.result);
            setFile(selectedFile);
          } else {
            alert("Image dimensions must be 3000x3000 pixels.");
            setPreview("");
            setFile(null);
          }
        };
        img.onerror = () => {
          alert("Error loading image.");
          setPreview("");
          setFile(null);
        };
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSubmitClick = () => {
    if (!file) {
      alert("Please upload an image before proceeding.");
      return;
    }
    navigate("/upload-submission");
  };

  return (
    <div>
      <div className="flex">
        {contentType === "audio" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Audio</span>
          </h2>
        )}
        {contentType === "video" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Video</span>
          </h2>
        )}
      </div>
      <div
        className="container1"
        style={{ width: "auto", minHeight: "700px", height: "auto" }}
      >
        <div className="flex flex-col items-start profileBar w-[28%] bg-[#ffffff0d] rounded-md ">
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%] mt-[13%]">
            <Link to="/details-audio">Details</Link>
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/content-distribution">Content Distribution</Link>
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/terms-uploads">T&C</Link>
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/iprs">IPRS</Link>
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            {contentType === "audio" && (
              <Link to="/attach-audio">Attach Audio</Link>
            )}
            {contentType === "video" && (
              <Link to="/attach-audio">Attach Video</Link>
            )}
          </button>
          <br />
          <button className="text-[#FF6C2F] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/cover-image">Select Cover Image</Link>
          </button>
        </div>
        <div className=" mt-[4%] flex flex-col items-center justify-center w-[70%]">
          <div className="w-[35rem] bg-[#ffffff0d] rounded-md p-[5%] flex flex-row justify-between">
            <div className="flex flex-col justify-between">
              <div>
                {contentType === "audio" && (
                  <h2
                    className="text-[#FFFFFF] text-[0.9rem] mb-[2%]"
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  >
                    Upload Artwork
                  </h2>
                )}
                {contentType === "video" && (
                  <h2
                    className="text-[#FFFFFF] text-[0.9rem] mb-[2%]"
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  >
                    Upload Thumbnail
                  </h2>
                )}
              </div>
              <div>
                <button className="w-[8rem] h-[2.3rem] text-[#FFFFFF] bg-[#FF6C2F] rounded-md hover:bg-[#d95a25] active:bg-[#c15122] mb-[7%]">
                  <label htmlFor="file-upload">Choose File</label>
                </button>
                <input
                  id="file-upload"
                  type="file"
                  hidden
                  onChange={handleFileChange}
                  accept=".jpeg,.jpg,.png"
                />
              </div>
            </div>
            <div>
              {preview && (
                <img
                  src={preview}
                  alt="Preview"
                  style={{
                    width: "180px",
                    height: "180px",
                    borderRadius: "5px",
                  }}
                />
              )}
            </div>
          </div>
          <div className="w-[35rem] rounded-md my-4">
            {contentType === "audio" && (
              <div
                className="text-[#e1dddd] text-[0.8rem] mt-[2%] w-[100%] flex flex-col gap-4"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                <div className="flex flex-row gap-2">
                  <div className="w-[50%] flex flex-col items-center justify-center text-center bg-[#121111] rounded-md p-[10px] gap-2">
                    <span>Artwork Dimension should be</span>
                    <div className="flex flex-row items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlSpace="preserve"
                        className="w-[50px] h-[40px] m-0 shrink-0"
                        style={{
                          shapeRendering: "geometricPrecision",
                          textRendering: "geometricPrecision",
                          imageRendering: "optimizeQuality",
                          fillRule: "evenodd",
                          clipRule: "evenodd",
                        }}
                        viewBox="0 0 6.827 6.827"
                      >
                        <defs>
                          <style>{".fil0{fill:none}"}</style>
                        </defs>
                        <g id="Layer_x0020_1">
                          <g id="_371355568">
                            <path
                              id="_371356744"
                              className="fil0"
                              d="M0 0h6.827v6.827H0z"
                            />
                            <path
                              id="_371355616"
                              className="fil0"
                              d="M.853.853h5.12v5.12H.853z"
                            />
                          </g>
                          <path
                            d="M1.137.853H5.69a.283.283 0 0 1 .284.284V5.69a.283.283 0 0 1-.284.284H1.137a.283.283 0 0 1-.284-.284V1.137a.283.283 0 0 1 .284-.284zm4.552.214H1.137a.07.07 0 0 0-.05.02.07.07 0 0 0-.02.05V5.69c0 .02.008.037.02.05a.07.07 0 0 0 .05.021H5.69a.07.07 0 0 0 .05-.02.07.07 0 0 0 .021-.05V1.136a.07.07 0 0 0-.02-.05.07.07 0 0 0-.05-.02z"
                            style={{ fill: "#ffffff", fillRule: "nonzero" }}
                          />
                          <path
                            style={{ fill: "#FF6C2F", fillRule: "nonzero" }}
                            d="m1.173 3.356.325-.325.075-.075.076.075.538.538.538-.538.075-.075.075.075.538.538.538-.538.076-.075.075.075.538.538.538-.538.075-.075.076.075.324.325v.302l-.4-.4-.538.537-.075.076-.075-.076-.538-.538-.538.538-.076.076-.075-.076-.538-.538-.538.538-.075.076-.076-.076-.538-.538-.4.4z"
                          />
                        </g>
                      </svg>
                      <span className="text-[#FF6C2F] text-[1rem] font-semibold">
                        3000x3000 px
                      </span>
                    </div>
                  </div>
                  <div className=" flex flex-col items-center justify-center text-center bg-[#121111] rounded-md p-[10px] gap-2">
                    <span>
                      Please{" "}
                      <span className="text-[#FF6C2F] text-[0.8rem]">
                        add title{" "}
                      </span>
                      on the{" "}
                      <span className="text-[#FF6C2F] text-[0.8rem]">
                        artwork{" "}
                      </span>{" "}
                      in sync with the track name.
                    </span>
                  </div>
                  
                </div>
                <div className="flex flex-row gap-2">
                <div className=" flex flex-col text-center bg-[#121111] rounded-md p-[10px]">
                    <span>Please ensure there are no </span>
                    <span className="text-[#FF2F2F] text-[0.8rem]">
                      social media handles{" "}
                      <span className="text-slate-200">, </span>music platforms
                      logos <span className="text-slate-200"> and </span>{" "}
                      personal <span className="text-slate-200"> or </span>{" "}
                      office contact details.
                    </span>{" "}
                  </div>
                  <div className="flex flex-col text-center rounded-md bg-[#121111] px-[14px] py-[10px]">
                      <span>File Format</span>
                      <span className="text-[#FF6C2F] text-[1.2rem] font-semibold">
                        .JPEG/.PNG
                      </span>
                    </div>
                </div>
              </div>
            )}

            {contentType === "video" && (
               <div
               className="text-[#e1dddd] text-[0.8rem] mt-[2%] w-[100%] flex flex-col gap-4"
               style={{ fontFamily: "Poppins, sans-serif" }}
             >
               <div className="flex flex-row gap-2">
                 <div className="w-[50%] flex flex-col items-center justify-center text-center bg-[#121111] rounded-md p-[10px] gap-2">
                   <span>Artwork Dimension should be</span>
                   <div className="flex flex-row items-center">
                     <svg
                       xmlns="http://www.w3.org/2000/svg"
                       xmlSpace="preserve"
                       className="w-[50px] h-[40px] m-0 shrink-0"
                       style={{
                         shapeRendering: "geometricPrecision",
                         textRendering: "geometricPrecision",
                         imageRendering: "optimizeQuality",
                         fillRule: "evenodd",
                         clipRule: "evenodd",
                       }}
                       viewBox="0 0 6.827 6.827"
                     >
                       <defs>
                         <style>{".fil0{fill:none}"}</style>
                       </defs>
                       <g id="Layer_x0020_1">
                         <g id="_371355568">
                           <path
                             id="_371356744"
                             className="fil0"
                             d="M0 0h6.827v6.827H0z"
                           />
                           <path
                             id="_371355616"
                             className="fil0"
                             d="M.853.853h5.12v5.12H.853z"
                           />
                         </g>
                         <path
                           d="M1.137.853H5.69a.283.283 0 0 1 .284.284V5.69a.283.283 0 0 1-.284.284H1.137a.283.283 0 0 1-.284-.284V1.137a.283.283 0 0 1 .284-.284zm4.552.214H1.137a.07.07 0 0 0-.05.02.07.07 0 0 0-.02.05V5.69c0 .02.008.037.02.05a.07.07 0 0 0 .05.021H5.69a.07.07 0 0 0 .05-.02.07.07 0 0 0 .021-.05V1.136a.07.07 0 0 0-.02-.05.07.07 0 0 0-.05-.02z"
                           style={{ fill: "#ffffff", fillRule: "nonzero" }}
                         />
                         <path
                           style={{ fill: "#FF6C2F", fillRule: "nonzero" }}
                           d="m1.173 3.356.325-.325.075-.075.076.075.538.538.538-.538.075-.075.075.075.538.538.538-.538.076-.075.075.075.538.538.538-.538.075-.075.076.075.324.325v.302l-.4-.4-.538.537-.075.076-.075-.076-.538-.538-.538.538-.076.076-.075-.076-.538-.538-.538.538-.075.076-.076-.076-.538-.538-.4.4z"
                         />
                       </g>
                     </svg>
                     <span className="text-[#FF6C2F] text-[1rem] font-semibold">
                       3000x3000 px
                     </span>
                   </div>
                 </div>
                 <div className=" flex flex-col items-center justify-center text-center bg-[#121111] rounded-md p-[10px] gap-2">
                   <span>
                     Please{" "}
                     <span className="text-[#FF6C2F] text-[0.8rem]">
                       add title{" "}
                     </span>
                     on the{" "}
                     <span className="text-[#FF6C2F] text-[0.8rem]">
                       artwork{" "}
                     </span>{" "}
                     in sync with the track name.
                   </span>
                 </div>
                 
               </div>
               <div className="flex flex-row gap-2">
               <div className=" flex flex-col text-center bg-[#121111] rounded-md p-[10px]">
                   <span>Please ensure there are no </span>
                   <span className="text-[#FF2F2F] text-[0.8rem]">
                     social media handles{" "}
                     <span className="text-slate-200">, </span>music platforms
                     logos <span className="text-slate-200"> and </span>{" "}
                     personal <span className="text-slate-200"> or </span>{" "}
                     office contact details.
                   </span>{" "}
                 </div>
                 <div className="flex flex-col text-center rounded-md bg-[#121111] px-[14px] py-[10px]">
                     <span>File Format</span>
                     <span className="text-[#FF6C2F] text-[1.2rem] font-semibold">
                       .JPEG/.PNG
                     </span>
                   </div>
               </div>
             </div>
            )}
          </div>
          <div
            className="mb-[5%] w-[80%]"
            style={{
              flexGrow: "8",
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
            }}
          >
            <button
              className="text-[#FFFFFF] w-[6rem] h-[3rem] bg-[#FF6C2F] rounded-md hover:bg-[#d95a25] active:bg-[#c15122]"
              style={{
                fontFamily: "Poppins, sanSerif",
              }}
              onClick={handleSubmitClick}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
