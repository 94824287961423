import React from "react";

const AboutUs = () => { 
    return (
        <>
            <div className="w-full bg-[#1A1A1A] py-16 px-20">
                <h1 className="pb-16 text-[33px] text-white font-medium">About <span className="text-[#FEC961]" >Us</span></h1>
                <div className="flex">
                    <div className="w-2/5 h-[500px] relative transition">
                        <img src="assets\Aboutus-bg.png" alt="" className="w-full h-full" />
                        <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center">
                            <img src="assets\logo-img.png" alt="" className="w-4/5 h-4/5 object-contain" />
                        </div>
                    </div>
                    <div className="w-3/5 flex items-center px-20 bg-[#00000040] rounded-lg">
                        <div className="text-base text-white font-medium">
                            <p >Welcome to the B2B website of <span className="text-[#FF6C2F]">Hungama Artist Aloud!</span> This is the place where YOU as an artist can share with us your creativity for Acquisition and Distribution.</p>
                            <p className="pt-5"><span className="text-[#FF6C2F]">Hungama Artist Aloud</span> is a talent and independent content platform for both, Independent content makers and fans of Independent content. We promote Musicians, Bands, Singers, Composers, Instrumentalists, Comedians, Dancers, etc. who create commercial non-film content and we help them distribute their original content, enabling them to reach out to their fans. And for the fans, we offer a huge library of Audio and Video content that's diverse in Genre, Language and style, making it suitable for tastes of all kind through our B2C platform, www.HungamaArtistAloud.com!</p>
                            <p className="pt-5">We work with Talent, established and upcoming and we put them in the spotlight with innovative music and entertainment content formats and IPs. We are a dedicated team and we do all this because for us, it's 'Talent First'.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutUs;