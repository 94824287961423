import React, { useState } from "react";
import { Link } from "react-router-dom";
import { publicRouteLink } from '../constant/Routes'

const Dashboard = () => {
    const [isNewUser, setNewUser] = useState(false);
    const [tdata, setTData] = useState([
        {
            content_name: 'Track title',
            type: 'Audio',
            release_date: '03/02/2024',
            status: 'Approved'
        },
        {
            content_name: 'Track title',
            type: 'Audio',
            release_date: '03/02/2024',
            status: 'Live'
        },
        {
            content_name: 'Track title',
            type: 'Video',
            release_date: '03/02/2024',
            status: 'Draft'
        },
        {
            content_name: 'Track title',
            type: 'Audio',
            release_date: '03/02/2024',
            status: 'Rejected'
        },
        {
            content_name: 'Track title',
            type: 'Video',
            release_date: '03/02/2024',
            status: 'Live'
        }
    ]);
    const [selectedType, setSelectedType] = useState('All');
    const [selectedStatus, setSelectedStatus] = useState('All');
    
    const filteredData = tdata.filter(item => {
        if (selectedType !== 'All' && selectedStatus !== 'All') {
            return item.type === selectedType && item.status === selectedStatus;
        } else if (selectedType !== 'All') {
            return item.type === selectedType;
        } else if (selectedStatus !== 'All') {
            return item.status === selectedStatus;
        } else {
            return true;
        }
    });
    const [isOpen, setIsOpen] = useState(false);
    const [statusIsOpen, setStatusIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const statusToggleDropdown = () => {
        setStatusIsOpen(!statusIsOpen);
    }

    return (
        <>
            <div className="bg-[#252525] w-full pb-20">
                <div className="flex flex-col md:flex-row pt-10 md:pt-20 px-10 md:px-20 gap-10 w-full items-end md:items-start">
                    <div className="w-full md:w-1/5">
                        <p className="text-white text-2xl font-medium ">Hello user</p>
                        <div className=" bg-[#FEC961] p-2 rounded h-20 w-60">
                            <p className="text-xs mb-4">Total Earnings</p>
                            <p className="text-3xl font-medium">00.0
                                <span className="text-xs">INR</span>
                            </p>
                        </div>
                    </div>

                    <div className="rounded bg-[#FF6C2F] px-4 h-20 flex items-center w-full md:w-4/5 md:mt-auto md:ml-5">
                        <p className="text-2xl font-medium gap-y-14">Create your first release now!</p>
                        <button className="flex items-center ml-auto bg-white text-black rounded p-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            New Release
                        </button>
                    </div>
                </div>

                <div className="container">
                    {isNewUser &&
                        <div className="text-white">
                            < div className="font-medium text-2xl ml-20 mt-20">
                                Your Releases
                            </div>
                            <div className="pb-20">
                                <div className="w-11/12 h-72 bg-[#313131] ml-20 mt-2.5 rounded flex items-center justify-center">
                                    <p className="text-center font-bold">No releases yet, create now</p>
                                </div>
                            </div>
                        </div>
                    }

                    {!isNewUser &&
                        <div className="text-white w-full px-10 md:px-20">
                            <div className="flex mt-20 w-full justify-between items-center">
                                < div className="font-medium text-2xl ">
                                    Your Releases
                                </div>
                                <Link to={publicRouteLink.RELEASES}>
                                    <button className="border-none text-right">View All</button>
                                </Link>
                            </div>

                            <table className="table-auto w-full justify-between sm:justify-center">
                                <thead className="">
                                    <tr className="bg-[#E8E8E8] text-black text-left h-11">
                                        <th className="px-2 rounded-tl-lg rounded-bl-lg">Content Name</th>
                                        <th>
                                            <button onClick={toggleDropdown} className="flex items-center">
                                                Type<svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg>
                                            </button>

                                            {isOpen && (
                                                <div className="absolute mt-3 w-24 text-center bg-[#252525] rounded-md shadow-sm">
                                                    <ul className="flex flex-col space-y-2 p-2">
                                                        <li className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                                                            onClick={() => { setSelectedType("All"); toggleDropdown() }}>All</li>
                                                        <li className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                                                            onClick={() => { setSelectedType("Audio"); toggleDropdown() }}>Audio</li>
                                                        <li className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                                                            onClick={() => { setSelectedType("Video"); toggleDropdown() }}> Video</li>
                                                    </ul>
                                                </div>
                                            )}
                                        </th>
                                        <th>Release Date</th>
                                        <th>
                                            <button onClick={statusToggleDropdown} className="flex items-center">
                                                Status
                                                <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7">
                                                    </path>
                                                </svg>
                                            </button>
                                            {statusIsOpen && (
                                                <div className="absolute mt-3 w-32 text-center bg-[#252525] rounded-md shadow-sm">
                                                    <ul className="flex flex-col space-y-2 p-2">
                                                        <li className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                                                            onClick={() => { setSelectedStatus("All"); statusToggleDropdown() }}>
                                                            All
                                                        </li>
                                                        <li className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                                                            onClick={() => { setSelectedStatus("Approved"); statusToggleDropdown() }}>
                                                            Approved
                                                        </li>
                                                        <li className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                                                            onClick={() => { setSelectedStatus("Disapproved"); statusToggleDropdown() }}>
                                                            Disapproved
                                                        </li>
                                                        <li className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                                                            onClick={() => { setSelectedStatus("Live"); statusToggleDropdown() }}>
                                                            Live
                                                        </li>
                                                        <li className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                                                            onClick={() => { setSelectedStatus("Draft"); statusToggleDropdown() }}>
                                                            Draft
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}
                                        </th>
                                        <th className="rounded-tr-lg rounded-br-lg">Action</th>
                                    </tr>
                                </thead>
                                <tbody className="text-start bg-[#313131] rounded-bl-lg rounded-br-lg">
                                    {filteredData.map((item, index) => (
                                        <tr key={index} className="">
                                            <td className="px-2 py-3">{item.content_name}</td>
                                            <td className="py-3">{item.type}</td>
                                            <td className="py-3">{item.release_date}</td>
                                            <td className="py-3">
                                                {(item.status === 'Live' || item.status === 'Approved') &&
                                                    <div className="flex items-center gap-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#2fd100" className="w-6 h-6">
                                                            <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
                                                        </svg>
                                                        {item.status}
                                                    </div>
                                                }
                                                {(item.status === 'Draft') &&
                                                    <div className="flex items-center gap-2">
                                                        <img src="assets\Edit File.svg" alt="edit-icon"></img>
                                                        {item.status}
                                                    </div>
                                                }
                                                {(item.status === 'Rejected') &&
                                                    <div className="flex items-center gap-2">
                                                        <img src="assets\Box Important.svg" alt="reject-icon"></img>
                                                        {item.status}
                                                    </div>
                                                }
                                            </td>
                                            <td className="py-3">
                                                {(item.status === 'Live' || item.status === 'Approved') &&
                                                    <button>
                                                        Request
                                                    </button>

                                                }
                                                {(item.status === 'Rejected' || item.status === 'Draft') &&
                                                    <button>
                                                        Edit
                                                    </button>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default Dashboard;