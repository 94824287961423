import React, { useState, useContext } from 'react';
import axios from "axios";
// import { Link } from 'react-router-dom';
import { publicRouteLink } from '../constant/Routes';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { MainContext } from '../context/main';
import { toast } from "react-hot-toast";
import { useNavigate } from 'react-router-dom';

export const ChangeNumber = () => {
    const [ph, setPh] = useState("");
    const { countryCode, setCountryCode, mob, setMob } = useContext(MainContext);
    const navigate = useNavigate();

    const handlePhChange = (phone, country) => {
        const newPh = phone.split(country.dialCode);
        setPh(phone);
        setMob(newPh[1]);
        setCountryCode(country.dialCode);
    }

    const sendOTP = async () => {
        try {
            const url = 'https://haartistaloud-backend.kollywoodhungama.com/v2/send-otp';

            const resp = await axios({
                url: url,
                method: "POST",
                headers: {
                    "Content-type": "application/json"
                },
                data: {
                    "mob": mob,
                    "countryCode": "+" + countryCode,
                }
            });

            if (resp.data.statusCode === 200) {
                toast.success("OTP sent successfully!");
                navigate(publicRouteLink.OTP_VERIFICATION);
                return;
            }
            else {
                toast.error("OTP not sent. Please try again!");
                return;
            }
        }
        catch (error) {
            console.log("Error from sendOTP function:", error);
            return
        }
    }

    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <div className='flex'>
                <div className='w-80 mt-9'>
                    <h2 className="text-3xl font-semibold mb-3 text-[#FF6C2F]">Change phone number</h2>
                    <p className='text-[#181818] text-sm font-medium mb-7 break-words'>Please enter a valid phone number</p>
                    <PhoneInput inputClass="w-full" country={"in"} value={ph} onChange={handlePhChange} required className="text-black" />

                    <button className="bg-[#FF6C2F] text-white text-sm font-normal px-4 py-2 mb-8 mt-4 rounded-md w-80 h-12"
                        onClick={sendOTP}
                    >
                        Send code
                    </button>
                    {/* <Link to={publicRouteLink.OTP_VERIFICATION}>
                        <button className="bg-[#FF6C2F] text-white text-sm font-normal px-4 py-2 mb-8 mt-4 rounded-md w-80 h-12"
                            onClick={handleClick}
                        >
                            Send code
                        </button>
                    </Link> */}
                </div>

                <div className='ml-16'>
                    <img src='assets\verification-pic.png' alt='verification-pic' className='' />
                </div>
            </div>
        </div>
    )
}
