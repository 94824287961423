export const state = [
    {
        "id": 1,
        "states": [
            {
                "id": 3901,
                "name": "Badakhshan",
                "state_code": "BDS"
            },
            {
                "id": 3871,
                "name": "Badghis",
                "state_code": "BDG"
            },
            {
                "id": 3875,
                "name": "Baghlan",
                "state_code": "BGL"
            },
            {
                "id": 3884,
                "name": "Balkh",
                "state_code": "BAL"
            },
            {
                "id": 3872,
                "name": "Bamyan",
                "state_code": "BAM"
            },
            {
                "id": 3892,
                "name": "Daykundi",
                "state_code": "DAY"
            },
            {
                "id": 3899,
                "name": "Farah",
                "state_code": "FRA"
            },
            {
                "id": 3889,
                "name": "Faryab",
                "state_code": "FYB"
            },
            {
                "id": 3870,
                "name": "Ghazni",
                "state_code": "GHA"
            },
            {
                "id": 3888,
                "name": "Ghōr",
                "state_code": "GHO"
            },
            {
                "id": 3873,
                "name": "Helmand",
                "state_code": "HEL"
            },
            {
                "id": 3887,
                "name": "Herat",
                "state_code": "HER"
            },
            {
                "id": 3886,
                "name": "Jowzjan",
                "state_code": "JOW"
            },
            {
                "id": 3902,
                "name": "Kabul",
                "state_code": "KAB"
            },
            {
                "id": 3890,
                "name": "Kandahar",
                "state_code": "KAN"
            },
            {
                "id": 3879,
                "name": "Kapisa",
                "state_code": "KAP"
            },
            {
                "id": 3878,
                "name": "Khost",
                "state_code": "KHO"
            },
            {
                "id": 3876,
                "name": "Kunar",
                "state_code": "KNR"
            },
            {
                "id": 3900,
                "name": "Kunduz Province",
                "state_code": "KDZ"
            },
            {
                "id": 3891,
                "name": "Laghman",
                "state_code": "LAG"
            },
            {
                "id": 3897,
                "name": "Logar",
                "state_code": "LOG"
            },
            {
                "id": 3882,
                "name": "Nangarhar",
                "state_code": "NAN"
            },
            {
                "id": 3896,
                "name": "Nimruz",
                "state_code": "NIM"
            },
            {
                "id": 3880,
                "name": "Nuristan",
                "state_code": "NUR"
            },
            {
                "id": 3894,
                "name": "Paktia",
                "state_code": "PIA"
            },
            {
                "id": 3877,
                "name": "Paktika",
                "state_code": "PKA"
            },
            {
                "id": 3881,
                "name": "Panjshir",
                "state_code": "PAN"
            },
            {
                "id": 3895,
                "name": "Parwan",
                "state_code": "PAR"
            },
            {
                "id": 3883,
                "name": "Samangan",
                "state_code": "SAM"
            },
            {
                "id": 3885,
                "name": "Sar-e Pol",
                "state_code": "SAR"
            },
            {
                "id": 3893,
                "name": "Takhar",
                "state_code": "TAK"
            },
            {
                "id": 3898,
                "name": "Urozgan",
                "state_code": "URU"
            },
            {
                "id": 3874,
                "name": "Zabul",
                "state_code": "ZAB"
            }
        ]
    },
    {
        "id": 2,
        "states": []
    },
    {
        "id": 3,
        "states": [
            {
                "id": 603,
                "name": "Berat County",
                "state_code": "01"
            },
            {
                "id": 629,
                "name": "Berat District",
                "state_code": "BR"
            },
            {
                "id": 607,
                "name": "Bulqizë District",
                "state_code": "BU"
            },
            {
                "id": 618,
                "name": "Delvinë District",
                "state_code": "DL"
            },
            {
                "id": 608,
                "name": "Devoll District",
                "state_code": "DV"
            },
            {
                "id": 610,
                "name": "Dibër County",
                "state_code": "09"
            },
            {
                "id": 605,
                "name": "Dibër District",
                "state_code": "DI"
            },
            {
                "id": 632,
                "name": "Durrës County",
                "state_code": "02"
            },
            {
                "id": 639,
                "name": "Durrës District",
                "state_code": "DR"
            },
            {
                "id": 598,
                "name": "Elbasan County",
                "state_code": "03"
            },
            {
                "id": 631,
                "name": "Fier County",
                "state_code": "04"
            },
            {
                "id": 627,
                "name": "Fier District",
                "state_code": "FR"
            },
            {
                "id": 604,
                "name": "Gjirokastër County",
                "state_code": "05"
            },
            {
                "id": 621,
                "name": "Gjirokastër District",
                "state_code": "GJ"
            },
            {
                "id": 617,
                "name": "Gramsh District",
                "state_code": "GR"
            },
            {
                "id": 600,
                "name": "Has District",
                "state_code": "HA"
            },
            {
                "id": 594,
                "name": "Kavajë District",
                "state_code": "KA"
            },
            {
                "id": 628,
                "name": "Kolonjë District",
                "state_code": "ER"
            },
            {
                "id": 630,
                "name": "Korçë County",
                "state_code": "06"
            },
            {
                "id": 597,
                "name": "Korçë District",
                "state_code": "KO"
            },
            {
                "id": 614,
                "name": "Krujë District",
                "state_code": "KR"
            },
            {
                "id": 612,
                "name": "Kuçovë District",
                "state_code": "KC"
            },
            {
                "id": 601,
                "name": "Kukës County",
                "state_code": "07"
            },
            {
                "id": 623,
                "name": "Kukës District",
                "state_code": "KU"
            },
            {
                "id": 622,
                "name": "Kurbin District",
                "state_code": "KB"
            },
            {
                "id": 609,
                "name": "Lezhë County",
                "state_code": "08"
            },
            {
                "id": 595,
                "name": "Lezhë District",
                "state_code": "LE"
            },
            {
                "id": 596,
                "name": "Librazhd District",
                "state_code": "LB"
            },
            {
                "id": 599,
                "name": "Lushnjë District",
                "state_code": "LU"
            },
            {
                "id": 602,
                "name": "Malësi e Madhe District",
                "state_code": "MM"
            },
            {
                "id": 637,
                "name": "Mallakastër District",
                "state_code": "MK"
            },
            {
                "id": 635,
                "name": "Mat District",
                "state_code": "MT"
            },
            {
                "id": 638,
                "name": "Mirditë District",
                "state_code": "MR"
            },
            {
                "id": 619,
                "name": "Peqin District",
                "state_code": "PQ"
            },
            {
                "id": 625,
                "name": "Përmet District",
                "state_code": "PR"
            },
            {
                "id": 606,
                "name": "Pogradec District",
                "state_code": "PG"
            },
            {
                "id": 620,
                "name": "Pukë District",
                "state_code": "PU"
            },
            {
                "id": 624,
                "name": "Sarandë District",
                "state_code": "SR"
            },
            {
                "id": 611,
                "name": "Shkodër County",
                "state_code": "10"
            },
            {
                "id": 626,
                "name": "Shkodër District",
                "state_code": "SH"
            },
            {
                "id": 593,
                "name": "Skrapar District",
                "state_code": "SK"
            },
            {
                "id": 616,
                "name": "Tepelenë District",
                "state_code": "TE"
            },
            {
                "id": 615,
                "name": "Tirana County",
                "state_code": "11"
            },
            {
                "id": 633,
                "name": "Tirana District",
                "state_code": "TR"
            },
            {
                "id": 636,
                "name": "Tropojë District",
                "state_code": "TP"
            },
            {
                "id": 634,
                "name": "Vlorë County",
                "state_code": "12"
            },
            {
                "id": 613,
                "name": "Vlorë District",
                "state_code": "VL"
            }
        ]
    },
    {
        "id": 4,
        "states": [
            {
                "id": 1118,
                "name": "Adrar",
                "state_code": "01"
            },
            {
                "id": 1119,
                "name": "Aïn Defla",
                "state_code": "44"
            },
            {
                "id": 1122,
                "name": "Aïn Témouchent",
                "state_code": "46"
            },
            {
                "id": 1144,
                "name": "Algiers",
                "state_code": "16"
            },
            {
                "id": 1103,
                "name": "Annaba",
                "state_code": "23"
            },
            {
                "id": 1142,
                "name": "Batna",
                "state_code": "05"
            },
            {
                "id": 1108,
                "name": "Béchar",
                "state_code": "08"
            },
            {
                "id": 1128,
                "name": "Béjaïa",
                "state_code": "06"
            },
            {
                "id": 4909,
                "name": "Béni Abbès",
                "state_code": "53"
            },
            {
                "id": 1114,
                "name": "Biskra",
                "state_code": "07"
            },
            {
                "id": 1111,
                "name": "Blida",
                "state_code": "09"
            },
            {
                "id": 4908,
                "name": "Bordj Baji Mokhtar",
                "state_code": "52"
            },
            {
                "id": 1116,
                "name": "Bordj Bou Arréridj",
                "state_code": "34"
            },
            {
                "id": 1104,
                "name": "Bouïra",
                "state_code": "10"
            },
            {
                "id": 1125,
                "name": "Boumerdès",
                "state_code": "35"
            },
            {
                "id": 1105,
                "name": "Chlef",
                "state_code": "02"
            },
            {
                "id": 1121,
                "name": "Constantine",
                "state_code": "25"
            },
            {
                "id": 4912,
                "name": "Djanet",
                "state_code": "56"
            },
            {
                "id": 1098,
                "name": "Djelfa",
                "state_code": "17"
            },
            {
                "id": 1129,
                "name": "El Bayadh",
                "state_code": "32"
            },
            {
                "id": 4905,
                "name": "El M'ghair",
                "state_code": "49"
            },
            {
                "id": 4906,
                "name": "El Menia",
                "state_code": "50"
            },
            {
                "id": 1099,
                "name": "El Oued",
                "state_code": "39"
            },
            {
                "id": 1100,
                "name": "El Tarf",
                "state_code": "36"
            },
            {
                "id": 1127,
                "name": "Ghardaïa",
                "state_code": "47"
            },
            {
                "id": 1137,
                "name": "Guelma",
                "state_code": "24"
            },
            {
                "id": 1112,
                "name": "Illizi",
                "state_code": "33"
            },
            {
                "id": 4914,
                "name": "In Guezzam",
                "state_code": "58"
            },
            {
                "id": 4913,
                "name": "In Salah",
                "state_code": "57"
            },
            {
                "id": 1113,
                "name": "Jijel",
                "state_code": "18"
            },
            {
                "id": 1126,
                "name": "Khenchela",
                "state_code": "40"
            },
            {
                "id": 1138,
                "name": "Laghouat",
                "state_code": "03"
            },
            {
                "id": 1134,
                "name": "M'Sila",
                "state_code": "28"
            },
            {
                "id": 1124,
                "name": "Mascara",
                "state_code": "29"
            },
            {
                "id": 1109,
                "name": "Médéa",
                "state_code": "26"
            },
            {
                "id": 1132,
                "name": "Mila",
                "state_code": "43"
            },
            {
                "id": 1140,
                "name": "Mostaganem",
                "state_code": "27"
            },
            {
                "id": 1102,
                "name": "Naama",
                "state_code": "45"
            },
            {
                "id": 1101,
                "name": "Oran",
                "state_code": "31"
            },
            {
                "id": 1139,
                "name": "Ouargla",
                "state_code": "30"
            },
            {
                "id": 4907,
                "name": "Ouled Djellal",
                "state_code": "51"
            },
            {
                "id": 1136,
                "name": "Oum El Bouaghi",
                "state_code": "04"
            },
            {
                "id": 1130,
                "name": "Relizane",
                "state_code": "48"
            },
            {
                "id": 1123,
                "name": "Saïda",
                "state_code": "20"
            },
            {
                "id": 1141,
                "name": "Sétif",
                "state_code": "19"
            },
            {
                "id": 4902,
                "name": "Sidi Bel Abbès",
                "state_code": "22"
            },
            {
                "id": 1110,
                "name": "Skikda",
                "state_code": "21"
            },
            {
                "id": 1143,
                "name": "Souk Ahras",
                "state_code": "41"
            },
            {
                "id": 1135,
                "name": "Tamanghasset",
                "state_code": "11"
            },
            {
                "id": 1117,
                "name": "Tébessa",
                "state_code": "12"
            },
            {
                "id": 1106,
                "name": "Tiaret",
                "state_code": "14"
            },
            {
                "id": 4910,
                "name": "Timimoun",
                "state_code": "54"
            },
            {
                "id": 1120,
                "name": "Tindouf",
                "state_code": "37"
            },
            {
                "id": 1115,
                "name": "Tipasa",
                "state_code": "42"
            },
            {
                "id": 1133,
                "name": "Tissemsilt",
                "state_code": "38"
            },
            {
                "id": 1131,
                "name": "Tizi Ouzou",
                "state_code": "15"
            },
            {
                "id": 1107,
                "name": "Tlemcen",
                "state_code": "13"
            },
            {
                "id": 4911,
                "name": "Touggourt",
                "state_code": "55"
            }
        ]
    },
    {
        "id": 5,
        "states": []
    },
    {
        "id": 6,
        "states": [
            {
                "id": 488,
                "name": "Andorra la Vella",
                "state_code": "07"
            },
            {
                "id": 489,
                "name": "Canillo",
                "state_code": "02"
            },
            {
                "id": 487,
                "name": "Encamp",
                "state_code": "03"
            },
            {
                "id": 492,
                "name": "Escaldes-Engordany",
                "state_code": "08"
            },
            {
                "id": 493,
                "name": "La Massana",
                "state_code": "04"
            },
            {
                "id": 491,
                "name": "Ordino",
                "state_code": "05"
            },
            {
                "id": 490,
                "name": "Sant Julià de Lòria",
                "state_code": "06"
            }
        ]
    },
    {
        "id": 7,
        "states": [
            {
                "id": 221,
                "name": "Bengo Province",
                "state_code": "BGO"
            },
            {
                "id": 218,
                "name": "Benguela Province",
                "state_code": "BGU"
            },
            {
                "id": 212,
                "name": "Bié Province",
                "state_code": "BIE"
            },
            {
                "id": 228,
                "name": "Cabinda Province",
                "state_code": "CAB"
            },
            {
                "id": 226,
                "name": "Cuando Cubango Province",
                "state_code": "CCU"
            },
            {
                "id": 217,
                "name": "Cuanza Norte Province",
                "state_code": "CNO"
            },
            {
                "id": 216,
                "name": "Cuanza Sul",
                "state_code": "CUS"
            },
            {
                "id": 215,
                "name": "Cunene Province",
                "state_code": "CNN"
            },
            {
                "id": 213,
                "name": "Huambo Province",
                "state_code": "HUA"
            },
            {
                "id": 225,
                "name": "Huíla Province",
                "state_code": "HUI"
            },
            {
                "id": 222,
                "name": "Luanda Province",
                "state_code": "LUA"
            },
            {
                "id": 223,
                "name": "Lunda Norte Province",
                "state_code": "LNO"
            },
            {
                "id": 220,
                "name": "Lunda Sul Province",
                "state_code": "LSU"
            },
            {
                "id": 227,
                "name": "Malanje Province",
                "state_code": "MAL"
            },
            {
                "id": 219,
                "name": "Moxico Province",
                "state_code": "MOX"
            },
            {
                "id": 224,
                "name": "Uíge Province",
                "state_code": "UIG"
            },
            {
                "id": 214,
                "name": "Zaire Province",
                "state_code": "ZAI"
            }
        ]
    },
    {
        "id": 8,
        "states": []
    },
    {
        "id": 9,
        "states": []
    },
    {
        "id": 10,
        "states": [
            {
                "id": 3708,
                "name": "Barbuda",
                "state_code": "10"
            },
            {
                "id": 3703,
                "name": "Redonda",
                "state_code": "11"
            },
            {
                "id": 3709,
                "name": "Saint George Parish",
                "state_code": "03"
            },
            {
                "id": 3706,
                "name": "Saint John Parish",
                "state_code": "04"
            },
            {
                "id": 3707,
                "name": "Saint Mary Parish",
                "state_code": "05"
            },
            {
                "id": 3705,
                "name": "Saint Paul Parish",
                "state_code": "06"
            },
            {
                "id": 3704,
                "name": "Saint Peter Parish",
                "state_code": "07"
            },
            {
                "id": 3710,
                "name": "Saint Philip Parish",
                "state_code": "08"
            }
        ]
    },
    {
        "id": 11,
        "states": [
            {
                "id": 3656,
                "name": "Buenos Aires",
                "state_code": "B"
            },
            {
                "id": 3647,
                "name": "Catamarca",
                "state_code": "K"
            },
            {
                "id": 3640,
                "name": "Chaco",
                "state_code": "H"
            },
            {
                "id": 3651,
                "name": "Chubut",
                "state_code": "U"
            },
            {
                "id": 4880,
                "name": "Ciudad Autónoma de Buenos Aires",
                "state_code": "C"
            },
            {
                "id": 3642,
                "name": "Córdoba",
                "state_code": "X"
            },
            {
                "id": 3638,
                "name": "Corrientes",
                "state_code": "W"
            },
            {
                "id": 3654,
                "name": "Entre Ríos",
                "state_code": "E"
            },
            {
                "id": 3652,
                "name": "Formosa",
                "state_code": "P"
            },
            {
                "id": 3645,
                "name": "Jujuy",
                "state_code": "Y"
            },
            {
                "id": 3655,
                "name": "La Pampa",
                "state_code": "L"
            },
            {
                "id": 3653,
                "name": "La Rioja",
                "state_code": "F"
            },
            {
                "id": 3646,
                "name": "Mendoza",
                "state_code": "M"
            },
            {
                "id": 3644,
                "name": "Misiones",
                "state_code": "N"
            },
            {
                "id": 3648,
                "name": "Neuquén",
                "state_code": "Q"
            },
            {
                "id": 3639,
                "name": "Río Negro",
                "state_code": "R"
            },
            {
                "id": 3643,
                "name": "Salta",
                "state_code": "A"
            },
            {
                "id": 3634,
                "name": "San Juan",
                "state_code": "J"
            },
            {
                "id": 3636,
                "name": "San Luis",
                "state_code": "D"
            },
            {
                "id": 3649,
                "name": "Santa Cruz",
                "state_code": "Z"
            },
            {
                "id": 3641,
                "name": "Santa Fe",
                "state_code": "S"
            },
            {
                "id": 3635,
                "name": "Santiago del Estero",
                "state_code": "G"
            },
            {
                "id": 3650,
                "name": "Tierra del Fuego",
                "state_code": "V"
            },
            {
                "id": 3637,
                "name": "Tucumán",
                "state_code": "T"
            }
        ]
    },
    {
        "id": 12,
        "states": [
            {
                "id": 2023,
                "name": "Aragatsotn Region",
                "state_code": "AG"
            },
            {
                "id": 2024,
                "name": "Ararat Province",
                "state_code": "AR"
            },
            {
                "id": 2026,
                "name": "Armavir Region",
                "state_code": "AV"
            },
            {
                "id": 2028,
                "name": "Gegharkunik Province",
                "state_code": "GR"
            },
            {
                "id": 2033,
                "name": "Kotayk Region",
                "state_code": "KT"
            },
            {
                "id": 2029,
                "name": "Lori Region",
                "state_code": "LO"
            },
            {
                "id": 2031,
                "name": "Shirak Region",
                "state_code": "SH"
            },
            {
                "id": 2027,
                "name": "Syunik Province",
                "state_code": "SU"
            },
            {
                "id": 2032,
                "name": "Tavush Region",
                "state_code": "TV"
            },
            {
                "id": 2025,
                "name": "Vayots Dzor Region",
                "state_code": "VD"
            },
            {
                "id": 2030,
                "name": "Yerevan",
                "state_code": "ER"
            }
        ]
    },
    {
        "id": 13,
        "states": []
    },
    {
        "id": 14,
        "states": [
            {
                "id": 3907,
                "name": "Australian Capital Territory",
                "state_code": "ACT"
            },
            {
                "id": 3909,
                "name": "New South Wales",
                "state_code": "NSW"
            },
            {
                "id": 3910,
                "name": "Northern Territory",
                "state_code": "NT"
            },
            {
                "id": 3905,
                "name": "Queensland",
                "state_code": "QLD"
            },
            {
                "id": 3904,
                "name": "South Australia",
                "state_code": "SA"
            },
            {
                "id": 3908,
                "name": "Tasmania",
                "state_code": "TAS"
            },
            {
                "id": 3903,
                "name": "Victoria",
                "state_code": "VIC"
            },
            {
                "id": 3906,
                "name": "Western Australia",
                "state_code": "WA"
            }
        ]
    },
    {
        "id": 15,
        "states": [
            {
                "id": 2062,
                "name": "Burgenland",
                "state_code": "1"
            },
            {
                "id": 2057,
                "name": "Carinthia",
                "state_code": "2"
            },
            {
                "id": 2065,
                "name": "Lower Austria",
                "state_code": "3"
            },
            {
                "id": 2061,
                "name": "Salzburg",
                "state_code": "5"
            },
            {
                "id": 2059,
                "name": "Styria",
                "state_code": "6"
            },
            {
                "id": 2064,
                "name": "Tyrol",
                "state_code": "7"
            },
            {
                "id": 2058,
                "name": "Upper Austria",
                "state_code": "4"
            },
            {
                "id": 2060,
                "name": "Vienna",
                "state_code": "9"
            },
            {
                "id": 2063,
                "name": "Vorarlberg",
                "state_code": "8"
            }
        ]
    },
    {
        "id": 16,
        "states": [
            {
                "id": 540,
                "name": "Absheron District",
                "state_code": "ABS"
            },
            {
                "id": 559,
                "name": "Agdam District",
                "state_code": "AGM"
            },
            {
                "id": 553,
                "name": "Agdash District",
                "state_code": "AGS"
            },
            {
                "id": 577,
                "name": "Aghjabadi District",
                "state_code": "AGC"
            },
            {
                "id": 543,
                "name": "Agstafa District",
                "state_code": "AGA"
            },
            {
                "id": 547,
                "name": "Agsu District",
                "state_code": "AGU"
            },
            {
                "id": 528,
                "name": "Astara District",
                "state_code": "AST"
            },
            {
                "id": 575,
                "name": "Babek District",
                "state_code": "BAB"
            },
            {
                "id": 552,
                "name": "Baku",
                "state_code": "BA"
            },
            {
                "id": 560,
                "name": "Balakan District",
                "state_code": "BAL"
            },
            {
                "id": 569,
                "name": "Barda District",
                "state_code": "BAR"
            },
            {
                "id": 554,
                "name": "Beylagan District",
                "state_code": "BEY"
            },
            {
                "id": 532,
                "name": "Bilasuvar District",
                "state_code": "BIL"
            },
            {
                "id": 561,
                "name": "Dashkasan District",
                "state_code": "DAS"
            },
            {
                "id": 527,
                "name": "Fizuli District",
                "state_code": "FUZ"
            },
            {
                "id": 585,
                "name": "Ganja",
                "state_code": "GA"
            },
            {
                "id": 589,
                "name": "Gədəbəy",
                "state_code": "GAD"
            },
            {
                "id": 573,
                "name": "Gobustan District",
                "state_code": "QOB"
            },
            {
                "id": 551,
                "name": "Goranboy District",
                "state_code": "GOR"
            },
            {
                "id": 531,
                "name": "Goychay",
                "state_code": "GOY"
            },
            {
                "id": 574,
                "name": "Goygol District",
                "state_code": "GYG"
            },
            {
                "id": 571,
                "name": "Hajigabul District",
                "state_code": "HAC"
            },
            {
                "id": 544,
                "name": "Imishli District",
                "state_code": "IMI"
            },
            {
                "id": 564,
                "name": "Ismailli District",
                "state_code": "ISM"
            },
            {
                "id": 570,
                "name": "Jabrayil District",
                "state_code": "CAB"
            },
            {
                "id": 578,
                "name": "Jalilabad District",
                "state_code": "CAL"
            },
            {
                "id": 572,
                "name": "Julfa District",
                "state_code": "CUL"
            },
            {
                "id": 525,
                "name": "Kalbajar District",
                "state_code": "KAL"
            },
            {
                "id": 567,
                "name": "Kangarli District",
                "state_code": "KAN"
            },
            {
                "id": 590,
                "name": "Khachmaz District",
                "state_code": "XAC"
            },
            {
                "id": 537,
                "name": "Khizi District",
                "state_code": "XIZ"
            },
            {
                "id": 524,
                "name": "Khojali District",
                "state_code": "XCI"
            },
            {
                "id": 549,
                "name": "Kurdamir District",
                "state_code": "KUR"
            },
            {
                "id": 541,
                "name": "Lachin District",
                "state_code": "LAC"
            },
            {
                "id": 587,
                "name": "Lankaran",
                "state_code": "LAN"
            },
            {
                "id": 558,
                "name": "Lankaran District",
                "state_code": "LA"
            },
            {
                "id": 546,
                "name": "Lerik District",
                "state_code": "LER"
            },
            {
                "id": 568,
                "name": "Martuni",
                "state_code": "XVD"
            },
            {
                "id": 555,
                "name": "Masally District",
                "state_code": "MAS"
            },
            {
                "id": 580,
                "name": "Mingachevir",
                "state_code": "MI"
            },
            {
                "id": 562,
                "name": "Nakhchivan Autonomous Republic",
                "state_code": "NX"
            },
            {
                "id": 530,
                "name": "Neftchala District",
                "state_code": "NEF"
            },
            {
                "id": 556,
                "name": "Oghuz District",
                "state_code": "OGU"
            },
            {
                "id": 534,
                "name": "Ordubad District",
                "state_code": "ORD"
            },
            {
                "id": 542,
                "name": "Qabala District",
                "state_code": "QAB"
            },
            {
                "id": 526,
                "name": "Qakh District",
                "state_code": "QAX"
            },
            {
                "id": 521,
                "name": "Qazakh District",
                "state_code": "QAZ"
            },
            {
                "id": 563,
                "name": "Quba District",
                "state_code": "QBA"
            },
            {
                "id": 548,
                "name": "Qubadli District",
                "state_code": "QBI"
            },
            {
                "id": 588,
                "name": "Qusar District",
                "state_code": "QUS"
            },
            {
                "id": 557,
                "name": "Saatly District",
                "state_code": "SAT"
            },
            {
                "id": 565,
                "name": "Sabirabad District",
                "state_code": "SAB"
            },
            {
                "id": 522,
                "name": "Sadarak District",
                "state_code": "SAD"
            },
            {
                "id": 545,
                "name": "Salyan District",
                "state_code": "SAL"
            },
            {
                "id": 536,
                "name": "Samukh District",
                "state_code": "SMX"
            },
            {
                "id": 591,
                "name": "Shabran District",
                "state_code": "SBN"
            },
            {
                "id": 579,
                "name": "Shahbuz District",
                "state_code": "SAH"
            },
            {
                "id": 518,
                "name": "Shaki",
                "state_code": "SA"
            },
            {
                "id": 586,
                "name": "Shaki District",
                "state_code": "SAK"
            },
            {
                "id": 529,
                "name": "Shamakhi District",
                "state_code": "SMI"
            },
            {
                "id": 583,
                "name": "Shamkir District",
                "state_code": "SKR"
            },
            {
                "id": 535,
                "name": "Sharur District",
                "state_code": "SAR"
            },
            {
                "id": 520,
                "name": "Shirvan",
                "state_code": "SR"
            },
            {
                "id": 592,
                "name": "Shusha District",
                "state_code": "SUS"
            },
            {
                "id": 584,
                "name": "Siazan District",
                "state_code": "SIY"
            },
            {
                "id": 582,
                "name": "Sumqayit",
                "state_code": "SM"
            },
            {
                "id": 519,
                "name": "Tartar District",
                "state_code": "TAR"
            },
            {
                "id": 533,
                "name": "Tovuz District",
                "state_code": "TOV"
            },
            {
                "id": 539,
                "name": "Ujar District",
                "state_code": "UCA"
            },
            {
                "id": 550,
                "name": "Yardymli District",
                "state_code": "YAR"
            },
            {
                "id": 538,
                "name": "Yevlakh",
                "state_code": "YE"
            },
            {
                "id": 523,
                "name": "Yevlakh District",
                "state_code": "YEV"
            },
            {
                "id": 581,
                "name": "Zangilan District",
                "state_code": "ZAN"
            },
            {
                "id": 566,
                "name": "Zaqatala District",
                "state_code": "ZAQ"
            },
            {
                "id": 576,
                "name": "Zardab District",
                "state_code": "ZAR"
            }
        ]
    },
    {
        "id": 18,
        "states": [
            {
                "id": 1992,
                "name": "Capital",
                "state_code": "13"
            },
            {
                "id": 1996,
                "name": "Central",
                "state_code": "16"
            },
            {
                "id": 1995,
                "name": "Muharraq",
                "state_code": "15"
            },
            {
                "id": 1994,
                "name": "Northern",
                "state_code": "17"
            },
            {
                "id": 1993,
                "name": "Southern",
                "state_code": "14"
            }
        ]
    },
    {
        "id": 19,
        "states": [
            {
                "id": 796,
                "name": "Bagerhat District",
                "state_code": "05"
            },
            {
                "id": 802,
                "name": "Bahadia",
                "state_code": "33"
            },
            {
                "id": 752,
                "name": "Bandarban District",
                "state_code": "01"
            },
            {
                "id": 784,
                "name": "Barguna District",
                "state_code": "02"
            },
            {
                "id": 818,
                "name": "Barisal District",
                "state_code": "06"
            },
            {
                "id": 807,
                "name": "Barisal Division",
                "state_code": "A"
            },
            {
                "id": 756,
                "name": "Bhola District",
                "state_code": "07"
            },
            {
                "id": 797,
                "name": "Bogra District",
                "state_code": "03"
            },
            {
                "id": 810,
                "name": "Brahmanbaria District",
                "state_code": "04"
            },
            {
                "id": 768,
                "name": "Chandpur District",
                "state_code": "09"
            },
            {
                "id": 761,
                "name": "Chapai Nawabganj District",
                "state_code": "45"
            },
            {
                "id": 785,
                "name": "Chittagong District",
                "state_code": "10"
            },
            {
                "id": 803,
                "name": "Chittagong Division",
                "state_code": "B"
            },
            {
                "id": 788,
                "name": "Chuadanga District",
                "state_code": "12"
            },
            {
                "id": 763,
                "name": "Comilla District",
                "state_code": "08"
            },
            {
                "id": 751,
                "name": "Cox's Bazar District",
                "state_code": "11"
            },
            {
                "id": 771,
                "name": "Dhaka District",
                "state_code": "13"
            },
            {
                "id": 760,
                "name": "Dhaka Division",
                "state_code": "C"
            },
            {
                "id": 783,
                "name": "Dinajpur District",
                "state_code": "14"
            },
            {
                "id": 762,
                "name": "Faridpur District",
                "state_code": "15"
            },
            {
                "id": 816,
                "name": "Feni District",
                "state_code": "16"
            },
            {
                "id": 795,
                "name": "Gaibandha District",
                "state_code": "19"
            },
            {
                "id": 798,
                "name": "Gazipur District",
                "state_code": "18"
            },
            {
                "id": 792,
                "name": "Gopalganj District",
                "state_code": "17"
            },
            {
                "id": 805,
                "name": "Habiganj District",
                "state_code": "20"
            },
            {
                "id": 808,
                "name": "Jamalpur District",
                "state_code": "21"
            },
            {
                "id": 757,
                "name": "Jessore District",
                "state_code": "22"
            },
            {
                "id": 778,
                "name": "Jhalokati District",
                "state_code": "25"
            },
            {
                "id": 789,
                "name": "Jhenaidah District",
                "state_code": "23"
            },
            {
                "id": 806,
                "name": "Joypurhat District",
                "state_code": "24"
            },
            {
                "id": 786,
                "name": "Khagrachari District",
                "state_code": "29"
            },
            {
                "id": 811,
                "name": "Khulna District",
                "state_code": "27"
            },
            {
                "id": 775,
                "name": "Khulna Division",
                "state_code": "D"
            },
            {
                "id": 779,
                "name": "Kishoreganj District",
                "state_code": "26"
            },
            {
                "id": 793,
                "name": "Kurigram District",
                "state_code": "28"
            },
            {
                "id": 774,
                "name": "Kushtia District",
                "state_code": "30"
            },
            {
                "id": 819,
                "name": "Lakshmipur District",
                "state_code": "31"
            },
            {
                "id": 780,
                "name": "Lalmonirhat District",
                "state_code": "32"
            },
            {
                "id": 817,
                "name": "Madaripur District",
                "state_code": "36"
            },
            {
                "id": 776,
                "name": "Meherpur District",
                "state_code": "39"
            },
            {
                "id": 794,
                "name": "Moulvibazar District",
                "state_code": "38"
            },
            {
                "id": 790,
                "name": "Munshiganj District",
                "state_code": "35"
            },
            {
                "id": 766,
                "name": "Mymensingh District",
                "state_code": "34"
            },
            {
                "id": 758,
                "name": "Mymensingh Division",
                "state_code": "H"
            },
            {
                "id": 814,
                "name": "Naogaon District",
                "state_code": "48"
            },
            {
                "id": 769,
                "name": "Narail District",
                "state_code": "43"
            },
            {
                "id": 770,
                "name": "Narayanganj District",
                "state_code": "40"
            },
            {
                "id": 787,
                "name": "Natore District",
                "state_code": "44"
            },
            {
                "id": 764,
                "name": "Netrokona District",
                "state_code": "41"
            },
            {
                "id": 772,
                "name": "Nilphamari District",
                "state_code": "46"
            },
            {
                "id": 815,
                "name": "Noakhali District",
                "state_code": "47"
            },
            {
                "id": 754,
                "name": "Pabna District",
                "state_code": "49"
            },
            {
                "id": 800,
                "name": "Panchagarh District",
                "state_code": "52"
            },
            {
                "id": 777,
                "name": "Patuakhali District",
                "state_code": "51"
            },
            {
                "id": 791,
                "name": "Pirojpur District",
                "state_code": "50"
            },
            {
                "id": 773,
                "name": "Rajbari District",
                "state_code": "53"
            },
            {
                "id": 813,
                "name": "Rajshahi District",
                "state_code": "54"
            },
            {
                "id": 753,
                "name": "Rajshahi Division",
                "state_code": "E"
            },
            {
                "id": 809,
                "name": "Rangamati Hill District",
                "state_code": "56"
            },
            {
                "id": 759,
                "name": "Rangpur District",
                "state_code": "55"
            },
            {
                "id": 750,
                "name": "Rangpur Division",
                "state_code": "F"
            },
            {
                "id": 799,
                "name": "Satkhira District",
                "state_code": "58"
            },
            {
                "id": 801,
                "name": "Shariatpur District",
                "state_code": "62"
            },
            {
                "id": 755,
                "name": "Sherpur District",
                "state_code": "57"
            },
            {
                "id": 781,
                "name": "Sirajganj District",
                "state_code": "59"
            },
            {
                "id": 812,
                "name": "Sunamganj District",
                "state_code": "61"
            },
            {
                "id": 767,
                "name": "Sylhet District",
                "state_code": "60"
            },
            {
                "id": 765,
                "name": "Sylhet Division",
                "state_code": "G"
            },
            {
                "id": 782,
                "name": "Tangail District",
                "state_code": "63"
            },
            {
                "id": 804,
                "name": "Thakurgaon District",
                "state_code": "64"
            }
        ]
    },
    {
        "id": 20,
        "states": [
            {
                "id": 1228,
                "name": "Christ Church",
                "state_code": "01"
            },
            {
                "id": 1229,
                "name": "Saint Andrew",
                "state_code": "02"
            },
            {
                "id": 1226,
                "name": "Saint George",
                "state_code": "03"
            },
            {
                "id": 1224,
                "name": "Saint James",
                "state_code": "04"
            },
            {
                "id": 1227,
                "name": "Saint John",
                "state_code": "05"
            },
            {
                "id": 1223,
                "name": "Saint Joseph",
                "state_code": "06"
            },
            {
                "id": 1221,
                "name": "Saint Lucy",
                "state_code": "07"
            },
            {
                "id": 1230,
                "name": "Saint Michael",
                "state_code": "08"
            },
            {
                "id": 1222,
                "name": "Saint Peter",
                "state_code": "09"
            },
            {
                "id": 1220,
                "name": "Saint Philip",
                "state_code": "10"
            },
            {
                "id": 1225,
                "name": "Saint Thomas",
                "state_code": "11"
            }
        ]
    },
    {
        "id": 21,
        "states": [
            {
                "id": 2959,
                "name": "Brest Region",
                "state_code": "BR"
            },
            {
                "id": 2955,
                "name": "Gomel Region",
                "state_code": "HO"
            },
            {
                "id": 2956,
                "name": "Grodno Region",
                "state_code": "HR"
            },
            {
                "id": 2958,
                "name": "Minsk",
                "state_code": "HM"
            },
            {
                "id": 2957,
                "name": "Minsk Region",
                "state_code": "MI"
            },
            {
                "id": 2954,
                "name": "Mogilev Region",
                "state_code": "MA"
            },
            {
                "id": 2960,
                "name": "Vitebsk Region",
                "state_code": "VI"
            }
        ]
    },
    {
        "id": 22,
        "states": [
            {
                "id": 1381,
                "name": "Antwerp",
                "state_code": "VAN"
            },
            {
                "id": 1376,
                "name": "Brussels-Capital Region",
                "state_code": "BRU"
            },
            {
                "id": 1377,
                "name": "East Flanders",
                "state_code": "VOV"
            },
            {
                "id": 1373,
                "name": "Flanders",
                "state_code": "VLG"
            },
            {
                "id": 1374,
                "name": "Flemish Brabant",
                "state_code": "VBR"
            },
            {
                "id": 1375,
                "name": "Hainaut",
                "state_code": "WHT"
            },
            {
                "id": 1384,
                "name": "Liège",
                "state_code": "WLG"
            },
            {
                "id": 1372,
                "name": "Limburg",
                "state_code": "VLI"
            },
            {
                "id": 1379,
                "name": "Luxembourg",
                "state_code": "WLX"
            },
            {
                "id": 1378,
                "name": "Namur",
                "state_code": "WNA"
            },
            {
                "id": 1380,
                "name": "Wallonia",
                "state_code": "WAL"
            },
            {
                "id": 1382,
                "name": "Walloon Brabant",
                "state_code": "WBR"
            },
            {
                "id": 1383,
                "name": "West Flanders",
                "state_code": "VWV"
            }
        ]
    },
    {
        "id": 23,
        "states": [
            {
                "id": 264,
                "name": "Belize District",
                "state_code": "BZ"
            },
            {
                "id": 269,
                "name": "Cayo District",
                "state_code": "CY"
            },
            {
                "id": 266,
                "name": "Corozal District",
                "state_code": "CZL"
            },
            {
                "id": 268,
                "name": "Orange Walk District",
                "state_code": "OW"
            },
            {
                "id": 265,
                "name": "Stann Creek District",
                "state_code": "SC"
            },
            {
                "id": 267,
                "name": "Toledo District",
                "state_code": "TOL"
            }
        ]
    },
    {
        "id": 24,
        "states": [
            {
                "id": 3077,
                "name": "Alibori Department",
                "state_code": "AL"
            },
            {
                "id": 3076,
                "name": "Atakora Department",
                "state_code": "AK"
            },
            {
                "id": 3079,
                "name": "Atlantique Department",
                "state_code": "AQ"
            },
            {
                "id": 3078,
                "name": "Borgou Department",
                "state_code": "BO"
            },
            {
                "id": 3070,
                "name": "Collines Department",
                "state_code": "CO"
            },
            {
                "id": 3072,
                "name": "Donga Department",
                "state_code": "DO"
            },
            {
                "id": 3071,
                "name": "Kouffo Department",
                "state_code": "KO"
            },
            {
                "id": 3081,
                "name": "Littoral Department",
                "state_code": "LI"
            },
            {
                "id": 3075,
                "name": "Mono Department",
                "state_code": "MO"
            },
            {
                "id": 3080,
                "name": "Ouémé Department",
                "state_code": "OU"
            },
            {
                "id": 3074,
                "name": "Plateau Department",
                "state_code": "PL"
            },
            {
                "id": 3073,
                "name": "Zou Department",
                "state_code": "ZO"
            }
        ]
    },
    {
        "id": 25,
        "states": [
            {
                "id": 4860,
                "name": "Devonshire Parish",
                "state_code": "DEV"
            },
            {
                "id": 4861,
                "name": "Hamilton Parish",
                "state_code": "HA"
            },
            {
                "id": 4863,
                "name": "Paget Parish",
                "state_code": "PAG"
            },
            {
                "id": 4864,
                "name": "Pembroke Parish",
                "state_code": "PEM"
            },
            {
                "id": 4866,
                "name": "Saint George's Parish",
                "state_code": "SGE"
            },
            {
                "id": 4867,
                "name": "Sandys Parish",
                "state_code": "SAN"
            },
            {
                "id": 4868,
                "name": "Smith's Parish,",
                "state_code": "SMI"
            },
            {
                "id": 4869,
                "name": "Southampton Parish",
                "state_code": "SOU"
            },
            {
                "id": 4870,
                "name": "Warwick Parish",
                "state_code": "WAR"
            }
        ]
    },
    {
        "id": 26,
        "states": [
            {
                "id": 240,
                "name": "Bumthang District",
                "state_code": "33"
            },
            {
                "id": 239,
                "name": "Chukha District",
                "state_code": "12"
            },
            {
                "id": 238,
                "name": "Dagana District",
                "state_code": "22"
            },
            {
                "id": 229,
                "name": "Gasa District",
                "state_code": "GA"
            },
            {
                "id": 232,
                "name": "Haa District",
                "state_code": "13"
            },
            {
                "id": 234,
                "name": "Lhuntse District",
                "state_code": "44"
            },
            {
                "id": 242,
                "name": "Mongar District",
                "state_code": "42"
            },
            {
                "id": 237,
                "name": "Paro District",
                "state_code": "11"
            },
            {
                "id": 244,
                "name": "Pemagatshel District",
                "state_code": "43"
            },
            {
                "id": 235,
                "name": "Punakha District",
                "state_code": "23"
            },
            {
                "id": 243,
                "name": "Samdrup Jongkhar District",
                "state_code": "45"
            },
            {
                "id": 246,
                "name": "Samtse District",
                "state_code": "14"
            },
            {
                "id": 247,
                "name": "Sarpang District",
                "state_code": "31"
            },
            {
                "id": 241,
                "name": "Thimphu District",
                "state_code": "15"
            },
            {
                "id": 236,
                "name": "Trashigang District",
                "state_code": "41"
            },
            {
                "id": 245,
                "name": "Trongsa District",
                "state_code": "32"
            },
            {
                "id": 230,
                "name": "Tsirang District",
                "state_code": "21"
            },
            {
                "id": 231,
                "name": "Wangdue Phodrang District",
                "state_code": "24"
            },
            {
                "id": 233,
                "name": "Zhemgang District",
                "state_code": "34"
            }
        ]
    },
    {
        "id": 27,
        "states": [
            {
                "id": 3375,
                "name": "Beni Department",
                "state_code": "B"
            },
            {
                "id": 3382,
                "name": "Chuquisaca Department",
                "state_code": "H"
            },
            {
                "id": 3381,
                "name": "Cochabamba Department",
                "state_code": "C"
            },
            {
                "id": 3380,
                "name": "La Paz Department",
                "state_code": "L"
            },
            {
                "id": 3376,
                "name": "Oruro Department",
                "state_code": "O"
            },
            {
                "id": 3379,
                "name": "Pando Department",
                "state_code": "N"
            },
            {
                "id": 3383,
                "name": "Potosí Department",
                "state_code": "P"
            },
            {
                "id": 3377,
                "name": "Santa Cruz Department",
                "state_code": "S"
            },
            {
                "id": 3378,
                "name": "Tarija Department",
                "state_code": "T"
            }
        ]
    },
    {
        "id": 155,
        "states": [
            {
                "id": 5086,
                "name": "Bonaire",
                "state_code": "BQ1"
            },
            {
                "id": 5087,
                "name": "Saba",
                "state_code": "BQ2"
            },
            {
                "id": 5088,
                "name": "Sint Eustatius",
                "state_code": "BQ3"
            }
        ]
    },
    {
        "id": 28,
        "states": [
            {
                "id": 472,
                "name": "Bosnian Podrinje Canton",
                "state_code": "05"
            },
            {
                "id": 460,
                "name": "Brčko District",
                "state_code": "BRC"
            },
            {
                "id": 471,
                "name": "Canton 10",
                "state_code": "10"
            },
            {
                "id": 462,
                "name": "Central Bosnia Canton",
                "state_code": "06"
            },
            {
                "id": 467,
                "name": "Federation of Bosnia and Herzegovina",
                "state_code": "BIH"
            },
            {
                "id": 463,
                "name": "Herzegovina-Neretva Canton",
                "state_code": "07"
            },
            {
                "id": 464,
                "name": "Posavina Canton",
                "state_code": "02"
            },
            {
                "id": 470,
                "name": "Republika Srpska",
                "state_code": "SRP"
            },
            {
                "id": 466,
                "name": "Sarajevo Canton",
                "state_code": "09"
            },
            {
                "id": 461,
                "name": "Tuzla Canton",
                "state_code": "03"
            },
            {
                "id": 465,
                "name": "Una-Sana Canton",
                "state_code": "01"
            },
            {
                "id": 469,
                "name": "West Herzegovina Canton",
                "state_code": "08"
            },
            {
                "id": 468,
                "name": "Zenica-Doboj Canton",
                "state_code": "04"
            }
        ]
    },
    {
        "id": 29,
        "states": [
            {
                "id": 3067,
                "name": "Central District",
                "state_code": "CE"
            },
            {
                "id": 3061,
                "name": "Ghanzi District",
                "state_code": "GH"
            },
            {
                "id": 3066,
                "name": "Kgalagadi District",
                "state_code": "KG"
            },
            {
                "id": 3062,
                "name": "Kgatleng District",
                "state_code": "KL"
            },
            {
                "id": 3069,
                "name": "Kweneng District",
                "state_code": "KW"
            },
            {
                "id": 3060,
                "name": "Ngamiland",
                "state_code": "NG"
            },
            {
                "id": 3068,
                "name": "North-East District",
                "state_code": "NE"
            },
            {
                "id": 3065,
                "name": "North-West District",
                "state_code": "NW"
            },
            {
                "id": 3064,
                "name": "South-East District",
                "state_code": "SE"
            },
            {
                "id": 3063,
                "name": "Southern District",
                "state_code": "SO"
            }
        ]
    },
    {
        "id": 30,
        "states": []
    },
    {
        "id": 31,
        "states": [
            {
                "id": 2012,
                "name": "Acre",
                "state_code": "AC"
            },
            {
                "id": 2007,
                "name": "Alagoas",
                "state_code": "AL"
            },
            {
                "id": 1999,
                "name": "Amapá",
                "state_code": "AP"
            },
            {
                "id": 2004,
                "name": "Amazonas",
                "state_code": "AM"
            },
            {
                "id": 2002,
                "name": "Bahia",
                "state_code": "BA"
            },
            {
                "id": 2016,
                "name": "Ceará",
                "state_code": "CE"
            },
            {
                "id": 2017,
                "name": "Distrito Federal",
                "state_code": "DF"
            },
            {
                "id": 2018,
                "name": "Espírito Santo",
                "state_code": "ES"
            },
            {
                "id": 2000,
                "name": "Goiás",
                "state_code": "GO"
            },
            {
                "id": 2015,
                "name": "Maranhão",
                "state_code": "MA"
            },
            {
                "id": 2011,
                "name": "Mato Grosso",
                "state_code": "MT"
            },
            {
                "id": 2010,
                "name": "Mato Grosso do Sul",
                "state_code": "MS"
            },
            {
                "id": 1998,
                "name": "Minas Gerais",
                "state_code": "MG"
            },
            {
                "id": 2009,
                "name": "Pará",
                "state_code": "PA"
            },
            {
                "id": 2005,
                "name": "Paraíba",
                "state_code": "PB"
            },
            {
                "id": 2022,
                "name": "Paraná",
                "state_code": "PR"
            },
            {
                "id": 2006,
                "name": "Pernambuco",
                "state_code": "PE"
            },
            {
                "id": 2008,
                "name": "Piauí",
                "state_code": "PI"
            },
            {
                "id": 1997,
                "name": "Rio de Janeiro",
                "state_code": "RJ"
            },
            {
                "id": 2019,
                "name": "Rio Grande do Norte",
                "state_code": "RN"
            },
            {
                "id": 2001,
                "name": "Rio Grande do Sul",
                "state_code": "RS"
            },
            {
                "id": 2013,
                "name": "Rondônia",
                "state_code": "RO"
            },
            {
                "id": 4858,
                "name": "Roraima",
                "state_code": "RR"
            },
            {
                "id": 2014,
                "name": "Santa Catarina",
                "state_code": "SC"
            },
            {
                "id": 2021,
                "name": "São Paulo",
                "state_code": "SP"
            },
            {
                "id": 2003,
                "name": "Sergipe",
                "state_code": "SE"
            },
            {
                "id": 2020,
                "name": "Tocantins",
                "state_code": "TO"
            }
        ]
    },
    {
        "id": 32,
        "states": []
    },
    {
        "id": 33,
        "states": [
            {
                "id": 1217,
                "name": "Belait District",
                "state_code": "BE"
            },
            {
                "id": 1216,
                "name": "Brunei-Muara District",
                "state_code": "BM"
            },
            {
                "id": 1218,
                "name": "Temburong District",
                "state_code": "TE"
            },
            {
                "id": 1219,
                "name": "Tutong District",
                "state_code": "TU"
            }
        ]
    },
    {
        "id": 34,
        "states": [
            {
                "id": 4699,
                "name": "Blagoevgrad Province",
                "state_code": "01"
            },
            {
                "id": 4715,
                "name": "Burgas Province",
                "state_code": "02"
            },
            {
                "id": 4718,
                "name": "Dobrich Province",
                "state_code": "08"
            },
            {
                "id": 4693,
                "name": "Gabrovo Province",
                "state_code": "07"
            },
            {
                "id": 4704,
                "name": "Haskovo Province",
                "state_code": "26"
            },
            {
                "id": 4702,
                "name": "Kardzhali Province",
                "state_code": "09"
            },
            {
                "id": 4703,
                "name": "Kyustendil Province",
                "state_code": "10"
            },
            {
                "id": 4710,
                "name": "Lovech Province",
                "state_code": "11"
            },
            {
                "id": 4696,
                "name": "Montana Province",
                "state_code": "12"
            },
            {
                "id": 4712,
                "name": "Pazardzhik Province",
                "state_code": "13"
            },
            {
                "id": 4695,
                "name": "Pernik Province",
                "state_code": "14"
            },
            {
                "id": 4706,
                "name": "Pleven Province",
                "state_code": "15"
            },
            {
                "id": 4701,
                "name": "Plovdiv Province",
                "state_code": "16"
            },
            {
                "id": 4698,
                "name": "Razgrad Province",
                "state_code": "17"
            },
            {
                "id": 4713,
                "name": "Ruse Province",
                "state_code": "18"
            },
            {
                "id": 4882,
                "name": "Shumen",
                "state_code": "27"
            },
            {
                "id": 4708,
                "name": "Silistra Province",
                "state_code": "19"
            },
            {
                "id": 4700,
                "name": "Sliven Province",
                "state_code": "20"
            },
            {
                "id": 4694,
                "name": "Smolyan Province",
                "state_code": "21"
            },
            {
                "id": 4705,
                "name": "Sofia City Province",
                "state_code": "22"
            },
            {
                "id": 4719,
                "name": "Sofia Province",
                "state_code": "23"
            },
            {
                "id": 4707,
                "name": "Stara Zagora Province",
                "state_code": "24"
            },
            {
                "id": 4714,
                "name": "Targovishte Province",
                "state_code": "25"
            },
            {
                "id": 4717,
                "name": "Varna Province",
                "state_code": "03"
            },
            {
                "id": 4709,
                "name": "Veliko Tarnovo Province",
                "state_code": "04"
            },
            {
                "id": 4697,
                "name": "Vidin Province",
                "state_code": "05"
            },
            {
                "id": 4711,
                "name": "Vratsa Province",
                "state_code": "06"
            },
            {
                "id": 4716,
                "name": "Yambol Province",
                "state_code": "28"
            }
        ]
    },
    {
        "id": 35,
        "states": [
            {
                "id": 3160,
                "name": "Balé Province",
                "state_code": "BAL"
            },
            {
                "id": 3155,
                "name": "Bam Province",
                "state_code": "BAM"
            },
            {
                "id": 3120,
                "name": "Banwa Province",
                "state_code": "BAN"
            },
            {
                "id": 3152,
                "name": "Bazèga Province",
                "state_code": "BAZ"
            },
            {
                "id": 3138,
                "name": "Boucle du Mouhoun Region",
                "state_code": "01"
            },
            {
                "id": 3121,
                "name": "Bougouriba Province",
                "state_code": "BGR"
            },
            {
                "id": 3131,
                "name": "Boulgou",
                "state_code": "BLG"
            },
            {
                "id": 3153,
                "name": "Cascades Region",
                "state_code": "02"
            },
            {
                "id": 3136,
                "name": "Centre",
                "state_code": "03"
            },
            {
                "id": 3162,
                "name": "Centre-Est Region",
                "state_code": "04"
            },
            {
                "id": 3127,
                "name": "Centre-Nord Region",
                "state_code": "05"
            },
            {
                "id": 3115,
                "name": "Centre-Ouest Region",
                "state_code": "06"
            },
            {
                "id": 3149,
                "name": "Centre-Sud Region",
                "state_code": "07"
            },
            {
                "id": 3167,
                "name": "Comoé Province",
                "state_code": "COM"
            },
            {
                "id": 3158,
                "name": "Est Region",
                "state_code": "08"
            },
            {
                "id": 3148,
                "name": "Ganzourgou Province",
                "state_code": "GAN"
            },
            {
                "id": 3122,
                "name": "Gnagna Province",
                "state_code": "GNA"
            },
            {
                "id": 3143,
                "name": "Gourma Province",
                "state_code": "GOU"
            },
            {
                "id": 3165,
                "name": "Hauts-Bassins Region",
                "state_code": "09"
            },
            {
                "id": 3129,
                "name": "Houet Province",
                "state_code": "HOU"
            },
            {
                "id": 3135,
                "name": "Ioba Province",
                "state_code": "IOB"
            },
            {
                "id": 3168,
                "name": "Kadiogo Province",
                "state_code": "KAD"
            },
            {
                "id": 3112,
                "name": "Kénédougou Province",
                "state_code": "KEN"
            },
            {
                "id": 3132,
                "name": "Komondjari Province",
                "state_code": "KMD"
            },
            {
                "id": 3157,
                "name": "Kompienga Province",
                "state_code": "KMP"
            },
            {
                "id": 3146,
                "name": "Kossi Province",
                "state_code": "KOS"
            },
            {
                "id": 3133,
                "name": "Koulpélogo Province",
                "state_code": "KOP"
            },
            {
                "id": 3161,
                "name": "Kouritenga Province",
                "state_code": "KOT"
            },
            {
                "id": 3147,
                "name": "Kourwéogo Province",
                "state_code": "KOW"
            },
            {
                "id": 3159,
                "name": "Léraba Province",
                "state_code": "LER"
            },
            {
                "id": 3151,
                "name": "Loroum Province",
                "state_code": "LOR"
            },
            {
                "id": 3123,
                "name": "Mouhoun",
                "state_code": "MOU"
            },
            {
                "id": 3116,
                "name": "Nahouri Province",
                "state_code": "NAO"
            },
            {
                "id": 3113,
                "name": "Namentenga Province",
                "state_code": "NAM"
            },
            {
                "id": 3142,
                "name": "Nayala Province",
                "state_code": "NAY"
            },
            {
                "id": 3164,
                "name": "Nord Region, Burkina Faso",
                "state_code": "10"
            },
            {
                "id": 3156,
                "name": "Noumbiel Province",
                "state_code": "NOU"
            },
            {
                "id": 3141,
                "name": "Oubritenga Province",
                "state_code": "OUB"
            },
            {
                "id": 3144,
                "name": "Oudalan Province",
                "state_code": "OUD"
            },
            {
                "id": 3117,
                "name": "Passoré Province",
                "state_code": "PAS"
            },
            {
                "id": 3125,
                "name": "Plateau-Central Region",
                "state_code": "11"
            },
            {
                "id": 3163,
                "name": "Poni Province",
                "state_code": "PON"
            },
            {
                "id": 3114,
                "name": "Sahel Region",
                "state_code": "12"
            },
            {
                "id": 3154,
                "name": "Sanguié Province",
                "state_code": "SNG"
            },
            {
                "id": 3126,
                "name": "Sanmatenga Province",
                "state_code": "SMT"
            },
            {
                "id": 3139,
                "name": "Séno Province",
                "state_code": "SEN"
            },
            {
                "id": 3119,
                "name": "Sissili Province",
                "state_code": "SIS"
            },
            {
                "id": 3166,
                "name": "Soum Province",
                "state_code": "SOM"
            },
            {
                "id": 3137,
                "name": "Sourou Province",
                "state_code": "SOR"
            },
            {
                "id": 3140,
                "name": "Sud-Ouest Region",
                "state_code": "13"
            },
            {
                "id": 3128,
                "name": "Tapoa Province",
                "state_code": "TAP"
            },
            {
                "id": 3134,
                "name": "Tuy Province",
                "state_code": "TUI"
            },
            {
                "id": 3124,
                "name": "Yagha Province",
                "state_code": "YAG"
            },
            {
                "id": 3150,
                "name": "Yatenga Province",
                "state_code": "YAT"
            },
            {
                "id": 3145,
                "name": "Ziro Province",
                "state_code": "ZIR"
            },
            {
                "id": 3130,
                "name": "Zondoma Province",
                "state_code": "ZON"
            },
            {
                "id": 3118,
                "name": "Zoundwéogo Province",
                "state_code": "ZOU"
            }
        ]
    },
    {
        "id": 36,
        "states": [
            {
                "id": 3196,
                "name": "Bubanza Province",
                "state_code": "BB"
            },
            {
                "id": 3198,
                "name": "Bujumbura Mairie Province",
                "state_code": "BM"
            },
            {
                "id": 3200,
                "name": "Bujumbura Rural Province",
                "state_code": "BL"
            },
            {
                "id": 3202,
                "name": "Bururi Province",
                "state_code": "BR"
            },
            {
                "id": 3201,
                "name": "Cankuzo Province",
                "state_code": "CA"
            },
            {
                "id": 3190,
                "name": "Cibitoke Province",
                "state_code": "CI"
            },
            {
                "id": 3197,
                "name": "Gitega Province",
                "state_code": "GI"
            },
            {
                "id": 3194,
                "name": "Karuzi Province",
                "state_code": "KR"
            },
            {
                "id": 3192,
                "name": "Kayanza Province",
                "state_code": "KY"
            },
            {
                "id": 3195,
                "name": "Kirundo Province",
                "state_code": "KI"
            },
            {
                "id": 3188,
                "name": "Makamba Province",
                "state_code": "MA"
            },
            {
                "id": 3193,
                "name": "Muramvya Province",
                "state_code": "MU"
            },
            {
                "id": 3186,
                "name": "Muyinga Province",
                "state_code": "MY"
            },
            {
                "id": 3187,
                "name": "Mwaro Province",
                "state_code": "MW"
            },
            {
                "id": 3199,
                "name": "Ngozi Province",
                "state_code": "NG"
            },
            {
                "id": 3185,
                "name": "Rumonge Province",
                "state_code": "RM"
            },
            {
                "id": 3189,
                "name": "Rutana Province",
                "state_code": "RT"
            },
            {
                "id": 3191,
                "name": "Ruyigi Province",
                "state_code": "RY"
            }
        ]
    },
    {
        "id": 37,
        "states": [
            {
                "id": 3984,
                "name": "Banteay Meanchey",
                "state_code": "1"
            },
            {
                "id": 3976,
                "name": "Battambang",
                "state_code": "2"
            },
            {
                "id": 3991,
                "name": "Kampong Cham",
                "state_code": "3"
            },
            {
                "id": 3979,
                "name": "Kampong Chhnang",
                "state_code": "4"
            },
            {
                "id": 3988,
                "name": "Kampong Speu",
                "state_code": "5"
            },
            {
                "id": 5070,
                "name": "Kampong Thom",
                "state_code": "6"
            },
            {
                "id": 3981,
                "name": "Kampot",
                "state_code": "7"
            },
            {
                "id": 3983,
                "name": "Kandal",
                "state_code": "8"
            },
            {
                "id": 3978,
                "name": "Kep",
                "state_code": "23"
            },
            {
                "id": 3982,
                "name": "Koh Kong",
                "state_code": "9"
            },
            {
                "id": 3986,
                "name": "Kratie",
                "state_code": "10"
            },
            {
                "id": 3985,
                "name": "Mondulkiri",
                "state_code": "11"
            },
            {
                "id": 3987,
                "name": "Oddar Meanchey",
                "state_code": "22"
            },
            {
                "id": 3980,
                "name": "Pailin",
                "state_code": "24"
            },
            {
                "id": 3994,
                "name": "Phnom Penh",
                "state_code": "12"
            },
            {
                "id": 3973,
                "name": "Preah Vihear",
                "state_code": "13"
            },
            {
                "id": 3974,
                "name": "Prey Veng",
                "state_code": "14"
            },
            {
                "id": 3977,
                "name": "Pursat",
                "state_code": "15"
            },
            {
                "id": 3990,
                "name": "Ratanakiri",
                "state_code": "16"
            },
            {
                "id": 3992,
                "name": "Siem Reap",
                "state_code": "17"
            },
            {
                "id": 3989,
                "name": "Sihanoukville",
                "state_code": "18"
            },
            {
                "id": 3993,
                "name": "Stung Treng",
                "state_code": "19"
            },
            {
                "id": 3972,
                "name": "Svay Rieng",
                "state_code": "20"
            },
            {
                "id": 3975,
                "name": "Takeo",
                "state_code": "21"
            }
        ]
    },
    {
        "id": 38,
        "states": [
            {
                "id": 2663,
                "name": "Adamawa",
                "state_code": "AD"
            },
            {
                "id": 2660,
                "name": "Centre",
                "state_code": "CE"
            },
            {
                "id": 2661,
                "name": "East",
                "state_code": "ES"
            },
            {
                "id": 2656,
                "name": "Far North",
                "state_code": "EN"
            },
            {
                "id": 2662,
                "name": "Littoral",
                "state_code": "LT"
            },
            {
                "id": 2665,
                "name": "North",
                "state_code": "NO"
            },
            {
                "id": 2657,
                "name": "Northwest",
                "state_code": "NW"
            },
            {
                "id": 2659,
                "name": "South",
                "state_code": "SU"
            },
            {
                "id": 2658,
                "name": "Southwest",
                "state_code": "SW"
            },
            {
                "id": 2664,
                "name": "West",
                "state_code": "OU"
            }
        ]
    },
    {
        "id": 39,
        "states": [
            {
                "id": 872,
                "name": "Alberta",
                "state_code": "AB"
            },
            {
                "id": 875,
                "name": "British Columbia",
                "state_code": "BC"
            },
            {
                "id": 867,
                "name": "Manitoba",
                "state_code": "MB"
            },
            {
                "id": 868,
                "name": "New Brunswick",
                "state_code": "NB"
            },
            {
                "id": 877,
                "name": "Newfoundland and Labrador",
                "state_code": "NL"
            },
            {
                "id": 878,
                "name": "Northwest Territories",
                "state_code": "NT"
            },
            {
                "id": 874,
                "name": "Nova Scotia",
                "state_code": "NS"
            },
            {
                "id": 876,
                "name": "Nunavut",
                "state_code": "NU"
            },
            {
                "id": 866,
                "name": "Ontario",
                "state_code": "ON"
            },
            {
                "id": 871,
                "name": "Prince Edward Island",
                "state_code": "PE"
            },
            {
                "id": 873,
                "name": "Quebec",
                "state_code": "QC"
            },
            {
                "id": 870,
                "name": "Saskatchewan",
                "state_code": "SK"
            },
            {
                "id": 869,
                "name": "Yukon",
                "state_code": "YT"
            }
        ]
    },
    {
        "id": 40,
        "states": [
            {
                "id": 2994,
                "name": "Barlavento Islands",
                "state_code": "B"
            },
            {
                "id": 2999,
                "name": "Boa Vista",
                "state_code": "BV"
            },
            {
                "id": 2996,
                "name": "Brava",
                "state_code": "BR"
            },
            {
                "id": 2991,
                "name": "Maio Municipality",
                "state_code": "MA"
            },
            {
                "id": 2987,
                "name": "Mosteiros",
                "state_code": "MO"
            },
            {
                "id": 2997,
                "name": "Paul",
                "state_code": "PA"
            },
            {
                "id": 2989,
                "name": "Porto Novo",
                "state_code": "PN"
            },
            {
                "id": 2988,
                "name": "Praia",
                "state_code": "PR"
            },
            {
                "id": 2982,
                "name": "Ribeira Brava Municipality",
                "state_code": "RB"
            },
            {
                "id": 3002,
                "name": "Ribeira Grande",
                "state_code": "RG"
            },
            {
                "id": 2984,
                "name": "Ribeira Grande de Santiago",
                "state_code": "RS"
            },
            {
                "id": 2998,
                "name": "Sal",
                "state_code": "SL"
            },
            {
                "id": 2985,
                "name": "Santa Catarina",
                "state_code": "CA"
            },
            {
                "id": 2995,
                "name": "Santa Catarina do Fogo",
                "state_code": "CF"
            },
            {
                "id": 3004,
                "name": "Santa Cruz",
                "state_code": "CR"
            },
            {
                "id": 2986,
                "name": "São Domingos",
                "state_code": "SD"
            },
            {
                "id": 3000,
                "name": "São Filipe",
                "state_code": "SF"
            },
            {
                "id": 2993,
                "name": "São Lourenço dos Órgãos",
                "state_code": "SO"
            },
            {
                "id": 2990,
                "name": "São Miguel",
                "state_code": "SM"
            },
            {
                "id": 3001,
                "name": "São Vicente",
                "state_code": "SV"
            },
            {
                "id": 2992,
                "name": "Sotavento Islands",
                "state_code": "S"
            },
            {
                "id": 2983,
                "name": "Tarrafal",
                "state_code": "TA"
            },
            {
                "id": 3003,
                "name": "Tarrafal de São Nicolau",
                "state_code": "TS"
            }
        ]
    },
    {
        "id": 41,
        "states": []
    },
    {
        "id": 42,
        "states": [
            {
                "id": 1259,
                "name": "Bamingui-Bangoran Prefecture",
                "state_code": "BB"
            },
            {
                "id": 1262,
                "name": "Bangui",
                "state_code": "BGF"
            },
            {
                "id": 1264,
                "name": "Basse-Kotto Prefecture",
                "state_code": "BK"
            },
            {
                "id": 1258,
                "name": "Haut-Mbomou Prefecture",
                "state_code": "HM"
            },
            {
                "id": 1268,
                "name": "Haute-Kotto Prefecture",
                "state_code": "HK"
            },
            {
                "id": 1263,
                "name": "Kémo Prefecture",
                "state_code": "KG"
            },
            {
                "id": 1256,
                "name": "Lobaye Prefecture",
                "state_code": "LB"
            },
            {
                "id": 1257,
                "name": "Mambéré-Kadéï",
                "state_code": "HS"
            },
            {
                "id": 1266,
                "name": "Mbomou Prefecture",
                "state_code": "MB"
            },
            {
                "id": 1253,
                "name": "Nana-Grébizi Economic Prefecture",
                "state_code": "KB"
            },
            {
                "id": 1260,
                "name": "Nana-Mambéré Prefecture",
                "state_code": "NM"
            },
            {
                "id": 1255,
                "name": "Ombella-M'Poko Prefecture",
                "state_code": "MP"
            },
            {
                "id": 1265,
                "name": "Ouaka Prefecture",
                "state_code": "UK"
            },
            {
                "id": 1254,
                "name": "Ouham Prefecture",
                "state_code": "AC"
            },
            {
                "id": 1267,
                "name": "Ouham-Pendé Prefecture",
                "state_code": "OP"
            },
            {
                "id": 1252,
                "name": "Sangha-Mbaéré",
                "state_code": "SE"
            },
            {
                "id": 1261,
                "name": "Vakaga Prefecture",
                "state_code": "VK"
            }
        ]
    },
    {
        "id": 43,
        "states": [
            {
                "id": 3583,
                "name": "Bahr el Gazel",
                "state_code": "BG"
            },
            {
                "id": 3590,
                "name": "Batha",
                "state_code": "BA"
            },
            {
                "id": 3574,
                "name": "Borkou",
                "state_code": "BO"
            },
            {
                "id": 5114,
                "name": "Chari-Baguirmi",
                "state_code": "CB"
            },
            {
                "id": 3575,
                "name": "Ennedi-Est",
                "state_code": "EE"
            },
            {
                "id": 3584,
                "name": "Ennedi-Ouest",
                "state_code": "EO"
            },
            {
                "id": 3576,
                "name": "Guéra",
                "state_code": "GR"
            },
            {
                "id": 3573,
                "name": "Hadjer-Lamis",
                "state_code": "HL"
            },
            {
                "id": 3588,
                "name": "Kanem",
                "state_code": "KA"
            },
            {
                "id": 3577,
                "name": "Lac",
                "state_code": "LC"
            },
            {
                "id": 3585,
                "name": "Logone Occidental",
                "state_code": "LO"
            },
            {
                "id": 3591,
                "name": "Logone Oriental",
                "state_code": "LR"
            },
            {
                "id": 3589,
                "name": "Mandoul",
                "state_code": "MA"
            },
            {
                "id": 3580,
                "name": "Mayo-Kebbi Est",
                "state_code": "ME"
            },
            {
                "id": 3571,
                "name": "Mayo-Kebbi Ouest",
                "state_code": "MO"
            },
            {
                "id": 3570,
                "name": "Moyen-Chari",
                "state_code": "MC"
            },
            {
                "id": 3586,
                "name": "N'Djamena",
                "state_code": "ND"
            },
            {
                "id": 3582,
                "name": "Ouaddaï",
                "state_code": "OD"
            },
            {
                "id": 3592,
                "name": "Salamat",
                "state_code": "SA"
            },
            {
                "id": 3572,
                "name": "Sila",
                "state_code": "SI"
            },
            {
                "id": 3579,
                "name": "Tandjilé",
                "state_code": "TA"
            },
            {
                "id": 3587,
                "name": "Tibesti",
                "state_code": "TI"
            },
            {
                "id": 3581,
                "name": "Wadi Fira",
                "state_code": "WF"
            }
        ]
    },
    {
        "id": 44,
        "states": [
            {
                "id": 2828,
                "name": "Aisén del General Carlos Ibañez del Campo",
                "state_code": "AI"
            },
            {
                "id": 2832,
                "name": "Antofagasta",
                "state_code": "AN"
            },
            {
                "id": 2829,
                "name": "Arica y Parinacota",
                "state_code": "AP"
            },
            {
                "id": 2823,
                "name": "Atacama",
                "state_code": "AT"
            },
            {
                "id": 2827,
                "name": "Biobío",
                "state_code": "BI"
            },
            {
                "id": 2825,
                "name": "Coquimbo",
                "state_code": "CO"
            },
            {
                "id": 2826,
                "name": "La Araucanía",
                "state_code": "AR"
            },
            {
                "id": 2838,
                "name": "Libertador General Bernardo O'Higgins",
                "state_code": "LI"
            },
            {
                "id": 2835,
                "name": "Los Lagos",
                "state_code": "LL"
            },
            {
                "id": 2834,
                "name": "Los Ríos",
                "state_code": "LR"
            },
            {
                "id": 2836,
                "name": "Magallanes y de la Antártica Chilena",
                "state_code": "MA"
            },
            {
                "id": 2833,
                "name": "Maule",
                "state_code": "ML"
            },
            {
                "id": 2831,
                "name": "Ñuble",
                "state_code": "NB"
            },
            {
                "id": 2824,
                "name": "Región Metropolitana de Santiago",
                "state_code": "RM"
            },
            {
                "id": 2837,
                "name": "Tarapacá",
                "state_code": "TA"
            },
            {
                "id": 2830,
                "name": "Valparaíso",
                "state_code": "VS"
            }
        ]
    },
    {
        "id": 45,
        "states": [
            {
                "id": 2251,
                "name": "Anhui",
                "state_code": "AH"
            },
            {
                "id": 2257,
                "name": "Beijing",
                "state_code": "BJ"
            },
            {
                "id": 2271,
                "name": "Chongqing",
                "state_code": "CQ"
            },
            {
                "id": 2248,
                "name": "Fujian",
                "state_code": "FJ"
            },
            {
                "id": 2275,
                "name": "Gansu",
                "state_code": "GS"
            },
            {
                "id": 2279,
                "name": "Guangdong",
                "state_code": "GD"
            },
            {
                "id": 2278,
                "name": "Guangxi Zhuang",
                "state_code": "GX"
            },
            {
                "id": 2261,
                "name": "Guizhou",
                "state_code": "GZ"
            },
            {
                "id": 2273,
                "name": "Hainan",
                "state_code": "HI"
            },
            {
                "id": 2280,
                "name": "Hebei",
                "state_code": "HE"
            },
            {
                "id": 2265,
                "name": "Heilongjiang",
                "state_code": "HL"
            },
            {
                "id": 2259,
                "name": "Henan",
                "state_code": "HA"
            },
            {
                "id": 2267,
                "name": "Hong Kong SAR",
                "state_code": "HK"
            },
            {
                "id": 2274,
                "name": "Hubei",
                "state_code": "HB"
            },
            {
                "id": 2258,
                "name": "Hunan",
                "state_code": "HN"
            },
            {
                "id": 2269,
                "name": "Inner Mongolia",
                "state_code": "NM"
            },
            {
                "id": 2250,
                "name": "Jiangsu",
                "state_code": "JS"
            },
            {
                "id": 2256,
                "name": "Jiangxi",
                "state_code": "JX"
            },
            {
                "id": 2253,
                "name": "Jilin",
                "state_code": "JL"
            },
            {
                "id": 2268,
                "name": "Liaoning",
                "state_code": "LN"
            },
            {
                "id": 2266,
                "name": "Macau SAR",
                "state_code": "MO"
            },
            {
                "id": 2262,
                "name": "Ningxia Huizu",
                "state_code": "NX"
            },
            {
                "id": 2270,
                "name": "Qinghai",
                "state_code": "QH"
            },
            {
                "id": 2272,
                "name": "Shaanxi",
                "state_code": "SN"
            },
            {
                "id": 2252,
                "name": "Shandong",
                "state_code": "SD"
            },
            {
                "id": 2249,
                "name": "Shanghai",
                "state_code": "SH"
            },
            {
                "id": 2254,
                "name": "Shanxi",
                "state_code": "SX"
            },
            {
                "id": 2277,
                "name": "Sichuan",
                "state_code": "SC"
            },
            {
                "id": 2255,
                "name": "Taiwan",
                "state_code": "TW"
            },
            {
                "id": 2276,
                "name": "Tianjin",
                "state_code": "TJ"
            },
            {
                "id": 2263,
                "name": "Xinjiang",
                "state_code": "XJ"
            },
            {
                "id": 2264,
                "name": "Xizang",
                "state_code": "XZ"
            },
            {
                "id": 2260,
                "name": "Yunnan",
                "state_code": "YN"
            },
            {
                "id": 2247,
                "name": "Zhejiang",
                "state_code": "ZJ"
            }
        ]
    },
    {
        "id": 46,
        "states": []
    },
    {
        "id": 47,
        "states": []
    },
    {
        "id": 48,
        "states": [
            {
                "id": 2895,
                "name": "Amazonas",
                "state_code": "AMA"
            },
            {
                "id": 2890,
                "name": "Antioquia",
                "state_code": "ANT"
            },
            {
                "id": 2881,
                "name": "Arauca",
                "state_code": "ARA"
            },
            {
                "id": 2900,
                "name": "Archipiélago de San Andrés, Providencia y Santa Catalina",
                "state_code": "SAP"
            },
            {
                "id": 2880,
                "name": "Atlántico",
                "state_code": "ATL"
            },
            {
                "id": 4921,
                "name": "Bogotá D.C.",
                "state_code": "DC"
            },
            {
                "id": 2893,
                "name": "Bolívar",
                "state_code": "BOL"
            },
            {
                "id": 2903,
                "name": "Boyacá",
                "state_code": "BOY"
            },
            {
                "id": 2887,
                "name": "Caldas",
                "state_code": "CAL"
            },
            {
                "id": 2891,
                "name": "Caquetá",
                "state_code": "CAQ"
            },
            {
                "id": 2892,
                "name": "Casanare",
                "state_code": "CAS"
            },
            {
                "id": 2884,
                "name": "Cauca",
                "state_code": "CAU"
            },
            {
                "id": 2899,
                "name": "Cesar",
                "state_code": "CES"
            },
            {
                "id": 2876,
                "name": "Chocó",
                "state_code": "CHO"
            },
            {
                "id": 2898,
                "name": "Córdoba",
                "state_code": "COR"
            },
            {
                "id": 2875,
                "name": "Cundinamarca",
                "state_code": "CUN"
            },
            {
                "id": 2882,
                "name": "Guainía",
                "state_code": "GUA"
            },
            {
                "id": 2888,
                "name": "Guaviare",
                "state_code": "GUV"
            },
            {
                "id": 4871,
                "name": "Huila",
                "state_code": "HUI"
            },
            {
                "id": 2889,
                "name": "La Guajira",
                "state_code": "LAG"
            },
            {
                "id": 2886,
                "name": "Magdalena",
                "state_code": "MAG"
            },
            {
                "id": 2878,
                "name": "Meta",
                "state_code": "MET"
            },
            {
                "id": 2897,
                "name": "Nariño",
                "state_code": "NAR"
            },
            {
                "id": 2877,
                "name": "Norte de Santander",
                "state_code": "NSA"
            },
            {
                "id": 2896,
                "name": "Putumayo",
                "state_code": "PUT"
            },
            {
                "id": 2874,
                "name": "Quindío",
                "state_code": "QUI"
            },
            {
                "id": 2879,
                "name": "Risaralda",
                "state_code": "RIS"
            },
            {
                "id": 2901,
                "name": "Santander",
                "state_code": "SAN"
            },
            {
                "id": 2902,
                "name": "Sucre",
                "state_code": "SUC"
            },
            {
                "id": 2883,
                "name": "Tolima",
                "state_code": "TOL"
            },
            {
                "id": 2904,
                "name": "Valle del Cauca",
                "state_code": "VAC"
            },
            {
                "id": 2885,
                "name": "Vaupés",
                "state_code": "VAU"
            },
            {
                "id": 2894,
                "name": "Vichada",
                "state_code": "VID"
            }
        ]
    },
    {
        "id": 49,
        "states": [
            {
                "id": 2821,
                "name": "Anjouan",
                "state_code": "A"
            },
            {
                "id": 2822,
                "name": "Grande Comore",
                "state_code": "G"
            },
            {
                "id": 2820,
                "name": "Mohéli",
                "state_code": "M"
            }
        ]
    },
    {
        "id": 50,
        "states": [
            {
                "id": 2866,
                "name": "Bouenza Department",
                "state_code": "11"
            },
            {
                "id": 2870,
                "name": "Brazzaville",
                "state_code": "BZV"
            },
            {
                "id": 2864,
                "name": "Cuvette Department",
                "state_code": "8"
            },
            {
                "id": 2869,
                "name": "Cuvette-Ouest Department",
                "state_code": "15"
            },
            {
                "id": 2867,
                "name": "Kouilou Department",
                "state_code": "5"
            },
            {
                "id": 2868,
                "name": "Lékoumou Department",
                "state_code": "2"
            },
            {
                "id": 2865,
                "name": "Likouala Department",
                "state_code": "7"
            },
            {
                "id": 2872,
                "name": "Niari Department",
                "state_code": "9"
            },
            {
                "id": 2862,
                "name": "Plateaux Department",
                "state_code": "14"
            },
            {
                "id": 2863,
                "name": "Pointe-Noire",
                "state_code": "16"
            },
            {
                "id": 2873,
                "name": "Pool Department",
                "state_code": "12"
            },
            {
                "id": 2871,
                "name": "Sangha Department",
                "state_code": "13"
            }
        ]
    },
    {
        "id": 52,
        "states": []
    },
    {
        "id": 53,
        "states": [
            {
                "id": 1215,
                "name": "Alajuela Province",
                "state_code": "A"
            },
            {
                "id": 1209,
                "name": "Guanacaste Province",
                "state_code": "G"
            },
            {
                "id": 1212,
                "name": "Heredia Province",
                "state_code": "H"
            },
            {
                "id": 1213,
                "name": "Limón Province",
                "state_code": "L"
            },
            {
                "id": 1211,
                "name": "Provincia de Cartago",
                "state_code": "C"
            },
            {
                "id": 1210,
                "name": "Puntarenas Province",
                "state_code": "P"
            },
            {
                "id": 1214,
                "name": "San José Province",
                "state_code": "SJ"
            }
        ]
    },
    {
        "id": 54,
        "states": [
            {
                "id": 2634,
                "name": "Abidjan",
                "state_code": "AB"
            },
            {
                "id": 2626,
                "name": "Agnéby",
                "state_code": "16"
            },
            {
                "id": 2636,
                "name": "Bafing Region",
                "state_code": "17"
            },
            {
                "id": 2643,
                "name": "Bas-Sassandra District",
                "state_code": "BS"
            },
            {
                "id": 2635,
                "name": "Bas-Sassandra Region",
                "state_code": "09"
            },
            {
                "id": 2654,
                "name": "Comoé District",
                "state_code": "CM"
            },
            {
                "id": 2644,
                "name": "Denguélé District",
                "state_code": "DN"
            },
            {
                "id": 2642,
                "name": "Denguélé Region",
                "state_code": "10"
            },
            {
                "id": 2645,
                "name": "Dix-Huit Montagnes",
                "state_code": "06"
            },
            {
                "id": 2633,
                "name": "Fromager",
                "state_code": "18"
            },
            {
                "id": 2651,
                "name": "Gôh-Djiboua District",
                "state_code": "GD"
            },
            {
                "id": 2638,
                "name": "Haut-Sassandra",
                "state_code": "02"
            },
            {
                "id": 2632,
                "name": "Lacs District",
                "state_code": "LC"
            },
            {
                "id": 2640,
                "name": "Lacs Region",
                "state_code": "07"
            },
            {
                "id": 2627,
                "name": "Lagunes District",
                "state_code": "LG"
            },
            {
                "id": 2639,
                "name": "Lagunes region",
                "state_code": "01"
            },
            {
                "id": 2631,
                "name": "Marahoué Region",
                "state_code": "12"
            },
            {
                "id": 2629,
                "name": "Montagnes District",
                "state_code": "MG"
            },
            {
                "id": 2646,
                "name": "Moyen-Cavally",
                "state_code": "19"
            },
            {
                "id": 2630,
                "name": "Moyen-Comoé",
                "state_code": "05"
            },
            {
                "id": 2655,
                "name": "N'zi-Comoé",
                "state_code": "11"
            },
            {
                "id": 2648,
                "name": "Sassandra-Marahoué District",
                "state_code": "SM"
            },
            {
                "id": 2625,
                "name": "Savanes Region",
                "state_code": "03"
            },
            {
                "id": 2628,
                "name": "Sud-Bandama",
                "state_code": "15"
            },
            {
                "id": 2652,
                "name": "Sud-Comoé",
                "state_code": "13"
            },
            {
                "id": 2637,
                "name": "Vallée du Bandama District",
                "state_code": "VB"
            },
            {
                "id": 2647,
                "name": "Vallée du Bandama Region",
                "state_code": "04"
            },
            {
                "id": 2650,
                "name": "Woroba District",
                "state_code": "WR"
            },
            {
                "id": 2649,
                "name": "Worodougou",
                "state_code": "14"
            },
            {
                "id": 2653,
                "name": "Yamoussoukro",
                "state_code": "YM"
            },
            {
                "id": 2641,
                "name": "Zanzan Region",
                "state_code": "ZZ"
            }
        ]
    },
    {
        "id": 55,
        "states": [
            {
                "id": 734,
                "name": "Bjelovar-Bilogora",
                "state_code": "07"
            },
            {
                "id": 737,
                "name": "Brod-Posavina",
                "state_code": "12"
            },
            {
                "id": 728,
                "name": "Dubrovnik-Neretva",
                "state_code": "19"
            },
            {
                "id": 743,
                "name": "Istria",
                "state_code": "18"
            },
            {
                "id": 5069,
                "name": "Karlovac",
                "state_code": "04"
            },
            {
                "id": 742,
                "name": "Koprivnica-Križevci",
                "state_code": "06"
            },
            {
                "id": 729,
                "name": "Krapina-Zagorje",
                "state_code": "02"
            },
            {
                "id": 731,
                "name": "Lika-Senj",
                "state_code": "09"
            },
            {
                "id": 726,
                "name": "Međimurje",
                "state_code": "20"
            },
            {
                "id": 740,
                "name": "Osijek-Baranja",
                "state_code": "14"
            },
            {
                "id": 724,
                "name": "Požega-Slavonia",
                "state_code": "11"
            },
            {
                "id": 735,
                "name": "Primorje-Gorski Kotar",
                "state_code": "08"
            },
            {
                "id": 730,
                "name": "Šibenik-Knin",
                "state_code": "15"
            },
            {
                "id": 733,
                "name": "Sisak-Moslavina",
                "state_code": "03"
            },
            {
                "id": 725,
                "name": "Split-Dalmatia",
                "state_code": "17"
            },
            {
                "id": 739,
                "name": "Varaždin",
                "state_code": "05"
            },
            {
                "id": 732,
                "name": "Virovitica-Podravina",
                "state_code": "10"
            },
            {
                "id": 741,
                "name": "Vukovar-Syrmia",
                "state_code": "16"
            },
            {
                "id": 727,
                "name": "Zadar",
                "state_code": "13"
            },
            {
                "id": 736,
                "name": "Zagreb",
                "state_code": "01"
            },
            {
                "id": 738,
                "name": "Zagreb",
                "state_code": "21"
            }
        ]
    },
    {
        "id": 56,
        "states": [
            {
                "id": 283,
                "name": "Artemisa Province",
                "state_code": "15"
            },
            {
                "id": 286,
                "name": "Camagüey Province",
                "state_code": "09"
            },
            {
                "id": 282,
                "name": "Ciego de Ávila Province",
                "state_code": "08"
            },
            {
                "id": 287,
                "name": "Cienfuegos Province",
                "state_code": "06"
            },
            {
                "id": 275,
                "name": "Granma Province",
                "state_code": "12"
            },
            {
                "id": 285,
                "name": "Guantánamo Province",
                "state_code": "14"
            },
            {
                "id": 272,
                "name": "Havana Province",
                "state_code": "03"
            },
            {
                "id": 279,
                "name": "Holguín Province",
                "state_code": "11"
            },
            {
                "id": 278,
                "name": "Isla de la Juventud",
                "state_code": "99"
            },
            {
                "id": 281,
                "name": "Las Tunas Province",
                "state_code": "10"
            },
            {
                "id": 284,
                "name": "Matanzas Province",
                "state_code": "04"
            },
            {
                "id": 276,
                "name": "Mayabeque Province",
                "state_code": "16"
            },
            {
                "id": 277,
                "name": "Pinar del Río Province",
                "state_code": "01"
            },
            {
                "id": 274,
                "name": "Sancti Spíritus Province",
                "state_code": "07"
            },
            {
                "id": 273,
                "name": "Santiago de Cuba Province",
                "state_code": "13"
            },
            {
                "id": 280,
                "name": "Villa Clara Province",
                "state_code": "05"
            }
        ]
    },
    {
        "id": 249,
        "states": []
    },
    {
        "id": 57,
        "states": [
            {
                "id": 749,
                "name": "Famagusta District (Mağusa)",
                "state_code": "04"
            },
            {
                "id": 744,
                "name": "Kyrenia District (Keryneia)",
                "state_code": "06"
            },
            {
                "id": 747,
                "name": "Larnaca District (Larnaka)",
                "state_code": "03"
            },
            {
                "id": 748,
                "name": "Limassol District (Leymasun)",
                "state_code": "02"
            },
            {
                "id": 745,
                "name": "Nicosia District (Lefkoşa)",
                "state_code": "01"
            },
            {
                "id": 746,
                "name": "Paphos District (Pafos)",
                "state_code": "05"
            }
        ]
    },
    {
        "id": 58,
        "states": [
            {
                "id": 4627,
                "name": "Benešov",
                "state_code": "201"
            },
            {
                "id": 4620,
                "name": "Beroun",
                "state_code": "202"
            },
            {
                "id": 4615,
                "name": "Blansko",
                "state_code": "641"
            },
            {
                "id": 4542,
                "name": "Břeclav",
                "state_code": "644"
            },
            {
                "id": 4568,
                "name": "Brno-město",
                "state_code": "642"
            },
            {
                "id": 4545,
                "name": "Brno-venkov",
                "state_code": "643"
            },
            {
                "id": 4644,
                "name": "Bruntál",
                "state_code": "801"
            },
            {
                "id": 4633,
                "name": "Česká Lípa",
                "state_code": "511"
            },
            {
                "id": 4556,
                "name": "České Budějovice",
                "state_code": "311"
            },
            {
                "id": 4543,
                "name": "Český Krumlov",
                "state_code": "312"
            },
            {
                "id": 4573,
                "name": "Cheb",
                "state_code": "411"
            },
            {
                "id": 4553,
                "name": "Chomutov",
                "state_code": "422"
            },
            {
                "id": 4634,
                "name": "Chrudim",
                "state_code": "531"
            },
            {
                "id": 4609,
                "name": "Děčín",
                "state_code": "421"
            },
            {
                "id": 4641,
                "name": "Domažlice",
                "state_code": "321"
            },
            {
                "id": 4559,
                "name": "Frýdek-Místek",
                "state_code": "802"
            },
            {
                "id": 4611,
                "name": "Havlíčkův Brod",
                "state_code": "631"
            },
            {
                "id": 4561,
                "name": "Hodonín",
                "state_code": "645"
            },
            {
                "id": 4580,
                "name": "Hradec Králové",
                "state_code": "521"
            },
            {
                "id": 4612,
                "name": "Jablonec nad Nisou",
                "state_code": "512"
            },
            {
                "id": 4625,
                "name": "Jeseník",
                "state_code": "711"
            },
            {
                "id": 4640,
                "name": "Jičín",
                "state_code": "522"
            },
            {
                "id": 4613,
                "name": "Jihlava",
                "state_code": "632"
            },
            {
                "id": 4639,
                "name": "Jihočeský kraj",
                "state_code": "31"
            },
            {
                "id": 4602,
                "name": "Jihomoravský kraj",
                "state_code": "64"
            },
            {
                "id": 4624,
                "name": "Jindřichův Hradec",
                "state_code": "313"
            },
            {
                "id": 4581,
                "name": "Karlovarský kraj",
                "state_code": "41"
            },
            {
                "id": 4604,
                "name": "Karlovy Vary",
                "state_code": "412"
            },
            {
                "id": 4586,
                "name": "Karviná",
                "state_code": "803"
            },
            {
                "id": 4631,
                "name": "Kladno",
                "state_code": "203"
            },
            {
                "id": 4591,
                "name": "Klatovy",
                "state_code": "322"
            },
            {
                "id": 4618,
                "name": "Kolín",
                "state_code": "204"
            },
            {
                "id": 4575,
                "name": "Kraj Vysočina",
                "state_code": "63"
            },
            {
                "id": 4614,
                "name": "Královéhradecký kraj",
                "state_code": "52"
            },
            {
                "id": 4593,
                "name": "Kroměříž",
                "state_code": "721"
            },
            {
                "id": 4923,
                "name": "Kutná Hora",
                "state_code": "205"
            },
            {
                "id": 4590,
                "name": "Liberec",
                "state_code": "513"
            },
            {
                "id": 4601,
                "name": "Liberecký kraj",
                "state_code": "51"
            },
            {
                "id": 4605,
                "name": "Litoměřice",
                "state_code": "423"
            },
            {
                "id": 4617,
                "name": "Louny",
                "state_code": "424"
            },
            {
                "id": 4638,
                "name": "Mělník",
                "state_code": "206"
            },
            {
                "id": 4643,
                "name": "Mladá Boleslav",
                "state_code": "207"
            },
            {
                "id": 4600,
                "name": "Moravskoslezský kraj",
                "state_code": "80"
            },
            {
                "id": 4629,
                "name": "Most",
                "state_code": "425"
            },
            {
                "id": 4550,
                "name": "Náchod",
                "state_code": "523"
            },
            {
                "id": 4548,
                "name": "Nový Jičín",
                "state_code": "804"
            },
            {
                "id": 4582,
                "name": "Nymburk",
                "state_code": "208"
            },
            {
                "id": 4574,
                "name": "Olomouc",
                "state_code": "712"
            },
            {
                "id": 4589,
                "name": "Olomoucký kraj",
                "state_code": "71"
            },
            {
                "id": 4623,
                "name": "Opava",
                "state_code": "805"
            },
            {
                "id": 4584,
                "name": "Ostrava-město",
                "state_code": "806"
            },
            {
                "id": 4547,
                "name": "Pardubice",
                "state_code": "532"
            },
            {
                "id": 4588,
                "name": "Pardubický kraj",
                "state_code": "53"
            },
            {
                "id": 4645,
                "name": "Pelhřimov",
                "state_code": "633"
            },
            {
                "id": 4560,
                "name": "Písek",
                "state_code": "314"
            },
            {
                "id": 4608,
                "name": "Plzeň-jih",
                "state_code": "324"
            },
            {
                "id": 4544,
                "name": "Plzeň-město",
                "state_code": "323"
            },
            {
                "id": 4564,
                "name": "Plzeň-sever",
                "state_code": "325"
            },
            {
                "id": 4607,
                "name": "Plzeňský kraj",
                "state_code": "32"
            },
            {
                "id": 4578,
                "name": "Prachatice",
                "state_code": "315"
            },
            {
                "id": 4606,
                "name": "Praha-východ",
                "state_code": "209"
            },
            {
                "id": 4619,
                "name": "Praha-západ",
                "state_code": "20A"
            },
            {
                "id": 4598,
                "name": "Praha, Hlavní město",
                "state_code": "10"
            },
            {
                "id": 4626,
                "name": "Přerov",
                "state_code": "714"
            },
            {
                "id": 4546,
                "name": "Příbram",
                "state_code": "20B"
            },
            {
                "id": 4551,
                "name": "Prostějov",
                "state_code": "713"
            },
            {
                "id": 4558,
                "name": "Rakovník",
                "state_code": "20C"
            },
            {
                "id": 4583,
                "name": "Rokycany",
                "state_code": "326"
            },
            {
                "id": 4636,
                "name": "Rychnov nad Kněžnou",
                "state_code": "524"
            },
            {
                "id": 4596,
                "name": "Semily",
                "state_code": "514"
            },
            {
                "id": 4595,
                "name": "Sokolov",
                "state_code": "413"
            },
            {
                "id": 4628,
                "name": "Strakonice",
                "state_code": "316"
            },
            {
                "id": 4554,
                "name": "Středočeský kraj",
                "state_code": "20"
            },
            {
                "id": 4642,
                "name": "Šumperk",
                "state_code": "715"
            },
            {
                "id": 4571,
                "name": "Svitavy",
                "state_code": "533"
            },
            {
                "id": 4565,
                "name": "Tábor",
                "state_code": "317"
            },
            {
                "id": 4646,
                "name": "Tachov",
                "state_code": "327"
            },
            {
                "id": 4621,
                "name": "Teplice",
                "state_code": "426"
            },
            {
                "id": 4597,
                "name": "Třebíč",
                "state_code": "634"
            },
            {
                "id": 4579,
                "name": "Trutnov",
                "state_code": "525"
            },
            {
                "id": 4592,
                "name": "Uherské Hradiště",
                "state_code": "722"
            },
            {
                "id": 4576,
                "name": "Ústecký kraj",
                "state_code": "42"
            },
            {
                "id": 4599,
                "name": "Ústí nad Labem",
                "state_code": "427"
            },
            {
                "id": 4647,
                "name": "Ústí nad Orlicí",
                "state_code": "534"
            },
            {
                "id": 4572,
                "name": "Vsetín",
                "state_code": "723"
            },
            {
                "id": 4622,
                "name": "Vyškov",
                "state_code": "646"
            },
            {
                "id": 4648,
                "name": "Žďár nad Sázavou",
                "state_code": "635"
            },
            {
                "id": 4563,
                "name": "Zlín",
                "state_code": "724"
            },
            {
                "id": 4552,
                "name": "Zlínský kraj",
                "state_code": "72"
            },
            {
                "id": 4630,
                "name": "Znojmo",
                "state_code": "647"
            }
        ]
    },
    {
        "id": 51,
        "states": [
            {
                "id": 2753,
                "name": "Bas-Uélé",
                "state_code": "BU"
            },
            {
                "id": 2744,
                "name": "Équateur",
                "state_code": "EQ"
            },
            {
                "id": 2750,
                "name": "Haut-Katanga",
                "state_code": "HK"
            },
            {
                "id": 2758,
                "name": "Haut-Lomami",
                "state_code": "HL"
            },
            {
                "id": 2734,
                "name": "Haut-Uélé",
                "state_code": "HU"
            },
            {
                "id": 2751,
                "name": "Ituri",
                "state_code": "IT"
            },
            {
                "id": 2757,
                "name": "Kasaï",
                "state_code": "KS"
            },
            {
                "id": 2742,
                "name": "Kasaï Central",
                "state_code": "KC"
            },
            {
                "id": 2735,
                "name": "Kasaï Oriental",
                "state_code": "KE"
            },
            {
                "id": 2741,
                "name": "Kinshasa",
                "state_code": "KN"
            },
            {
                "id": 2746,
                "name": "Kongo Central",
                "state_code": "BC"
            },
            {
                "id": 2740,
                "name": "Kwango",
                "state_code": "KG"
            },
            {
                "id": 2759,
                "name": "Kwilu",
                "state_code": "KL"
            },
            {
                "id": 2747,
                "name": "Lomami",
                "state_code": "LO"
            },
            {
                "id": 4953,
                "name": "Lualaba",
                "state_code": "LU"
            },
            {
                "id": 2755,
                "name": "Mai-Ndombe",
                "state_code": "MN"
            },
            {
                "id": 2745,
                "name": "Maniema",
                "state_code": "MA"
            },
            {
                "id": 2752,
                "name": "Mongala",
                "state_code": "MO"
            },
            {
                "id": 2749,
                "name": "Nord-Kivu",
                "state_code": "NK"
            },
            {
                "id": 2739,
                "name": "Nord-Ubangi",
                "state_code": "NU"
            },
            {
                "id": 2743,
                "name": "Sankuru",
                "state_code": "SA"
            },
            {
                "id": 2738,
                "name": "Sud-Kivu",
                "state_code": "SK"
            },
            {
                "id": 2748,
                "name": "Sud-Ubangi",
                "state_code": "SU"
            },
            {
                "id": 2733,
                "name": "Tanganyika",
                "state_code": "TA"
            },
            {
                "id": 2756,
                "name": "Tshopo",
                "state_code": "TO"
            },
            {
                "id": 2732,
                "name": "Tshuapa",
                "state_code": "TU"
            }
        ]
    },
    {
        "id": 59,
        "states": [
            {
                "id": 1530,
                "name": "Capital Region of Denmark",
                "state_code": "84"
            },
            {
                "id": 1531,
                "name": "Central Denmark Region",
                "state_code": "82"
            },
            {
                "id": 1532,
                "name": "North Denmark Region",
                "state_code": "81"
            },
            {
                "id": 1529,
                "name": "Region of Southern Denmark",
                "state_code": "83"
            },
            {
                "id": 1528,
                "name": "Region Zealand",
                "state_code": "85"
            }
        ]
    },
    {
        "id": 60,
        "states": [
            {
                "id": 2933,
                "name": "Ali Sabieh Region",
                "state_code": "AS"
            },
            {
                "id": 2932,
                "name": "Arta Region",
                "state_code": "AR"
            },
            {
                "id": 2930,
                "name": "Dikhil Region",
                "state_code": "DI"
            },
            {
                "id": 2929,
                "name": "Djibouti",
                "state_code": "DJ"
            },
            {
                "id": 2928,
                "name": "Obock Region",
                "state_code": "OB"
            },
            {
                "id": 2931,
                "name": "Tadjourah Region",
                "state_code": "TA"
            }
        ]
    },
    {
        "id": 61,
        "states": [
            {
                "id": 4082,
                "name": "Saint Andrew Parish",
                "state_code": "02"
            },
            {
                "id": 4078,
                "name": "Saint David Parish",
                "state_code": "03"
            },
            {
                "id": 4079,
                "name": "Saint George Parish",
                "state_code": "04"
            },
            {
                "id": 4076,
                "name": "Saint John Parish",
                "state_code": "05"
            },
            {
                "id": 4085,
                "name": "Saint Joseph Parish",
                "state_code": "06"
            },
            {
                "id": 4083,
                "name": "Saint Luke Parish",
                "state_code": "07"
            },
            {
                "id": 4077,
                "name": "Saint Mark Parish",
                "state_code": "08"
            },
            {
                "id": 4080,
                "name": "Saint Patrick Parish",
                "state_code": "09"
            },
            {
                "id": 4084,
                "name": "Saint Paul Parish",
                "state_code": "10"
            },
            {
                "id": 4081,
                "name": "Saint Peter Parish",
                "state_code": "11"
            }
        ]
    },
    {
        "id": 62,
        "states": [
            {
                "id": 4114,
                "name": "Azua Province",
                "state_code": "02"
            },
            {
                "id": 4105,
                "name": "Baoruco Province",
                "state_code": "03"
            },
            {
                "id": 4090,
                "name": "Barahona Province",
                "state_code": "04"
            },
            {
                "id": 4107,
                "name": "Dajabón Province",
                "state_code": "05"
            },
            {
                "id": 4095,
                "name": "Distrito Nacional",
                "state_code": "01"
            },
            {
                "id": 4113,
                "name": "Duarte Province",
                "state_code": "06"
            },
            {
                "id": 4086,
                "name": "El Seibo Province",
                "state_code": "08"
            },
            {
                "id": 4102,
                "name": "Espaillat Province",
                "state_code": "09"
            },
            {
                "id": 4106,
                "name": "Hato Mayor Province",
                "state_code": "30"
            },
            {
                "id": 4089,
                "name": "Hermanas Mirabal Province",
                "state_code": "19"
            },
            {
                "id": 4097,
                "name": "Independencia",
                "state_code": "10"
            },
            {
                "id": 4109,
                "name": "La Altagracia Province",
                "state_code": "11"
            },
            {
                "id": 4087,
                "name": "La Romana Province",
                "state_code": "12"
            },
            {
                "id": 4116,
                "name": "La Vega Province",
                "state_code": "13"
            },
            {
                "id": 4094,
                "name": "María Trinidad Sánchez Province",
                "state_code": "14"
            },
            {
                "id": 4099,
                "name": "Monseñor Nouel Province",
                "state_code": "28"
            },
            {
                "id": 4115,
                "name": "Monte Cristi Province",
                "state_code": "15"
            },
            {
                "id": 4111,
                "name": "Monte Plata Province",
                "state_code": "29"
            },
            {
                "id": 4101,
                "name": "Pedernales Province",
                "state_code": "16"
            },
            {
                "id": 4096,
                "name": "Peravia Province",
                "state_code": "17"
            },
            {
                "id": 4092,
                "name": "Puerto Plata Province",
                "state_code": "18"
            },
            {
                "id": 4103,
                "name": "Samaná Province",
                "state_code": "20"
            },
            {
                "id": 4091,
                "name": "San Cristóbal Province",
                "state_code": "21"
            },
            {
                "id": 4112,
                "name": "San José de Ocoa Province",
                "state_code": "31"
            },
            {
                "id": 4098,
                "name": "San Juan Province",
                "state_code": "22"
            },
            {
                "id": 4110,
                "name": "San Pedro de Macorís",
                "state_code": "23"
            },
            {
                "id": 4088,
                "name": "Sánchez Ramírez Province",
                "state_code": "24"
            },
            {
                "id": 4108,
                "name": "Santiago Province",
                "state_code": "25"
            },
            {
                "id": 4100,
                "name": "Santiago Rodríguez Province",
                "state_code": "26"
            },
            {
                "id": 4093,
                "name": "Santo Domingo Province",
                "state_code": "32"
            },
            {
                "id": 4104,
                "name": "Valverde Province",
                "state_code": "27"
            }
        ]
    },
    {
        "id": 63,
        "states": [
            {
                "id": 4520,
                "name": "Aileu municipality",
                "state_code": "AL"
            },
            {
                "id": 4518,
                "name": "Ainaro Municipality",
                "state_code": "AN"
            },
            {
                "id": 4521,
                "name": "Baucau Municipality",
                "state_code": "BA"
            },
            {
                "id": 4525,
                "name": "Bobonaro Municipality",
                "state_code": "BO"
            },
            {
                "id": 4522,
                "name": "Cova Lima Municipality",
                "state_code": "CO"
            },
            {
                "id": 4524,
                "name": "Dili municipality",
                "state_code": "DI"
            },
            {
                "id": 4516,
                "name": "Ermera District",
                "state_code": "ER"
            },
            {
                "id": 4523,
                "name": "Lautém Municipality",
                "state_code": "LA"
            },
            {
                "id": 4515,
                "name": "Liquiçá Municipality",
                "state_code": "LI"
            },
            {
                "id": 4517,
                "name": "Manatuto District",
                "state_code": "MT"
            },
            {
                "id": 4519,
                "name": "Manufahi Municipality",
                "state_code": "MF"
            },
            {
                "id": 4514,
                "name": "Viqueque Municipality",
                "state_code": "VI"
            }
        ]
    },
    {
        "id": 64,
        "states": [
            {
                "id": 2923,
                "name": "Azuay",
                "state_code": "A"
            },
            {
                "id": 2920,
                "name": "Bolívar",
                "state_code": "B"
            },
            {
                "id": 2917,
                "name": "Cañar",
                "state_code": "F"
            },
            {
                "id": 2915,
                "name": "Carchi",
                "state_code": "C"
            },
            {
                "id": 2925,
                "name": "Chimborazo",
                "state_code": "H"
            },
            {
                "id": 2921,
                "name": "Cotopaxi",
                "state_code": "X"
            },
            {
                "id": 2924,
                "name": "El Oro",
                "state_code": "O"
            },
            {
                "id": 2922,
                "name": "Esmeraldas",
                "state_code": "E"
            },
            {
                "id": 2905,
                "name": "Galápagos",
                "state_code": "W"
            },
            {
                "id": 2914,
                "name": "Guayas",
                "state_code": "G"
            },
            {
                "id": 2911,
                "name": "Imbabura",
                "state_code": "I"
            },
            {
                "id": 5068,
                "name": "Loja",
                "state_code": "L"
            },
            {
                "id": 2910,
                "name": "Los Ríos",
                "state_code": "R"
            },
            {
                "id": 2913,
                "name": "Manabí",
                "state_code": "M"
            },
            {
                "id": 2918,
                "name": "Morona-Santiago",
                "state_code": "S"
            },
            {
                "id": 2916,
                "name": "Napo",
                "state_code": "N"
            },
            {
                "id": 2926,
                "name": "Orellana",
                "state_code": "D"
            },
            {
                "id": 2907,
                "name": "Pastaza",
                "state_code": "Y"
            },
            {
                "id": 2927,
                "name": "Pichincha",
                "state_code": "P"
            },
            {
                "id": 2912,
                "name": "Santa Elena",
                "state_code": "SE"
            },
            {
                "id": 2919,
                "name": "Santo Domingo de los Tsáchilas",
                "state_code": "SD"
            },
            {
                "id": 2906,
                "name": "Sucumbíos",
                "state_code": "U"
            },
            {
                "id": 2908,
                "name": "Tungurahua",
                "state_code": "T"
            },
            {
                "id": 2909,
                "name": "Zamora Chinchipe",
                "state_code": "Z"
            }
        ]
    },
    {
        "id": 65,
        "states": [
            {
                "id": 3235,
                "name": "Alexandria",
                "state_code": "ALX"
            },
            {
                "id": 3225,
                "name": "Aswan",
                "state_code": "ASN"
            },
            {
                "id": 3236,
                "name": "Asyut",
                "state_code": "AST"
            },
            {
                "id": 3241,
                "name": "Beheira",
                "state_code": "BH"
            },
            {
                "id": 3230,
                "name": "Beni Suef",
                "state_code": "BNS"
            },
            {
                "id": 3223,
                "name": "Cairo",
                "state_code": "C"
            },
            {
                "id": 3245,
                "name": "Dakahlia",
                "state_code": "DK"
            },
            {
                "id": 3224,
                "name": "Damietta",
                "state_code": "DT"
            },
            {
                "id": 3238,
                "name": "Faiyum",
                "state_code": "FYM"
            },
            {
                "id": 3234,
                "name": "Gharbia",
                "state_code": "GH"
            },
            {
                "id": 3239,
                "name": "Giza",
                "state_code": "GZ"
            },
            {
                "id": 3244,
                "name": "Ismailia",
                "state_code": "IS"
            },
            {
                "id": 3222,
                "name": "Kafr el-Sheikh",
                "state_code": "KFS"
            },
            {
                "id": 3242,
                "name": "Luxor",
                "state_code": "LX"
            },
            {
                "id": 3231,
                "name": "Matrouh",
                "state_code": "MT"
            },
            {
                "id": 3243,
                "name": "Minya",
                "state_code": "MN"
            },
            {
                "id": 3228,
                "name": "Monufia",
                "state_code": "MNF"
            },
            {
                "id": 3246,
                "name": "New Valley",
                "state_code": "WAD"
            },
            {
                "id": 3227,
                "name": "North Sinai",
                "state_code": "SIN"
            },
            {
                "id": 3229,
                "name": "Port Said",
                "state_code": "PTS"
            },
            {
                "id": 3232,
                "name": "Qalyubia",
                "state_code": "KB"
            },
            {
                "id": 3247,
                "name": "Qena",
                "state_code": "KN"
            },
            {
                "id": 3240,
                "name": "Red Sea",
                "state_code": "BA"
            },
            {
                "id": 5067,
                "name": "Sharqia",
                "state_code": "SHR"
            },
            {
                "id": 3226,
                "name": "Sohag",
                "state_code": "SHG"
            },
            {
                "id": 3237,
                "name": "South Sinai",
                "state_code": "JS"
            },
            {
                "id": 3233,
                "name": "Suez",
                "state_code": "SUZ"
            }
        ]
    },
    {
        "id": 66,
        "states": [
            {
                "id": 4139,
                "name": "Ahuachapán Department",
                "state_code": "AH"
            },
            {
                "id": 4132,
                "name": "Cabañas Department",
                "state_code": "CA"
            },
            {
                "id": 4131,
                "name": "Chalatenango Department",
                "state_code": "CH"
            },
            {
                "id": 4137,
                "name": "Cuscatlán Department",
                "state_code": "CU"
            },
            {
                "id": 4134,
                "name": "La Libertad Department",
                "state_code": "LI"
            },
            {
                "id": 4136,
                "name": "La Paz Department",
                "state_code": "PA"
            },
            {
                "id": 4138,
                "name": "La Unión Department",
                "state_code": "UN"
            },
            {
                "id": 4130,
                "name": "Morazán Department",
                "state_code": "MO"
            },
            {
                "id": 4135,
                "name": "San Miguel Department",
                "state_code": "SM"
            },
            {
                "id": 4133,
                "name": "San Salvador Department",
                "state_code": "SS"
            },
            {
                "id": 4127,
                "name": "San Vicente Department",
                "state_code": "SV"
            },
            {
                "id": 4128,
                "name": "Santa Ana Department",
                "state_code": "SA"
            },
            {
                "id": 4140,
                "name": "Sonsonate Department",
                "state_code": "SO"
            },
            {
                "id": 4129,
                "name": "Usulután Department",
                "state_code": "US"
            }
        ]
    },
    {
        "id": 67,
        "states": [
            {
                "id": 3444,
                "name": "Annobón Province",
                "state_code": "AN"
            },
            {
                "id": 3446,
                "name": "Bioko Norte Province",
                "state_code": "BN"
            },
            {
                "id": 3443,
                "name": "Bioko Sur Province",
                "state_code": "BS"
            },
            {
                "id": 3445,
                "name": "Centro Sur Province",
                "state_code": "CS"
            },
            {
                "id": 3442,
                "name": "Insular Region",
                "state_code": "I"
            },
            {
                "id": 3439,
                "name": "Kié-Ntem Province",
                "state_code": "KN"
            },
            {
                "id": 3441,
                "name": "Litoral Province",
                "state_code": "LI"
            },
            {
                "id": 3438,
                "name": "Río Muni",
                "state_code": "C"
            },
            {
                "id": 3440,
                "name": "Wele-Nzas Province",
                "state_code": "WN"
            }
        ]
    },
    {
        "id": 68,
        "states": [
            {
                "id": 3425,
                "name": "Anseba Region",
                "state_code": "AN"
            },
            {
                "id": 3427,
                "name": "Debub Region",
                "state_code": "DU"
            },
            {
                "id": 3428,
                "name": "Gash-Barka Region",
                "state_code": "GB"
            },
            {
                "id": 3426,
                "name": "Maekel Region",
                "state_code": "MA"
            },
            {
                "id": 3424,
                "name": "Northern Red Sea Region",
                "state_code": "SK"
            },
            {
                "id": 3429,
                "name": "Southern Red Sea Region",
                "state_code": "DK"
            }
        ]
    },
    {
        "id": 69,
        "states": [
            {
                "id": 3567,
                "name": "Harju County",
                "state_code": "37"
            },
            {
                "id": 3555,
                "name": "Hiiu County",
                "state_code": "39"
            },
            {
                "id": 3569,
                "name": "Ida-Viru County",
                "state_code": "44"
            },
            {
                "id": 3566,
                "name": "Järva County",
                "state_code": "51"
            },
            {
                "id": 3565,
                "name": "Jõgeva County",
                "state_code": "49"
            },
            {
                "id": 3568,
                "name": "Lääne County",
                "state_code": "57"
            },
            {
                "id": 3564,
                "name": "Lääne-Viru County",
                "state_code": "59"
            },
            {
                "id": 3562,
                "name": "Pärnu County",
                "state_code": "67"
            },
            {
                "id": 3563,
                "name": "Põlva County",
                "state_code": "65"
            },
            {
                "id": 3559,
                "name": "Rapla County",
                "state_code": "70"
            },
            {
                "id": 3561,
                "name": "Saare County",
                "state_code": "74"
            },
            {
                "id": 3557,
                "name": "Tartu County",
                "state_code": "78"
            },
            {
                "id": 3558,
                "name": "Valga County",
                "state_code": "82"
            },
            {
                "id": 3556,
                "name": "Viljandi County",
                "state_code": "84"
            },
            {
                "id": 3560,
                "name": "Võru County",
                "state_code": "86"
            }
        ]
    },
    {
        "id": 70,
        "states": [
            {
                "id": 11,
                "name": "Addis Ababa",
                "state_code": "AA"
            },
            {
                "id": 6,
                "name": "Afar Region",
                "state_code": "AF"
            },
            {
                "id": 3,
                "name": "Amhara Region",
                "state_code": "AM"
            },
            {
                "id": 9,
                "name": "Benishangul-Gumuz Region",
                "state_code": "BE"
            },
            {
                "id": 8,
                "name": "Dire Dawa",
                "state_code": "DD"
            },
            {
                "id": 10,
                "name": "Gambela Region",
                "state_code": "GA"
            },
            {
                "id": 7,
                "name": "Harari Region",
                "state_code": "HA"
            },
            {
                "id": 5,
                "name": "Oromia Region",
                "state_code": "OR"
            },
            {
                "id": 2,
                "name": "Somali Region",
                "state_code": "SO"
            },
            {
                "id": 1,
                "name": "Southern Nations, Nationalities, and Peoples' Region",
                "state_code": "SN"
            },
            {
                "id": 4,
                "name": "Tigray Region",
                "state_code": "TI"
            }
        ]
    },
    {
        "id": 71,
        "states": []
    },
    {
        "id": 72,
        "states": []
    },
    {
        "id": 73,
        "states": [
            {
                "id": 1917,
                "name": "Ba",
                "state_code": "01"
            },
            {
                "id": 1930,
                "name": "Bua",
                "state_code": "02"
            },
            {
                "id": 1924,
                "name": "Cakaudrove",
                "state_code": "03"
            },
            {
                "id": 1929,
                "name": "Central Division",
                "state_code": "C"
            },
            {
                "id": 1932,
                "name": "Eastern Division",
                "state_code": "E"
            },
            {
                "id": 1934,
                "name": "Kadavu",
                "state_code": "04"
            },
            {
                "id": 1933,
                "name": "Lau",
                "state_code": "05"
            },
            {
                "id": 1916,
                "name": "Lomaiviti",
                "state_code": "06"
            },
            {
                "id": 1922,
                "name": "Macuata",
                "state_code": "07"
            },
            {
                "id": 1919,
                "name": "Nadroga-Navosa",
                "state_code": "08"
            },
            {
                "id": 1927,
                "name": "Naitasiri",
                "state_code": "09"
            },
            {
                "id": 1928,
                "name": "Namosi",
                "state_code": "10"
            },
            {
                "id": 1921,
                "name": "Northern Division",
                "state_code": "N"
            },
            {
                "id": 1926,
                "name": "Ra",
                "state_code": "11"
            },
            {
                "id": 1920,
                "name": "Rewa",
                "state_code": "12"
            },
            {
                "id": 1931,
                "name": "Rotuma",
                "state_code": "R"
            },
            {
                "id": 1925,
                "name": "Serua",
                "state_code": "13"
            },
            {
                "id": 1918,
                "name": "Tailevu",
                "state_code": "14"
            },
            {
                "id": 1923,
                "name": "Western Division",
                "state_code": "W"
            }
        ]
    },
    {
        "id": 74,
        "states": [
            {
                "id": 1509,
                "name": "Åland Islands",
                "state_code": "01"
            },
            {
                "id": 1511,
                "name": "Central Finland",
                "state_code": "08"
            },
            {
                "id": 1494,
                "name": "Central Ostrobothnia",
                "state_code": "07"
            },
            {
                "id": 1507,
                "name": "Finland Proper",
                "state_code": "19"
            },
            {
                "id": 1496,
                "name": "Kainuu",
                "state_code": "05"
            },
            {
                "id": 1512,
                "name": "Kymenlaakso",
                "state_code": "09"
            },
            {
                "id": 1500,
                "name": "Lapland",
                "state_code": "10"
            },
            {
                "id": 1504,
                "name": "North Karelia",
                "state_code": "13"
            },
            {
                "id": 1505,
                "name": "Northern Ostrobothnia",
                "state_code": "14"
            },
            {
                "id": 1503,
                "name": "Northern Savonia",
                "state_code": "15"
            },
            {
                "id": 1508,
                "name": "Ostrobothnia",
                "state_code": "12"
            },
            {
                "id": 1502,
                "name": "Päijänne Tavastia",
                "state_code": "16"
            },
            {
                "id": 1506,
                "name": "Pirkanmaa",
                "state_code": "11"
            },
            {
                "id": 1501,
                "name": "Satakunta",
                "state_code": "17"
            },
            {
                "id": 1497,
                "name": "South Karelia",
                "state_code": "02"
            },
            {
                "id": 1498,
                "name": "Southern Ostrobothnia",
                "state_code": "03"
            },
            {
                "id": 1495,
                "name": "Southern Savonia",
                "state_code": "04"
            },
            {
                "id": 1493,
                "name": "Tavastia Proper",
                "state_code": "06"
            },
            {
                "id": 1510,
                "name": "Uusimaa",
                "state_code": "18"
            }
        ]
    },
    {
        "id": 75,
        "states": [
            {
                "id": 4967,
                "name": "Ain",
                "state_code": "01"
            },
            {
                "id": 4968,
                "name": "Aisne",
                "state_code": "02"
            },
            {
                "id": 4969,
                "name": "Allier",
                "state_code": "03"
            },
            {
                "id": 4970,
                "name": "Alpes-de-Haute-Provence",
                "state_code": "04"
            },
            {
                "id": 4972,
                "name": "Alpes-Maritimes",
                "state_code": "06"
            },
            {
                "id": 4811,
                "name": "Alsace",
                "state_code": "6AE"
            },
            {
                "id": 4973,
                "name": "Ardèche",
                "state_code": "07"
            },
            {
                "id": 4974,
                "name": "Ardennes",
                "state_code": "08"
            },
            {
                "id": 4975,
                "name": "Ariège",
                "state_code": "09"
            },
            {
                "id": 4976,
                "name": "Aube",
                "state_code": "10"
            },
            {
                "id": 4977,
                "name": "Aude",
                "state_code": "11"
            },
            {
                "id": 4798,
                "name": "Auvergne-Rhône-Alpes",
                "state_code": "ARA"
            },
            {
                "id": 4978,
                "name": "Aveyron",
                "state_code": "12"
            },
            {
                "id": 5035,
                "name": "Bas-Rhin",
                "state_code": "67"
            },
            {
                "id": 4979,
                "name": "Bouches-du-Rhône",
                "state_code": "13"
            },
            {
                "id": 4825,
                "name": "Bourgogne-Franche-Comté",
                "state_code": "BFC"
            },
            {
                "id": 4807,
                "name": "Bretagne",
                "state_code": "BRE"
            },
            {
                "id": 4981,
                "name": "Calvados",
                "state_code": "14"
            },
            {
                "id": 4982,
                "name": "Cantal",
                "state_code": "15"
            },
            {
                "id": 4818,
                "name": "Centre-Val de Loire",
                "state_code": "CVL"
            },
            {
                "id": 4983,
                "name": "Charente",
                "state_code": "16"
            },
            {
                "id": 4984,
                "name": "Charente-Maritime",
                "state_code": "17"
            },
            {
                "id": 4985,
                "name": "Cher",
                "state_code": "18"
            },
            {
                "id": 5064,
                "name": "Clipperton",
                "state_code": "CP"
            },
            {
                "id": 4986,
                "name": "Corrèze",
                "state_code": "19"
            },
            {
                "id": 4806,
                "name": "Corse",
                "state_code": "20R"
            },
            {
                "id": 4996,
                "name": "Corse-du-Sud",
                "state_code": "2A"
            },
            {
                "id": 4987,
                "name": "Côte-d'Or",
                "state_code": "21"
            },
            {
                "id": 4988,
                "name": "Côtes-d'Armor",
                "state_code": "22"
            },
            {
                "id": 4989,
                "name": "Creuse",
                "state_code": "23"
            },
            {
                "id": 5047,
                "name": "Deux-Sèvres",
                "state_code": "79"
            },
            {
                "id": 4990,
                "name": "Dordogne",
                "state_code": "24"
            },
            {
                "id": 4991,
                "name": "Doubs",
                "state_code": "25"
            },
            {
                "id": 4992,
                "name": "Drôme",
                "state_code": "26"
            },
            {
                "id": 5059,
                "name": "Essonne",
                "state_code": "91"
            },
            {
                "id": 4993,
                "name": "Eure",
                "state_code": "27"
            },
            {
                "id": 4994,
                "name": "Eure-et-Loir",
                "state_code": "28"
            },
            {
                "id": 4995,
                "name": "Finistère",
                "state_code": "29"
            },
            {
                "id": 4822,
                "name": "French Guiana",
                "state_code": "973"
            },
            {
                "id": 4824,
                "name": "French Polynesia",
                "state_code": "PF"
            },
            {
                "id": 5065,
                "name": "French Southern and Antarctic Lands",
                "state_code": "TF"
            },
            {
                "id": 4998,
                "name": "Gard",
                "state_code": "30"
            },
            {
                "id": 5000,
                "name": "Gers",
                "state_code": "32"
            },
            {
                "id": 5001,
                "name": "Gironde",
                "state_code": "33"
            },
            {
                "id": 4820,
                "name": "Grand-Est",
                "state_code": "GES"
            },
            {
                "id": 4829,
                "name": "Guadeloupe",
                "state_code": "971"
            },
            {
                "id": 5036,
                "name": "Haut-Rhin",
                "state_code": "68"
            },
            {
                "id": 4997,
                "name": "Haute-Corse",
                "state_code": "2B"
            },
            {
                "id": 4999,
                "name": "Haute-Garonne",
                "state_code": "31"
            },
            {
                "id": 5011,
                "name": "Haute-Loire",
                "state_code": "43"
            },
            {
                "id": 5020,
                "name": "Haute-Marne",
                "state_code": "52"
            },
            {
                "id": 5039,
                "name": "Haute-Saône",
                "state_code": "70"
            },
            {
                "id": 5043,
                "name": "Haute-Savoie",
                "state_code": "74"
            },
            {
                "id": 5055,
                "name": "Haute-Vienne",
                "state_code": "87"
            },
            {
                "id": 4971,
                "name": "Hautes-Alpes",
                "state_code": "05"
            },
            {
                "id": 5033,
                "name": "Hautes-Pyrénées",
                "state_code": "65"
            },
            {
                "id": 4828,
                "name": "Hauts-de-France",
                "state_code": "HDF"
            },
            {
                "id": 5060,
                "name": "Hauts-de-Seine",
                "state_code": "92"
            },
            {
                "id": 5002,
                "name": "Hérault",
                "state_code": "34"
            },
            {
                "id": 4796,
                "name": "Île-de-France",
                "state_code": "IDF"
            },
            {
                "id": 5003,
                "name": "Ille-et-Vilaine",
                "state_code": "35"
            },
            {
                "id": 5004,
                "name": "Indre",
                "state_code": "36"
            },
            {
                "id": 5005,
                "name": "Indre-et-Loire",
                "state_code": "37"
            },
            {
                "id": 5006,
                "name": "Isère",
                "state_code": "38"
            },
            {
                "id": 5007,
                "name": "Jura",
                "state_code": "39"
            },
            {
                "id": 4823,
                "name": "La Réunion",
                "state_code": "974"
            },
            {
                "id": 5008,
                "name": "Landes",
                "state_code": "40"
            },
            {
                "id": 5009,
                "name": "Loir-et-Cher",
                "state_code": "41"
            },
            {
                "id": 5010,
                "name": "Loire",
                "state_code": "42"
            },
            {
                "id": 5012,
                "name": "Loire-Atlantique",
                "state_code": "44"
            },
            {
                "id": 5013,
                "name": "Loiret",
                "state_code": "45"
            },
            {
                "id": 5014,
                "name": "Lot",
                "state_code": "46"
            },
            {
                "id": 5015,
                "name": "Lot-et-Garonne",
                "state_code": "47"
            },
            {
                "id": 5016,
                "name": "Lozère",
                "state_code": "48"
            },
            {
                "id": 5017,
                "name": "Maine-et-Loire",
                "state_code": "49"
            },
            {
                "id": 5018,
                "name": "Manche",
                "state_code": "50"
            },
            {
                "id": 5019,
                "name": "Marne",
                "state_code": "51"
            },
            {
                "id": 4827,
                "name": "Martinique",
                "state_code": "972"
            },
            {
                "id": 5021,
                "name": "Mayenne",
                "state_code": "53"
            },
            {
                "id": 4797,
                "name": "Mayotte",
                "state_code": "976"
            },
            {
                "id": 5038,
                "name": "Métropole de Lyon",
                "state_code": "69M"
            },
            {
                "id": 5022,
                "name": "Meurthe-et-Moselle",
                "state_code": "54"
            },
            {
                "id": 5023,
                "name": "Meuse",
                "state_code": "55"
            },
            {
                "id": 5024,
                "name": "Morbihan",
                "state_code": "56"
            },
            {
                "id": 5025,
                "name": "Moselle",
                "state_code": "57"
            },
            {
                "id": 5026,
                "name": "Nièvre",
                "state_code": "58"
            },
            {
                "id": 5027,
                "name": "Nord",
                "state_code": "59"
            },
            {
                "id": 4804,
                "name": "Normandie",
                "state_code": "NOR"
            },
            {
                "id": 4795,
                "name": "Nouvelle-Aquitaine",
                "state_code": "NAQ"
            },
            {
                "id": 4799,
                "name": "Occitanie",
                "state_code": "OCC"
            },
            {
                "id": 5028,
                "name": "Oise",
                "state_code": "60"
            },
            {
                "id": 5029,
                "name": "Orne",
                "state_code": "61"
            },
            {
                "id": 4816,
                "name": "Paris",
                "state_code": "75C"
            },
            {
                "id": 5030,
                "name": "Pas-de-Calais",
                "state_code": "62"
            },
            {
                "id": 4802,
                "name": "Pays-de-la-Loire",
                "state_code": "PDL"
            },
            {
                "id": 4812,
                "name": "Provence-Alpes-Côte-d’Azur",
                "state_code": "PAC"
            },
            {
                "id": 5031,
                "name": "Puy-de-Dôme",
                "state_code": "63"
            },
            {
                "id": 5032,
                "name": "Pyrénées-Atlantiques",
                "state_code": "64"
            },
            {
                "id": 5034,
                "name": "Pyrénées-Orientales",
                "state_code": "66"
            },
            {
                "id": 5037,
                "name": "Rhône",
                "state_code": "69"
            },
            {
                "id": 4821,
                "name": "Saint Pierre and Miquelon",
                "state_code": "PM"
            },
            {
                "id": 4794,
                "name": "Saint-Barthélemy",
                "state_code": "BL"
            },
            {
                "id": 4809,
                "name": "Saint-Martin",
                "state_code": "MF"
            },
            {
                "id": 5040,
                "name": "Saône-et-Loire",
                "state_code": "71"
            },
            {
                "id": 5041,
                "name": "Sarthe",
                "state_code": "72"
            },
            {
                "id": 5042,
                "name": "Savoie",
                "state_code": "73"
            },
            {
                "id": 5045,
                "name": "Seine-et-Marne",
                "state_code": "77"
            },
            {
                "id": 5044,
                "name": "Seine-Maritime",
                "state_code": "76"
            },
            {
                "id": 5061,
                "name": "Seine-Saint-Denis",
                "state_code": "93"
            },
            {
                "id": 5048,
                "name": "Somme",
                "state_code": "80"
            },
            {
                "id": 5049,
                "name": "Tarn",
                "state_code": "81"
            },
            {
                "id": 5050,
                "name": "Tarn-et-Garonne",
                "state_code": "82"
            },
            {
                "id": 5058,
                "name": "Territoire de Belfort",
                "state_code": "90"
            },
            {
                "id": 5063,
                "name": "Val-d'Oise",
                "state_code": "95"
            },
            {
                "id": 5062,
                "name": "Val-de-Marne",
                "state_code": "94"
            },
            {
                "id": 5051,
                "name": "Var",
                "state_code": "83"
            },
            {
                "id": 5052,
                "name": "Vaucluse",
                "state_code": "84"
            },
            {
                "id": 5053,
                "name": "Vendée",
                "state_code": "85"
            },
            {
                "id": 5054,
                "name": "Vienne",
                "state_code": "86"
            },
            {
                "id": 5056,
                "name": "Vosges",
                "state_code": "88"
            },
            {
                "id": 4810,
                "name": "Wallis and Futuna",
                "state_code": "WF"
            },
            {
                "id": 5057,
                "name": "Yonne",
                "state_code": "89"
            },
            {
                "id": 5046,
                "name": "Yvelines",
                "state_code": "78"
            }
        ]
    },
    {
        "id": 76,
        "states": []
    },
    {
        "id": 77,
        "states": []
    },
    {
        "id": 78,
        "states": []
    },
    {
        "id": 79,
        "states": [
            {
                "id": 2727,
                "name": "Estuaire Province",
                "state_code": "1"
            },
            {
                "id": 2726,
                "name": "Haut-Ogooué Province",
                "state_code": "2"
            },
            {
                "id": 2730,
                "name": "Moyen-Ogooué Province",
                "state_code": "3"
            },
            {
                "id": 2731,
                "name": "Ngounié Province",
                "state_code": "4"
            },
            {
                "id": 2725,
                "name": "Nyanga Province",
                "state_code": "5"
            },
            {
                "id": 2724,
                "name": "Ogooué-Ivindo Province",
                "state_code": "6"
            },
            {
                "id": 2729,
                "name": "Ogooué-Lolo Province",
                "state_code": "7"
            },
            {
                "id": 2728,
                "name": "Ogooué-Maritime Province",
                "state_code": "8"
            },
            {
                "id": 2723,
                "name": "Woleu-Ntem Province",
                "state_code": "9"
            }
        ]
    },
    {
        "id": 80,
        "states": [
            {
                "id": 2666,
                "name": "Banjul",
                "state_code": "B"
            },
            {
                "id": 2669,
                "name": "Central River Division",
                "state_code": "M"
            },
            {
                "id": 2670,
                "name": "Lower River Division",
                "state_code": "L"
            },
            {
                "id": 2671,
                "name": "North Bank Division",
                "state_code": "N"
            },
            {
                "id": 2668,
                "name": "Upper River Division",
                "state_code": "U"
            },
            {
                "id": 2667,
                "name": "West Coast Division",
                "state_code": "W"
            }
        ]
    },
    {
        "id": 81,
        "states": [
            {
                "id": 900,
                "name": "Adjara",
                "state_code": "AJ"
            },
            {
                "id": 901,
                "name": "Autonomous Republic of Abkhazia",
                "state_code": "AB"
            },
            {
                "id": 907,
                "name": "Guria",
                "state_code": "GU"
            },
            {
                "id": 905,
                "name": "Imereti",
                "state_code": "IM"
            },
            {
                "id": 910,
                "name": "Kakheti",
                "state_code": "KA"
            },
            {
                "id": 897,
                "name": "Khelvachauri Municipality",
                "state_code": "29"
            },
            {
                "id": 904,
                "name": "Kvemo Kartli",
                "state_code": "KK"
            },
            {
                "id": 902,
                "name": "Mtskheta-Mtianeti",
                "state_code": "MM"
            },
            {
                "id": 909,
                "name": "Racha-Lechkhumi and Kvemo Svaneti",
                "state_code": "RL"
            },
            {
                "id": 908,
                "name": "Samegrelo-Zemo Svaneti",
                "state_code": "SZ"
            },
            {
                "id": 906,
                "name": "Samtskhe-Javakheti",
                "state_code": "SJ"
            },
            {
                "id": 898,
                "name": "Senaki Municipality",
                "state_code": "50"
            },
            {
                "id": 903,
                "name": "Shida Kartli",
                "state_code": "SK"
            },
            {
                "id": 899,
                "name": "Tbilisi",
                "state_code": "TB"
            }
        ]
    },
    {
        "id": 82,
        "states": [
            {
                "id": 3006,
                "name": "Baden-Württemberg",
                "state_code": "BW"
            },
            {
                "id": 3009,
                "name": "Bavaria",
                "state_code": "BY"
            },
            {
                "id": 3010,
                "name": "Berlin",
                "state_code": "BE"
            },
            {
                "id": 3013,
                "name": "Brandenburg",
                "state_code": "BB"
            },
            {
                "id": 3014,
                "name": "Bremen",
                "state_code": "HB"
            },
            {
                "id": 3016,
                "name": "Hamburg",
                "state_code": "HH"
            },
            {
                "id": 3018,
                "name": "Hesse",
                "state_code": "HE"
            },
            {
                "id": 3008,
                "name": "Lower Saxony",
                "state_code": "NI"
            },
            {
                "id": 3007,
                "name": "Mecklenburg-Vorpommern",
                "state_code": "MV"
            },
            {
                "id": 3017,
                "name": "North Rhine-Westphalia",
                "state_code": "NW"
            },
            {
                "id": 3019,
                "name": "Rhineland-Palatinate",
                "state_code": "RP"
            },
            {
                "id": 3020,
                "name": "Saarland",
                "state_code": "SL"
            },
            {
                "id": 3021,
                "name": "Saxony",
                "state_code": "SN"
            },
            {
                "id": 3011,
                "name": "Saxony-Anhalt",
                "state_code": "ST"
            },
            {
                "id": 3005,
                "name": "Schleswig-Holstein",
                "state_code": "SH"
            },
            {
                "id": 3015,
                "name": "Thuringia",
                "state_code": "TH"
            }
        ]
    },
    {
        "id": 83,
        "states": [
            {
                "id": 53,
                "name": "Ahafo",
                "state_code": "AF"
            },
            {
                "id": 48,
                "name": "Ashanti",
                "state_code": "AH"
            },
            {
                "id": 4959,
                "name": "Bono",
                "state_code": "BO"
            },
            {
                "id": 4958,
                "name": "Bono East",
                "state_code": "BE"
            },
            {
                "id": 52,
                "name": "Central",
                "state_code": "CP"
            },
            {
                "id": 50,
                "name": "Eastern",
                "state_code": "EP"
            },
            {
                "id": 54,
                "name": "Greater Accra",
                "state_code": "AA"
            },
            {
                "id": 4960,
                "name": "North East",
                "state_code": "NE"
            },
            {
                "id": 51,
                "name": "Northern",
                "state_code": "NP"
            },
            {
                "id": 4961,
                "name": "Oti",
                "state_code": "OT"
            },
            {
                "id": 4962,
                "name": "Savannah",
                "state_code": "SV"
            },
            {
                "id": 55,
                "name": "Upper East",
                "state_code": "UE"
            },
            {
                "id": 57,
                "name": "Upper West",
                "state_code": "UW"
            },
            {
                "id": 56,
                "name": "Volta",
                "state_code": "TV"
            },
            {
                "id": 49,
                "name": "Western",
                "state_code": "WP"
            },
            {
                "id": 4963,
                "name": "Western North",
                "state_code": "WN"
            }
        ]
    },
    {
        "id": 84,
        "states": []
    },
    {
        "id": 85,
        "states": [
            {
                "id": 2116,
                "name": "Achaea Regional Unit",
                "state_code": "13"
            },
            {
                "id": 2123,
                "name": "Aetolia-Acarnania Regional Unit",
                "state_code": "01"
            },
            {
                "id": 2098,
                "name": "Arcadia Prefecture",
                "state_code": "12"
            },
            {
                "id": 2105,
                "name": "Argolis Regional Unit",
                "state_code": "11"
            },
            {
                "id": 2122,
                "name": "Attica Region",
                "state_code": "I"
            },
            {
                "id": 2126,
                "name": "Boeotia Regional Unit",
                "state_code": "03"
            },
            {
                "id": 2128,
                "name": "Central Greece Region",
                "state_code": "H"
            },
            {
                "id": 2125,
                "name": "Central Macedonia",
                "state_code": "B"
            },
            {
                "id": 2115,
                "name": "Chania Regional Unit",
                "state_code": "94"
            },
            {
                "id": 2124,
                "name": "Corfu Prefecture",
                "state_code": "22"
            },
            {
                "id": 2129,
                "name": "Corinthia Regional Unit",
                "state_code": "15"
            },
            {
                "id": 2109,
                "name": "Crete Region",
                "state_code": "M"
            },
            {
                "id": 2130,
                "name": "Drama Regional Unit",
                "state_code": "52"
            },
            {
                "id": 2120,
                "name": "East Attica Regional Unit",
                "state_code": "A2"
            },
            {
                "id": 2117,
                "name": "East Macedonia and Thrace",
                "state_code": "A"
            },
            {
                "id": 2110,
                "name": "Epirus Region",
                "state_code": "D"
            },
            {
                "id": 2101,
                "name": "Euboea",
                "state_code": "04"
            },
            {
                "id": 2102,
                "name": "Grevena Prefecture",
                "state_code": "51"
            },
            {
                "id": 2099,
                "name": "Imathia Regional Unit",
                "state_code": "53"
            },
            {
                "id": 2113,
                "name": "Ioannina Regional Unit",
                "state_code": "33"
            },
            {
                "id": 2131,
                "name": "Ionian Islands Region",
                "state_code": "F"
            },
            {
                "id": 2095,
                "name": "Karditsa Regional Unit",
                "state_code": "41"
            },
            {
                "id": 2100,
                "name": "Kastoria Regional Unit",
                "state_code": "56"
            },
            {
                "id": 2127,
                "name": "Kefalonia Prefecture",
                "state_code": "23"
            },
            {
                "id": 2111,
                "name": "Kilkis Regional Unit",
                "state_code": "57"
            },
            {
                "id": 2112,
                "name": "Kozani Prefecture",
                "state_code": "58"
            },
            {
                "id": 2106,
                "name": "Laconia",
                "state_code": "16"
            },
            {
                "id": 2132,
                "name": "Larissa Prefecture",
                "state_code": "42"
            },
            {
                "id": 2104,
                "name": "Lefkada Regional Unit",
                "state_code": "24"
            },
            {
                "id": 2107,
                "name": "Pella Regional Unit",
                "state_code": "59"
            },
            {
                "id": 2119,
                "name": "Peloponnese Region",
                "state_code": "J"
            },
            {
                "id": 2114,
                "name": "Phthiotis Prefecture",
                "state_code": "06"
            },
            {
                "id": 2103,
                "name": "Preveza Prefecture",
                "state_code": "34"
            },
            {
                "id": 2121,
                "name": "Serres Prefecture",
                "state_code": "62"
            },
            {
                "id": 2118,
                "name": "South Aegean",
                "state_code": "L"
            },
            {
                "id": 2097,
                "name": "Thessaloniki Regional Unit",
                "state_code": "54"
            },
            {
                "id": 2096,
                "name": "West Greece Region",
                "state_code": "G"
            },
            {
                "id": 2108,
                "name": "West Macedonia Region",
                "state_code": "C"
            }
        ]
    },
    {
        "id": 86,
        "states": []
    },
    {
        "id": 87,
        "states": [
            {
                "id": 3867,
                "name": "Carriacou and Petite Martinique",
                "state_code": "10"
            },
            {
                "id": 3865,
                "name": "Saint Andrew Parish",
                "state_code": "01"
            },
            {
                "id": 3869,
                "name": "Saint David Parish",
                "state_code": "02"
            },
            {
                "id": 3864,
                "name": "Saint George Parish",
                "state_code": "03"
            },
            {
                "id": 3868,
                "name": "Saint John Parish",
                "state_code": "04"
            },
            {
                "id": 3866,
                "name": "Saint Mark Parish",
                "state_code": "05"
            },
            {
                "id": 3863,
                "name": "Saint Patrick Parish",
                "state_code": "06"
            }
        ]
    },
    {
        "id": 88,
        "states": []
    },
    {
        "id": 89,
        "states": []
    },
    {
        "id": 90,
        "states": [
            {
                "id": 3671,
                "name": "Alta Verapaz Department",
                "state_code": "AV"
            },
            {
                "id": 3674,
                "name": "Baja Verapaz Department",
                "state_code": "BV"
            },
            {
                "id": 3675,
                "name": "Chimaltenango Department",
                "state_code": "CM"
            },
            {
                "id": 3666,
                "name": "Chiquimula Department",
                "state_code": "CQ"
            },
            {
                "id": 3662,
                "name": "El Progreso Department",
                "state_code": "PR"
            },
            {
                "id": 3677,
                "name": "Escuintla Department",
                "state_code": "ES"
            },
            {
                "id": 3672,
                "name": "Guatemala Department",
                "state_code": "GU"
            },
            {
                "id": 3670,
                "name": "Huehuetenango Department",
                "state_code": "HU"
            },
            {
                "id": 3659,
                "name": "Izabal Department",
                "state_code": "IZ"
            },
            {
                "id": 3658,
                "name": "Jalapa Department",
                "state_code": "JA"
            },
            {
                "id": 3673,
                "name": "Jutiapa Department",
                "state_code": "JU"
            },
            {
                "id": 3669,
                "name": "Petén Department",
                "state_code": "PE"
            },
            {
                "id": 3668,
                "name": "Quetzaltenango Department",
                "state_code": "QZ"
            },
            {
                "id": 3657,
                "name": "Quiché Department",
                "state_code": "QC"
            },
            {
                "id": 3664,
                "name": "Retalhuleu Department",
                "state_code": "RE"
            },
            {
                "id": 3676,
                "name": "Sacatepéquez Department",
                "state_code": "SA"
            },
            {
                "id": 3667,
                "name": "San Marcos Department",
                "state_code": "SM"
            },
            {
                "id": 3665,
                "name": "Santa Rosa Department",
                "state_code": "SR"
            },
            {
                "id": 3661,
                "name": "Sololá Department",
                "state_code": "SO"
            },
            {
                "id": 3660,
                "name": "Suchitepéquez Department",
                "state_code": "SU"
            },
            {
                "id": 3663,
                "name": "Totonicapán Department",
                "state_code": "TO"
            }
        ]
    },
    {
        "id": 91,
        "states": []
    },
    {
        "id": 92,
        "states": [
            {
                "id": 2672,
                "name": "Beyla Prefecture",
                "state_code": "BE"
            },
            {
                "id": 2699,
                "name": "Boffa Prefecture",
                "state_code": "BF"
            },
            {
                "id": 2709,
                "name": "Boké Prefecture",
                "state_code": "BK"
            },
            {
                "id": 2676,
                "name": "Boké Region",
                "state_code": "B"
            },
            {
                "id": 2686,
                "name": "Conakry",
                "state_code": "C"
            },
            {
                "id": 2705,
                "name": "Coyah Prefecture",
                "state_code": "CO"
            },
            {
                "id": 2679,
                "name": "Dabola Prefecture",
                "state_code": "DB"
            },
            {
                "id": 2706,
                "name": "Dalaba Prefecture",
                "state_code": "DL"
            },
            {
                "id": 2688,
                "name": "Dinguiraye Prefecture",
                "state_code": "DI"
            },
            {
                "id": 2681,
                "name": "Dubréka Prefecture",
                "state_code": "DU"
            },
            {
                "id": 2682,
                "name": "Faranah Prefecture",
                "state_code": "FA"
            },
            {
                "id": 2683,
                "name": "Forécariah Prefecture",
                "state_code": "FO"
            },
            {
                "id": 2675,
                "name": "Fria Prefecture",
                "state_code": "FR"
            },
            {
                "id": 2685,
                "name": "Gaoual Prefecture",
                "state_code": "GA"
            },
            {
                "id": 2711,
                "name": "Guéckédou Prefecture",
                "state_code": "GU"
            },
            {
                "id": 2704,
                "name": "Kankan Prefecture",
                "state_code": "KA"
            },
            {
                "id": 2697,
                "name": "Kankan Region",
                "state_code": "K"
            },
            {
                "id": 2710,
                "name": "Kérouané Prefecture",
                "state_code": "KE"
            },
            {
                "id": 2693,
                "name": "Kindia Prefecture",
                "state_code": "KD"
            },
            {
                "id": 2701,
                "name": "Kindia Region",
                "state_code": "D"
            },
            {
                "id": 2691,
                "name": "Kissidougou Prefecture",
                "state_code": "KS"
            },
            {
                "id": 2692,
                "name": "Koubia Prefecture",
                "state_code": "KB"
            },
            {
                "id": 2703,
                "name": "Koundara Prefecture",
                "state_code": "KN"
            },
            {
                "id": 2695,
                "name": "Kouroussa Prefecture",
                "state_code": "KO"
            },
            {
                "id": 2680,
                "name": "Labé Prefecture",
                "state_code": "LA"
            },
            {
                "id": 2677,
                "name": "Labé Region",
                "state_code": "L"
            },
            {
                "id": 2690,
                "name": "Lélouma Prefecture",
                "state_code": "LE"
            },
            {
                "id": 2708,
                "name": "Lola Prefecture",
                "state_code": "LO"
            },
            {
                "id": 2702,
                "name": "Macenta Prefecture",
                "state_code": "MC"
            },
            {
                "id": 2700,
                "name": "Mali Prefecture",
                "state_code": "ML"
            },
            {
                "id": 2689,
                "name": "Mamou Prefecture",
                "state_code": "MM"
            },
            {
                "id": 2698,
                "name": "Mamou Region",
                "state_code": "M"
            },
            {
                "id": 2673,
                "name": "Mandiana Prefecture",
                "state_code": "MD"
            },
            {
                "id": 2678,
                "name": "Nzérékoré Prefecture",
                "state_code": "NZ"
            },
            {
                "id": 2684,
                "name": "Nzérékoré Region",
                "state_code": "N"
            },
            {
                "id": 2694,
                "name": "Pita Prefecture",
                "state_code": "PI"
            },
            {
                "id": 2707,
                "name": "Siguiri Prefecture",
                "state_code": "SI"
            },
            {
                "id": 2687,
                "name": "Télimélé Prefecture",
                "state_code": "TE"
            },
            {
                "id": 2696,
                "name": "Tougué Prefecture",
                "state_code": "TO"
            },
            {
                "id": 2674,
                "name": "Yomou Prefecture",
                "state_code": "YO"
            }
        ]
    },
    {
        "id": 93,
        "states": [
            {
                "id": 2720,
                "name": "Bafatá",
                "state_code": "BA"
            },
            {
                "id": 2714,
                "name": "Biombo Region",
                "state_code": "BM"
            },
            {
                "id": 2722,
                "name": "Bolama Region",
                "state_code": "BL"
            },
            {
                "id": 2713,
                "name": "Cacheu Region",
                "state_code": "CA"
            },
            {
                "id": 2719,
                "name": "Gabú Region",
                "state_code": "GA"
            },
            {
                "id": 2721,
                "name": "Leste Province",
                "state_code": "L"
            },
            {
                "id": 2717,
                "name": "Norte Province",
                "state_code": "N"
            },
            {
                "id": 2718,
                "name": "Oio Region",
                "state_code": "OI"
            },
            {
                "id": 2715,
                "name": "Quinara Region",
                "state_code": "QU"
            },
            {
                "id": 2716,
                "name": "Sul Province",
                "state_code": "S"
            },
            {
                "id": 2712,
                "name": "Tombali Region",
                "state_code": "TO"
            }
        ]
    },
    {
        "id": 94,
        "states": [
            {
                "id": 2764,
                "name": "Barima-Waini",
                "state_code": "BA"
            },
            {
                "id": 2760,
                "name": "Cuyuni-Mazaruni",
                "state_code": "CU"
            },
            {
                "id": 2767,
                "name": "Demerara-Mahaica",
                "state_code": "DE"
            },
            {
                "id": 2766,
                "name": "East Berbice-Corentyne",
                "state_code": "EB"
            },
            {
                "id": 2768,
                "name": "Essequibo Islands-West Demerara",
                "state_code": "ES"
            },
            {
                "id": 2762,
                "name": "Mahaica-Berbice",
                "state_code": "MA"
            },
            {
                "id": 2765,
                "name": "Pomeroon-Supenaam",
                "state_code": "PM"
            },
            {
                "id": 2761,
                "name": "Potaro-Siparuni",
                "state_code": "PT"
            },
            {
                "id": 2763,
                "name": "Upper Demerara-Berbice",
                "state_code": "UD"
            },
            {
                "id": 2769,
                "name": "Upper Takutu-Upper Essequibo",
                "state_code": "UT"
            }
        ]
    },
    {
        "id": 95,
        "states": [
            {
                "id": 4123,
                "name": "Artibonite",
                "state_code": "AR"
            },
            {
                "id": 4125,
                "name": "Centre",
                "state_code": "CE"
            },
            {
                "id": 4119,
                "name": "Grand'Anse",
                "state_code": "GA"
            },
            {
                "id": 4118,
                "name": "Nippes",
                "state_code": "NI"
            },
            {
                "id": 4117,
                "name": "Nord",
                "state_code": "ND"
            },
            {
                "id": 4121,
                "name": "Nord-Est",
                "state_code": "NE"
            },
            {
                "id": 4126,
                "name": "Nord-Ouest",
                "state_code": "NO"
            },
            {
                "id": 4120,
                "name": "Ouest",
                "state_code": "OU"
            },
            {
                "id": 4122,
                "name": "Sud",
                "state_code": "SD"
            },
            {
                "id": 4124,
                "name": "Sud-Est",
                "state_code": "SE"
            }
        ]
    },
    {
        "id": 96,
        "states": []
    },
    {
        "id": 97,
        "states": [
            {
                "id": 4047,
                "name": "Atlántida Department",
                "state_code": "AT"
            },
            {
                "id": 4045,
                "name": "Bay Islands Department",
                "state_code": "IB"
            },
            {
                "id": 4041,
                "name": "Choluteca Department",
                "state_code": "CH"
            },
            {
                "id": 4051,
                "name": "Colón Department",
                "state_code": "CL"
            },
            {
                "id": 4042,
                "name": "Comayagua Department",
                "state_code": "CM"
            },
            {
                "id": 4049,
                "name": "Copán Department",
                "state_code": "CP"
            },
            {
                "id": 4046,
                "name": "Cortés Department",
                "state_code": "CR"
            },
            {
                "id": 4043,
                "name": "El Paraíso Department",
                "state_code": "EP"
            },
            {
                "id": 4052,
                "name": "Francisco Morazán Department",
                "state_code": "FM"
            },
            {
                "id": 4048,
                "name": "Gracias a Dios Department",
                "state_code": "GD"
            },
            {
                "id": 4044,
                "name": "Intibucá Department",
                "state_code": "IN"
            },
            {
                "id": 4058,
                "name": "La Paz Department",
                "state_code": "LP"
            },
            {
                "id": 4054,
                "name": "Lempira Department",
                "state_code": "LE"
            },
            {
                "id": 4056,
                "name": "Ocotepeque Department",
                "state_code": "OC"
            },
            {
                "id": 4050,
                "name": "Olancho Department",
                "state_code": "OL"
            },
            {
                "id": 4053,
                "name": "Santa Bárbara Department",
                "state_code": "SB"
            },
            {
                "id": 4055,
                "name": "Valle Department",
                "state_code": "VA"
            },
            {
                "id": 4057,
                "name": "Yoro Department",
                "state_code": "YO"
            }
        ]
    },
    {
        "id": 98,
        "states": [
            {
                "id": 4889,
                "name": "Central and Western District",
                "state_code": "HCW"
            },
            {
                "id": 4891,
                "name": "Eastern",
                "state_code": "HEA"
            },
            {
                "id": 4888,
                "name": "Islands District",
                "state_code": "NIS"
            },
            {
                "id": 4895,
                "name": "Kowloon City",
                "state_code": "KKC"
            },
            {
                "id": 4898,
                "name": "Kwai Tsing",
                "state_code": "NKT"
            },
            {
                "id": 4897,
                "name": "Kwun Tong",
                "state_code": "KKT"
            },
            {
                "id": 4900,
                "name": "North",
                "state_code": "NNO"
            },
            {
                "id": 4887,
                "name": "Sai Kung District",
                "state_code": "NSK"
            },
            {
                "id": 4901,
                "name": "Sha Tin",
                "state_code": "NST"
            },
            {
                "id": 4894,
                "name": "Sham Shui Po",
                "state_code": "KSS"
            },
            {
                "id": 4892,
                "name": "Southern",
                "state_code": "HSO"
            },
            {
                "id": 4885,
                "name": "Tai Po District",
                "state_code": "NTP"
            },
            {
                "id": 4884,
                "name": "Tsuen Wan District",
                "state_code": "NTW"
            },
            {
                "id": 4899,
                "name": "Tuen Mun",
                "state_code": "NTM"
            },
            {
                "id": 4890,
                "name": "Wan Chai",
                "state_code": "HWC"
            },
            {
                "id": 4896,
                "name": "Wong Tai Sin",
                "state_code": "KWT"
            },
            {
                "id": 4893,
                "name": "Yau Tsim Mong",
                "state_code": "KYT"
            },
            {
                "id": 4883,
                "name": "Yuen Long District",
                "state_code": "NYL"
            }
        ]
    },
    {
        "id": 99,
        "states": [
            {
                "id": 1048,
                "name": "Bács-Kiskun",
                "state_code": "BK"
            },
            {
                "id": 1055,
                "name": "Baranya",
                "state_code": "BA"
            },
            {
                "id": 1060,
                "name": "Békés",
                "state_code": "BE"
            },
            {
                "id": 1036,
                "name": "Békéscsaba",
                "state_code": "BC"
            },
            {
                "id": 1058,
                "name": "Borsod-Abaúj-Zemplén",
                "state_code": "BZ"
            },
            {
                "id": 1064,
                "name": "Budapest",
                "state_code": "BU"
            },
            {
                "id": 1031,
                "name": "Csongrád County",
                "state_code": "CS"
            },
            {
                "id": 1032,
                "name": "Debrecen",
                "state_code": "DE"
            },
            {
                "id": 1049,
                "name": "Dunaújváros",
                "state_code": "DU"
            },
            {
                "id": 1037,
                "name": "Eger",
                "state_code": "EG"
            },
            {
                "id": 1028,
                "name": "Érd",
                "state_code": "ER"
            },
            {
                "id": 1044,
                "name": "Fejér County",
                "state_code": "FE"
            },
            {
                "id": 1041,
                "name": "Győr",
                "state_code": "GY"
            },
            {
                "id": 1042,
                "name": "Győr-Moson-Sopron County",
                "state_code": "GS"
            },
            {
                "id": 1063,
                "name": "Hajdú-Bihar County",
                "state_code": "HB"
            },
            {
                "id": 1040,
                "name": "Heves County",
                "state_code": "HE"
            },
            {
                "id": 1027,
                "name": "Hódmezővásárhely",
                "state_code": "HV"
            },
            {
                "id": 1043,
                "name": "Jász-Nagykun-Szolnok County",
                "state_code": "JN"
            },
            {
                "id": 1067,
                "name": "Kaposvár",
                "state_code": "KV"
            },
            {
                "id": 1056,
                "name": "Kecskemét",
                "state_code": "KM"
            },
            {
                "id": 5085,
                "name": "Komárom-Esztergom",
                "state_code": "KE"
            },
            {
                "id": 1065,
                "name": "Miskolc",
                "state_code": "MI"
            },
            {
                "id": 1030,
                "name": "Nagykanizsa",
                "state_code": "NK"
            },
            {
                "id": 1051,
                "name": "Nógrád County",
                "state_code": "NO"
            },
            {
                "id": 1034,
                "name": "Nyíregyháza",
                "state_code": "NY"
            },
            {
                "id": 1053,
                "name": "Pécs",
                "state_code": "PS"
            },
            {
                "id": 1059,
                "name": "Pest County",
                "state_code": "PE"
            },
            {
                "id": 1068,
                "name": "Salgótarján",
                "state_code": "ST"
            },
            {
                "id": 1035,
                "name": "Somogy County",
                "state_code": "SO"
            },
            {
                "id": 1057,
                "name": "Sopron",
                "state_code": "SN"
            },
            {
                "id": 1045,
                "name": "Szabolcs-Szatmár-Bereg County",
                "state_code": "SZ"
            },
            {
                "id": 1029,
                "name": "Szeged",
                "state_code": "SD"
            },
            {
                "id": 1033,
                "name": "Székesfehérvár",
                "state_code": "SF"
            },
            {
                "id": 1061,
                "name": "Szekszárd",
                "state_code": "SS"
            },
            {
                "id": 1047,
                "name": "Szolnok",
                "state_code": "SK"
            },
            {
                "id": 1052,
                "name": "Szombathely",
                "state_code": "SH"
            },
            {
                "id": 1066,
                "name": "Tatabánya",
                "state_code": "TB"
            },
            {
                "id": 1038,
                "name": "Tolna County",
                "state_code": "TO"
            },
            {
                "id": 1039,
                "name": "Vas County",
                "state_code": "VA"
            },
            {
                "id": 1062,
                "name": "Veszprém",
                "state_code": "VM"
            },
            {
                "id": 1054,
                "name": "Veszprém County",
                "state_code": "VE"
            },
            {
                "id": 1046,
                "name": "Zala County",
                "state_code": "ZA"
            },
            {
                "id": 1050,
                "name": "Zalaegerszeg",
                "state_code": "ZE"
            }
        ]
    },
    {
        "id": 100,
        "states": [
            {
                "id": 3431,
                "name": "Capital Region",
                "state_code": "1"
            },
            {
                "id": 3433,
                "name": "Eastern Region",
                "state_code": "7"
            },
            {
                "id": 3437,
                "name": "Northeastern Region",
                "state_code": "6"
            },
            {
                "id": 3435,
                "name": "Northwestern Region",
                "state_code": "5"
            },
            {
                "id": 3430,
                "name": "Southern Peninsula Region",
                "state_code": "2"
            },
            {
                "id": 3434,
                "name": "Southern Region",
                "state_code": "8"
            },
            {
                "id": 3436,
                "name": "Western Region",
                "state_code": "3"
            },
            {
                "id": 3432,
                "name": "Westfjords",
                "state_code": "4"
            }
        ]
    },
    {
        "id": 101,
        "states": [
            {
                "id": 4023,
                "name": "Andaman and Nicobar Islands",
                "state_code": "AN"
            },
            {
                "id": 4017,
                "name": "Andhra Pradesh",
                "state_code": "AP"
            },
            {
                "id": 4024,
                "name": "Arunachal Pradesh",
                "state_code": "AR"
            },
            {
                "id": 4027,
                "name": "Assam",
                "state_code": "AS"
            },
            {
                "id": 4037,
                "name": "Bihar",
                "state_code": "BR"
            },
            {
                "id": 4031,
                "name": "Chandigarh",
                "state_code": "CH"
            },
            {
                "id": 4040,
                "name": "Chhattisgarh",
                "state_code": "CT"
            },
            {
                "id": 4033,
                "name": "Dadra and Nagar Haveli and Daman and Diu",
                "state_code": "DH"
            },
            {
                "id": 4021,
                "name": "Delhi",
                "state_code": "DL"
            },
            {
                "id": 4009,
                "name": "Goa",
                "state_code": "GA"
            },
            {
                "id": 4030,
                "name": "Gujarat",
                "state_code": "GJ"
            },
            {
                "id": 4007,
                "name": "Haryana",
                "state_code": "HR"
            },
            {
                "id": 4020,
                "name": "Himachal Pradesh",
                "state_code": "HP"
            },
            {
                "id": 4029,
                "name": "Jammu and Kashmir",
                "state_code": "JK"
            },
            {
                "id": 4025,
                "name": "Jharkhand",
                "state_code": "JH"
            },
            {
                "id": 4026,
                "name": "Karnataka",
                "state_code": "KA"
            },
            {
                "id": 4028,
                "name": "Kerala",
                "state_code": "KL"
            },
            {
                "id": 4852,
                "name": "Ladakh",
                "state_code": "LA"
            },
            {
                "id": 4019,
                "name": "Lakshadweep",
                "state_code": "LD"
            },
            {
                "id": 4039,
                "name": "Madhya Pradesh",
                "state_code": "MP"
            },
            {
                "id": 4008,
                "name": "Maharashtra",
                "state_code": "MH"
            },
            {
                "id": 4010,
                "name": "Manipur",
                "state_code": "MN"
            },
            {
                "id": 4006,
                "name": "Meghalaya",
                "state_code": "ML"
            },
            {
                "id": 4036,
                "name": "Mizoram",
                "state_code": "MZ"
            },
            {
                "id": 4018,
                "name": "Nagaland",
                "state_code": "NL"
            },
            {
                "id": 4013,
                "name": "Odisha",
                "state_code": "OR"
            },
            {
                "id": 4011,
                "name": "Puducherry",
                "state_code": "PY"
            },
            {
                "id": 4015,
                "name": "Punjab",
                "state_code": "PB"
            },
            {
                "id": 4014,
                "name": "Rajasthan",
                "state_code": "RJ"
            },
            {
                "id": 4034,
                "name": "Sikkim",
                "state_code": "SK"
            },
            {
                "id": 4035,
                "name": "Tamil Nadu",
                "state_code": "TN"
            },
            {
                "id": 4012,
                "name": "Telangana",
                "state_code": "TG"
            },
            {
                "id": 4038,
                "name": "Tripura",
                "state_code": "TR"
            },
            {
                "id": 4022,
                "name": "Uttar Pradesh",
                "state_code": "UP"
            },
            {
                "id": 4016,
                "name": "Uttarakhand",
                "state_code": "UT"
            },
            {
                "id": 4853,
                "name": "West Bengal",
                "state_code": "WB"
            }
        ]
    },
    {
        "id": 102,
        "states": [
            {
                "id": 1822,
                "name": "Aceh",
                "state_code": "AC"
            },
            {
                "id": 1826,
                "name": "Bali",
                "state_code": "BA"
            },
            {
                "id": 1810,
                "name": "Banten",
                "state_code": "BT"
            },
            {
                "id": 1793,
                "name": "Bengkulu",
                "state_code": "BE"
            },
            {
                "id": 1829,
                "name": "DI Yogyakarta",
                "state_code": "YO"
            },
            {
                "id": 1805,
                "name": "DKI Jakarta",
                "state_code": "JK"
            },
            {
                "id": 1812,
                "name": "Gorontalo",
                "state_code": "GO"
            },
            {
                "id": 1815,
                "name": "Jambi",
                "state_code": "JA"
            },
            {
                "id": 1825,
                "name": "Jawa Barat",
                "state_code": "JB"
            },
            {
                "id": 1802,
                "name": "Jawa Tengah",
                "state_code": "JT"
            },
            {
                "id": 1827,
                "name": "Jawa Timur",
                "state_code": "JI"
            },
            {
                "id": 1806,
                "name": "Kalimantan Barat",
                "state_code": "KB"
            },
            {
                "id": 1819,
                "name": "Kalimantan Selatan",
                "state_code": "KS"
            },
            {
                "id": 1794,
                "name": "Kalimantan Tengah",
                "state_code": "KT"
            },
            {
                "id": 1804,
                "name": "Kalimantan Timur",
                "state_code": "KI"
            },
            {
                "id": 1824,
                "name": "Kalimantan Utara",
                "state_code": "KU"
            },
            {
                "id": 1820,
                "name": "Kepulauan Bangka Belitung",
                "state_code": "BB"
            },
            {
                "id": 1807,
                "name": "Kepulauan Riau",
                "state_code": "KR"
            },
            {
                "id": 1811,
                "name": "Lampung",
                "state_code": "LA"
            },
            {
                "id": 1800,
                "name": "Maluku",
                "state_code": "MA"
            },
            {
                "id": 1801,
                "name": "Maluku Utara",
                "state_code": "MU"
            },
            {
                "id": 1814,
                "name": "Nusa Tenggara Barat",
                "state_code": "NB"
            },
            {
                "id": 1818,
                "name": "Nusa Tenggara Timur",
                "state_code": "NT"
            },
            {
                "id": 1798,
                "name": "Papua",
                "state_code": "PA"
            },
            {
                "id": 1799,
                "name": "Papua Barat",
                "state_code": "PB"
            },
            {
                "id": 1809,
                "name": "Riau",
                "state_code": "RI"
            },
            {
                "id": 1817,
                "name": "Sulawesi Barat",
                "state_code": "SR"
            },
            {
                "id": 1795,
                "name": "Sulawesi Selatan",
                "state_code": "SN"
            },
            {
                "id": 1813,
                "name": "Sulawesi Tengah",
                "state_code": "ST"
            },
            {
                "id": 1796,
                "name": "Sulawesi Tenggara",
                "state_code": "SG"
            },
            {
                "id": 1808,
                "name": "Sulawesi Utara",
                "state_code": "SA"
            },
            {
                "id": 1828,
                "name": "Sumatera Barat",
                "state_code": "SB"
            },
            {
                "id": 1816,
                "name": "Sumatera Selatan",
                "state_code": "SS"
            },
            {
                "id": 1792,
                "name": "Sumatera Utara",
                "state_code": "SU"
            }
        ]
    },
    {
        "id": 103,
        "states": [
            {
                "id": 3929,
                "name": "Alborz",
                "state_code": "30"
            },
            {
                "id": 3934,
                "name": "Ardabil",
                "state_code": "24"
            },
            {
                "id": 3932,
                "name": "Bushehr",
                "state_code": "18"
            },
            {
                "id": 3921,
                "name": "Chaharmahal and Bakhtiari",
                "state_code": "14"
            },
            {
                "id": 3944,
                "name": "East Azerbaijan",
                "state_code": "03"
            },
            {
                "id": 3939,
                "name": "Fars",
                "state_code": "07"
            },
            {
                "id": 3920,
                "name": "Gilan",
                "state_code": "01"
            },
            {
                "id": 3933,
                "name": "Golestan",
                "state_code": "27"
            },
            {
                "id": 4920,
                "name": "Hamadan",
                "state_code": "13"
            },
            {
                "id": 3937,
                "name": "Hormozgan",
                "state_code": "22"
            },
            {
                "id": 3918,
                "name": "Ilam",
                "state_code": "16"
            },
            {
                "id": 3923,
                "name": "Isfahan",
                "state_code": "10"
            },
            {
                "id": 3943,
                "name": "Kerman",
                "state_code": "08"
            },
            {
                "id": 3919,
                "name": "Kermanshah",
                "state_code": "05"
            },
            {
                "id": 3917,
                "name": "Khuzestan",
                "state_code": "06"
            },
            {
                "id": 3926,
                "name": "Kohgiluyeh and Boyer-Ahmad",
                "state_code": "17"
            },
            {
                "id": 3935,
                "name": "Kurdistan",
                "state_code": "12"
            },
            {
                "id": 3928,
                "name": "Lorestan",
                "state_code": "15"
            },
            {
                "id": 3916,
                "name": "Markazi",
                "state_code": "00"
            },
            {
                "id": 3938,
                "name": "Mazandaran",
                "state_code": "02"
            },
            {
                "id": 3942,
                "name": "North Khorasan",
                "state_code": "28"
            },
            {
                "id": 3941,
                "name": "Qazvin",
                "state_code": "26"
            },
            {
                "id": 3922,
                "name": "Qom",
                "state_code": "25"
            },
            {
                "id": 3927,
                "name": "Razavi Khorasan",
                "state_code": "09"
            },
            {
                "id": 3940,
                "name": "Semnan",
                "state_code": "20"
            },
            {
                "id": 3931,
                "name": "Sistan and Baluchestan",
                "state_code": "11"
            },
            {
                "id": 3930,
                "name": "South Khorasan",
                "state_code": "29"
            },
            {
                "id": 3945,
                "name": "Tehran",
                "state_code": "23"
            },
            {
                "id": 3924,
                "name": "West Azarbaijan",
                "state_code": "04"
            },
            {
                "id": 3936,
                "name": "Yazd",
                "state_code": "21"
            },
            {
                "id": 3925,
                "name": "Zanjan",
                "state_code": "19"
            }
        ]
    },
    {
        "id": 104,
        "states": [
            {
                "id": 3964,
                "name": "Al Anbar",
                "state_code": "AN"
            },
            {
                "id": 3958,
                "name": "Al Muthanna",
                "state_code": "MU"
            },
            {
                "id": 3956,
                "name": "Al-Qādisiyyah",
                "state_code": "QA"
            },
            {
                "id": 3955,
                "name": "Babylon",
                "state_code": "BB"
            },
            {
                "id": 3959,
                "name": "Baghdad",
                "state_code": "BG"
            },
            {
                "id": 3960,
                "name": "Basra",
                "state_code": "BA"
            },
            {
                "id": 3954,
                "name": "Dhi Qar",
                "state_code": "DQ"
            },
            {
                "id": 3965,
                "name": "Diyala",
                "state_code": "DI"
            },
            {
                "id": 3967,
                "name": "Dohuk",
                "state_code": "DA"
            },
            {
                "id": 3968,
                "name": "Erbil",
                "state_code": "AR"
            },
            {
                "id": 3957,
                "name": "Karbala",
                "state_code": "KA"
            },
            {
                "id": 3971,
                "name": "Kirkuk",
                "state_code": "KI"
            },
            {
                "id": 3966,
                "name": "Maysan",
                "state_code": "MA"
            },
            {
                "id": 3962,
                "name": "Najaf",
                "state_code": "NA"
            },
            {
                "id": 3963,
                "name": "Nineveh",
                "state_code": "NI"
            },
            {
                "id": 3961,
                "name": "Saladin",
                "state_code": "SD"
            },
            {
                "id": 3969,
                "name": "Sulaymaniyah",
                "state_code": "SU"
            },
            {
                "id": 3970,
                "name": "Wasit",
                "state_code": "WA"
            }
        ]
    },
    {
        "id": 105,
        "states": [
            {
                "id": 1095,
                "name": "Carlow",
                "state_code": "CW"
            },
            {
                "id": 1088,
                "name": "Cavan",
                "state_code": "CN"
            },
            {
                "id": 1091,
                "name": "Clare",
                "state_code": "CE"
            },
            {
                "id": 1087,
                "name": "Connacht",
                "state_code": "C"
            },
            {
                "id": 1074,
                "name": "Cork",
                "state_code": "CO"
            },
            {
                "id": 1071,
                "name": "Donegal",
                "state_code": "DL"
            },
            {
                "id": 1072,
                "name": "Dublin",
                "state_code": "D"
            },
            {
                "id": 1079,
                "name": "Galway",
                "state_code": "G"
            },
            {
                "id": 1077,
                "name": "Kerry",
                "state_code": "KY"
            },
            {
                "id": 1082,
                "name": "Kildare",
                "state_code": "KE"
            },
            {
                "id": 1090,
                "name": "Kilkenny",
                "state_code": "KK"
            },
            {
                "id": 1096,
                "name": "Laois",
                "state_code": "LS"
            },
            {
                "id": 1073,
                "name": "Leinster",
                "state_code": "L"
            },
            {
                "id": 1094,
                "name": "Limerick",
                "state_code": "LK"
            },
            {
                "id": 1076,
                "name": "Longford",
                "state_code": "LD"
            },
            {
                "id": 1083,
                "name": "Louth",
                "state_code": "LH"
            },
            {
                "id": 1084,
                "name": "Mayo",
                "state_code": "MO"
            },
            {
                "id": 1092,
                "name": "Meath",
                "state_code": "MH"
            },
            {
                "id": 1075,
                "name": "Monaghan",
                "state_code": "MN"
            },
            {
                "id": 1080,
                "name": "Munster",
                "state_code": "M"
            },
            {
                "id": 1078,
                "name": "Offaly",
                "state_code": "OY"
            },
            {
                "id": 1081,
                "name": "Roscommon",
                "state_code": "RN"
            },
            {
                "id": 1070,
                "name": "Sligo",
                "state_code": "SO"
            },
            {
                "id": 1069,
                "name": "Tipperary",
                "state_code": "TA"
            },
            {
                "id": 1086,
                "name": "Ulster",
                "state_code": "U"
            },
            {
                "id": 1089,
                "name": "Waterford",
                "state_code": "WD"
            },
            {
                "id": 1097,
                "name": "Westmeath",
                "state_code": "WH"
            },
            {
                "id": 1093,
                "name": "Wexford",
                "state_code": "WX"
            },
            {
                "id": 1085,
                "name": "Wicklow",
                "state_code": "WW"
            }
        ]
    },
    {
        "id": 106,
        "states": [
            {
                "id": 1367,
                "name": "Central District",
                "state_code": "M"
            },
            {
                "id": 1369,
                "name": "Haifa District",
                "state_code": "HA"
            },
            {
                "id": 1370,
                "name": "Jerusalem District",
                "state_code": "JM"
            },
            {
                "id": 1366,
                "name": "Northern District",
                "state_code": "Z"
            },
            {
                "id": 1368,
                "name": "Southern District",
                "state_code": "D"
            },
            {
                "id": 1371,
                "name": "Tel Aviv District",
                "state_code": "TA"
            }
        ]
    },
    {
        "id": 107,
        "states": [
            {
                "id": 1679,
                "name": "Abruzzo",
                "state_code": "65"
            },
            {
                "id": 1727,
                "name": "Agrigento",
                "state_code": "AG"
            },
            {
                "id": 1783,
                "name": "Alessandria",
                "state_code": "AL"
            },
            {
                "id": 1672,
                "name": "Ancona",
                "state_code": "AN"
            },
            {
                "id": 1716,
                "name": "Aosta Valley",
                "state_code": "23"
            },
            {
                "id": 1688,
                "name": "Apulia",
                "state_code": "75"
            },
            {
                "id": 1681,
                "name": "Ascoli Piceno",
                "state_code": "AP"
            },
            {
                "id": 1780,
                "name": "Asti",
                "state_code": "AT"
            },
            {
                "id": 1692,
                "name": "Avellino",
                "state_code": "AV"
            },
            {
                "id": 1686,
                "name": "Barletta-Andria-Trani",
                "state_code": "BT"
            },
            {
                "id": 1706,
                "name": "Basilicata",
                "state_code": "77"
            },
            {
                "id": 1689,
                "name": "Belluno",
                "state_code": "BL"
            },
            {
                "id": 1701,
                "name": "Benevento",
                "state_code": "BN"
            },
            {
                "id": 1704,
                "name": "Bergamo",
                "state_code": "BG"
            },
            {
                "id": 1778,
                "name": "Biella",
                "state_code": "BI"
            },
            {
                "id": 1717,
                "name": "Brescia",
                "state_code": "BS"
            },
            {
                "id": 1714,
                "name": "Brindisi",
                "state_code": "BR"
            },
            {
                "id": 1703,
                "name": "Calabria",
                "state_code": "78"
            },
            {
                "id": 1718,
                "name": "Caltanissetta",
                "state_code": "CL"
            },
            {
                "id": 1669,
                "name": "Campania",
                "state_code": "72"
            },
            {
                "id": 1721,
                "name": "Campobasso",
                "state_code": "CB"
            },
            {
                "id": 1731,
                "name": "Caserta",
                "state_code": "CE"
            },
            {
                "id": 1728,
                "name": "Catanzaro",
                "state_code": "CZ"
            },
            {
                "id": 1739,
                "name": "Chieti",
                "state_code": "CH"
            },
            {
                "id": 1740,
                "name": "Como",
                "state_code": "CO"
            },
            {
                "id": 1742,
                "name": "Cosenza",
                "state_code": "CS"
            },
            {
                "id": 1751,
                "name": "Cremona",
                "state_code": "CR"
            },
            {
                "id": 1754,
                "name": "Crotone",
                "state_code": "KR"
            },
            {
                "id": 1775,
                "name": "Cuneo",
                "state_code": "CN"
            },
            {
                "id": 1773,
                "name": "Emilia-Romagna",
                "state_code": "45"
            },
            {
                "id": 1723,
                "name": "Enna",
                "state_code": "EN"
            },
            {
                "id": 1744,
                "name": "Fermo",
                "state_code": "FM"
            },
            {
                "id": 1746,
                "name": "Ferrara",
                "state_code": "FE"
            },
            {
                "id": 1771,
                "name": "Foggia",
                "state_code": "FG"
            },
            {
                "id": 1779,
                "name": "Forlì-Cesena",
                "state_code": "FC"
            },
            {
                "id": 1756,
                "name": "Friuli–Venezia Giulia",
                "state_code": "36"
            },
            {
                "id": 1776,
                "name": "Frosinone",
                "state_code": "FR"
            },
            {
                "id": 1777,
                "name": "Gorizia",
                "state_code": "GO"
            },
            {
                "id": 1787,
                "name": "Grosseto",
                "state_code": "GR"
            },
            {
                "id": 1788,
                "name": "Imperia",
                "state_code": "IM"
            },
            {
                "id": 1789,
                "name": "Isernia",
                "state_code": "IS"
            },
            {
                "id": 1781,
                "name": "L'Aquila",
                "state_code": "AQ"
            },
            {
                "id": 1791,
                "name": "La Spezia",
                "state_code": "SP"
            },
            {
                "id": 1674,
                "name": "Latina",
                "state_code": "LT"
            },
            {
                "id": 1678,
                "name": "Lazio",
                "state_code": "62"
            },
            {
                "id": 1675,
                "name": "Lecce",
                "state_code": "LE"
            },
            {
                "id": 1677,
                "name": "Lecco",
                "state_code": "LC"
            },
            {
                "id": 1768,
                "name": "Liguria",
                "state_code": "42"
            },
            {
                "id": 1745,
                "name": "Livorno",
                "state_code": "LI"
            },
            {
                "id": 1747,
                "name": "Lodi",
                "state_code": "LO"
            },
            {
                "id": 1705,
                "name": "Lombardy",
                "state_code": "25"
            },
            {
                "id": 1749,
                "name": "Lucca",
                "state_code": "LU"
            },
            {
                "id": 1750,
                "name": "Macerata",
                "state_code": "MC"
            },
            {
                "id": 1758,
                "name": "Mantua",
                "state_code": "MN"
            },
            {
                "id": 1670,
                "name": "Marche",
                "state_code": "57"
            },
            {
                "id": 1759,
                "name": "Massa and Carrara",
                "state_code": "MS"
            },
            {
                "id": 1760,
                "name": "Matera",
                "state_code": "MT"
            },
            {
                "id": 1761,
                "name": "Medio Campidano",
                "state_code": "VS"
            },
            {
                "id": 1757,
                "name": "Modena",
                "state_code": "MO"
            },
            {
                "id": 1695,
                "name": "Molise",
                "state_code": "67"
            },
            {
                "id": 1769,
                "name": "Monza and Brianza",
                "state_code": "MB"
            },
            {
                "id": 1774,
                "name": "Novara",
                "state_code": "NO"
            },
            {
                "id": 1790,
                "name": "Nuoro",
                "state_code": "NU"
            },
            {
                "id": 1786,
                "name": "Oristano",
                "state_code": "OR"
            },
            {
                "id": 1665,
                "name": "Padua",
                "state_code": "PD"
            },
            {
                "id": 1668,
                "name": "Palermo",
                "state_code": "PA"
            },
            {
                "id": 1666,
                "name": "Parma",
                "state_code": "PR"
            },
            {
                "id": 1676,
                "name": "Pavia",
                "state_code": "PV"
            },
            {
                "id": 1691,
                "name": "Perugia",
                "state_code": "PG"
            },
            {
                "id": 1693,
                "name": "Pesaro and Urbino",
                "state_code": "PU"
            },
            {
                "id": 1694,
                "name": "Pescara",
                "state_code": "PE"
            },
            {
                "id": 1696,
                "name": "Piacenza",
                "state_code": "PC"
            },
            {
                "id": 1702,
                "name": "Piedmont",
                "state_code": "21"
            },
            {
                "id": 1685,
                "name": "Pisa",
                "state_code": "PI"
            },
            {
                "id": 1687,
                "name": "Pistoia",
                "state_code": "PT"
            },
            {
                "id": 1690,
                "name": "Pordenone",
                "state_code": "PN"
            },
            {
                "id": 1697,
                "name": "Potenza",
                "state_code": "PZ"
            },
            {
                "id": 1700,
                "name": "Prato",
                "state_code": "PO"
            },
            {
                "id": 1729,
                "name": "Ragusa",
                "state_code": "RG"
            },
            {
                "id": 1707,
                "name": "Ravenna",
                "state_code": "RA"
            },
            {
                "id": 1708,
                "name": "Reggio Emilia",
                "state_code": "RE"
            },
            {
                "id": 1712,
                "name": "Rieti",
                "state_code": "RI"
            },
            {
                "id": 1713,
                "name": "Rimini",
                "state_code": "RN"
            },
            {
                "id": 1719,
                "name": "Rovigo",
                "state_code": "RO"
            },
            {
                "id": 1720,
                "name": "Salerno",
                "state_code": "SA"
            },
            {
                "id": 1715,
                "name": "Sardinia",
                "state_code": "88"
            },
            {
                "id": 1722,
                "name": "Sassari",
                "state_code": "SS"
            },
            {
                "id": 1732,
                "name": "Savona",
                "state_code": "SV"
            },
            {
                "id": 1709,
                "name": "Sicily",
                "state_code": "82"
            },
            {
                "id": 1734,
                "name": "Siena",
                "state_code": "SI"
            },
            {
                "id": 1667,
                "name": "Siracusa",
                "state_code": "SR"
            },
            {
                "id": 1741,
                "name": "Sondrio",
                "state_code": "SO"
            },
            {
                "id": 1730,
                "name": "South Sardinia",
                "state_code": "SU"
            },
            {
                "id": 1743,
                "name": "Taranto",
                "state_code": "TA"
            },
            {
                "id": 1752,
                "name": "Teramo",
                "state_code": "TE"
            },
            {
                "id": 1755,
                "name": "Terni",
                "state_code": "TR"
            },
            {
                "id": 1733,
                "name": "Trapani",
                "state_code": "TP"
            },
            {
                "id": 1725,
                "name": "Trentino-South Tyrol",
                "state_code": "32"
            },
            {
                "id": 1762,
                "name": "Treviso",
                "state_code": "TV"
            },
            {
                "id": 1763,
                "name": "Trieste",
                "state_code": "TS"
            },
            {
                "id": 1664,
                "name": "Tuscany",
                "state_code": "52"
            },
            {
                "id": 1764,
                "name": "Udine",
                "state_code": "UD"
            },
            {
                "id": 1683,
                "name": "Umbria",
                "state_code": "55"
            },
            {
                "id": 1765,
                "name": "Varese",
                "state_code": "VA"
            },
            {
                "id": 1753,
                "name": "Veneto",
                "state_code": "34"
            },
            {
                "id": 1726,
                "name": "Verbano-Cusio-Ossola",
                "state_code": "VB"
            },
            {
                "id": 1785,
                "name": "Vercelli",
                "state_code": "VC"
            },
            {
                "id": 1736,
                "name": "Verona",
                "state_code": "VR"
            },
            {
                "id": 1737,
                "name": "Vibo Valentia",
                "state_code": "VV"
            },
            {
                "id": 1738,
                "name": "Vicenza",
                "state_code": "VI"
            },
            {
                "id": 1735,
                "name": "Viterbo",
                "state_code": "VT"
            }
        ]
    },
    {
        "id": 108,
        "states": [
            {
                "id": 3753,
                "name": "Clarendon Parish",
                "state_code": "13"
            },
            {
                "id": 3749,
                "name": "Hanover Parish",
                "state_code": "09"
            },
            {
                "id": 3748,
                "name": "Kingston Parish",
                "state_code": "01"
            },
            {
                "id": 3754,
                "name": "Manchester Parish",
                "state_code": "12"
            },
            {
                "id": 3752,
                "name": "Portland Parish",
                "state_code": "04"
            },
            {
                "id": 3751,
                "name": "Saint Andrew",
                "state_code": "02"
            },
            {
                "id": 3744,
                "name": "Saint Ann Parish",
                "state_code": "06"
            },
            {
                "id": 3746,
                "name": "Saint Catherine Parish",
                "state_code": "14"
            },
            {
                "id": 3743,
                "name": "Saint Elizabeth Parish",
                "state_code": "11"
            },
            {
                "id": 3745,
                "name": "Saint James Parish",
                "state_code": "08"
            },
            {
                "id": 3747,
                "name": "Saint Mary Parish",
                "state_code": "05"
            },
            {
                "id": 3750,
                "name": "Saint Thomas Parish",
                "state_code": "03"
            },
            {
                "id": 3755,
                "name": "Trelawny Parish",
                "state_code": "07"
            },
            {
                "id": 3742,
                "name": "Westmoreland Parish",
                "state_code": "10"
            }
        ]
    },
    {
        "id": 109,
        "states": [
            {
                "id": 827,
                "name": "Aichi Prefecture",
                "state_code": "23"
            },
            {
                "id": 829,
                "name": "Akita Prefecture",
                "state_code": "05"
            },
            {
                "id": 839,
                "name": "Aomori Prefecture",
                "state_code": "02"
            },
            {
                "id": 821,
                "name": "Chiba Prefecture",
                "state_code": "12"
            },
            {
                "id": 865,
                "name": "Ehime Prefecture",
                "state_code": "38"
            },
            {
                "id": 848,
                "name": "Fukui Prefecture",
                "state_code": "18"
            },
            {
                "id": 861,
                "name": "Fukuoka Prefecture",
                "state_code": "40"
            },
            {
                "id": 847,
                "name": "Fukushima Prefecture",
                "state_code": "07"
            },
            {
                "id": 858,
                "name": "Gifu Prefecture",
                "state_code": "21"
            },
            {
                "id": 862,
                "name": "Gunma Prefecture",
                "state_code": "10"
            },
            {
                "id": 828,
                "name": "Hiroshima Prefecture",
                "state_code": "34"
            },
            {
                "id": 832,
                "name": "Hokkaidō Prefecture",
                "state_code": "01"
            },
            {
                "id": 831,
                "name": "Hyōgo Prefecture",
                "state_code": "28"
            },
            {
                "id": 851,
                "name": "Ibaraki Prefecture",
                "state_code": "08"
            },
            {
                "id": 830,
                "name": "Ishikawa Prefecture",
                "state_code": "17"
            },
            {
                "id": 856,
                "name": "Iwate Prefecture",
                "state_code": "03"
            },
            {
                "id": 864,
                "name": "Kagawa Prefecture",
                "state_code": "37"
            },
            {
                "id": 840,
                "name": "Kagoshima Prefecture",
                "state_code": "46"
            },
            {
                "id": 842,
                "name": "Kanagawa Prefecture",
                "state_code": "14"
            },
            {
                "id": 4924,
                "name": "Kōchi Prefecture",
                "state_code": "39"
            },
            {
                "id": 846,
                "name": "Kumamoto Prefecture",
                "state_code": "43"
            },
            {
                "id": 834,
                "name": "Kyōto Prefecture",
                "state_code": "26"
            },
            {
                "id": 833,
                "name": "Mie Prefecture",
                "state_code": "24"
            },
            {
                "id": 857,
                "name": "Miyagi Prefecture",
                "state_code": "04"
            },
            {
                "id": 855,
                "name": "Miyazaki Prefecture",
                "state_code": "45"
            },
            {
                "id": 843,
                "name": "Nagano Prefecture",
                "state_code": "20"
            },
            {
                "id": 849,
                "name": "Nagasaki Prefecture",
                "state_code": "42"
            },
            {
                "id": 824,
                "name": "Nara Prefecture",
                "state_code": "29"
            },
            {
                "id": 841,
                "name": "Niigata Prefecture",
                "state_code": "15"
            },
            {
                "id": 822,
                "name": "Ōita Prefecture",
                "state_code": "44"
            },
            {
                "id": 820,
                "name": "Okayama Prefecture",
                "state_code": "33"
            },
            {
                "id": 853,
                "name": "Okinawa Prefecture",
                "state_code": "47"
            },
            {
                "id": 859,
                "name": "Ōsaka Prefecture",
                "state_code": "27"
            },
            {
                "id": 863,
                "name": "Saga Prefecture",
                "state_code": "41"
            },
            {
                "id": 860,
                "name": "Saitama Prefecture",
                "state_code": "11"
            },
            {
                "id": 845,
                "name": "Shiga Prefecture",
                "state_code": "25"
            },
            {
                "id": 826,
                "name": "Shimane Prefecture",
                "state_code": "32"
            },
            {
                "id": 825,
                "name": "Shizuoka Prefecture",
                "state_code": "22"
            },
            {
                "id": 854,
                "name": "Tochigi Prefecture",
                "state_code": "09"
            },
            {
                "id": 836,
                "name": "Tokushima Prefecture",
                "state_code": "36"
            },
            {
                "id": 823,
                "name": "Tokyo",
                "state_code": "13"
            },
            {
                "id": 850,
                "name": "Tottori Prefecture",
                "state_code": "31"
            },
            {
                "id": 838,
                "name": "Toyama Prefecture",
                "state_code": "16"
            },
            {
                "id": 844,
                "name": "Wakayama Prefecture",
                "state_code": "30"
            },
            {
                "id": 837,
                "name": "Yamagata Prefecture",
                "state_code": "06"
            },
            {
                "id": 835,
                "name": "Yamaguchi Prefecture",
                "state_code": "35"
            },
            {
                "id": 852,
                "name": "Yamanashi Prefecture",
                "state_code": "19"
            }
        ]
    },
    {
        "id": 110,
        "states": []
    },
    {
        "id": 111,
        "states": [
            {
                "id": 963,
                "name": "Ajloun",
                "state_code": "AJ"
            },
            {
                "id": 965,
                "name": "Amman",
                "state_code": "AM"
            },
            {
                "id": 959,
                "name": "Aqaba",
                "state_code": "AQ"
            },
            {
                "id": 961,
                "name": "Balqa",
                "state_code": "BA"
            },
            {
                "id": 960,
                "name": "Irbid",
                "state_code": "IR"
            },
            {
                "id": 966,
                "name": "Jerash",
                "state_code": "JA"
            },
            {
                "id": 956,
                "name": "Karak",
                "state_code": "KA"
            },
            {
                "id": 964,
                "name": "Ma'an",
                "state_code": "MN"
            },
            {
                "id": 958,
                "name": "Madaba",
                "state_code": "MD"
            },
            {
                "id": 962,
                "name": "Mafraq",
                "state_code": "MA"
            },
            {
                "id": 957,
                "name": "Tafilah",
                "state_code": "AT"
            },
            {
                "id": 967,
                "name": "Zarqa",
                "state_code": "AZ"
            }
        ]
    },
    {
        "id": 112,
        "states": [
            {
                "id": 145,
                "name": "Akmola Region",
                "state_code": "AKM"
            },
            {
                "id": 151,
                "name": "Aktobe Region",
                "state_code": "AKT"
            },
            {
                "id": 152,
                "name": "Almaty",
                "state_code": "ALA"
            },
            {
                "id": 143,
                "name": "Almaty Region",
                "state_code": "ALM"
            },
            {
                "id": 153,
                "name": "Atyrau Region",
                "state_code": "ATY"
            },
            {
                "id": 155,
                "name": "Baikonur",
                "state_code": "BAY"
            },
            {
                "id": 154,
                "name": "East Kazakhstan Region",
                "state_code": "VOS"
            },
            {
                "id": 147,
                "name": "Jambyl Region",
                "state_code": "ZHA"
            },
            {
                "id": 150,
                "name": "Karaganda Region",
                "state_code": "KAR"
            },
            {
                "id": 157,
                "name": "Kostanay Region",
                "state_code": "KUS"
            },
            {
                "id": 142,
                "name": "Kyzylorda Region",
                "state_code": "KZY"
            },
            {
                "id": 141,
                "name": "Mangystau Region",
                "state_code": "MAN"
            },
            {
                "id": 144,
                "name": "North Kazakhstan Region",
                "state_code": "SEV"
            },
            {
                "id": 156,
                "name": "Nur-Sultan",
                "state_code": "AST"
            },
            {
                "id": 146,
                "name": "Pavlodar Region",
                "state_code": "PAV"
            },
            {
                "id": 149,
                "name": "Turkestan Region",
                "state_code": "YUZ"
            },
            {
                "id": 148,
                "name": "West Kazakhstan Province",
                "state_code": "ZAP"
            }
        ]
    },
    {
        "id": 113,
        "states": [
            {
                "id": 181,
                "name": "Baringo",
                "state_code": "01"
            },
            {
                "id": 210,
                "name": "Bomet",
                "state_code": "02"
            },
            {
                "id": 168,
                "name": "Bungoma",
                "state_code": "03"
            },
            {
                "id": 161,
                "name": "Busia",
                "state_code": "04"
            },
            {
                "id": 201,
                "name": "Elgeyo-Marakwet",
                "state_code": "05"
            },
            {
                "id": 163,
                "name": "Embu",
                "state_code": "06"
            },
            {
                "id": 196,
                "name": "Garissa",
                "state_code": "07"
            },
            {
                "id": 195,
                "name": "Homa Bay",
                "state_code": "08"
            },
            {
                "id": 170,
                "name": "Isiolo",
                "state_code": "09"
            },
            {
                "id": 197,
                "name": "Kajiado",
                "state_code": "10"
            },
            {
                "id": 158,
                "name": "Kakamega",
                "state_code": "11"
            },
            {
                "id": 193,
                "name": "Kericho",
                "state_code": "12"
            },
            {
                "id": 199,
                "name": "Kiambu",
                "state_code": "13"
            },
            {
                "id": 174,
                "name": "Kilifi",
                "state_code": "14"
            },
            {
                "id": 167,
                "name": "Kirinyaga",
                "state_code": "15"
            },
            {
                "id": 159,
                "name": "Kisii",
                "state_code": "16"
            },
            {
                "id": 171,
                "name": "Kisumu",
                "state_code": "17"
            },
            {
                "id": 211,
                "name": "Kitui",
                "state_code": "18"
            },
            {
                "id": 173,
                "name": "Kwale",
                "state_code": "19"
            },
            {
                "id": 164,
                "name": "Laikipia",
                "state_code": "20"
            },
            {
                "id": 166,
                "name": "Lamu",
                "state_code": "21"
            },
            {
                "id": 184,
                "name": "Machakos",
                "state_code": "22"
            },
            {
                "id": 188,
                "name": "Makueni",
                "state_code": "23"
            },
            {
                "id": 187,
                "name": "Mandera",
                "state_code": "24"
            },
            {
                "id": 194,
                "name": "Marsabit",
                "state_code": "25"
            },
            {
                "id": 198,
                "name": "Meru",
                "state_code": "26"
            },
            {
                "id": 190,
                "name": "Migori",
                "state_code": "27"
            },
            {
                "id": 200,
                "name": "Mombasa",
                "state_code": "28"
            },
            {
                "id": 178,
                "name": "Murang'a",
                "state_code": "29"
            },
            {
                "id": 191,
                "name": "Nairobi City",
                "state_code": "30"
            },
            {
                "id": 203,
                "name": "Nakuru",
                "state_code": "31"
            },
            {
                "id": 165,
                "name": "Nandi",
                "state_code": "32"
            },
            {
                "id": 175,
                "name": "Narok",
                "state_code": "33"
            },
            {
                "id": 209,
                "name": "Nyamira",
                "state_code": "34"
            },
            {
                "id": 192,
                "name": "Nyandarua",
                "state_code": "35"
            },
            {
                "id": 180,
                "name": "Nyeri",
                "state_code": "36"
            },
            {
                "id": 207,
                "name": "Samburu",
                "state_code": "37"
            },
            {
                "id": 186,
                "name": "Siaya",
                "state_code": "38"
            },
            {
                "id": 176,
                "name": "Taita–Taveta",
                "state_code": "39"
            },
            {
                "id": 205,
                "name": "Tana River",
                "state_code": "40"
            },
            {
                "id": 185,
                "name": "Tharaka-Nithi",
                "state_code": "41"
            },
            {
                "id": 183,
                "name": "Trans Nzoia",
                "state_code": "42"
            },
            {
                "id": 206,
                "name": "Turkana",
                "state_code": "43"
            },
            {
                "id": 169,
                "name": "Uasin Gishu",
                "state_code": "44"
            },
            {
                "id": 202,
                "name": "Vihiga",
                "state_code": "45"
            },
            {
                "id": 182,
                "name": "Wajir",
                "state_code": "46"
            },
            {
                "id": 208,
                "name": "West Pokot",
                "state_code": "47"
            }
        ]
    },
    {
        "id": 114,
        "states": [
            {
                "id": 1831,
                "name": "Gilbert Islands",
                "state_code": "G"
            },
            {
                "id": 1832,
                "name": "Line Islands",
                "state_code": "L"
            },
            {
                "id": 1830,
                "name": "Phoenix Islands",
                "state_code": "P"
            }
        ]
    },
    {
        "id": 248,
        "states": [
            {
                "id": 4876,
                "name": "Đakovica District (Gjakove)",
                "state_code": "XDG"
            },
            {
                "id": 4877,
                "name": "Gjilan District",
                "state_code": "XGJ"
            },
            {
                "id": 4878,
                "name": "Kosovska Mitrovica District",
                "state_code": "XKM"
            },
            {
                "id": 3738,
                "name": "Peć District",
                "state_code": "XPE"
            },
            {
                "id": 4879,
                "name": "Pristina (Priştine)",
                "state_code": "XPI"
            },
            {
                "id": 3723,
                "name": "Prizren District",
                "state_code": "XPR"
            },
            {
                "id": 4874,
                "name": "Uroševac District (Ferizaj)",
                "state_code": "XUF"
            }
        ]
    },
    {
        "id": 117,
        "states": [
            {
                "id": 977,
                "name": "Al Ahmadi",
                "state_code": "AH"
            },
            {
                "id": 975,
                "name": "Al Farwaniyah",
                "state_code": "FA"
            },
            {
                "id": 972,
                "name": "Al Jahra",
                "state_code": "JA"
            },
            {
                "id": 976,
                "name": "Capital",
                "state_code": "KU"
            },
            {
                "id": 973,
                "name": "Hawalli",
                "state_code": "HA"
            },
            {
                "id": 974,
                "name": "Mubarak Al-Kabeer",
                "state_code": "MU"
            }
        ]
    },
    {
        "id": 118,
        "states": [
            {
                "id": 998,
                "name": "Batken Region",
                "state_code": "B"
            },
            {
                "id": 1001,
                "name": "Bishkek",
                "state_code": "GB"
            },
            {
                "id": 1004,
                "name": "Chuy Region",
                "state_code": "C"
            },
            {
                "id": 1002,
                "name": "Issyk-Kul Region",
                "state_code": "Y"
            },
            {
                "id": 1000,
                "name": "Jalal-Abad Region",
                "state_code": "J"
            },
            {
                "id": 999,
                "name": "Naryn Region",
                "state_code": "N"
            },
            {
                "id": 1003,
                "name": "Osh",
                "state_code": "GO"
            },
            {
                "id": 1005,
                "name": "Osh Region",
                "state_code": "O"
            },
            {
                "id": 997,
                "name": "Talas Region",
                "state_code": "T"
            }
        ]
    },
    {
        "id": 119,
        "states": [
            {
                "id": 982,
                "name": "Attapeu Province",
                "state_code": "AT"
            },
            {
                "id": 991,
                "name": "Bokeo Province",
                "state_code": "BK"
            },
            {
                "id": 985,
                "name": "Bolikhamsai Province",
                "state_code": "BL"
            },
            {
                "id": 996,
                "name": "Champasak Province",
                "state_code": "CH"
            },
            {
                "id": 989,
                "name": "Houaphanh Province",
                "state_code": "HO"
            },
            {
                "id": 986,
                "name": "Khammouane Province",
                "state_code": "KH"
            },
            {
                "id": 992,
                "name": "Luang Namtha Province",
                "state_code": "LM"
            },
            {
                "id": 978,
                "name": "Luang Prabang Province",
                "state_code": "LP"
            },
            {
                "id": 988,
                "name": "Oudomxay Province",
                "state_code": "OU"
            },
            {
                "id": 987,
                "name": "Phongsaly Province",
                "state_code": "PH"
            },
            {
                "id": 993,
                "name": "Sainyabuli Province",
                "state_code": "XA"
            },
            {
                "id": 981,
                "name": "Salavan Province",
                "state_code": "SL"
            },
            {
                "id": 990,
                "name": "Savannakhet Province",
                "state_code": "SV"
            },
            {
                "id": 984,
                "name": "Sekong Province",
                "state_code": "XE"
            },
            {
                "id": 979,
                "name": "Vientiane Prefecture",
                "state_code": "VT"
            },
            {
                "id": 980,
                "name": "Vientiane Province",
                "state_code": "VI"
            },
            {
                "id": 994,
                "name": "Xaisomboun",
                "state_code": "XN"
            },
            {
                "id": 983,
                "name": "Xaisomboun Province",
                "state_code": "XS"
            },
            {
                "id": 995,
                "name": "Xiangkhouang Province",
                "state_code": "XI"
            }
        ]
    },
    {
        "id": 120,
        "states": [
            {
                "id": 4445,
                "name": "Aglona Municipality",
                "state_code": "001"
            },
            {
                "id": 4472,
                "name": "Aizkraukle Municipality",
                "state_code": "002"
            },
            {
                "id": 4496,
                "name": "Aizpute Municipality",
                "state_code": "003"
            },
            {
                "id": 4499,
                "name": "Aknīste Municipality",
                "state_code": "004"
            },
            {
                "id": 4484,
                "name": "Aloja Municipality",
                "state_code": "005"
            },
            {
                "id": 4485,
                "name": "Alsunga Municipality",
                "state_code": "006"
            },
            {
                "id": 4487,
                "name": "Alūksne Municipality",
                "state_code": "007"
            },
            {
                "id": 4497,
                "name": "Amata Municipality",
                "state_code": "008"
            },
            {
                "id": 4457,
                "name": "Ape Municipality",
                "state_code": "009"
            },
            {
                "id": 4481,
                "name": "Auce Municipality",
                "state_code": "010"
            },
            {
                "id": 4427,
                "name": "Babīte Municipality",
                "state_code": "012"
            },
            {
                "id": 4482,
                "name": "Baldone Municipality",
                "state_code": "013"
            },
            {
                "id": 4498,
                "name": "Baltinava Municipality",
                "state_code": "014"
            },
            {
                "id": 4505,
                "name": "Balvi Municipality",
                "state_code": "015"
            },
            {
                "id": 4465,
                "name": "Bauska Municipality",
                "state_code": "016"
            },
            {
                "id": 4471,
                "name": "Beverīna Municipality",
                "state_code": "017"
            },
            {
                "id": 4468,
                "name": "Brocēni Municipality",
                "state_code": "018"
            },
            {
                "id": 4411,
                "name": "Burtnieki Municipality",
                "state_code": "019"
            },
            {
                "id": 4454,
                "name": "Carnikava Municipality",
                "state_code": "020"
            },
            {
                "id": 4469,
                "name": "Cēsis Municipality",
                "state_code": "022"
            },
            {
                "id": 4414,
                "name": "Cesvaine Municipality",
                "state_code": "021"
            },
            {
                "id": 4410,
                "name": "Cibla Municipality",
                "state_code": "023"
            },
            {
                "id": 4504,
                "name": "Dagda Municipality",
                "state_code": "024"
            },
            {
                "id": 4463,
                "name": "Daugavpils",
                "state_code": "DGV"
            },
            {
                "id": 4492,
                "name": "Daugavpils Municipality",
                "state_code": "025"
            },
            {
                "id": 4437,
                "name": "Dobele Municipality",
                "state_code": "026"
            },
            {
                "id": 4428,
                "name": "Dundaga Municipality",
                "state_code": "027"
            },
            {
                "id": 4458,
                "name": "Durbe Municipality",
                "state_code": "028"
            },
            {
                "id": 4448,
                "name": "Engure Municipality",
                "state_code": "029"
            },
            {
                "id": 4444,
                "name": "Ērgļi Municipality",
                "state_code": "030"
            },
            {
                "id": 4510,
                "name": "Garkalne Municipality",
                "state_code": "031"
            },
            {
                "id": 4470,
                "name": "Grobiņa Municipality",
                "state_code": "032"
            },
            {
                "id": 4400,
                "name": "Gulbene Municipality",
                "state_code": "033"
            },
            {
                "id": 4441,
                "name": "Iecava Municipality",
                "state_code": "034"
            },
            {
                "id": 4511,
                "name": "Ikšķile Municipality",
                "state_code": "035"
            },
            {
                "id": 4399,
                "name": "Ilūkste Municipality",
                "state_code": "036"
            },
            {
                "id": 4449,
                "name": "Inčukalns Municipality",
                "state_code": "037"
            },
            {
                "id": 4475,
                "name": "Jaunjelgava Municipality",
                "state_code": "038"
            },
            {
                "id": 4407,
                "name": "Jaunpiebalga Municipality",
                "state_code": "039"
            },
            {
                "id": 4489,
                "name": "Jaunpils Municipality",
                "state_code": "040"
            },
            {
                "id": 4464,
                "name": "Jēkabpils",
                "state_code": "JKB"
            },
            {
                "id": 4438,
                "name": "Jēkabpils Municipality",
                "state_code": "042"
            },
            {
                "id": 4500,
                "name": "Jelgava",
                "state_code": "JEL"
            },
            {
                "id": 4424,
                "name": "Jelgava Municipality",
                "state_code": "041"
            },
            {
                "id": 4446,
                "name": "Jūrmala",
                "state_code": "JUR"
            },
            {
                "id": 4420,
                "name": "Kandava Municipality",
                "state_code": "043"
            },
            {
                "id": 4453,
                "name": "Kārsava Municipality",
                "state_code": "044"
            },
            {
                "id": 4412,
                "name": "Ķegums Municipality",
                "state_code": "051"
            },
            {
                "id": 4435,
                "name": "Ķekava Municipality",
                "state_code": "052"
            },
            {
                "id": 4495,
                "name": "Kocēni Municipality",
                "state_code": "045"
            },
            {
                "id": 4452,
                "name": "Koknese Municipality",
                "state_code": "046"
            },
            {
                "id": 4474,
                "name": "Krāslava Municipality",
                "state_code": "047"
            },
            {
                "id": 4422,
                "name": "Krimulda Municipality",
                "state_code": "048"
            },
            {
                "id": 4413,
                "name": "Krustpils Municipality",
                "state_code": "049"
            },
            {
                "id": 4490,
                "name": "Kuldīga Municipality",
                "state_code": "050"
            },
            {
                "id": 4512,
                "name": "Lielvārde Municipality",
                "state_code": "053"
            },
            {
                "id": 4460,
                "name": "Liepāja",
                "state_code": "LPX"
            },
            {
                "id": 4488,
                "name": "Līgatne Municipality",
                "state_code": "055"
            },
            {
                "id": 4418,
                "name": "Limbaži Municipality",
                "state_code": "054"
            },
            {
                "id": 4401,
                "name": "Līvāni Municipality",
                "state_code": "056"
            },
            {
                "id": 4419,
                "name": "Lubāna Municipality",
                "state_code": "057"
            },
            {
                "id": 4501,
                "name": "Ludza Municipality",
                "state_code": "058"
            },
            {
                "id": 4433,
                "name": "Madona Municipality",
                "state_code": "059"
            },
            {
                "id": 4461,
                "name": "Mālpils Municipality",
                "state_code": "061"
            },
            {
                "id": 4450,
                "name": "Mārupe Municipality",
                "state_code": "062"
            },
            {
                "id": 4513,
                "name": "Mazsalaca Municipality",
                "state_code": "060"
            },
            {
                "id": 4451,
                "name": "Mērsrags Municipality",
                "state_code": "063"
            },
            {
                "id": 4398,
                "name": "Naukšēni Municipality",
                "state_code": "064"
            },
            {
                "id": 4432,
                "name": "Nereta Municipality",
                "state_code": "065"
            },
            {
                "id": 4436,
                "name": "Nīca Municipality",
                "state_code": "066"
            },
            {
                "id": 4416,
                "name": "Ogre Municipality",
                "state_code": "067"
            },
            {
                "id": 4417,
                "name": "Olaine Municipality",
                "state_code": "068"
            },
            {
                "id": 4442,
                "name": "Ozolnieki Municipality",
                "state_code": "069"
            },
            {
                "id": 4507,
                "name": "Pārgauja Municipality",
                "state_code": "070"
            },
            {
                "id": 4467,
                "name": "Pāvilosta Municipality",
                "state_code": "071"
            },
            {
                "id": 4405,
                "name": "Pļaviņas Municipality",
                "state_code": "072"
            },
            {
                "id": 4483,
                "name": "Preiļi Municipality",
                "state_code": "073"
            },
            {
                "id": 4429,
                "name": "Priekule Municipality",
                "state_code": "074"
            },
            {
                "id": 4506,
                "name": "Priekuļi Municipality",
                "state_code": "075"
            },
            {
                "id": 4479,
                "name": "Rauna Municipality",
                "state_code": "076"
            },
            {
                "id": 4509,
                "name": "Rēzekne",
                "state_code": "REZ"
            },
            {
                "id": 4455,
                "name": "Rēzekne Municipality",
                "state_code": "077"
            },
            {
                "id": 4502,
                "name": "Riebiņi Municipality",
                "state_code": "078"
            },
            {
                "id": 4491,
                "name": "Riga",
                "state_code": "RIX"
            },
            {
                "id": 4440,
                "name": "Roja Municipality",
                "state_code": "079"
            },
            {
                "id": 4493,
                "name": "Ropaži Municipality",
                "state_code": "080"
            },
            {
                "id": 4503,
                "name": "Rucava Municipality",
                "state_code": "081"
            },
            {
                "id": 4423,
                "name": "Rugāji Municipality",
                "state_code": "082"
            },
            {
                "id": 4426,
                "name": "Rūjiena Municipality",
                "state_code": "084"
            },
            {
                "id": 4404,
                "name": "Rundāle Municipality",
                "state_code": "083"
            },
            {
                "id": 4434,
                "name": "Sala Municipality",
                "state_code": "085"
            },
            {
                "id": 4396,
                "name": "Salacgrīva Municipality",
                "state_code": "086"
            },
            {
                "id": 4402,
                "name": "Salaspils Municipality",
                "state_code": "087"
            },
            {
                "id": 4439,
                "name": "Saldus Municipality",
                "state_code": "088"
            },
            {
                "id": 4443,
                "name": "Saulkrasti Municipality",
                "state_code": "089"
            },
            {
                "id": 4408,
                "name": "Sēja Municipality",
                "state_code": "090"
            },
            {
                "id": 4476,
                "name": "Sigulda Municipality",
                "state_code": "091"
            },
            {
                "id": 4415,
                "name": "Skrīveri Municipality",
                "state_code": "092"
            },
            {
                "id": 4447,
                "name": "Skrunda Municipality",
                "state_code": "093"
            },
            {
                "id": 4462,
                "name": "Smiltene Municipality",
                "state_code": "094"
            },
            {
                "id": 4478,
                "name": "Stopiņi Municipality",
                "state_code": "095"
            },
            {
                "id": 4494,
                "name": "Strenči Municipality",
                "state_code": "096"
            },
            {
                "id": 4459,
                "name": "Talsi Municipality",
                "state_code": "097"
            },
            {
                "id": 4480,
                "name": "Tērvete Municipality",
                "state_code": "098"
            },
            {
                "id": 4409,
                "name": "Tukums Municipality",
                "state_code": "099"
            },
            {
                "id": 4508,
                "name": "Vaiņode Municipality",
                "state_code": "100"
            },
            {
                "id": 4425,
                "name": "Valka Municipality",
                "state_code": "101"
            },
            {
                "id": 4473,
                "name": "Valmiera",
                "state_code": "VMR"
            },
            {
                "id": 4431,
                "name": "Varakļāni Municipality",
                "state_code": "102"
            },
            {
                "id": 4406,
                "name": "Vārkava Municipality",
                "state_code": "103"
            },
            {
                "id": 4466,
                "name": "Vecpiebalga Municipality",
                "state_code": "104"
            },
            {
                "id": 4397,
                "name": "Vecumnieki Municipality",
                "state_code": "105"
            },
            {
                "id": 4421,
                "name": "Ventspils",
                "state_code": "VEN"
            },
            {
                "id": 4403,
                "name": "Ventspils Municipality",
                "state_code": "106"
            },
            {
                "id": 4456,
                "name": "Viesīte Municipality",
                "state_code": "107"
            },
            {
                "id": 4477,
                "name": "Viļaka Municipality",
                "state_code": "108"
            },
            {
                "id": 4486,
                "name": "Viļāni Municipality",
                "state_code": "109"
            },
            {
                "id": 4430,
                "name": "Zilupe Municipality",
                "state_code": "110"
            }
        ]
    },
    {
        "id": 121,
        "states": [
            {
                "id": 2285,
                "name": "Akkar",
                "state_code": "AK"
            },
            {
                "id": 2283,
                "name": "Baalbek-Hermel",
                "state_code": "BH"
            },
            {
                "id": 2286,
                "name": "Beirut",
                "state_code": "BA"
            },
            {
                "id": 2287,
                "name": "Beqaa",
                "state_code": "BI"
            },
            {
                "id": 2282,
                "name": "Mount Lebanon",
                "state_code": "JL"
            },
            {
                "id": 2288,
                "name": "Nabatieh",
                "state_code": "NA"
            },
            {
                "id": 2284,
                "name": "North",
                "state_code": "AS"
            },
            {
                "id": 2281,
                "name": "South",
                "state_code": "JA"
            }
        ]
    },
    {
        "id": 122,
        "states": [
            {
                "id": 3030,
                "name": "Berea District",
                "state_code": "D"
            },
            {
                "id": 3029,
                "name": "Butha-Buthe District",
                "state_code": "B"
            },
            {
                "id": 3026,
                "name": "Leribe District",
                "state_code": "C"
            },
            {
                "id": 3022,
                "name": "Mafeteng District",
                "state_code": "E"
            },
            {
                "id": 3028,
                "name": "Maseru District",
                "state_code": "A"
            },
            {
                "id": 3023,
                "name": "Mohale's Hoek District",
                "state_code": "F"
            },
            {
                "id": 3024,
                "name": "Mokhotlong District",
                "state_code": "J"
            },
            {
                "id": 3025,
                "name": "Qacha's Nek District",
                "state_code": "H"
            },
            {
                "id": 3027,
                "name": "Quthing District",
                "state_code": "G"
            },
            {
                "id": 3031,
                "name": "Thaba-Tseka District",
                "state_code": "K"
            }
        ]
    },
    {
        "id": 123,
        "states": [
            {
                "id": 3041,
                "name": "Bomi County",
                "state_code": "BM"
            },
            {
                "id": 3034,
                "name": "Bong County",
                "state_code": "BG"
            },
            {
                "id": 3044,
                "name": "Gbarpolu County",
                "state_code": "GP"
            },
            {
                "id": 3040,
                "name": "Grand Bassa County",
                "state_code": "GB"
            },
            {
                "id": 3036,
                "name": "Grand Cape Mount County",
                "state_code": "CM"
            },
            {
                "id": 3039,
                "name": "Grand Gedeh County",
                "state_code": "GG"
            },
            {
                "id": 3045,
                "name": "Grand Kru County",
                "state_code": "GK"
            },
            {
                "id": 3037,
                "name": "Lofa County",
                "state_code": "LO"
            },
            {
                "id": 3043,
                "name": "Margibi County",
                "state_code": "MG"
            },
            {
                "id": 3042,
                "name": "Maryland County",
                "state_code": "MY"
            },
            {
                "id": 3032,
                "name": "Montserrado County",
                "state_code": "MO"
            },
            {
                "id": 3046,
                "name": "Nimba",
                "state_code": "NI"
            },
            {
                "id": 3033,
                "name": "River Cess County",
                "state_code": "RI"
            },
            {
                "id": 3038,
                "name": "River Gee County",
                "state_code": "RG"
            },
            {
                "id": 3035,
                "name": "Sinoe County",
                "state_code": "SI"
            }
        ]
    },
    {
        "id": 124,
        "states": [
            {
                "id": 2964,
                "name": "Al Wahat District",
                "state_code": "WA"
            },
            {
                "id": 2981,
                "name": "Benghazi",
                "state_code": "BA"
            },
            {
                "id": 2966,
                "name": "Derna District",
                "state_code": "DR"
            },
            {
                "id": 2969,
                "name": "Ghat District",
                "state_code": "GT"
            },
            {
                "id": 2980,
                "name": "Jabal al Akhdar",
                "state_code": "JA"
            },
            {
                "id": 2974,
                "name": "Jabal al Gharbi District",
                "state_code": "JG"
            },
            {
                "id": 2979,
                "name": "Jafara",
                "state_code": "JI"
            },
            {
                "id": 2970,
                "name": "Jufra",
                "state_code": "JU"
            },
            {
                "id": 2972,
                "name": "Kufra District",
                "state_code": "KF"
            },
            {
                "id": 2968,
                "name": "Marj District",
                "state_code": "MJ"
            },
            {
                "id": 2978,
                "name": "Misrata District",
                "state_code": "MI"
            },
            {
                "id": 2961,
                "name": "Murqub",
                "state_code": "MB"
            },
            {
                "id": 2967,
                "name": "Murzuq District",
                "state_code": "MQ"
            },
            {
                "id": 2976,
                "name": "Nalut District",
                "state_code": "NL"
            },
            {
                "id": 2962,
                "name": "Nuqat al Khams",
                "state_code": "NQ"
            },
            {
                "id": 2965,
                "name": "Sabha District",
                "state_code": "SB"
            },
            {
                "id": 2977,
                "name": "Sirte District",
                "state_code": "SR"
            },
            {
                "id": 2971,
                "name": "Tripoli District",
                "state_code": "TB"
            },
            {
                "id": 2973,
                "name": "Wadi al Hayaa District",
                "state_code": "WD"
            },
            {
                "id": 2975,
                "name": "Wadi al Shatii District",
                "state_code": "WS"
            },
            {
                "id": 2963,
                "name": "Zawiya District",
                "state_code": "ZA"
            }
        ]
    },
    {
        "id": 125,
        "states": [
            {
                "id": 458,
                "name": "Balzers",
                "state_code": "01"
            },
            {
                "id": 451,
                "name": "Eschen",
                "state_code": "02"
            },
            {
                "id": 457,
                "name": "Gamprin",
                "state_code": "03"
            },
            {
                "id": 455,
                "name": "Mauren",
                "state_code": "04"
            },
            {
                "id": 454,
                "name": "Planken",
                "state_code": "05"
            },
            {
                "id": 453,
                "name": "Ruggell",
                "state_code": "06"
            },
            {
                "id": 450,
                "name": "Schaan",
                "state_code": "07"
            },
            {
                "id": 449,
                "name": "Schellenberg",
                "state_code": "08"
            },
            {
                "id": 459,
                "name": "Triesen",
                "state_code": "09"
            },
            {
                "id": 456,
                "name": "Triesenberg",
                "state_code": "10"
            },
            {
                "id": 452,
                "name": "Vaduz",
                "state_code": "11"
            }
        ]
    },
    {
        "id": 126,
        "states": [
            {
                "id": 1561,
                "name": "Akmenė District Municipality",
                "state_code": "01"
            },
            {
                "id": 1605,
                "name": "Alytus City Municipality",
                "state_code": "02"
            },
            {
                "id": 1574,
                "name": "Alytus County",
                "state_code": "AL"
            },
            {
                "id": 1599,
                "name": "Alytus District Municipality",
                "state_code": "03"
            },
            {
                "id": 1603,
                "name": "Birštonas Municipality",
                "state_code": "05"
            },
            {
                "id": 1566,
                "name": "Biržai District Municipality",
                "state_code": "06"
            },
            {
                "id": 1579,
                "name": "Druskininkai municipality",
                "state_code": "07"
            },
            {
                "id": 1559,
                "name": "Elektrėnai municipality",
                "state_code": "08"
            },
            {
                "id": 1562,
                "name": "Ignalina District Municipality",
                "state_code": "09"
            },
            {
                "id": 1567,
                "name": "Jonava District Municipality",
                "state_code": "10"
            },
            {
                "id": 1581,
                "name": "Joniškis District Municipality",
                "state_code": "11"
            },
            {
                "id": 1555,
                "name": "Jurbarkas District Municipality",
                "state_code": "12"
            },
            {
                "id": 1583,
                "name": "Kaišiadorys District Municipality",
                "state_code": "13"
            },
            {
                "id": 1591,
                "name": "Kalvarija municipality",
                "state_code": "14"
            },
            {
                "id": 1580,
                "name": "Kaunas City Municipality",
                "state_code": "15"
            },
            {
                "id": 1556,
                "name": "Kaunas County",
                "state_code": "KU"
            },
            {
                "id": 1565,
                "name": "Kaunas District Municipality",
                "state_code": "16"
            },
            {
                "id": 1575,
                "name": "Kazlų Rūda municipality",
                "state_code": "17"
            },
            {
                "id": 1584,
                "name": "Kėdainiai District Municipality",
                "state_code": "18"
            },
            {
                "id": 1618,
                "name": "Kelmė District Municipality",
                "state_code": "19"
            },
            {
                "id": 1597,
                "name": "Klaipeda City Municipality",
                "state_code": "20"
            },
            {
                "id": 1600,
                "name": "Klaipėda County",
                "state_code": "KL"
            },
            {
                "id": 1604,
                "name": "Klaipėda District Municipality",
                "state_code": "21"
            },
            {
                "id": 1571,
                "name": "Kretinga District Municipality",
                "state_code": "22"
            },
            {
                "id": 1585,
                "name": "Kupiškis District Municipality",
                "state_code": "23"
            },
            {
                "id": 1611,
                "name": "Lazdijai District Municipality",
                "state_code": "24"
            },
            {
                "id": 1570,
                "name": "Marijampolė County",
                "state_code": "MR"
            },
            {
                "id": 1610,
                "name": "Marijampolė Municipality",
                "state_code": "25"
            },
            {
                "id": 1557,
                "name": "Mažeikiai District Municipality",
                "state_code": "26"
            },
            {
                "id": 1582,
                "name": "Molėtai District Municipality",
                "state_code": "27"
            },
            {
                "id": 1563,
                "name": "Neringa Municipality",
                "state_code": "28"
            },
            {
                "id": 1612,
                "name": "Pagėgiai municipality",
                "state_code": "29"
            },
            {
                "id": 1595,
                "name": "Pakruojis District Municipality",
                "state_code": "30"
            },
            {
                "id": 1588,
                "name": "Palanga City Municipality",
                "state_code": "31"
            },
            {
                "id": 1589,
                "name": "Panevėžys City Municipality",
                "state_code": "32"
            },
            {
                "id": 1558,
                "name": "Panevėžys County",
                "state_code": "PN"
            },
            {
                "id": 1614,
                "name": "Panevėžys District Municipality",
                "state_code": "33"
            },
            {
                "id": 1616,
                "name": "Pasvalys District Municipality",
                "state_code": "34"
            },
            {
                "id": 1553,
                "name": "Plungė District Municipality",
                "state_code": "35"
            },
            {
                "id": 1578,
                "name": "Prienai District Municipality",
                "state_code": "36"
            },
            {
                "id": 1568,
                "name": "Radviliškis District Municipality",
                "state_code": "37"
            },
            {
                "id": 1587,
                "name": "Raseiniai District Municipality",
                "state_code": "38"
            },
            {
                "id": 1590,
                "name": "Rietavas municipality",
                "state_code": "39"
            },
            {
                "id": 1615,
                "name": "Rokiškis District Municipality",
                "state_code": "40"
            },
            {
                "id": 1576,
                "name": "Šakiai District Municipality",
                "state_code": "41"
            },
            {
                "id": 1577,
                "name": "Šalčininkai District Municipality",
                "state_code": "42"
            },
            {
                "id": 1609,
                "name": "Šiauliai City Municipality",
                "state_code": "43"
            },
            {
                "id": 1586,
                "name": "Šiauliai County",
                "state_code": "SA"
            },
            {
                "id": 1554,
                "name": "Šiauliai District Municipality",
                "state_code": "44"
            },
            {
                "id": 1613,
                "name": "Šilalė District Municipality",
                "state_code": "45"
            },
            {
                "id": 1607,
                "name": "Šilutė District Municipality",
                "state_code": "46"
            },
            {
                "id": 1594,
                "name": "Širvintos District Municipality",
                "state_code": "47"
            },
            {
                "id": 1617,
                "name": "Skuodas District Municipality",
                "state_code": "48"
            },
            {
                "id": 1560,
                "name": "Švenčionys District Municipality",
                "state_code": "49"
            },
            {
                "id": 1573,
                "name": "Tauragė County",
                "state_code": "TA"
            },
            {
                "id": 1572,
                "name": "Tauragė District Municipality",
                "state_code": "50"
            },
            {
                "id": 1569,
                "name": "Telšiai County",
                "state_code": "TE"
            },
            {
                "id": 1608,
                "name": "Telšiai District Municipality",
                "state_code": "51"
            },
            {
                "id": 1593,
                "name": "Trakai District Municipality",
                "state_code": "52"
            },
            {
                "id": 1596,
                "name": "Ukmergė District Municipality",
                "state_code": "53"
            },
            {
                "id": 1621,
                "name": "Utena County",
                "state_code": "UT"
            },
            {
                "id": 1598,
                "name": "Utena District Municipality",
                "state_code": "54"
            },
            {
                "id": 1602,
                "name": "Varėna District Municipality",
                "state_code": "55"
            },
            {
                "id": 1620,
                "name": "Vilkaviškis District Municipality",
                "state_code": "56"
            },
            {
                "id": 1606,
                "name": "Vilnius City Municipality",
                "state_code": "57"
            },
            {
                "id": 1601,
                "name": "Vilnius County",
                "state_code": "VL"
            },
            {
                "id": 1592,
                "name": "Vilnius District Municipality",
                "state_code": "58"
            },
            {
                "id": 1564,
                "name": "Visaginas Municipality",
                "state_code": "59"
            },
            {
                "id": 1619,
                "name": "Zarasai District Municipality",
                "state_code": "60"
            }
        ]
    },
    {
        "id": 127,
        "states": [
            {
                "id": 1518,
                "name": "Canton of Capellen",
                "state_code": "CA"
            },
            {
                "id": 1521,
                "name": "Canton of Clervaux",
                "state_code": "CL"
            },
            {
                "id": 1513,
                "name": "Canton of Diekirch",
                "state_code": "DI"
            },
            {
                "id": 1515,
                "name": "Canton of Echternach",
                "state_code": "EC"
            },
            {
                "id": 1517,
                "name": "Canton of Esch-sur-Alzette",
                "state_code": "ES"
            },
            {
                "id": 1525,
                "name": "Canton of Grevenmacher",
                "state_code": "GR"
            },
            {
                "id": 1527,
                "name": "Canton of Luxembourg",
                "state_code": "LU"
            },
            {
                "id": 1522,
                "name": "Canton of Mersch",
                "state_code": "ME"
            },
            {
                "id": 1516,
                "name": "Canton of Redange",
                "state_code": "RD"
            },
            {
                "id": 1519,
                "name": "Canton of Remich",
                "state_code": "RM"
            },
            {
                "id": 1523,
                "name": "Canton of Vianden",
                "state_code": "VD"
            },
            {
                "id": 1526,
                "name": "Canton of Wiltz",
                "state_code": "WI"
            },
            {
                "id": 1524,
                "name": "Diekirch District",
                "state_code": "D"
            },
            {
                "id": 1520,
                "name": "Grevenmacher District",
                "state_code": "G"
            },
            {
                "id": 1514,
                "name": "Luxembourg District",
                "state_code": "L"
            }
        ]
    },
    {
        "id": 128,
        "states": []
    },
    {
        "id": 129,
        "states": [
            {
                "id": 703,
                "name": "Aerodrom Municipality",
                "state_code": "01"
            },
            {
                "id": 656,
                "name": "Aračinovo Municipality",
                "state_code": "02"
            },
            {
                "id": 716,
                "name": "Berovo Municipality",
                "state_code": "03"
            },
            {
                "id": 679,
                "name": "Bitola Municipality",
                "state_code": "04"
            },
            {
                "id": 649,
                "name": "Bogdanci Municipality",
                "state_code": "05"
            },
            {
                "id": 721,
                "name": "Bogovinje Municipality",
                "state_code": "06"
            },
            {
                "id": 652,
                "name": "Bosilovo Municipality",
                "state_code": "07"
            },
            {
                "id": 660,
                "name": "Brvenica Municipality",
                "state_code": "08"
            },
            {
                "id": 694,
                "name": "Butel Municipality",
                "state_code": "09"
            },
            {
                "id": 704,
                "name": "Čair Municipality",
                "state_code": "79"
            },
            {
                "id": 676,
                "name": "Čaška Municipality",
                "state_code": "80"
            },
            {
                "id": 702,
                "name": "Centar Municipality",
                "state_code": "77"
            },
            {
                "id": 720,
                "name": "Centar Župa Municipality",
                "state_code": "78"
            },
            {
                "id": 644,
                "name": "Češinovo-Obleševo Municipality",
                "state_code": "81"
            },
            {
                "id": 715,
                "name": "Čučer-Sandevo Municipality",
                "state_code": "82"
            },
            {
                "id": 645,
                "name": "Debarca Municipality",
                "state_code": "22"
            },
            {
                "id": 695,
                "name": "Delčevo Municipality",
                "state_code": "23"
            },
            {
                "id": 687,
                "name": "Demir Hisar Municipality",
                "state_code": "25"
            },
            {
                "id": 655,
                "name": "Demir Kapija Municipality",
                "state_code": "24"
            },
            {
                "id": 697,
                "name": "Dojran Municipality",
                "state_code": "26"
            },
            {
                "id": 675,
                "name": "Dolneni Municipality",
                "state_code": "27"
            },
            {
                "id": 657,
                "name": "Drugovo Municipality",
                "state_code": "28"
            },
            {
                "id": 707,
                "name": "Gazi Baba Municipality",
                "state_code": "17"
            },
            {
                "id": 648,
                "name": "Gevgelija Municipality",
                "state_code": "18"
            },
            {
                "id": 722,
                "name": "Gjorče Petrov Municipality",
                "state_code": "29"
            },
            {
                "id": 693,
                "name": "Gostivar Municipality",
                "state_code": "19"
            },
            {
                "id": 708,
                "name": "Gradsko Municipality",
                "state_code": "20"
            },
            {
                "id": 684,
                "name": "Greater Skopje",
                "state_code": "85"
            },
            {
                "id": 690,
                "name": "Ilinden Municipality",
                "state_code": "34"
            },
            {
                "id": 678,
                "name": "Jegunovce Municipality",
                "state_code": "35"
            },
            {
                "id": 674,
                "name": "Karbinci",
                "state_code": "37"
            },
            {
                "id": 681,
                "name": "Karpoš Municipality",
                "state_code": "38"
            },
            {
                "id": 713,
                "name": "Kavadarci Municipality",
                "state_code": "36"
            },
            {
                "id": 688,
                "name": "Kičevo Municipality",
                "state_code": "40"
            },
            {
                "id": 686,
                "name": "Kisela Voda Municipality",
                "state_code": "39"
            },
            {
                "id": 723,
                "name": "Kočani Municipality",
                "state_code": "42"
            },
            {
                "id": 665,
                "name": "Konče Municipality",
                "state_code": "41"
            },
            {
                "id": 641,
                "name": "Kratovo Municipality",
                "state_code": "43"
            },
            {
                "id": 677,
                "name": "Kriva Palanka Municipality",
                "state_code": "44"
            },
            {
                "id": 647,
                "name": "Krivogaštani Municipality",
                "state_code": "45"
            },
            {
                "id": 714,
                "name": "Kruševo Municipality",
                "state_code": "46"
            },
            {
                "id": 683,
                "name": "Kumanovo Municipality",
                "state_code": "47"
            },
            {
                "id": 659,
                "name": "Lipkovo Municipality",
                "state_code": "48"
            },
            {
                "id": 705,
                "name": "Lozovo Municipality",
                "state_code": "49"
            },
            {
                "id": 701,
                "name": "Makedonska Kamenica Municipality",
                "state_code": "51"
            },
            {
                "id": 692,
                "name": "Makedonski Brod Municipality",
                "state_code": "52"
            },
            {
                "id": 669,
                "name": "Mavrovo and Rostuša Municipality",
                "state_code": "50"
            },
            {
                "id": 653,
                "name": "Mogila Municipality",
                "state_code": "53"
            },
            {
                "id": 664,
                "name": "Negotino Municipality",
                "state_code": "54"
            },
            {
                "id": 696,
                "name": "Novaci Municipality",
                "state_code": "55"
            },
            {
                "id": 718,
                "name": "Novo Selo Municipality",
                "state_code": "56"
            },
            {
                "id": 699,
                "name": "Ohrid Municipality",
                "state_code": "58"
            },
            {
                "id": 682,
                "name": "Oslomej Municipality",
                "state_code": "57"
            },
            {
                "id": 685,
                "name": "Pehčevo Municipality",
                "state_code": "60"
            },
            {
                "id": 698,
                "name": "Petrovec Municipality",
                "state_code": "59"
            },
            {
                "id": 670,
                "name": "Plasnica Municipality",
                "state_code": "61"
            },
            {
                "id": 666,
                "name": "Prilep Municipality",
                "state_code": "62"
            },
            {
                "id": 646,
                "name": "Probištip Municipality",
                "state_code": "63"
            },
            {
                "id": 709,
                "name": "Radoviš Municipality",
                "state_code": "64"
            },
            {
                "id": 717,
                "name": "Rankovce Municipality",
                "state_code": "65"
            },
            {
                "id": 712,
                "name": "Resen Municipality",
                "state_code": "66"
            },
            {
                "id": 691,
                "name": "Rosoman Municipality",
                "state_code": "67"
            },
            {
                "id": 667,
                "name": "Saraj Municipality",
                "state_code": "68"
            },
            {
                "id": 719,
                "name": "Sopište Municipality",
                "state_code": "70"
            },
            {
                "id": 643,
                "name": "Staro Nagoričane Municipality",
                "state_code": "71"
            },
            {
                "id": 661,
                "name": "Štip Municipality",
                "state_code": "83"
            },
            {
                "id": 700,
                "name": "Struga Municipality",
                "state_code": "72"
            },
            {
                "id": 710,
                "name": "Strumica Municipality",
                "state_code": "73"
            },
            {
                "id": 711,
                "name": "Studeničani Municipality",
                "state_code": "74"
            },
            {
                "id": 680,
                "name": "Šuto Orizari Municipality",
                "state_code": "84"
            },
            {
                "id": 640,
                "name": "Sveti Nikole Municipality",
                "state_code": "69"
            },
            {
                "id": 654,
                "name": "Tearce Municipality",
                "state_code": "75"
            },
            {
                "id": 663,
                "name": "Tetovo Municipality",
                "state_code": "76"
            },
            {
                "id": 671,
                "name": "Valandovo Municipality",
                "state_code": "10"
            },
            {
                "id": 658,
                "name": "Vasilevo Municipality",
                "state_code": "11"
            },
            {
                "id": 651,
                "name": "Veles Municipality",
                "state_code": "13"
            },
            {
                "id": 662,
                "name": "Vevčani Municipality",
                "state_code": "12"
            },
            {
                "id": 672,
                "name": "Vinica Municipality",
                "state_code": "14"
            },
            {
                "id": 650,
                "name": "Vraneštica Municipality",
                "state_code": "15"
            },
            {
                "id": 689,
                "name": "Vrapčište Municipality",
                "state_code": "16"
            },
            {
                "id": 642,
                "name": "Zajas Municipality",
                "state_code": "31"
            },
            {
                "id": 706,
                "name": "Zelenikovo Municipality",
                "state_code": "32"
            },
            {
                "id": 668,
                "name": "Želino Municipality",
                "state_code": "30"
            },
            {
                "id": 673,
                "name": "Zrnovci Municipality",
                "state_code": "33"
            }
        ]
    },
    {
        "id": 130,
        "states": [
            {
                "id": 2951,
                "name": "Antananarivo Province",
                "state_code": "T"
            },
            {
                "id": 2950,
                "name": "Antsiranana Province",
                "state_code": "D"
            },
            {
                "id": 2948,
                "name": "Fianarantsoa Province",
                "state_code": "F"
            },
            {
                "id": 2953,
                "name": "Mahajanga Province",
                "state_code": "M"
            },
            {
                "id": 2952,
                "name": "Toamasina Province",
                "state_code": "A"
            },
            {
                "id": 2949,
                "name": "Toliara Province",
                "state_code": "U"
            }
        ]
    },
    {
        "id": 131,
        "states": [
            {
                "id": 3096,
                "name": "Balaka District",
                "state_code": "BA"
            },
            {
                "id": 3102,
                "name": "Blantyre District",
                "state_code": "BL"
            },
            {
                "id": 3092,
                "name": "Central Region",
                "state_code": "C"
            },
            {
                "id": 3107,
                "name": "Chikwawa District",
                "state_code": "CK"
            },
            {
                "id": 3109,
                "name": "Chiradzulu District",
                "state_code": "CR"
            },
            {
                "id": 3087,
                "name": "Chitipa district",
                "state_code": "CT"
            },
            {
                "id": 3097,
                "name": "Dedza District",
                "state_code": "DE"
            },
            {
                "id": 3090,
                "name": "Dowa District",
                "state_code": "DO"
            },
            {
                "id": 3091,
                "name": "Karonga District",
                "state_code": "KR"
            },
            {
                "id": 3094,
                "name": "Kasungu District",
                "state_code": "KS"
            },
            {
                "id": 3093,
                "name": "Likoma District",
                "state_code": "LK"
            },
            {
                "id": 3101,
                "name": "Lilongwe District",
                "state_code": "LI"
            },
            {
                "id": 3082,
                "name": "Machinga District",
                "state_code": "MH"
            },
            {
                "id": 3110,
                "name": "Mangochi District",
                "state_code": "MG"
            },
            {
                "id": 3099,
                "name": "Mchinji District",
                "state_code": "MC"
            },
            {
                "id": 3103,
                "name": "Mulanje District",
                "state_code": "MU"
            },
            {
                "id": 3084,
                "name": "Mwanza District",
                "state_code": "MW"
            },
            {
                "id": 3104,
                "name": "Mzimba District",
                "state_code": "MZ"
            },
            {
                "id": 3095,
                "name": "Nkhata Bay District",
                "state_code": "NB"
            },
            {
                "id": 3100,
                "name": "Nkhotakota District",
                "state_code": "NK"
            },
            {
                "id": 3105,
                "name": "Northern Region",
                "state_code": "N"
            },
            {
                "id": 3085,
                "name": "Nsanje District",
                "state_code": "NS"
            },
            {
                "id": 3088,
                "name": "Ntcheu District",
                "state_code": "NU"
            },
            {
                "id": 3111,
                "name": "Ntchisi District",
                "state_code": "NI"
            },
            {
                "id": 3108,
                "name": "Phalombe District",
                "state_code": "PH"
            },
            {
                "id": 3089,
                "name": "Rumphi District",
                "state_code": "RU"
            },
            {
                "id": 3086,
                "name": "Salima District",
                "state_code": "SA"
            },
            {
                "id": 3106,
                "name": "Southern Region",
                "state_code": "S"
            },
            {
                "id": 3098,
                "name": "Thyolo District",
                "state_code": "TH"
            },
            {
                "id": 3083,
                "name": "Zomba District",
                "state_code": "ZO"
            }
        ]
    },
    {
        "id": 132,
        "states": [
            {
                "id": 1950,
                "name": "Johor",
                "state_code": "01"
            },
            {
                "id": 1947,
                "name": "Kedah",
                "state_code": "02"
            },
            {
                "id": 1946,
                "name": "Kelantan",
                "state_code": "03"
            },
            {
                "id": 1949,
                "name": "Kuala Lumpur",
                "state_code": "14"
            },
            {
                "id": 1935,
                "name": "Labuan",
                "state_code": "15"
            },
            {
                "id": 1941,
                "name": "Malacca",
                "state_code": "04"
            },
            {
                "id": 1948,
                "name": "Negeri Sembilan",
                "state_code": "05"
            },
            {
                "id": 1940,
                "name": "Pahang",
                "state_code": "06"
            },
            {
                "id": 1939,
                "name": "Penang",
                "state_code": "07"
            },
            {
                "id": 1943,
                "name": "Perak",
                "state_code": "08"
            },
            {
                "id": 1938,
                "name": "Perlis",
                "state_code": "09"
            },
            {
                "id": 1945,
                "name": "Putrajaya",
                "state_code": "16"
            },
            {
                "id": 1936,
                "name": "Sabah",
                "state_code": "12"
            },
            {
                "id": 1937,
                "name": "Sarawak",
                "state_code": "13"
            },
            {
                "id": 1944,
                "name": "Selangor",
                "state_code": "10"
            },
            {
                "id": 1942,
                "name": "Terengganu",
                "state_code": "11"
            }
        ]
    },
    {
        "id": 133,
        "states": [
            {
                "id": 2594,
                "name": "Addu Atoll",
                "state_code": "01"
            },
            {
                "id": 2587,
                "name": "Alif Alif Atoll",
                "state_code": "02"
            },
            {
                "id": 2600,
                "name": "Alif Dhaal Atoll",
                "state_code": "00"
            },
            {
                "id": 2604,
                "name": "Central Province",
                "state_code": "CE"
            },
            {
                "id": 2590,
                "name": "Dhaalu Atoll",
                "state_code": "17"
            },
            {
                "id": 2599,
                "name": "Faafu Atoll",
                "state_code": "14"
            },
            {
                "id": 2598,
                "name": "Gaafu Alif Atoll",
                "state_code": "27"
            },
            {
                "id": 2603,
                "name": "Gaafu Dhaalu Atoll",
                "state_code": "28"
            },
            {
                "id": 2595,
                "name": "Gnaviyani Atoll",
                "state_code": "29"
            },
            {
                "id": 2586,
                "name": "Haa Alif Atoll",
                "state_code": "07"
            },
            {
                "id": 2597,
                "name": "Haa Dhaalu Atoll",
                "state_code": "23"
            },
            {
                "id": 2596,
                "name": "Kaafu Atoll",
                "state_code": "26"
            },
            {
                "id": 2601,
                "name": "Laamu Atoll",
                "state_code": "05"
            },
            {
                "id": 2607,
                "name": "Lhaviyani Atoll",
                "state_code": "03"
            },
            {
                "id": 2609,
                "name": "Malé",
                "state_code": "MLE"
            },
            {
                "id": 2608,
                "name": "Meemu Atoll",
                "state_code": "12"
            },
            {
                "id": 2592,
                "name": "Noonu Atoll",
                "state_code": "25"
            },
            {
                "id": 2589,
                "name": "North Central Province",
                "state_code": "NC"
            },
            {
                "id": 2588,
                "name": "North Province",
                "state_code": "NO"
            },
            {
                "id": 2602,
                "name": "Raa Atoll",
                "state_code": "13"
            },
            {
                "id": 2585,
                "name": "Shaviyani Atoll",
                "state_code": "24"
            },
            {
                "id": 2606,
                "name": "South Central Province",
                "state_code": "SC"
            },
            {
                "id": 2605,
                "name": "South Province",
                "state_code": "SU"
            },
            {
                "id": 2591,
                "name": "Thaa Atoll",
                "state_code": "08"
            },
            {
                "id": 2593,
                "name": "Upper South Province",
                "state_code": "US"
            },
            {
                "id": 2584,
                "name": "Vaavu Atoll",
                "state_code": "04"
            }
        ]
    },
    {
        "id": 134,
        "states": [
            {
                "id": 253,
                "name": "Bamako",
                "state_code": "BKO"
            },
            {
                "id": 258,
                "name": "Gao Region",
                "state_code": "7"
            },
            {
                "id": 252,
                "name": "Kayes Region",
                "state_code": "1"
            },
            {
                "id": 257,
                "name": "Kidal Region",
                "state_code": "8"
            },
            {
                "id": 250,
                "name": "Koulikoro Region",
                "state_code": "2"
            },
            {
                "id": 251,
                "name": "Ménaka Region",
                "state_code": "9"
            },
            {
                "id": 255,
                "name": "Mopti Region",
                "state_code": "5"
            },
            {
                "id": 249,
                "name": "Ségou Region",
                "state_code": "4"
            },
            {
                "id": 254,
                "name": "Sikasso Region",
                "state_code": "3"
            },
            {
                "id": 256,
                "name": "Taoudénit Region",
                "state_code": "10"
            },
            {
                "id": 248,
                "name": "Tombouctou Region",
                "state_code": "6"
            }
        ]
    },
    {
        "id": 135,
        "states": [
            {
                "id": 110,
                "name": "Attard",
                "state_code": "01"
            },
            {
                "id": 108,
                "name": "Balzan",
                "state_code": "02"
            },
            {
                "id": 107,
                "name": "Birgu",
                "state_code": "03"
            },
            {
                "id": 97,
                "name": "Birkirkara",
                "state_code": "04"
            },
            {
                "id": 88,
                "name": "Birżebbuġa",
                "state_code": "05"
            },
            {
                "id": 138,
                "name": "Cospicua",
                "state_code": "06"
            },
            {
                "id": 117,
                "name": "Dingli",
                "state_code": "07"
            },
            {
                "id": 129,
                "name": "Fgura",
                "state_code": "08"
            },
            {
                "id": 84,
                "name": "Floriana",
                "state_code": "09"
            },
            {
                "id": 134,
                "name": "Fontana",
                "state_code": "10"
            },
            {
                "id": 130,
                "name": "Għajnsielem",
                "state_code": "13"
            },
            {
                "id": 92,
                "name": "Għarb",
                "state_code": "14"
            },
            {
                "id": 120,
                "name": "Għargħur",
                "state_code": "15"
            },
            {
                "id": 106,
                "name": "Għasri",
                "state_code": "16"
            },
            {
                "id": 124,
                "name": "Għaxaq",
                "state_code": "17"
            },
            {
                "id": 118,
                "name": "Gudja",
                "state_code": "11"
            },
            {
                "id": 113,
                "name": "Gżira",
                "state_code": "12"
            },
            {
                "id": 105,
                "name": "Ħamrun",
                "state_code": "18"
            },
            {
                "id": 93,
                "name": "Iklin",
                "state_code": "19"
            },
            {
                "id": 99,
                "name": "Kalkara",
                "state_code": "21"
            },
            {
                "id": 91,
                "name": "Kerċem",
                "state_code": "22"
            },
            {
                "id": 82,
                "name": "Kirkop",
                "state_code": "23"
            },
            {
                "id": 126,
                "name": "Lija",
                "state_code": "24"
            },
            {
                "id": 77,
                "name": "Luqa",
                "state_code": "25"
            },
            {
                "id": 128,
                "name": "Marsa",
                "state_code": "26"
            },
            {
                "id": 137,
                "name": "Marsaskala",
                "state_code": "27"
            },
            {
                "id": 78,
                "name": "Marsaxlokk",
                "state_code": "28"
            },
            {
                "id": 89,
                "name": "Mdina",
                "state_code": "29"
            },
            {
                "id": 102,
                "name": "Mellieħa",
                "state_code": "30"
            },
            {
                "id": 109,
                "name": "Mġarr",
                "state_code": "31"
            },
            {
                "id": 140,
                "name": "Mosta",
                "state_code": "32"
            },
            {
                "id": 74,
                "name": "Mqabba",
                "state_code": "33"
            },
            {
                "id": 96,
                "name": "Msida",
                "state_code": "34"
            },
            {
                "id": 131,
                "name": "Mtarfa",
                "state_code": "35"
            },
            {
                "id": 132,
                "name": "Munxar",
                "state_code": "36"
            },
            {
                "id": 133,
                "name": "Nadur",
                "state_code": "37"
            },
            {
                "id": 112,
                "name": "Naxxar",
                "state_code": "38"
            },
            {
                "id": 115,
                "name": "Paola",
                "state_code": "39"
            },
            {
                "id": 125,
                "name": "Pembroke",
                "state_code": "40"
            },
            {
                "id": 127,
                "name": "Pietà",
                "state_code": "41"
            },
            {
                "id": 79,
                "name": "Qala",
                "state_code": "42"
            },
            {
                "id": 119,
                "name": "Qormi",
                "state_code": "43"
            },
            {
                "id": 111,
                "name": "Qrendi",
                "state_code": "44"
            },
            {
                "id": 83,
                "name": "Rabat",
                "state_code": "46"
            },
            {
                "id": 87,
                "name": "Saint Lawrence",
                "state_code": "50"
            },
            {
                "id": 75,
                "name": "San Ġwann",
                "state_code": "49"
            },
            {
                "id": 116,
                "name": "Sannat",
                "state_code": "52"
            },
            {
                "id": 94,
                "name": "Santa Luċija",
                "state_code": "53"
            },
            {
                "id": 90,
                "name": "Santa Venera",
                "state_code": "54"
            },
            {
                "id": 136,
                "name": "Senglea",
                "state_code": "20"
            },
            {
                "id": 98,
                "name": "Siġġiewi",
                "state_code": "55"
            },
            {
                "id": 104,
                "name": "Sliema",
                "state_code": "56"
            },
            {
                "id": 100,
                "name": "St. Julian's",
                "state_code": "48"
            },
            {
                "id": 139,
                "name": "St. Paul's Bay",
                "state_code": "51"
            },
            {
                "id": 86,
                "name": "Swieqi",
                "state_code": "57"
            },
            {
                "id": 122,
                "name": "Ta' Xbiex",
                "state_code": "58"
            },
            {
                "id": 103,
                "name": "Tarxien",
                "state_code": "59"
            },
            {
                "id": 95,
                "name": "Valletta",
                "state_code": "60"
            },
            {
                "id": 101,
                "name": "Victoria",
                "state_code": "45"
            },
            {
                "id": 114,
                "name": "Xagħra",
                "state_code": "61"
            },
            {
                "id": 121,
                "name": "Xewkija",
                "state_code": "62"
            },
            {
                "id": 81,
                "name": "Xgħajra",
                "state_code": "63"
            },
            {
                "id": 123,
                "name": "Żabbar",
                "state_code": "64"
            },
            {
                "id": 85,
                "name": "Żebbuġ Gozo",
                "state_code": "65"
            },
            {
                "id": 80,
                "name": "Żebbuġ Malta",
                "state_code": "66"
            },
            {
                "id": 135,
                "name": "Żejtun",
                "state_code": "67"
            },
            {
                "id": 76,
                "name": "Żurrieq",
                "state_code": "68"
            }
        ]
    },
    {
        "id": 136,
        "states": []
    },
    {
        "id": 137,
        "states": [
            {
                "id": 2574,
                "name": "Ralik Chain",
                "state_code": "L"
            },
            {
                "id": 2573,
                "name": "Ratak Chain",
                "state_code": "T"
            }
        ]
    },
    {
        "id": 138,
        "states": []
    },
    {
        "id": 139,
        "states": [
            {
                "id": 3344,
                "name": "Adrar",
                "state_code": "07"
            },
            {
                "id": 3349,
                "name": "Assaba",
                "state_code": "03"
            },
            {
                "id": 3339,
                "name": "Brakna",
                "state_code": "05"
            },
            {
                "id": 3346,
                "name": "Dakhlet Nouadhibou",
                "state_code": "08"
            },
            {
                "id": 3341,
                "name": "Gorgol",
                "state_code": "04"
            },
            {
                "id": 3350,
                "name": "Guidimaka",
                "state_code": "10"
            },
            {
                "id": 3338,
                "name": "Hodh Ech Chargui",
                "state_code": "01"
            },
            {
                "id": 3351,
                "name": "Hodh El Gharbi",
                "state_code": "02"
            },
            {
                "id": 3342,
                "name": "Inchiri",
                "state_code": "12"
            },
            {
                "id": 3343,
                "name": "Nouakchott-Nord",
                "state_code": "14"
            },
            {
                "id": 3352,
                "name": "Nouakchott-Ouest",
                "state_code": "13"
            },
            {
                "id": 3347,
                "name": "Nouakchott-Sud",
                "state_code": "15"
            },
            {
                "id": 3345,
                "name": "Tagant",
                "state_code": "09"
            },
            {
                "id": 3340,
                "name": "Tiris Zemmour",
                "state_code": "11"
            },
            {
                "id": 3348,
                "name": "Trarza",
                "state_code": "06"
            }
        ]
    },
    {
        "id": 140,
        "states": [
            {
                "id": 3248,
                "name": "Agalega Islands",
                "state_code": "AG"
            },
            {
                "id": 3259,
                "name": "Black River",
                "state_code": "BL"
            },
            {
                "id": 3254,
                "name": "Flacq",
                "state_code": "FL"
            },
            {
                "id": 3264,
                "name": "Grand Port",
                "state_code": "GP"
            },
            {
                "id": 3253,
                "name": "Moka",
                "state_code": "MO"
            },
            {
                "id": 3250,
                "name": "Pamplemousses",
                "state_code": "PA"
            },
            {
                "id": 3263,
                "name": "Plaines Wilhems",
                "state_code": "PW"
            },
            {
                "id": 3260,
                "name": "Port Louis",
                "state_code": "PL"
            },
            {
                "id": 3261,
                "name": "Rivière du Rempart",
                "state_code": "RR"
            },
            {
                "id": 3249,
                "name": "Rodrigues Island",
                "state_code": "RO"
            },
            {
                "id": 3251,
                "name": "Saint Brandon Islands",
                "state_code": "CC"
            },
            {
                "id": 3257,
                "name": "Savanne",
                "state_code": "SA"
            }
        ]
    },
    {
        "id": 141,
        "states": []
    },
    {
        "id": 142,
        "states": [
            {
                "id": 3456,
                "name": "Aguascalientes",
                "state_code": "AGU"
            },
            {
                "id": 3457,
                "name": "Baja California",
                "state_code": "BCN"
            },
            {
                "id": 3460,
                "name": "Baja California Sur",
                "state_code": "BCS"
            },
            {
                "id": 3475,
                "name": "Campeche",
                "state_code": "CAM"
            },
            {
                "id": 3451,
                "name": "Chiapas",
                "state_code": "CHP"
            },
            {
                "id": 3447,
                "name": "Chihuahua",
                "state_code": "CHH"
            },
            {
                "id": 3473,
                "name": "Ciudad de México",
                "state_code": "CDMX"
            },
            {
                "id": 3471,
                "name": "Coahuila de Zaragoza",
                "state_code": "COA"
            },
            {
                "id": 3472,
                "name": "Colima",
                "state_code": "COL"
            },
            {
                "id": 3453,
                "name": "Durango",
                "state_code": "DUR"
            },
            {
                "id": 3450,
                "name": "Estado de México",
                "state_code": "MEX"
            },
            {
                "id": 3469,
                "name": "Guanajuato",
                "state_code": "GUA"
            },
            {
                "id": 3459,
                "name": "Guerrero",
                "state_code": "GRO"
            },
            {
                "id": 3470,
                "name": "Hidalgo",
                "state_code": "HID"
            },
            {
                "id": 4857,
                "name": "Jalisco",
                "state_code": "JAL"
            },
            {
                "id": 3474,
                "name": "Michoacán de Ocampo",
                "state_code": "MIC"
            },
            {
                "id": 3465,
                "name": "Morelos",
                "state_code": "MOR"
            },
            {
                "id": 3477,
                "name": "Nayarit",
                "state_code": "NAY"
            },
            {
                "id": 3452,
                "name": "Nuevo León",
                "state_code": "NLE"
            },
            {
                "id": 3448,
                "name": "Oaxaca",
                "state_code": "OAX"
            },
            {
                "id": 3476,
                "name": "Puebla",
                "state_code": "PUE"
            },
            {
                "id": 3455,
                "name": "Querétaro",
                "state_code": "QUE"
            },
            {
                "id": 3467,
                "name": "Quintana Roo",
                "state_code": "ROO"
            },
            {
                "id": 3461,
                "name": "San Luis Potosí",
                "state_code": "SLP"
            },
            {
                "id": 3449,
                "name": "Sinaloa",
                "state_code": "SIN"
            },
            {
                "id": 3468,
                "name": "Sonora",
                "state_code": "SON"
            },
            {
                "id": 3454,
                "name": "Tabasco",
                "state_code": "TAB"
            },
            {
                "id": 3463,
                "name": "Tamaulipas",
                "state_code": "TAM"
            },
            {
                "id": 3458,
                "name": "Tlaxcala",
                "state_code": "TLA"
            },
            {
                "id": 3464,
                "name": "Veracruz de Ignacio de la Llave",
                "state_code": "VER"
            },
            {
                "id": 3466,
                "name": "Yucatán",
                "state_code": "YUC"
            },
            {
                "id": 3462,
                "name": "Zacatecas",
                "state_code": "ZAC"
            }
        ]
    },
    {
        "id": 143,
        "states": [
            {
                "id": 2580,
                "name": "Chuuk State",
                "state_code": "TRK"
            },
            {
                "id": 2583,
                "name": "Kosrae State",
                "state_code": "KSA"
            },
            {
                "id": 2581,
                "name": "Pohnpei State",
                "state_code": "PNI"
            },
            {
                "id": 2582,
                "name": "Yap State",
                "state_code": "YAP"
            }
        ]
    },
    {
        "id": 144,
        "states": [
            {
                "id": 4368,
                "name": "Anenii Noi District",
                "state_code": "AN"
            },
            {
                "id": 4393,
                "name": "Bălți Municipality",
                "state_code": "BA"
            },
            {
                "id": 4379,
                "name": "Basarabeasca District",
                "state_code": "BS"
            },
            {
                "id": 4362,
                "name": "Bender Municipality",
                "state_code": "BD"
            },
            {
                "id": 4375,
                "name": "Briceni District",
                "state_code": "BR"
            },
            {
                "id": 4391,
                "name": "Cahul District",
                "state_code": "CA"
            },
            {
                "id": 4366,
                "name": "Călărași District",
                "state_code": "CL"
            },
            {
                "id": 4380,
                "name": "Cantemir District",
                "state_code": "CT"
            },
            {
                "id": 4365,
                "name": "Căușeni District",
                "state_code": "CS"
            },
            {
                "id": 4373,
                "name": "Chișinău Municipality",
                "state_code": "CU"
            },
            {
                "id": 4360,
                "name": "Cimișlia District",
                "state_code": "CM"
            },
            {
                "id": 4390,
                "name": "Criuleni District",
                "state_code": "CR"
            },
            {
                "id": 4384,
                "name": "Dondușeni District",
                "state_code": "DO"
            },
            {
                "id": 4392,
                "name": "Drochia District",
                "state_code": "DR"
            },
            {
                "id": 4383,
                "name": "Dubăsari District",
                "state_code": "DU"
            },
            {
                "id": 4387,
                "name": "Edineț District",
                "state_code": "ED"
            },
            {
                "id": 4381,
                "name": "Fălești District",
                "state_code": "FA"
            },
            {
                "id": 4370,
                "name": "Florești District",
                "state_code": "FL"
            },
            {
                "id": 4385,
                "name": "Gagauzia",
                "state_code": "GA"
            },
            {
                "id": 4367,
                "name": "Glodeni District",
                "state_code": "GL"
            },
            {
                "id": 4382,
                "name": "Hîncești District",
                "state_code": "HI"
            },
            {
                "id": 4369,
                "name": "Ialoveni District",
                "state_code": "IA"
            },
            {
                "id": 4363,
                "name": "Nisporeni District",
                "state_code": "NI"
            },
            {
                "id": 4389,
                "name": "Ocnița District",
                "state_code": "OC"
            },
            {
                "id": 4361,
                "name": "Orhei District",
                "state_code": "OR"
            },
            {
                "id": 4394,
                "name": "Rezina District",
                "state_code": "RE"
            },
            {
                "id": 4376,
                "name": "Rîșcani District",
                "state_code": "RI"
            },
            {
                "id": 4364,
                "name": "Sîngerei District",
                "state_code": "SI"
            },
            {
                "id": 4388,
                "name": "Șoldănești District",
                "state_code": "SD"
            },
            {
                "id": 4374,
                "name": "Soroca District",
                "state_code": "SO"
            },
            {
                "id": 4378,
                "name": "Ștefan Vodă District",
                "state_code": "SV"
            },
            {
                "id": 4377,
                "name": "Strășeni District",
                "state_code": "ST"
            },
            {
                "id": 4372,
                "name": "Taraclia District",
                "state_code": "TA"
            },
            {
                "id": 4371,
                "name": "Telenești District",
                "state_code": "TE"
            },
            {
                "id": 4395,
                "name": "Transnistria autonomous territorial unit",
                "state_code": "SN"
            },
            {
                "id": 4386,
                "name": "Ungheni District",
                "state_code": "UN"
            }
        ]
    },
    {
        "id": 145,
        "states": [
            {
                "id": 4917,
                "name": "La Colle",
                "state_code": "CL"
            },
            {
                "id": 4918,
                "name": "La Condamine",
                "state_code": "CO"
            },
            {
                "id": 4919,
                "name": "Moneghetti",
                "state_code": "MG"
            }
        ]
    },
    {
        "id": 146,
        "states": [
            {
                "id": 1973,
                "name": "Arkhangai Province",
                "state_code": "073"
            },
            {
                "id": 1969,
                "name": "Bayan-Ölgii Province",
                "state_code": "071"
            },
            {
                "id": 1976,
                "name": "Bayankhongor Province",
                "state_code": "069"
            },
            {
                "id": 1961,
                "name": "Bulgan Province",
                "state_code": "067"
            },
            {
                "id": 1962,
                "name": "Darkhan-Uul Province",
                "state_code": "037"
            },
            {
                "id": 1963,
                "name": "Dornod Province",
                "state_code": "061"
            },
            {
                "id": 1981,
                "name": "Dornogovi Province",
                "state_code": "063"
            },
            {
                "id": 1970,
                "name": "Dundgovi Province",
                "state_code": "059"
            },
            {
                "id": 1972,
                "name": "Govi-Altai Province",
                "state_code": "065"
            },
            {
                "id": 1978,
                "name": "Govisümber Province",
                "state_code": "064"
            },
            {
                "id": 1974,
                "name": "Khentii Province",
                "state_code": "039"
            },
            {
                "id": 1964,
                "name": "Khovd Province",
                "state_code": "043"
            },
            {
                "id": 1975,
                "name": "Khövsgöl Province",
                "state_code": "041"
            },
            {
                "id": 1967,
                "name": "Ömnögovi Province",
                "state_code": "053"
            },
            {
                "id": 1966,
                "name": "Orkhon Province",
                "state_code": "035"
            },
            {
                "id": 1965,
                "name": "Övörkhangai Province",
                "state_code": "055"
            },
            {
                "id": 1980,
                "name": "Selenge Province",
                "state_code": "049"
            },
            {
                "id": 1977,
                "name": "Sükhbaatar Province",
                "state_code": "051"
            },
            {
                "id": 1968,
                "name": "Töv Province",
                "state_code": "047"
            },
            {
                "id": 1971,
                "name": "Uvs Province",
                "state_code": "046"
            },
            {
                "id": 1979,
                "name": "Zavkhan Province",
                "state_code": "057"
            }
        ]
    },
    {
        "id": 147,
        "states": [
            {
                "id": 23,
                "name": "Andrijevica Municipality",
                "state_code": "01"
            },
            {
                "id": 13,
                "name": "Bar Municipality",
                "state_code": "02"
            },
            {
                "id": 21,
                "name": "Berane Municipality",
                "state_code": "03"
            },
            {
                "id": 25,
                "name": "Bijelo Polje Municipality",
                "state_code": "04"
            },
            {
                "id": 30,
                "name": "Budva Municipality",
                "state_code": "05"
            },
            {
                "id": 14,
                "name": "Danilovgrad Municipality",
                "state_code": "07"
            },
            {
                "id": 24,
                "name": "Gusinje Municipality",
                "state_code": "22"
            },
            {
                "id": 31,
                "name": "Kolašin Municipality",
                "state_code": "09"
            },
            {
                "id": 26,
                "name": "Kotor Municipality",
                "state_code": "10"
            },
            {
                "id": 22,
                "name": "Mojkovac Municipality",
                "state_code": "11"
            },
            {
                "id": 17,
                "name": "Nikšić Municipality",
                "state_code": "12"
            },
            {
                "id": 28,
                "name": "Old Royal Capital Cetinje",
                "state_code": "06"
            },
            {
                "id": 12,
                "name": "Petnjica Municipality",
                "state_code": "23"
            },
            {
                "id": 19,
                "name": "Plav Municipality",
                "state_code": "13"
            },
            {
                "id": 20,
                "name": "Pljevlja Municipality",
                "state_code": "14"
            },
            {
                "id": 16,
                "name": "Plužine Municipality",
                "state_code": "15"
            },
            {
                "id": 27,
                "name": "Podgorica Municipality",
                "state_code": "16"
            },
            {
                "id": 15,
                "name": "Rožaje Municipality",
                "state_code": "17"
            },
            {
                "id": 18,
                "name": "Šavnik Municipality",
                "state_code": "18"
            },
            {
                "id": 29,
                "name": "Tivat Municipality",
                "state_code": "19"
            },
            {
                "id": 33,
                "name": "Ulcinj Municipality",
                "state_code": "20"
            },
            {
                "id": 32,
                "name": "Žabljak Municipality",
                "state_code": "21"
            }
        ]
    },
    {
        "id": 148,
        "states": []
    },
    {
        "id": 149,
        "states": [
            {
                "id": 4928,
                "name": "Agadir-Ida-Ou-Tanane",
                "state_code": "AGD"
            },
            {
                "id": 3320,
                "name": "Al Haouz",
                "state_code": "HAO"
            },
            {
                "id": 3267,
                "name": "Al Hoceïma",
                "state_code": "HOC"
            },
            {
                "id": 3266,
                "name": "Aousserd (EH)",
                "state_code": "AOU"
            },
            {
                "id": 3297,
                "name": "Assa-Zag (EH-partial)",
                "state_code": "ASZ"
            },
            {
                "id": 3321,
                "name": "Azilal",
                "state_code": "AZI"
            },
            {
                "id": 3272,
                "name": "Béni Mellal",
                "state_code": "BEM"
            },
            {
                "id": 3278,
                "name": "Béni Mellal-Khénifra",
                "state_code": "05"
            },
            {
                "id": 3304,
                "name": "Benslimane",
                "state_code": "BES"
            },
            {
                "id": 3285,
                "name": "Berkane",
                "state_code": "BER"
            },
            {
                "id": 4929,
                "name": "Berrechid",
                "state_code": "BRR"
            },
            {
                "id": 3275,
                "name": "Boujdour (EH)",
                "state_code": "BOD"
            },
            {
                "id": 3270,
                "name": "Boulemane",
                "state_code": "BOM"
            },
            {
                "id": 4930,
                "name": "Casablanca",
                "state_code": "CAS"
            },
            {
                "id": 3303,
                "name": "Casablanca-Settat",
                "state_code": "06"
            },
            {
                "id": 3310,
                "name": "Chefchaouen",
                "state_code": "CHE"
            },
            {
                "id": 3274,
                "name": "Chichaoua",
                "state_code": "CHI"
            },
            {
                "id": 3302,
                "name": "Chtouka-Ait Baha",
                "state_code": "CHT"
            },
            {
                "id": 3306,
                "name": "Dakhla-Oued Ed-Dahab (EH)",
                "state_code": "12"
            },
            {
                "id": 3290,
                "name": "Drâa-Tafilalet",
                "state_code": "08"
            },
            {
                "id": 4931,
                "name": "Driouch",
                "state_code": "DRI"
            },
            {
                "id": 3291,
                "name": "El Hajeb",
                "state_code": "HAJ"
            },
            {
                "id": 3280,
                "name": "El Jadida",
                "state_code": "JDI"
            },
            {
                "id": 3309,
                "name": "El Kelâa des Sraghna",
                "state_code": "KES"
            },
            {
                "id": 3299,
                "name": "Errachidia",
                "state_code": "ERR"
            },
            {
                "id": 3292,
                "name": "Es-Semara (EH-partial)",
                "state_code": "ESM"
            },
            {
                "id": 3316,
                "name": "Essaouira",
                "state_code": "ESI"
            },
            {
                "id": 3300,
                "name": "Fahs-Anjra",
                "state_code": "FAH"
            },
            {
                "id": 4932,
                "name": "Fès",
                "state_code": "FES"
            },
            {
                "id": 3313,
                "name": "Fès-Meknès",
                "state_code": "03"
            },
            {
                "id": 3301,
                "name": "Figuig",
                "state_code": "FIG"
            },
            {
                "id": 4933,
                "name": "Fquih Ben Salah",
                "state_code": "FQH"
            },
            {
                "id": 3265,
                "name": "Guelmim",
                "state_code": "GUE"
            },
            {
                "id": 3305,
                "name": "Guelmim-Oued Noun (EH-partial)",
                "state_code": "10"
            },
            {
                "id": 4934,
                "name": "Guercif",
                "state_code": "GUF"
            },
            {
                "id": 3325,
                "name": "Ifrane",
                "state_code": "IFR"
            },
            {
                "id": 3294,
                "name": "Inezgane-Ait Melloul",
                "state_code": "INE"
            },
            {
                "id": 3307,
                "name": "Jerada",
                "state_code": "JRA"
            },
            {
                "id": 3308,
                "name": "Kénitra",
                "state_code": "KEN"
            },
            {
                "id": 3276,
                "name": "Khémisset",
                "state_code": "KHE"
            },
            {
                "id": 3317,
                "name": "Khénifra",
                "state_code": "KHN"
            },
            {
                "id": 3326,
                "name": "Khouribga",
                "state_code": "KHO"
            },
            {
                "id": 3271,
                "name": "L'Oriental",
                "state_code": "02"
            },
            {
                "id": 3293,
                "name": "Laâyoune (EH)",
                "state_code": "LAA"
            },
            {
                "id": 3298,
                "name": "Laâyoune-Sakia El Hamra (EH-partial)",
                "state_code": "11"
            },
            {
                "id": 3268,
                "name": "Larache",
                "state_code": "LAR"
            },
            {
                "id": 4936,
                "name": "M’diq-Fnideq",
                "state_code": "MDF"
            },
            {
                "id": 4935,
                "name": "Marrakech",
                "state_code": "MAR"
            },
            {
                "id": 3288,
                "name": "Marrakesh-Safi",
                "state_code": "07"
            },
            {
                "id": 3284,
                "name": "Médiouna",
                "state_code": "MED"
            },
            {
                "id": 4937,
                "name": "Meknès",
                "state_code": "MEK"
            },
            {
                "id": 4938,
                "name": "Midelt",
                "state_code": "MID"
            },
            {
                "id": 4939,
                "name": "Mohammadia",
                "state_code": "MOH"
            },
            {
                "id": 3315,
                "name": "Moulay Yacoub",
                "state_code": "MOU"
            },
            {
                "id": 3281,
                "name": "Nador",
                "state_code": "NAD"
            },
            {
                "id": 3287,
                "name": "Nouaceur",
                "state_code": "NOU"
            },
            {
                "id": 3269,
                "name": "Ouarzazate",
                "state_code": "OUA"
            },
            {
                "id": 3319,
                "name": "Oued Ed-Dahab (EH)",
                "state_code": "OUD"
            },
            {
                "id": 4941,
                "name": "Ouezzane",
                "state_code": "OUZ"
            },
            {
                "id": 4940,
                "name": "Oujda-Angad",
                "state_code": "OUJ"
            },
            {
                "id": 4942,
                "name": "Rabat",
                "state_code": "RAB"
            },
            {
                "id": 4927,
                "name": "Rabat-Salé-Kénitra",
                "state_code": "04"
            },
            {
                "id": 4943,
                "name": "Rehamna",
                "state_code": "REH"
            },
            {
                "id": 3311,
                "name": "Safi",
                "state_code": "SAF"
            },
            {
                "id": 4944,
                "name": "Salé",
                "state_code": "SAL"
            },
            {
                "id": 3289,
                "name": "Sefrou",
                "state_code": "SEF"
            },
            {
                "id": 3282,
                "name": "Settat",
                "state_code": "SET"
            },
            {
                "id": 4945,
                "name": "Sidi Bennour",
                "state_code": "SIB"
            },
            {
                "id": 4946,
                "name": "Sidi Ifni",
                "state_code": "SIF"
            },
            {
                "id": 3279,
                "name": "Sidi Kacem",
                "state_code": "SIK"
            },
            {
                "id": 4952,
                "name": "Sidi Slimane",
                "state_code": "SIL"
            },
            {
                "id": 4947,
                "name": "Skhirate-Témara",
                "state_code": "SKH"
            },
            {
                "id": 3295,
                "name": "Souss-Massa",
                "state_code": "09"
            },
            {
                "id": 3286,
                "name": "Tan-Tan (EH-partial)",
                "state_code": "TNT"
            },
            {
                "id": 4950,
                "name": "Tanger-Assilah",
                "state_code": "TNG"
            },
            {
                "id": 3324,
                "name": "Tanger-Tétouan-Al Hoceïma",
                "state_code": "01"
            },
            {
                "id": 3323,
                "name": "Taounate",
                "state_code": "TAO"
            },
            {
                "id": 3322,
                "name": "Taourirt",
                "state_code": "TAI"
            },
            {
                "id": 4948,
                "name": "Tarfaya (EH-partial)",
                "state_code": "TAF"
            },
            {
                "id": 3314,
                "name": "Taroudannt",
                "state_code": "TAR"
            },
            {
                "id": 3312,
                "name": "Tata",
                "state_code": "TAT"
            },
            {
                "id": 3296,
                "name": "Taza",
                "state_code": "TAZ"
            },
            {
                "id": 3318,
                "name": "Tétouan",
                "state_code": "TET"
            },
            {
                "id": 4949,
                "name": "Tinghir",
                "state_code": "TIN"
            },
            {
                "id": 3277,
                "name": "Tiznit",
                "state_code": "TIZ"
            },
            {
                "id": 4951,
                "name": "Youssoufia",
                "state_code": "YUS"
            },
            {
                "id": 3283,
                "name": "Zagora",
                "state_code": "ZAG"
            }
        ]
    },
    {
        "id": 150,
        "states": [
            {
                "id": 3327,
                "name": "Cabo Delgado Province",
                "state_code": "P"
            },
            {
                "id": 3329,
                "name": "Gaza Province",
                "state_code": "G"
            },
            {
                "id": 3330,
                "name": "Inhambane Province",
                "state_code": "I"
            },
            {
                "id": 3337,
                "name": "Manica Province",
                "state_code": "B"
            },
            {
                "id": 3335,
                "name": "Maputo",
                "state_code": "MPM"
            },
            {
                "id": 3332,
                "name": "Maputo Province",
                "state_code": "L"
            },
            {
                "id": 3336,
                "name": "Nampula Province",
                "state_code": "N"
            },
            {
                "id": 3333,
                "name": "Niassa Province",
                "state_code": "A"
            },
            {
                "id": 3331,
                "name": "Sofala Province",
                "state_code": "S"
            },
            {
                "id": 3334,
                "name": "Tete Province",
                "state_code": "T"
            },
            {
                "id": 3328,
                "name": "Zambezia Province",
                "state_code": "Q"
            }
        ]
    },
    {
        "id": 151,
        "states": [
            {
                "id": 2142,
                "name": "Ayeyarwady Region",
                "state_code": "07"
            },
            {
                "id": 2141,
                "name": "Bago",
                "state_code": "02"
            },
            {
                "id": 2137,
                "name": "Chin State",
                "state_code": "14"
            },
            {
                "id": 2143,
                "name": "Kachin State",
                "state_code": "11"
            },
            {
                "id": 2144,
                "name": "Kayah State",
                "state_code": "12"
            },
            {
                "id": 2133,
                "name": "Kayin State",
                "state_code": "13"
            },
            {
                "id": 2136,
                "name": "Magway Region",
                "state_code": "03"
            },
            {
                "id": 2134,
                "name": "Mandalay Region",
                "state_code": "04"
            },
            {
                "id": 2147,
                "name": "Mon State",
                "state_code": "15"
            },
            {
                "id": 2146,
                "name": "Naypyidaw Union Territory",
                "state_code": "18"
            },
            {
                "id": 2138,
                "name": "Rakhine State",
                "state_code": "16"
            },
            {
                "id": 2145,
                "name": "Sagaing Region",
                "state_code": "01"
            },
            {
                "id": 2139,
                "name": "Shan State",
                "state_code": "17"
            },
            {
                "id": 2140,
                "name": "Tanintharyi Region",
                "state_code": "05"
            },
            {
                "id": 2135,
                "name": "Yangon Region",
                "state_code": "06"
            }
        ]
    },
    {
        "id": 152,
        "states": [
            {
                "id": 43,
                "name": "Erongo Region",
                "state_code": "ER"
            },
            {
                "id": 38,
                "name": "Hardap Region",
                "state_code": "HA"
            },
            {
                "id": 45,
                "name": "Karas Region",
                "state_code": "KA"
            },
            {
                "id": 36,
                "name": "Kavango East Region",
                "state_code": "KE"
            },
            {
                "id": 35,
                "name": "Kavango West Region",
                "state_code": "KW"
            },
            {
                "id": 44,
                "name": "Khomas Region",
                "state_code": "KH"
            },
            {
                "id": 34,
                "name": "Kunene Region",
                "state_code": "KU"
            },
            {
                "id": 40,
                "name": "Ohangwena Region",
                "state_code": "OW"
            },
            {
                "id": 41,
                "name": "Omaheke Region",
                "state_code": "OH"
            },
            {
                "id": 39,
                "name": "Omusati Region",
                "state_code": "OS"
            },
            {
                "id": 37,
                "name": "Oshana Region",
                "state_code": "ON"
            },
            {
                "id": 42,
                "name": "Oshikoto Region",
                "state_code": "OT"
            },
            {
                "id": 46,
                "name": "Otjozondjupa Region",
                "state_code": "OD"
            },
            {
                "id": 47,
                "name": "Zambezi Region",
                "state_code": "CA"
            }
        ]
    },
    {
        "id": 153,
        "states": [
            {
                "id": 4656,
                "name": "Aiwo District",
                "state_code": "01"
            },
            {
                "id": 4658,
                "name": "Anabar District",
                "state_code": "02"
            },
            {
                "id": 4667,
                "name": "Anetan District",
                "state_code": "03"
            },
            {
                "id": 4663,
                "name": "Anibare District",
                "state_code": "04"
            },
            {
                "id": 4660,
                "name": "Baiti District",
                "state_code": "05"
            },
            {
                "id": 4665,
                "name": "Boe District",
                "state_code": "06"
            },
            {
                "id": 4662,
                "name": "Buada District",
                "state_code": "07"
            },
            {
                "id": 4666,
                "name": "Denigomodu District",
                "state_code": "08"
            },
            {
                "id": 4654,
                "name": "Ewa District",
                "state_code": "09"
            },
            {
                "id": 4661,
                "name": "Ijuw District",
                "state_code": "10"
            },
            {
                "id": 4657,
                "name": "Meneng District",
                "state_code": "11"
            },
            {
                "id": 4659,
                "name": "Nibok District",
                "state_code": "12"
            },
            {
                "id": 4655,
                "name": "Uaboe District",
                "state_code": "13"
            },
            {
                "id": 4664,
                "name": "Yaren District",
                "state_code": "14"
            }
        ]
    },
    {
        "id": 154,
        "states": [
            {
                "id": 2082,
                "name": "Bagmati Zone",
                "state_code": "BA"
            },
            {
                "id": 2071,
                "name": "Bheri Zone",
                "state_code": "BH"
            },
            {
                "id": 2073,
                "name": "Central Region",
                "state_code": "1"
            },
            {
                "id": 2080,
                "name": "Dhaulagiri Zone",
                "state_code": "DH"
            },
            {
                "id": 2069,
                "name": "Eastern Development Region",
                "state_code": "4"
            },
            {
                "id": 2068,
                "name": "Far-Western Development Region",
                "state_code": "5"
            },
            {
                "id": 2081,
                "name": "Gandaki Zone",
                "state_code": "GA"
            },
            {
                "id": 2076,
                "name": "Janakpur Zone",
                "state_code": "JA"
            },
            {
                "id": 2079,
                "name": "Karnali Zone",
                "state_code": "KA"
            },
            {
                "id": 2072,
                "name": "Kosi Zone",
                "state_code": "KO"
            },
            {
                "id": 2074,
                "name": "Lumbini Zone",
                "state_code": "LU"
            },
            {
                "id": 2083,
                "name": "Mahakali Zone",
                "state_code": "MA"
            },
            {
                "id": 2070,
                "name": "Mechi Zone",
                "state_code": "ME"
            },
            {
                "id": 2066,
                "name": "Mid-Western Region",
                "state_code": "2"
            },
            {
                "id": 2075,
                "name": "Narayani Zone",
                "state_code": "NA"
            },
            {
                "id": 2077,
                "name": "Rapti Zone",
                "state_code": "RA"
            },
            {
                "id": 2084,
                "name": "Sagarmatha Zone",
                "state_code": "SA"
            },
            {
                "id": 2078,
                "name": "Seti Zone",
                "state_code": "SE"
            },
            {
                "id": 2067,
                "name": "Western Region",
                "state_code": "3"
            }
        ]
    },
    {
        "id": 156,
        "states": [
            {
                "id": 2624,
                "name": "Bonaire",
                "state_code": "BQ1"
            },
            {
                "id": 2613,
                "name": "Drenthe",
                "state_code": "DR"
            },
            {
                "id": 2619,
                "name": "Flevoland",
                "state_code": "FL"
            },
            {
                "id": 2622,
                "name": "Friesland",
                "state_code": "FR"
            },
            {
                "id": 2611,
                "name": "Gelderland",
                "state_code": "GE"
            },
            {
                "id": 2617,
                "name": "Groningen",
                "state_code": "GR"
            },
            {
                "id": 2615,
                "name": "Limburg",
                "state_code": "LI"
            },
            {
                "id": 2623,
                "name": "North Brabant",
                "state_code": "NB"
            },
            {
                "id": 2612,
                "name": "North Holland",
                "state_code": "NH"
            },
            {
                "id": 2618,
                "name": "Overijssel",
                "state_code": "OV"
            },
            {
                "id": 2621,
                "name": "Saba",
                "state_code": "BQ2"
            },
            {
                "id": 2616,
                "name": "Sint Eustatius",
                "state_code": "BQ3"
            },
            {
                "id": 2614,
                "name": "South Holland",
                "state_code": "ZH"
            },
            {
                "id": 2610,
                "name": "Utrecht",
                "state_code": "UT"
            },
            {
                "id": 2620,
                "name": "Zeeland",
                "state_code": "ZE"
            }
        ]
    },
    {
        "id": 157,
        "states": []
    },
    {
        "id": 158,
        "states": [
            {
                "id": 4072,
                "name": "Auckland Region",
                "state_code": "AUK"
            },
            {
                "id": 4074,
                "name": "Bay of Plenty Region",
                "state_code": "BOP"
            },
            {
                "id": 4066,
                "name": "Canterbury Region",
                "state_code": "CAN"
            },
            {
                "id": 4067,
                "name": "Chatham Islands",
                "state_code": "CIT"
            },
            {
                "id": 4068,
                "name": "Gisborne District",
                "state_code": "GIS"
            },
            {
                "id": 4075,
                "name": "Hawke's Bay Region",
                "state_code": "HKB"
            },
            {
                "id": 4060,
                "name": "Manawatu-Wanganui Region",
                "state_code": "MWT"
            },
            {
                "id": 4063,
                "name": "Marlborough Region",
                "state_code": "MBH"
            },
            {
                "id": 4070,
                "name": "Nelson Region",
                "state_code": "NSN"
            },
            {
                "id": 4059,
                "name": "Northland Region",
                "state_code": "NTL"
            },
            {
                "id": 4062,
                "name": "Otago Region",
                "state_code": "OTA"
            },
            {
                "id": 4071,
                "name": "Southland Region",
                "state_code": "STL"
            },
            {
                "id": 4069,
                "name": "Taranaki Region",
                "state_code": "TKI"
            },
            {
                "id": 4073,
                "name": "Tasman District",
                "state_code": "TAS"
            },
            {
                "id": 4061,
                "name": "Waikato Region",
                "state_code": "WKO"
            },
            {
                "id": 4065,
                "name": "Wellington Region",
                "state_code": "WGN"
            },
            {
                "id": 4064,
                "name": "West Coast Region",
                "state_code": "WTC"
            }
        ]
    },
    {
        "id": 159,
        "states": [
            {
                "id": 946,
                "name": "Boaco",
                "state_code": "BO"
            },
            {
                "id": 950,
                "name": "Carazo",
                "state_code": "CA"
            },
            {
                "id": 954,
                "name": "Chinandega",
                "state_code": "CI"
            },
            {
                "id": 940,
                "name": "Chontales",
                "state_code": "CO"
            },
            {
                "id": 945,
                "name": "Estelí",
                "state_code": "ES"
            },
            {
                "id": 943,
                "name": "Granada",
                "state_code": "GR"
            },
            {
                "id": 955,
                "name": "Jinotega",
                "state_code": "JI"
            },
            {
                "id": 944,
                "name": "León",
                "state_code": "LE"
            },
            {
                "id": 948,
                "name": "Madriz",
                "state_code": "MD"
            },
            {
                "id": 941,
                "name": "Managua",
                "state_code": "MN"
            },
            {
                "id": 953,
                "name": "Masaya",
                "state_code": "MS"
            },
            {
                "id": 947,
                "name": "Matagalpa",
                "state_code": "MT"
            },
            {
                "id": 951,
                "name": "North Caribbean Coast",
                "state_code": "AN"
            },
            {
                "id": 4964,
                "name": "Nueva Segovia",
                "state_code": "NS"
            },
            {
                "id": 949,
                "name": "Río San Juan",
                "state_code": "SJ"
            },
            {
                "id": 942,
                "name": "Rivas",
                "state_code": "RI"
            },
            {
                "id": 952,
                "name": "South Caribbean Coast",
                "state_code": "AS"
            }
        ]
    },
    {
        "id": 160,
        "states": [
            {
                "id": 71,
                "name": "Agadez Region",
                "state_code": "1"
            },
            {
                "id": 72,
                "name": "Diffa Region",
                "state_code": "2"
            },
            {
                "id": 68,
                "name": "Dosso Region",
                "state_code": "3"
            },
            {
                "id": 70,
                "name": "Maradi Region",
                "state_code": "4"
            },
            {
                "id": 73,
                "name": "Tahoua Region",
                "state_code": "5"
            },
            {
                "id": 67,
                "name": "Tillabéri Region",
                "state_code": "6"
            },
            {
                "id": 69,
                "name": "Zinder Region",
                "state_code": "7"
            }
        ]
    },
    {
        "id": 161,
        "states": [
            {
                "id": 303,
                "name": "Abia",
                "state_code": "AB"
            },
            {
                "id": 293,
                "name": "Abuja Federal Capital Territory",
                "state_code": "FC"
            },
            {
                "id": 320,
                "name": "Adamawa",
                "state_code": "AD"
            },
            {
                "id": 304,
                "name": "Akwa Ibom",
                "state_code": "AK"
            },
            {
                "id": 315,
                "name": "Anambra",
                "state_code": "AN"
            },
            {
                "id": 312,
                "name": "Bauchi",
                "state_code": "BA"
            },
            {
                "id": 305,
                "name": "Bayelsa",
                "state_code": "BY"
            },
            {
                "id": 291,
                "name": "Benue",
                "state_code": "BE"
            },
            {
                "id": 307,
                "name": "Borno",
                "state_code": "BO"
            },
            {
                "id": 314,
                "name": "Cross River",
                "state_code": "CR"
            },
            {
                "id": 316,
                "name": "Delta",
                "state_code": "DE"
            },
            {
                "id": 311,
                "name": "Ebonyi",
                "state_code": "EB"
            },
            {
                "id": 318,
                "name": "Edo",
                "state_code": "ED"
            },
            {
                "id": 309,
                "name": "Ekiti",
                "state_code": "EK"
            },
            {
                "id": 289,
                "name": "Enugu",
                "state_code": "EN"
            },
            {
                "id": 310,
                "name": "Gombe",
                "state_code": "GO"
            },
            {
                "id": 308,
                "name": "Imo",
                "state_code": "IM"
            },
            {
                "id": 288,
                "name": "Jigawa",
                "state_code": "JI"
            },
            {
                "id": 294,
                "name": "Kaduna",
                "state_code": "KD"
            },
            {
                "id": 300,
                "name": "Kano",
                "state_code": "KN"
            },
            {
                "id": 313,
                "name": "Katsina",
                "state_code": "KT"
            },
            {
                "id": 290,
                "name": "Kebbi",
                "state_code": "KE"
            },
            {
                "id": 298,
                "name": "Kogi",
                "state_code": "KO"
            },
            {
                "id": 295,
                "name": "Kwara",
                "state_code": "KW"
            },
            {
                "id": 306,
                "name": "Lagos",
                "state_code": "LA"
            },
            {
                "id": 301,
                "name": "Nasarawa",
                "state_code": "NA"
            },
            {
                "id": 317,
                "name": "Niger",
                "state_code": "NI"
            },
            {
                "id": 323,
                "name": "Ogun",
                "state_code": "OG"
            },
            {
                "id": 321,
                "name": "Ondo",
                "state_code": "ON"
            },
            {
                "id": 322,
                "name": "Osun",
                "state_code": "OS"
            },
            {
                "id": 296,
                "name": "Oyo",
                "state_code": "OY"
            },
            {
                "id": 302,
                "name": "Plateau",
                "state_code": "PL"
            },
            {
                "id": 4926,
                "name": "Rivers",
                "state_code": "RI"
            },
            {
                "id": 292,
                "name": "Sokoto",
                "state_code": "SO"
            },
            {
                "id": 319,
                "name": "Taraba",
                "state_code": "TA"
            },
            {
                "id": 297,
                "name": "Yobe",
                "state_code": "YO"
            },
            {
                "id": 299,
                "name": "Zamfara",
                "state_code": "ZA"
            }
        ]
    },
    {
        "id": 162,
        "states": []
    },
    {
        "id": 163,
        "states": []
    },
    {
        "id": 115,
        "states": [
            {
                "id": 3998,
                "name": "Chagang Province",
                "state_code": "04"
            },
            {
                "id": 3999,
                "name": "Kangwon Province",
                "state_code": "07"
            },
            {
                "id": 3995,
                "name": "North Hamgyong Province",
                "state_code": "09"
            },
            {
                "id": 4004,
                "name": "North Hwanghae Province",
                "state_code": "06"
            },
            {
                "id": 4002,
                "name": "North Pyongan Province",
                "state_code": "03"
            },
            {
                "id": 4005,
                "name": "Pyongyang",
                "state_code": "01"
            },
            {
                "id": 4001,
                "name": "Rason",
                "state_code": "13"
            },
            {
                "id": 3996,
                "name": "Ryanggang Province",
                "state_code": "10"
            },
            {
                "id": 4000,
                "name": "South Hamgyong Province",
                "state_code": "08"
            },
            {
                "id": 4003,
                "name": "South Hwanghae Province",
                "state_code": "05"
            },
            {
                "id": 3997,
                "name": "South Pyongan Province",
                "state_code": "02"
            }
        ]
    },
    {
        "id": 164,
        "states": []
    },
    {
        "id": 165,
        "states": [
            {
                "id": 1014,
                "name": "Agder",
                "state_code": "42"
            },
            {
                "id": 1009,
                "name": "Innlandet",
                "state_code": "34"
            },
            {
                "id": 1026,
                "name": "Jan Mayen",
                "state_code": "22"
            },
            {
                "id": 1020,
                "name": "Møre og Romsdal",
                "state_code": "15"
            },
            {
                "id": 1025,
                "name": "Nordland",
                "state_code": "18"
            },
            {
                "id": 1007,
                "name": "Oslo",
                "state_code": "03"
            },
            {
                "id": 1021,
                "name": "Rogaland",
                "state_code": "11"
            },
            {
                "id": 1013,
                "name": "Svalbard",
                "state_code": "21"
            },
            {
                "id": 1015,
                "name": "Troms og Finnmark",
                "state_code": "54"
            },
            {
                "id": 1006,
                "name": "Trøndelag",
                "state_code": "50"
            },
            {
                "id": 1024,
                "name": "Vestfold og Telemark",
                "state_code": "38"
            },
            {
                "id": 1018,
                "name": "Vestland",
                "state_code": "46"
            },
            {
                "id": 1011,
                "name": "Viken",
                "state_code": "30"
            }
        ]
    },
    {
        "id": 166,
        "states": [
            {
                "id": 3058,
                "name": "Ad Dakhiliyah",
                "state_code": "DA"
            },
            {
                "id": 3047,
                "name": "Ad Dhahirah",
                "state_code": "ZA"
            },
            {
                "id": 3048,
                "name": "Al Batinah North",
                "state_code": "BS"
            },
            {
                "id": 3050,
                "name": "Al Batinah Region",
                "state_code": "BA"
            },
            {
                "id": 3049,
                "name": "Al Batinah South",
                "state_code": "BJ"
            },
            {
                "id": 3059,
                "name": "Al Buraimi",
                "state_code": "BU"
            },
            {
                "id": 3056,
                "name": "Al Wusta",
                "state_code": "WU"
            },
            {
                "id": 3053,
                "name": "Ash Sharqiyah North",
                "state_code": "SS"
            },
            {
                "id": 3051,
                "name": "Ash Sharqiyah Region",
                "state_code": "SH"
            },
            {
                "id": 3054,
                "name": "Ash Sharqiyah South",
                "state_code": "SJ"
            },
            {
                "id": 3057,
                "name": "Dhofar",
                "state_code": "ZU"
            },
            {
                "id": 3052,
                "name": "Musandam",
                "state_code": "MU"
            },
            {
                "id": 3055,
                "name": "Muscat",
                "state_code": "MA"
            }
        ]
    },
    {
        "id": 167,
        "states": [
            {
                "id": 3172,
                "name": "Azad Kashmir",
                "state_code": "JK"
            },
            {
                "id": 3174,
                "name": "Balochistan",
                "state_code": "BA"
            },
            {
                "id": 3173,
                "name": "Federally Administered Tribal Areas",
                "state_code": "TA"
            },
            {
                "id": 3170,
                "name": "Gilgit-Baltistan",
                "state_code": "GB"
            },
            {
                "id": 3169,
                "name": "Islamabad Capital Territory",
                "state_code": "IS"
            },
            {
                "id": 3171,
                "name": "Khyber Pakhtunkhwa",
                "state_code": "KP"
            },
            {
                "id": 3176,
                "name": "Punjab",
                "state_code": "PB"
            },
            {
                "id": 3175,
                "name": "Sindh",
                "state_code": "SD"
            }
        ]
    },
    {
        "id": 168,
        "states": [
            {
                "id": 4540,
                "name": "Aimeliik",
                "state_code": "002"
            },
            {
                "id": 4528,
                "name": "Airai",
                "state_code": "004"
            },
            {
                "id": 4538,
                "name": "Angaur",
                "state_code": "010"
            },
            {
                "id": 4529,
                "name": "Hatohobei",
                "state_code": "050"
            },
            {
                "id": 4539,
                "name": "Kayangel",
                "state_code": "100"
            },
            {
                "id": 4532,
                "name": "Koror",
                "state_code": "150"
            },
            {
                "id": 4530,
                "name": "Melekeok",
                "state_code": "212"
            },
            {
                "id": 4537,
                "name": "Ngaraard",
                "state_code": "214"
            },
            {
                "id": 4533,
                "name": "Ngarchelong",
                "state_code": "218"
            },
            {
                "id": 4527,
                "name": "Ngardmau",
                "state_code": "222"
            },
            {
                "id": 4531,
                "name": "Ngatpang",
                "state_code": "224"
            },
            {
                "id": 4536,
                "name": "Ngchesar",
                "state_code": "226"
            },
            {
                "id": 4541,
                "name": "Ngeremlengui",
                "state_code": "227"
            },
            {
                "id": 4534,
                "name": "Ngiwal",
                "state_code": "228"
            },
            {
                "id": 4526,
                "name": "Peleliu",
                "state_code": "350"
            },
            {
                "id": 4535,
                "name": "Sonsorol",
                "state_code": "370"
            }
        ]
    },
    {
        "id": 169,
        "states": [
            {
                "id": 5118,
                "name": "Bethlehem",
                "state_code": "BTH"
            },
            {
                "id": 5119,
                "name": "Deir El Balah",
                "state_code": "DEB"
            },
            {
                "id": 5120,
                "name": "Gaza",
                "state_code": "GZA"
            },
            {
                "id": 5121,
                "name": "Hebron",
                "state_code": "HBN"
            },
            {
                "id": 5122,
                "name": "Jenin",
                "state_code": "JEN"
            },
            {
                "id": 5123,
                "name": "Jericho and Al Aghwar",
                "state_code": "JRH"
            },
            {
                "id": 5124,
                "name": "Jerusalem",
                "state_code": "JEM"
            },
            {
                "id": 5125,
                "name": "Khan Yunis",
                "state_code": "KYS"
            },
            {
                "id": 5126,
                "name": "Nablus",
                "state_code": "NBS"
            },
            {
                "id": 5127,
                "name": "North Gaza",
                "state_code": "NGZ"
            },
            {
                "id": 5128,
                "name": "Qalqilya",
                "state_code": "QQA"
            },
            {
                "id": 5129,
                "name": "Rafah",
                "state_code": "RFH"
            },
            {
                "id": 5130,
                "name": "Ramallah",
                "state_code": "RBH"
            },
            {
                "id": 5131,
                "name": "Salfit",
                "state_code": "SLT"
            },
            {
                "id": 5132,
                "name": "Tubas",
                "state_code": "TBS"
            },
            {
                "id": 5133,
                "name": "Tulkarm",
                "state_code": "TKM"
            }
        ]
    },
    {
        "id": 170,
        "states": [
            {
                "id": 1393,
                "name": "Bocas del Toro Province",
                "state_code": "1"
            },
            {
                "id": 1397,
                "name": "Chiriquí Province",
                "state_code": "4"
            },
            {
                "id": 1387,
                "name": "Coclé Province",
                "state_code": "2"
            },
            {
                "id": 1386,
                "name": "Colón Province",
                "state_code": "3"
            },
            {
                "id": 1385,
                "name": "Darién Province",
                "state_code": "5"
            },
            {
                "id": 1396,
                "name": "Emberá-Wounaan Comarca",
                "state_code": "EM"
            },
            {
                "id": 1388,
                "name": "Guna Yala",
                "state_code": "KY"
            },
            {
                "id": 1389,
                "name": "Herrera Province",
                "state_code": "6"
            },
            {
                "id": 1390,
                "name": "Los Santos Province",
                "state_code": "7"
            },
            {
                "id": 1391,
                "name": "Ngöbe-Buglé Comarca",
                "state_code": "NB"
            },
            {
                "id": 1394,
                "name": "Panamá Oeste Province",
                "state_code": "10"
            },
            {
                "id": 1395,
                "name": "Panamá Province",
                "state_code": "8"
            },
            {
                "id": 1392,
                "name": "Veraguas Province",
                "state_code": "9"
            }
        ]
    },
    {
        "id": 171,
        "states": [
            {
                "id": 4831,
                "name": "Bougainville",
                "state_code": "NSB"
            },
            {
                "id": 4847,
                "name": "Central Province",
                "state_code": "CPM"
            },
            {
                "id": 4846,
                "name": "Chimbu Province",
                "state_code": "CPK"
            },
            {
                "id": 4834,
                "name": "East New Britain",
                "state_code": "EBR"
            },
            {
                "id": 4845,
                "name": "Eastern Highlands Province",
                "state_code": "EHG"
            },
            {
                "id": 4848,
                "name": "Enga Province",
                "state_code": "EPW"
            },
            {
                "id": 4839,
                "name": "Gulf",
                "state_code": "GPK"
            },
            {
                "id": 4833,
                "name": "Hela",
                "state_code": "HLA"
            },
            {
                "id": 4832,
                "name": "Jiwaka Province",
                "state_code": "JWK"
            },
            {
                "id": 4843,
                "name": "Madang Province",
                "state_code": "MPM"
            },
            {
                "id": 4842,
                "name": "Manus Province",
                "state_code": "MRL"
            },
            {
                "id": 4849,
                "name": "Milne Bay Province",
                "state_code": "MBA"
            },
            {
                "id": 4835,
                "name": "Morobe Province",
                "state_code": "MPL"
            },
            {
                "id": 4841,
                "name": "New Ireland Province",
                "state_code": "NIK"
            },
            {
                "id": 4838,
                "name": "Oro Province",
                "state_code": "NPP"
            },
            {
                "id": 4837,
                "name": "Port Moresby",
                "state_code": "NCD"
            },
            {
                "id": 4836,
                "name": "Sandaun Province",
                "state_code": "SAN"
            },
            {
                "id": 4844,
                "name": "Southern Highlands Province",
                "state_code": "SHM"
            },
            {
                "id": 4830,
                "name": "West New Britain Province",
                "state_code": "WBK"
            },
            {
                "id": 4840,
                "name": "Western Highlands Province",
                "state_code": "WHM"
            },
            {
                "id": 4850,
                "name": "Western Province",
                "state_code": "WPD"
            }
        ]
    },
    {
        "id": 172,
        "states": [
            {
                "id": 2785,
                "name": "Alto Paraguay Department",
                "state_code": "16"
            },
            {
                "id": 2784,
                "name": "Alto Paraná Department",
                "state_code": "10"
            },
            {
                "id": 2782,
                "name": "Amambay Department",
                "state_code": "13"
            },
            {
                "id": 2780,
                "name": "Boquerón Department",
                "state_code": "19"
            },
            {
                "id": 2773,
                "name": "Caaguazú",
                "state_code": "5"
            },
            {
                "id": 2775,
                "name": "Caazapá",
                "state_code": "6"
            },
            {
                "id": 2771,
                "name": "Canindeyú",
                "state_code": "14"
            },
            {
                "id": 2777,
                "name": "Central Department",
                "state_code": "11"
            },
            {
                "id": 2779,
                "name": "Concepción Department",
                "state_code": "1"
            },
            {
                "id": 2783,
                "name": "Cordillera Department",
                "state_code": "3"
            },
            {
                "id": 2772,
                "name": "Guairá Department",
                "state_code": "4"
            },
            {
                "id": 2778,
                "name": "Itapúa",
                "state_code": "7"
            },
            {
                "id": 2786,
                "name": "Misiones Department",
                "state_code": "8"
            },
            {
                "id": 2781,
                "name": "Ñeembucú Department",
                "state_code": "12"
            },
            {
                "id": 2774,
                "name": "Paraguarí Department",
                "state_code": "9"
            },
            {
                "id": 2770,
                "name": "Presidente Hayes Department",
                "state_code": "15"
            },
            {
                "id": 2776,
                "name": "San Pedro Department",
                "state_code": "2"
            }
        ]
    },
    {
        "id": 173,
        "states": [
            {
                "id": 3685,
                "name": "Amazonas",
                "state_code": "AMA"
            },
            {
                "id": 3680,
                "name": "Áncash",
                "state_code": "ANC"
            },
            {
                "id": 3699,
                "name": "Apurímac",
                "state_code": "APU"
            },
            {
                "id": 3681,
                "name": "Arequipa",
                "state_code": "ARE"
            },
            {
                "id": 3692,
                "name": "Ayacucho",
                "state_code": "AYA"
            },
            {
                "id": 3688,
                "name": "Cajamarca",
                "state_code": "CAJ"
            },
            {
                "id": 3701,
                "name": "Callao",
                "state_code": "CAL"
            },
            {
                "id": 3691,
                "name": "Cusco",
                "state_code": "CUS"
            },
            {
                "id": 3679,
                "name": "Huancavelica",
                "state_code": "HUV"
            },
            {
                "id": 3687,
                "name": "Huanuco",
                "state_code": "HUC"
            },
            {
                "id": 3700,
                "name": "Ica",
                "state_code": "ICA"
            },
            {
                "id": 3693,
                "name": "Junín",
                "state_code": "JUN"
            },
            {
                "id": 3683,
                "name": "La Libertad",
                "state_code": "LAL"
            },
            {
                "id": 3702,
                "name": "Lambayeque",
                "state_code": "LAM"
            },
            {
                "id": 3695,
                "name": "Lima",
                "state_code": "LIM"
            },
            {
                "id": 4922,
                "name": "Loreto",
                "state_code": "LOR"
            },
            {
                "id": 3678,
                "name": "Madre de Dios",
                "state_code": "MDD"
            },
            {
                "id": 3698,
                "name": "Moquegua",
                "state_code": "MOQ"
            },
            {
                "id": 3686,
                "name": "Pasco",
                "state_code": "PAS"
            },
            {
                "id": 3697,
                "name": "Piura",
                "state_code": "PIU"
            },
            {
                "id": 3682,
                "name": "Puno",
                "state_code": "PUN"
            },
            {
                "id": 3694,
                "name": "San Martín",
                "state_code": "SAM"
            },
            {
                "id": 3696,
                "name": "Tacna",
                "state_code": "TAC"
            },
            {
                "id": 3689,
                "name": "Tumbes",
                "state_code": "TUM"
            },
            {
                "id": 3684,
                "name": "Ucayali",
                "state_code": "UCA"
            }
        ]
    },
    {
        "id": 174,
        "states": [
            {
                "id": 1324,
                "name": "Abra",
                "state_code": "ABR"
            },
            {
                "id": 1323,
                "name": "Agusan del Norte",
                "state_code": "AGN"
            },
            {
                "id": 1326,
                "name": "Agusan del Sur",
                "state_code": "AGS"
            },
            {
                "id": 1331,
                "name": "Aklan",
                "state_code": "AKL"
            },
            {
                "id": 1337,
                "name": "Albay",
                "state_code": "ALB"
            },
            {
                "id": 1336,
                "name": "Antique",
                "state_code": "ANT"
            },
            {
                "id": 1334,
                "name": "Apayao",
                "state_code": "APA"
            },
            {
                "id": 1341,
                "name": "Aurora",
                "state_code": "AUR"
            },
            {
                "id": 1316,
                "name": "Autonomous Region in Muslim Mindanao",
                "state_code": "14"
            },
            {
                "id": 1346,
                "name": "Basilan",
                "state_code": "BAS"
            },
            {
                "id": 1344,
                "name": "Bataan",
                "state_code": "BAN"
            },
            {
                "id": 1352,
                "name": "Batanes",
                "state_code": "BTN"
            },
            {
                "id": 1359,
                "name": "Batangas",
                "state_code": "BTG"
            },
            {
                "id": 1363,
                "name": "Benguet",
                "state_code": "BEN"
            },
            {
                "id": 1304,
                "name": "Bicol",
                "state_code": "05"
            },
            {
                "id": 1274,
                "name": "Biliran",
                "state_code": "BIL"
            },
            {
                "id": 1272,
                "name": "Bohol",
                "state_code": "BOH"
            },
            {
                "id": 1270,
                "name": "Bukidnon",
                "state_code": "BUK"
            },
            {
                "id": 1278,
                "name": "Bulacan",
                "state_code": "BUL"
            },
            {
                "id": 1279,
                "name": "Cagayan",
                "state_code": "CAG"
            },
            {
                "id": 1342,
                "name": "Cagayan Valley",
                "state_code": "02"
            },
            {
                "id": 1294,
                "name": "Calabarzon",
                "state_code": "40"
            },
            {
                "id": 1283,
                "name": "Camarines Norte",
                "state_code": "CAN"
            },
            {
                "id": 1287,
                "name": "Camarines Sur",
                "state_code": "CAS"
            },
            {
                "id": 1285,
                "name": "Camiguin",
                "state_code": "CAM"
            },
            {
                "id": 1292,
                "name": "Capiz",
                "state_code": "CAP"
            },
            {
                "id": 1314,
                "name": "Caraga",
                "state_code": "13"
            },
            {
                "id": 1301,
                "name": "Catanduanes",
                "state_code": "CAT"
            },
            {
                "id": 1307,
                "name": "Cavite",
                "state_code": "CAV"
            },
            {
                "id": 1306,
                "name": "Cebu",
                "state_code": "CEB"
            },
            {
                "id": 1345,
                "name": "Central Luzon",
                "state_code": "03"
            },
            {
                "id": 1308,
                "name": "Central Visayas",
                "state_code": "07"
            },
            {
                "id": 1311,
                "name": "Compostela Valley",
                "state_code": "COM"
            },
            {
                "id": 1335,
                "name": "Cordillera Administrative",
                "state_code": "15"
            },
            {
                "id": 1320,
                "name": "Cotabato",
                "state_code": "NCO"
            },
            {
                "id": 1340,
                "name": "Davao",
                "state_code": "11"
            },
            {
                "id": 1319,
                "name": "Davao del Norte",
                "state_code": "DAV"
            },
            {
                "id": 1318,
                "name": "Davao del Sur",
                "state_code": "DAS"
            },
            {
                "id": 1309,
                "name": "Davao Occidental",
                "state_code": "DVO"
            },
            {
                "id": 1289,
                "name": "Davao Oriental",
                "state_code": "DAO"
            },
            {
                "id": 1291,
                "name": "Dinagat Islands",
                "state_code": "DIN"
            },
            {
                "id": 1290,
                "name": "Eastern Samar",
                "state_code": "EAS"
            },
            {
                "id": 1322,
                "name": "Eastern Visayas",
                "state_code": "08"
            },
            {
                "id": 1303,
                "name": "Guimaras",
                "state_code": "GUI"
            },
            {
                "id": 1300,
                "name": "Ifugao",
                "state_code": "IFU"
            },
            {
                "id": 1355,
                "name": "Ilocos",
                "state_code": "01"
            },
            {
                "id": 1298,
                "name": "Ilocos Norte",
                "state_code": "ILN"
            },
            {
                "id": 1321,
                "name": "Ilocos Sur",
                "state_code": "ILS"
            },
            {
                "id": 1315,
                "name": "Iloilo",
                "state_code": "ILI"
            },
            {
                "id": 1313,
                "name": "Isabela",
                "state_code": "ISA"
            },
            {
                "id": 1312,
                "name": "Kalinga",
                "state_code": "KAL"
            },
            {
                "id": 1317,
                "name": "La Union",
                "state_code": "LUN"
            },
            {
                "id": 1328,
                "name": "Laguna",
                "state_code": "LAG"
            },
            {
                "id": 1327,
                "name": "Lanao del Norte",
                "state_code": "LAN"
            },
            {
                "id": 1333,
                "name": "Lanao del Sur",
                "state_code": "LAS"
            },
            {
                "id": 1332,
                "name": "Leyte",
                "state_code": "LEY"
            },
            {
                "id": 1330,
                "name": "Maguindanao",
                "state_code": "MAG"
            },
            {
                "id": 1329,
                "name": "Marinduque",
                "state_code": "MAD"
            },
            {
                "id": 1338,
                "name": "Masbate",
                "state_code": "MAS"
            },
            {
                "id": 1347,
                "name": "Metro Manila",
                "state_code": "NCR"
            },
            {
                "id": 1299,
                "name": "Mimaropa",
                "state_code": "41"
            },
            {
                "id": 1343,
                "name": "Misamis Occidental",
                "state_code": "MSC"
            },
            {
                "id": 1348,
                "name": "Misamis Oriental",
                "state_code": "MSR"
            },
            {
                "id": 1353,
                "name": "Mountain Province",
                "state_code": "MOU"
            },
            {
                "id": 1351,
                "name": "Negros Occidental",
                "state_code": "NEC"
            },
            {
                "id": 1350,
                "name": "Negros Oriental",
                "state_code": "NER"
            },
            {
                "id": 1339,
                "name": "Northern Mindanao",
                "state_code": "10"
            },
            {
                "id": 1349,
                "name": "Northern Samar",
                "state_code": "NSA"
            },
            {
                "id": 1360,
                "name": "Nueva Ecija",
                "state_code": "NUE"
            },
            {
                "id": 1358,
                "name": "Nueva Vizcaya",
                "state_code": "NUV"
            },
            {
                "id": 1356,
                "name": "Occidental Mindoro",
                "state_code": "MDC"
            },
            {
                "id": 1354,
                "name": "Oriental Mindoro",
                "state_code": "MDR"
            },
            {
                "id": 1361,
                "name": "Palawan",
                "state_code": "PLW"
            },
            {
                "id": 1365,
                "name": "Pampanga",
                "state_code": "PAM"
            },
            {
                "id": 1364,
                "name": "Pangasinan",
                "state_code": "PAN"
            },
            {
                "id": 1275,
                "name": "Quezon",
                "state_code": "QUE"
            },
            {
                "id": 1273,
                "name": "Quirino",
                "state_code": "QUI"
            },
            {
                "id": 1271,
                "name": "Rizal",
                "state_code": "RIZ"
            },
            {
                "id": 1269,
                "name": "Romblon",
                "state_code": "ROM"
            },
            {
                "id": 1277,
                "name": "Sarangani",
                "state_code": "SAR"
            },
            {
                "id": 1276,
                "name": "Siquijor",
                "state_code": "SIG"
            },
            {
                "id": 1310,
                "name": "Soccsksargen",
                "state_code": "12"
            },
            {
                "id": 1281,
                "name": "Sorsogon",
                "state_code": "SOR"
            },
            {
                "id": 1280,
                "name": "South Cotabato",
                "state_code": "SCO"
            },
            {
                "id": 1284,
                "name": "Southern Leyte",
                "state_code": "SLE"
            },
            {
                "id": 1282,
                "name": "Sultan Kudarat",
                "state_code": "SUK"
            },
            {
                "id": 1288,
                "name": "Sulu",
                "state_code": "SLU"
            },
            {
                "id": 1286,
                "name": "Surigao del Norte",
                "state_code": "SUN"
            },
            {
                "id": 1296,
                "name": "Surigao del Sur",
                "state_code": "SUR"
            },
            {
                "id": 1295,
                "name": "Tarlac",
                "state_code": "TAR"
            },
            {
                "id": 1293,
                "name": "Tawi-Tawi",
                "state_code": "TAW"
            },
            {
                "id": 5115,
                "name": "Western Samar",
                "state_code": "WSA"
            },
            {
                "id": 1305,
                "name": "Western Visayas",
                "state_code": "06"
            },
            {
                "id": 1297,
                "name": "Zambales",
                "state_code": "ZMB"
            },
            {
                "id": 1302,
                "name": "Zamboanga del Norte",
                "state_code": "ZAN"
            },
            {
                "id": 1357,
                "name": "Zamboanga del Sur",
                "state_code": "ZAS"
            },
            {
                "id": 1325,
                "name": "Zamboanga Peninsula",
                "state_code": "09"
            },
            {
                "id": 1362,
                "name": "Zamboanga Sibugay",
                "state_code": "ZSI"
            }
        ]
    },
    {
        "id": 175,
        "states": []
    },
    {
        "id": 176,
        "states": [
            {
                "id": 1634,
                "name": "Greater Poland Voivodeship",
                "state_code": "WP"
            },
            {
                "id": 1625,
                "name": "Kuyavian-Pomeranian Voivodeship",
                "state_code": "KP"
            },
            {
                "id": 1635,
                "name": "Lesser Poland Voivodeship",
                "state_code": "MA"
            },
            {
                "id": 1629,
                "name": "Lower Silesian Voivodeship",
                "state_code": "DS"
            },
            {
                "id": 1638,
                "name": "Lublin Voivodeship",
                "state_code": "LU"
            },
            {
                "id": 1631,
                "name": "Lubusz Voivodeship",
                "state_code": "LB"
            },
            {
                "id": 1636,
                "name": "Łódź Voivodeship",
                "state_code": "LD"
            },
            {
                "id": 1637,
                "name": "Masovian Voivodeship",
                "state_code": "MZ"
            },
            {
                "id": 1622,
                "name": "Opole Voivodeship",
                "state_code": "OP"
            },
            {
                "id": 1626,
                "name": "Podkarpackie Voivodeship",
                "state_code": "PK"
            },
            {
                "id": 1632,
                "name": "Podlaskie Voivodeship",
                "state_code": "PD"
            },
            {
                "id": 1624,
                "name": "Pomeranian Voivodeship",
                "state_code": "PM"
            },
            {
                "id": 1623,
                "name": "Silesian Voivodeship",
                "state_code": "SL"
            },
            {
                "id": 1630,
                "name": "Świętokrzyskie Voivodeship",
                "state_code": "SK"
            },
            {
                "id": 1628,
                "name": "Warmian-Masurian Voivodeship",
                "state_code": "WN"
            },
            {
                "id": 1633,
                "name": "West Pomeranian Voivodeship",
                "state_code": "ZP"
            }
        ]
    },
    {
        "id": 177,
        "states": [
            {
                "id": 2233,
                "name": "Açores",
                "state_code": "20"
            },
            {
                "id": 2235,
                "name": "Aveiro",
                "state_code": "01"
            },
            {
                "id": 2230,
                "name": "Beja",
                "state_code": "02"
            },
            {
                "id": 2244,
                "name": "Braga",
                "state_code": "03"
            },
            {
                "id": 2229,
                "name": "Bragança",
                "state_code": "04"
            },
            {
                "id": 2241,
                "name": "Castelo Branco",
                "state_code": "05"
            },
            {
                "id": 2246,
                "name": "Coimbra",
                "state_code": "06"
            },
            {
                "id": 2236,
                "name": "Évora",
                "state_code": "07"
            },
            {
                "id": 2239,
                "name": "Faro",
                "state_code": "08"
            },
            {
                "id": 4859,
                "name": "Guarda",
                "state_code": "09"
            },
            {
                "id": 2240,
                "name": "Leiria",
                "state_code": "10"
            },
            {
                "id": 2228,
                "name": "Lisbon",
                "state_code": "11"
            },
            {
                "id": 2231,
                "name": "Madeira",
                "state_code": "30"
            },
            {
                "id": 2232,
                "name": "Portalegre",
                "state_code": "12"
            },
            {
                "id": 2243,
                "name": "Porto",
                "state_code": "13"
            },
            {
                "id": 2238,
                "name": "Santarém",
                "state_code": "14"
            },
            {
                "id": 2242,
                "name": "Setúbal",
                "state_code": "15"
            },
            {
                "id": 2245,
                "name": "Viana do Castelo",
                "state_code": "16"
            },
            {
                "id": 2234,
                "name": "Vila Real",
                "state_code": "17"
            },
            {
                "id": 2237,
                "name": "Viseu",
                "state_code": "18"
            }
        ]
    },
    {
        "id": 178,
        "states": [
            {
                "id": 5081,
                "name": "Arecibo",
                "state_code": "AR"
            },
            {
                "id": 5076,
                "name": "Bayamon",
                "state_code": "BY"
            },
            {
                "id": 5079,
                "name": "Caguas",
                "state_code": "CG"
            },
            {
                "id": 5077,
                "name": "Carolina",
                "state_code": "CL"
            },
            {
                "id": 5080,
                "name": "Guaynabo",
                "state_code": "GN"
            },
            {
                "id": 5083,
                "name": "Mayagüez",
                "state_code": "MG"
            },
            {
                "id": 5078,
                "name": "Ponce",
                "state_code": "PO"
            },
            {
                "id": 5075,
                "name": "San Juan",
                "state_code": "SJ"
            },
            {
                "id": 5082,
                "name": "Toa Baja",
                "state_code": "TB"
            },
            {
                "id": 5084,
                "name": "Trujillo Alto",
                "state_code": "TA"
            }
        ]
    },
    {
        "id": 179,
        "states": [
            {
                "id": 3182,
                "name": "Al Daayen",
                "state_code": "ZA"
            },
            {
                "id": 3183,
                "name": "Al Khor",
                "state_code": "KH"
            },
            {
                "id": 3177,
                "name": "Al Rayyan Municipality",
                "state_code": "RA"
            },
            {
                "id": 3179,
                "name": "Al Wakrah",
                "state_code": "WA"
            },
            {
                "id": 3178,
                "name": "Al-Shahaniya",
                "state_code": "SH"
            },
            {
                "id": 3181,
                "name": "Doha",
                "state_code": "DA"
            },
            {
                "id": 3180,
                "name": "Madinat ash Shamal",
                "state_code": "MS"
            },
            {
                "id": 3184,
                "name": "Umm Salal Municipality",
                "state_code": "US"
            }
        ]
    },
    {
        "id": 180,
        "states": []
    },
    {
        "id": 181,
        "states": [
            {
                "id": 4724,
                "name": "Alba",
                "state_code": "AB"
            },
            {
                "id": 4739,
                "name": "Arad County",
                "state_code": "AR"
            },
            {
                "id": 4722,
                "name": "Arges",
                "state_code": "AG"
            },
            {
                "id": 4744,
                "name": "Bacău County",
                "state_code": "BC"
            },
            {
                "id": 4723,
                "name": "Bihor County",
                "state_code": "BH"
            },
            {
                "id": 4733,
                "name": "Bistrița-Năsăud County",
                "state_code": "BN"
            },
            {
                "id": 4740,
                "name": "Botoșani County",
                "state_code": "BT"
            },
            {
                "id": 4736,
                "name": "Braila",
                "state_code": "BR"
            },
            {
                "id": 4759,
                "name": "Brașov County",
                "state_code": "BV"
            },
            {
                "id": 4730,
                "name": "Bucharest",
                "state_code": "B"
            },
            {
                "id": 4756,
                "name": "Buzău County",
                "state_code": "BZ"
            },
            {
                "id": 4732,
                "name": "Călărași County",
                "state_code": "CL"
            },
            {
                "id": 4753,
                "name": "Caraș-Severin County",
                "state_code": "CS"
            },
            {
                "id": 4734,
                "name": "Cluj County",
                "state_code": "CJ"
            },
            {
                "id": 4737,
                "name": "Constanța County",
                "state_code": "CT"
            },
            {
                "id": 4754,
                "name": "Covasna County",
                "state_code": "CV"
            },
            {
                "id": 4745,
                "name": "Dâmbovița County",
                "state_code": "DB"
            },
            {
                "id": 4742,
                "name": "Dolj County",
                "state_code": "DJ"
            },
            {
                "id": 4747,
                "name": "Galați County",
                "state_code": "GL"
            },
            {
                "id": 4726,
                "name": "Giurgiu County",
                "state_code": "GR"
            },
            {
                "id": 4750,
                "name": "Gorj County",
                "state_code": "GJ"
            },
            {
                "id": 4749,
                "name": "Harghita County",
                "state_code": "HR"
            },
            {
                "id": 4721,
                "name": "Hunedoara County",
                "state_code": "HD"
            },
            {
                "id": 4743,
                "name": "Ialomița County",
                "state_code": "IL"
            },
            {
                "id": 4735,
                "name": "Iași County",
                "state_code": "IS"
            },
            {
                "id": 4725,
                "name": "Ilfov County",
                "state_code": "IF"
            },
            {
                "id": 4760,
                "name": "Maramureș County",
                "state_code": "MM"
            },
            {
                "id": 4751,
                "name": "Mehedinți County",
                "state_code": "MH"
            },
            {
                "id": 4915,
                "name": "Mureș County",
                "state_code": "MS"
            },
            {
                "id": 4731,
                "name": "Neamț County",
                "state_code": "NT"
            },
            {
                "id": 4738,
                "name": "Olt County",
                "state_code": "OT"
            },
            {
                "id": 4729,
                "name": "Prahova County",
                "state_code": "PH"
            },
            {
                "id": 4741,
                "name": "Sălaj County",
                "state_code": "SJ"
            },
            {
                "id": 4746,
                "name": "Satu Mare County",
                "state_code": "SM"
            },
            {
                "id": 4755,
                "name": "Sibiu County",
                "state_code": "SB"
            },
            {
                "id": 4720,
                "name": "Suceava County",
                "state_code": "SV"
            },
            {
                "id": 4728,
                "name": "Teleorman County",
                "state_code": "TR"
            },
            {
                "id": 4748,
                "name": "Timiș County",
                "state_code": "TM"
            },
            {
                "id": 4727,
                "name": "Tulcea County",
                "state_code": "TL"
            },
            {
                "id": 4757,
                "name": "Vâlcea County",
                "state_code": "VL"
            },
            {
                "id": 4752,
                "name": "Vaslui County",
                "state_code": "VS"
            },
            {
                "id": 4758,
                "name": "Vrancea County",
                "state_code": "VN"
            }
        ]
    },
    {
        "id": 182,
        "states": [
            {
                "id": 1911,
                "name": "Altai Krai",
                "state_code": "ALT"
            },
            {
                "id": 1876,
                "name": "Altai Republic",
                "state_code": "AL"
            },
            {
                "id": 1858,
                "name": "Amur Oblast",
                "state_code": "AMU"
            },
            {
                "id": 1849,
                "name": "Arkhangelsk",
                "state_code": "ARK"
            },
            {
                "id": 1866,
                "name": "Astrakhan Oblast",
                "state_code": "AST"
            },
            {
                "id": 1903,
                "name": "Belgorod Oblast",
                "state_code": "BEL"
            },
            {
                "id": 1867,
                "name": "Bryansk Oblast",
                "state_code": "BRY"
            },
            {
                "id": 1893,
                "name": "Chechen Republic",
                "state_code": "CE"
            },
            {
                "id": 1845,
                "name": "Chelyabinsk Oblast",
                "state_code": "CHE"
            },
            {
                "id": 1859,
                "name": "Chukotka Autonomous Okrug",
                "state_code": "CHU"
            },
            {
                "id": 1914,
                "name": "Chuvash Republic",
                "state_code": "CU"
            },
            {
                "id": 1880,
                "name": "Irkutsk",
                "state_code": "IRK"
            },
            {
                "id": 1864,
                "name": "Ivanovo Oblast",
                "state_code": "IVA"
            },
            {
                "id": 1835,
                "name": "Jewish Autonomous Oblast",
                "state_code": "YEV"
            },
            {
                "id": 1892,
                "name": "Kabardino-Balkar Republic",
                "state_code": "KB"
            },
            {
                "id": 1902,
                "name": "Kaliningrad",
                "state_code": "KGD"
            },
            {
                "id": 1844,
                "name": "Kaluga Oblast",
                "state_code": "KLU"
            },
            {
                "id": 1865,
                "name": "Kamchatka Krai",
                "state_code": "KAM"
            },
            {
                "id": 1869,
                "name": "Karachay-Cherkess Republic",
                "state_code": "KC"
            },
            {
                "id": 1897,
                "name": "Kemerovo Oblast",
                "state_code": "KEM"
            },
            {
                "id": 1873,
                "name": "Khabarovsk Krai",
                "state_code": "KHA"
            },
            {
                "id": 1838,
                "name": "Khanty-Mansi Autonomous Okrug",
                "state_code": "KHM"
            },
            {
                "id": 1890,
                "name": "Kirov Oblast",
                "state_code": "KIR"
            },
            {
                "id": 1899,
                "name": "Komi Republic",
                "state_code": "KO"
            },
            {
                "id": 1910,
                "name": "Kostroma Oblast",
                "state_code": "KOS"
            },
            {
                "id": 1891,
                "name": "Krasnodar Krai",
                "state_code": "KDA"
            },
            {
                "id": 1840,
                "name": "Krasnoyarsk Krai",
                "state_code": "KYA"
            },
            {
                "id": 1915,
                "name": "Kurgan Oblast",
                "state_code": "KGN"
            },
            {
                "id": 1855,
                "name": "Kursk Oblast",
                "state_code": "KRS"
            },
            {
                "id": 1896,
                "name": "Leningrad Oblast",
                "state_code": "LEN"
            },
            {
                "id": 1889,
                "name": "Lipetsk Oblast",
                "state_code": "LIP"
            },
            {
                "id": 1839,
                "name": "Magadan Oblast",
                "state_code": "MAG"
            },
            {
                "id": 1870,
                "name": "Mari El Republic",
                "state_code": "ME"
            },
            {
                "id": 1901,
                "name": "Moscow",
                "state_code": "MOW"
            },
            {
                "id": 1882,
                "name": "Moscow Oblast",
                "state_code": "MOS"
            },
            {
                "id": 1843,
                "name": "Murmansk Oblast",
                "state_code": "MUR"
            },
            {
                "id": 1836,
                "name": "Nenets Autonomous Okrug",
                "state_code": "NEN"
            },
            {
                "id": 1857,
                "name": "Nizhny Novgorod Oblast",
                "state_code": "NIZ"
            },
            {
                "id": 1834,
                "name": "Novgorod Oblast",
                "state_code": "NGR"
            },
            {
                "id": 1888,
                "name": "Novosibirsk",
                "state_code": "NVS"
            },
            {
                "id": 1846,
                "name": "Omsk Oblast",
                "state_code": "OMS"
            },
            {
                "id": 1886,
                "name": "Orenburg Oblast",
                "state_code": "ORE"
            },
            {
                "id": 1908,
                "name": "Oryol Oblast",
                "state_code": "ORL"
            },
            {
                "id": 1909,
                "name": "Penza Oblast",
                "state_code": "PNZ"
            },
            {
                "id": 1871,
                "name": "Perm Krai",
                "state_code": "PER"
            },
            {
                "id": 1833,
                "name": "Primorsky Krai",
                "state_code": "PRI"
            },
            {
                "id": 1863,
                "name": "Pskov Oblast",
                "state_code": "PSK"
            },
            {
                "id": 1852,
                "name": "Republic of Adygea",
                "state_code": "AD"
            },
            {
                "id": 1854,
                "name": "Republic of Bashkortostan",
                "state_code": "BA"
            },
            {
                "id": 1842,
                "name": "Republic of Buryatia",
                "state_code": "BU"
            },
            {
                "id": 1850,
                "name": "Republic of Dagestan",
                "state_code": "DA"
            },
            {
                "id": 1884,
                "name": "Republic of Ingushetia",
                "state_code": "IN"
            },
            {
                "id": 1883,
                "name": "Republic of Kalmykia",
                "state_code": "KL"
            },
            {
                "id": 1841,
                "name": "Republic of Karelia",
                "state_code": "KR"
            },
            {
                "id": 1877,
                "name": "Republic of Khakassia",
                "state_code": "KK"
            },
            {
                "id": 1898,
                "name": "Republic of Mordovia",
                "state_code": "MO"
            },
            {
                "id": 1853,
                "name": "Republic of North Ossetia-Alania",
                "state_code": "SE"
            },
            {
                "id": 1861,
                "name": "Republic of Tatarstan",
                "state_code": "TA"
            },
            {
                "id": 1837,
                "name": "Rostov Oblast",
                "state_code": "ROS"
            },
            {
                "id": 1905,
                "name": "Ryazan Oblast",
                "state_code": "RYA"
            },
            {
                "id": 1879,
                "name": "Saint Petersburg",
                "state_code": "SPE"
            },
            {
                "id": 1848,
                "name": "Sakha Republic",
                "state_code": "SA"
            },
            {
                "id": 1875,
                "name": "Sakhalin",
                "state_code": "SAK"
            },
            {
                "id": 1862,
                "name": "Samara Oblast",
                "state_code": "SAM"
            },
            {
                "id": 1887,
                "name": "Saratov Oblast",
                "state_code": "SAR"
            },
            {
                "id": 1912,
                "name": "Sevastopol",
                "state_code": "UA-40"
            },
            {
                "id": 1885,
                "name": "Smolensk Oblast",
                "state_code": "SMO"
            },
            {
                "id": 1868,
                "name": "Stavropol Krai",
                "state_code": "STA"
            },
            {
                "id": 1894,
                "name": "Sverdlovsk",
                "state_code": "SVE"
            },
            {
                "id": 1878,
                "name": "Tambov Oblast",
                "state_code": "TAM"
            },
            {
                "id": 1872,
                "name": "Tomsk Oblast",
                "state_code": "TOM"
            },
            {
                "id": 1895,
                "name": "Tula Oblast",
                "state_code": "TUL"
            },
            {
                "id": 1900,
                "name": "Tuva Republic",
                "state_code": "TY"
            },
            {
                "id": 1860,
                "name": "Tver Oblast",
                "state_code": "TVE"
            },
            {
                "id": 1907,
                "name": "Tyumen Oblast",
                "state_code": "TYU"
            },
            {
                "id": 1913,
                "name": "Udmurt Republic",
                "state_code": "UD"
            },
            {
                "id": 1856,
                "name": "Ulyanovsk Oblast",
                "state_code": "ULY"
            },
            {
                "id": 1881,
                "name": "Vladimir Oblast",
                "state_code": "VLA"
            },
            {
                "id": 4916,
                "name": "Volgograd Oblast",
                "state_code": "VGG"
            },
            {
                "id": 1874,
                "name": "Vologda Oblast",
                "state_code": "VLG"
            },
            {
                "id": 1906,
                "name": "Voronezh Oblast",
                "state_code": "VOR"
            },
            {
                "id": 1847,
                "name": "Yamalo-Nenets Autonomous Okrug",
                "state_code": "YAN"
            },
            {
                "id": 1851,
                "name": "Yaroslavl Oblast",
                "state_code": "YAR"
            },
            {
                "id": 1904,
                "name": "Zabaykalsky Krai",
                "state_code": "ZAB"
            }
        ]
    },
    {
        "id": 183,
        "states": [
            {
                "id": 261,
                "name": "Eastern Province",
                "state_code": "02"
            },
            {
                "id": 262,
                "name": "Kigali district",
                "state_code": "01"
            },
            {
                "id": 263,
                "name": "Northern Province",
                "state_code": "03"
            },
            {
                "id": 259,
                "name": "Southern Province",
                "state_code": "05"
            },
            {
                "id": 260,
                "name": "Western Province",
                "state_code": "04"
            }
        ]
    },
    {
        "id": 184,
        "states": []
    },
    {
        "id": 185,
        "states": [
            {
                "id": 3833,
                "name": "Christ Church Nichola Town Parish",
                "state_code": "01"
            },
            {
                "id": 3832,
                "name": "Nevis",
                "state_code": "N"
            },
            {
                "id": 3836,
                "name": "Saint Anne Sandy Point Parish",
                "state_code": "02"
            },
            {
                "id": 3837,
                "name": "Saint George Gingerland Parish",
                "state_code": "04"
            },
            {
                "id": 3835,
                "name": "Saint James Windward Parish",
                "state_code": "05"
            },
            {
                "id": 3845,
                "name": "Saint John Capisterre Parish",
                "state_code": "06"
            },
            {
                "id": 3840,
                "name": "Saint John Figtree Parish",
                "state_code": "07"
            },
            {
                "id": 3841,
                "name": "Saint Kitts",
                "state_code": "K"
            },
            {
                "id": 3844,
                "name": "Saint Mary Cayon Parish",
                "state_code": "08"
            },
            {
                "id": 3834,
                "name": "Saint Paul Capisterre Parish",
                "state_code": "09"
            },
            {
                "id": 3838,
                "name": "Saint Paul Charlestown Parish",
                "state_code": "10"
            },
            {
                "id": 3831,
                "name": "Saint Peter Basseterre Parish",
                "state_code": "11"
            },
            {
                "id": 3839,
                "name": "Saint Thomas Lowland Parish",
                "state_code": "12"
            },
            {
                "id": 3842,
                "name": "Saint Thomas Middle Island Parish",
                "state_code": "13"
            },
            {
                "id": 3843,
                "name": "Trinity Palmetto Point Parish",
                "state_code": "15"
            }
        ]
    },
    {
        "id": 186,
        "states": [
            {
                "id": 3757,
                "name": "Anse la Raye Quarter",
                "state_code": "01"
            },
            {
                "id": 3761,
                "name": "Canaries",
                "state_code": "12"
            },
            {
                "id": 3758,
                "name": "Castries Quarter",
                "state_code": "02"
            },
            {
                "id": 3760,
                "name": "Choiseul Quarter",
                "state_code": "03"
            },
            {
                "id": 3767,
                "name": "Dauphin Quarter",
                "state_code": "04"
            },
            {
                "id": 3756,
                "name": "Dennery Quarter",
                "state_code": "05"
            },
            {
                "id": 3766,
                "name": "Gros Islet Quarter",
                "state_code": "06"
            },
            {
                "id": 3759,
                "name": "Laborie Quarter",
                "state_code": "07"
            },
            {
                "id": 3762,
                "name": "Micoud Quarter",
                "state_code": "08"
            },
            {
                "id": 3765,
                "name": "Praslin Quarter",
                "state_code": "09"
            },
            {
                "id": 3764,
                "name": "Soufrière Quarter",
                "state_code": "10"
            },
            {
                "id": 3763,
                "name": "Vieux Fort Quarter",
                "state_code": "11"
            }
        ]
    },
    {
        "id": 187,
        "states": []
    },
    {
        "id": 188,
        "states": [
            {
                "id": 3389,
                "name": "Charlotte Parish",
                "state_code": "01"
            },
            {
                "id": 3388,
                "name": "Grenadines Parish",
                "state_code": "06"
            },
            {
                "id": 3386,
                "name": "Saint Andrew Parish",
                "state_code": "02"
            },
            {
                "id": 3387,
                "name": "Saint David Parish",
                "state_code": "03"
            },
            {
                "id": 3384,
                "name": "Saint George Parish",
                "state_code": "04"
            },
            {
                "id": 3385,
                "name": "Saint Patrick Parish",
                "state_code": "05"
            }
        ]
    },
    {
        "id": 189,
        "states": []
    },
    {
        "id": 190,
        "states": []
    },
    {
        "id": 191,
        "states": [
            {
                "id": 4763,
                "name": "A'ana",
                "state_code": "AA"
            },
            {
                "id": 4761,
                "name": "Aiga-i-le-Tai",
                "state_code": "AL"
            },
            {
                "id": 4765,
                "name": "Atua",
                "state_code": "AT"
            },
            {
                "id": 4764,
                "name": "Fa'asaleleaga",
                "state_code": "FA"
            },
            {
                "id": 4769,
                "name": "Gaga'emauga",
                "state_code": "GE"
            },
            {
                "id": 4771,
                "name": "Gaga'ifomauga",
                "state_code": "GI"
            },
            {
                "id": 4767,
                "name": "Palauli",
                "state_code": "PA"
            },
            {
                "id": 4762,
                "name": "Satupa'itea",
                "state_code": "SA"
            },
            {
                "id": 4770,
                "name": "Tuamasaga",
                "state_code": "TU"
            },
            {
                "id": 4768,
                "name": "Va'a-o-Fonoti",
                "state_code": "VF"
            },
            {
                "id": 4766,
                "name": "Vaisigano",
                "state_code": "VS"
            }
        ]
    },
    {
        "id": 192,
        "states": [
            {
                "id": 59,
                "name": "Acquaviva",
                "state_code": "01"
            },
            {
                "id": 61,
                "name": "Borgo Maggiore",
                "state_code": "06"
            },
            {
                "id": 60,
                "name": "Chiesanuova",
                "state_code": "02"
            },
            {
                "id": 64,
                "name": "Domagnano",
                "state_code": "03"
            },
            {
                "id": 62,
                "name": "Faetano",
                "state_code": "04"
            },
            {
                "id": 66,
                "name": "Fiorentino",
                "state_code": "05"
            },
            {
                "id": 63,
                "name": "Montegiardino",
                "state_code": "08"
            },
            {
                "id": 58,
                "name": "San Marino",
                "state_code": "07"
            },
            {
                "id": 65,
                "name": "Serravalle",
                "state_code": "09"
            }
        ]
    },
    {
        "id": 193,
        "states": [
            {
                "id": 270,
                "name": "Príncipe Province",
                "state_code": "P"
            },
            {
                "id": 271,
                "name": "São Tomé Province",
                "state_code": "S"
            }
        ]
    },
    {
        "id": 194,
        "states": [
            {
                "id": 2853,
                "name": "'Asir",
                "state_code": "14"
            },
            {
                "id": 2859,
                "name": "Al Bahah",
                "state_code": "11"
            },
            {
                "id": 2857,
                "name": "Al Jawf",
                "state_code": "12"
            },
            {
                "id": 2851,
                "name": "Al Madinah",
                "state_code": "03"
            },
            {
                "id": 2861,
                "name": "Al-Qassim",
                "state_code": "05"
            },
            {
                "id": 2856,
                "name": "Eastern Province",
                "state_code": "04"
            },
            {
                "id": 2855,
                "name": "Ha'il",
                "state_code": "06"
            },
            {
                "id": 2858,
                "name": "Jizan",
                "state_code": "09"
            },
            {
                "id": 2850,
                "name": "Makkah",
                "state_code": "02"
            },
            {
                "id": 2860,
                "name": "Najran",
                "state_code": "10"
            },
            {
                "id": 2854,
                "name": "Northern Borders",
                "state_code": "08"
            },
            {
                "id": 2849,
                "name": "Riyadh",
                "state_code": "01"
            },
            {
                "id": 2852,
                "name": "Tabuk",
                "state_code": "07"
            }
        ]
    },
    {
        "id": 195,
        "states": [
            {
                "id": 473,
                "name": "Dakar",
                "state_code": "DK"
            },
            {
                "id": 480,
                "name": "Diourbel Region",
                "state_code": "DB"
            },
            {
                "id": 479,
                "name": "Fatick",
                "state_code": "FK"
            },
            {
                "id": 475,
                "name": "Kaffrine",
                "state_code": "KA"
            },
            {
                "id": 483,
                "name": "Kaolack",
                "state_code": "KL"
            },
            {
                "id": 481,
                "name": "Kédougou",
                "state_code": "KE"
            },
            {
                "id": 474,
                "name": "Kolda",
                "state_code": "KD"
            },
            {
                "id": 485,
                "name": "Louga",
                "state_code": "LG"
            },
            {
                "id": 476,
                "name": "Matam",
                "state_code": "MT"
            },
            {
                "id": 477,
                "name": "Saint-Louis",
                "state_code": "SL"
            },
            {
                "id": 482,
                "name": "Sédhiou",
                "state_code": "SE"
            },
            {
                "id": 486,
                "name": "Tambacounda Region",
                "state_code": "TC"
            },
            {
                "id": 484,
                "name": "Thiès Region",
                "state_code": "TH"
            },
            {
                "id": 478,
                "name": "Ziguinchor",
                "state_code": "ZG"
            }
        ]
    },
    {
        "id": 196,
        "states": [
            {
                "id": 3728,
                "name": "Belgrade",
                "state_code": "00"
            },
            {
                "id": 3717,
                "name": "Bor District",
                "state_code": "14"
            },
            {
                "id": 3732,
                "name": "Braničevo District",
                "state_code": "11"
            },
            {
                "id": 3716,
                "name": "Central Banat District",
                "state_code": "02"
            },
            {
                "id": 3715,
                "name": "Jablanica District",
                "state_code": "23"
            },
            {
                "id": 3724,
                "name": "Kolubara District",
                "state_code": "09"
            },
            {
                "id": 3719,
                "name": "Mačva District",
                "state_code": "08"
            },
            {
                "id": 3727,
                "name": "Moravica District",
                "state_code": "17"
            },
            {
                "id": 3722,
                "name": "Nišava District",
                "state_code": "20"
            },
            {
                "id": 3714,
                "name": "North Bačka District",
                "state_code": "01"
            },
            {
                "id": 3736,
                "name": "North Banat District",
                "state_code": "03"
            },
            {
                "id": 3721,
                "name": "Pčinja District",
                "state_code": "24"
            },
            {
                "id": 3712,
                "name": "Pirot District",
                "state_code": "22"
            },
            {
                "id": 3741,
                "name": "Podunavlje District",
                "state_code": "10"
            },
            {
                "id": 3737,
                "name": "Pomoravlje District",
                "state_code": "13"
            },
            {
                "id": 3720,
                "name": "Rasina District",
                "state_code": "19"
            },
            {
                "id": 3725,
                "name": "Raška District",
                "state_code": "18"
            },
            {
                "id": 3711,
                "name": "South Bačka District",
                "state_code": "06"
            },
            {
                "id": 3713,
                "name": "South Banat District",
                "state_code": "04"
            },
            {
                "id": 3740,
                "name": "Srem District",
                "state_code": "07"
            },
            {
                "id": 3734,
                "name": "Šumadija District",
                "state_code": "12"
            },
            {
                "id": 3718,
                "name": "Toplica District",
                "state_code": "21"
            },
            {
                "id": 3733,
                "name": "Vojvodina",
                "state_code": "VO"
            },
            {
                "id": 3726,
                "name": "West Bačka District",
                "state_code": "05"
            },
            {
                "id": 3731,
                "name": "Zaječar District",
                "state_code": "15"
            },
            {
                "id": 3729,
                "name": "Zlatibor District",
                "state_code": "16"
            }
        ]
    },
    {
        "id": 197,
        "states": [
            {
                "id": 513,
                "name": "Anse Boileau",
                "state_code": "02"
            },
            {
                "id": 502,
                "name": "Anse Royale",
                "state_code": "05"
            },
            {
                "id": 506,
                "name": "Anse-aux-Pins",
                "state_code": "01"
            },
            {
                "id": 508,
                "name": "Au Cap",
                "state_code": "04"
            },
            {
                "id": 497,
                "name": "Baie Lazare",
                "state_code": "06"
            },
            {
                "id": 514,
                "name": "Baie Sainte Anne",
                "state_code": "07"
            },
            {
                "id": 512,
                "name": "Beau Vallon",
                "state_code": "08"
            },
            {
                "id": 515,
                "name": "Bel Air",
                "state_code": "09"
            },
            {
                "id": 505,
                "name": "Bel Ombre",
                "state_code": "10"
            },
            {
                "id": 517,
                "name": "Cascade",
                "state_code": "11"
            },
            {
                "id": 503,
                "name": "Glacis",
                "state_code": "12"
            },
            {
                "id": 500,
                "name": "Grand'Anse Mahé",
                "state_code": "13"
            },
            {
                "id": 504,
                "name": "Grand'Anse Praslin",
                "state_code": "14"
            },
            {
                "id": 495,
                "name": "La Digue",
                "state_code": "15"
            },
            {
                "id": 516,
                "name": "La Rivière Anglaise",
                "state_code": "16"
            },
            {
                "id": 499,
                "name": "Les Mamelles",
                "state_code": "24"
            },
            {
                "id": 494,
                "name": "Mont Buxton",
                "state_code": "17"
            },
            {
                "id": 498,
                "name": "Mont Fleuri",
                "state_code": "18"
            },
            {
                "id": 511,
                "name": "Plaisance",
                "state_code": "19"
            },
            {
                "id": 510,
                "name": "Pointe La Rue",
                "state_code": "20"
            },
            {
                "id": 507,
                "name": "Port Glaud",
                "state_code": "21"
            },
            {
                "id": 501,
                "name": "Roche Caiman",
                "state_code": "25"
            },
            {
                "id": 496,
                "name": "Saint Louis",
                "state_code": "22"
            },
            {
                "id": 509,
                "name": "Takamaka",
                "state_code": "23"
            }
        ]
    },
    {
        "id": 198,
        "states": [
            {
                "id": 914,
                "name": "Eastern Province",
                "state_code": "E"
            },
            {
                "id": 911,
                "name": "Northern Province",
                "state_code": "N"
            },
            {
                "id": 912,
                "name": "Southern Province",
                "state_code": "S"
            },
            {
                "id": 913,
                "name": "Western Area",
                "state_code": "W"
            }
        ]
    },
    {
        "id": 199,
        "states": [
            {
                "id": 4651,
                "name": "Central Singapore Community Development Council",
                "state_code": "01"
            },
            {
                "id": 4649,
                "name": "North East Community Development Council",
                "state_code": "02"
            },
            {
                "id": 4653,
                "name": "North West Community Development Council",
                "state_code": "03"
            },
            {
                "id": 4650,
                "name": "South East Community Development Council",
                "state_code": "04"
            },
            {
                "id": 4652,
                "name": "South West Community Development Council",
                "state_code": "05"
            }
        ]
    },
    {
        "id": 250,
        "states": []
    },
    {
        "id": 200,
        "states": [
            {
                "id": 4352,
                "name": "Banská Bystrica Region",
                "state_code": "BC"
            },
            {
                "id": 4356,
                "name": "Bratislava Region",
                "state_code": "BL"
            },
            {
                "id": 4353,
                "name": "Košice Region",
                "state_code": "KI"
            },
            {
                "id": 4357,
                "name": "Nitra Region",
                "state_code": "NI"
            },
            {
                "id": 4354,
                "name": "Prešov Region",
                "state_code": "PV"
            },
            {
                "id": 4358,
                "name": "Trenčín Region",
                "state_code": "TC"
            },
            {
                "id": 4355,
                "name": "Trnava Region",
                "state_code": "TA"
            },
            {
                "id": 4359,
                "name": "Žilina Region",
                "state_code": "ZI"
            }
        ]
    },
    {
        "id": 201,
        "states": [
            {
                "id": 4183,
                "name": "Ajdovščina Municipality",
                "state_code": "001"
            },
            {
                "id": 4326,
                "name": "Ankaran Municipality",
                "state_code": "213"
            },
            {
                "id": 4301,
                "name": "Beltinci Municipality",
                "state_code": "002"
            },
            {
                "id": 4166,
                "name": "Benedikt Municipality",
                "state_code": "148"
            },
            {
                "id": 4179,
                "name": "Bistrica ob Sotli Municipality",
                "state_code": "149"
            },
            {
                "id": 4202,
                "name": "Bled Municipality",
                "state_code": "003"
            },
            {
                "id": 4278,
                "name": "Bloke Municipality",
                "state_code": "150"
            },
            {
                "id": 4282,
                "name": "Bohinj Municipality",
                "state_code": "004"
            },
            {
                "id": 4200,
                "name": "Borovnica Municipality",
                "state_code": "005"
            },
            {
                "id": 4181,
                "name": "Bovec Municipality",
                "state_code": "006"
            },
            {
                "id": 4141,
                "name": "Braslovče Municipality",
                "state_code": "151"
            },
            {
                "id": 4240,
                "name": "Brda Municipality",
                "state_code": "007"
            },
            {
                "id": 4215,
                "name": "Brežice Municipality",
                "state_code": "009"
            },
            {
                "id": 4165,
                "name": "Brezovica Municipality",
                "state_code": "008"
            },
            {
                "id": 4147,
                "name": "Cankova Municipality",
                "state_code": "152"
            },
            {
                "id": 4310,
                "name": "Cerklje na Gorenjskem Municipality",
                "state_code": "012"
            },
            {
                "id": 4162,
                "name": "Cerknica Municipality",
                "state_code": "013"
            },
            {
                "id": 4178,
                "name": "Cerkno Municipality",
                "state_code": "014"
            },
            {
                "id": 4176,
                "name": "Cerkvenjak Municipality",
                "state_code": "153"
            },
            {
                "id": 4191,
                "name": "City Municipality of Celje",
                "state_code": "011"
            },
            {
                "id": 4236,
                "name": "City Municipality of Novo Mesto",
                "state_code": "085"
            },
            {
                "id": 4151,
                "name": "Črenšovci Municipality",
                "state_code": "015"
            },
            {
                "id": 4232,
                "name": "Črna na Koroškem Municipality",
                "state_code": "016"
            },
            {
                "id": 4291,
                "name": "Črnomelj Municipality",
                "state_code": "017"
            },
            {
                "id": 4304,
                "name": "Destrnik Municipality",
                "state_code": "018"
            },
            {
                "id": 4167,
                "name": "Divača Municipality",
                "state_code": "019"
            },
            {
                "id": 4295,
                "name": "Dobje Municipality",
                "state_code": "154"
            },
            {
                "id": 4216,
                "name": "Dobrepolje Municipality",
                "state_code": "020"
            },
            {
                "id": 4252,
                "name": "Dobrna Municipality",
                "state_code": "155"
            },
            {
                "id": 4308,
                "name": "Dobrova–Polhov Gradec Municipality",
                "state_code": "021"
            },
            {
                "id": 4189,
                "name": "Dobrovnik Municipality",
                "state_code": "156"
            },
            {
                "id": 4173,
                "name": "Dol pri Ljubljani Municipality",
                "state_code": "022"
            },
            {
                "id": 4281,
                "name": "Dolenjske Toplice Municipality",
                "state_code": "157"
            },
            {
                "id": 4159,
                "name": "Domžale Municipality",
                "state_code": "023"
            },
            {
                "id": 4290,
                "name": "Dornava Municipality",
                "state_code": "024"
            },
            {
                "id": 4345,
                "name": "Dravograd Municipality",
                "state_code": "025"
            },
            {
                "id": 4213,
                "name": "Duplek Municipality",
                "state_code": "026"
            },
            {
                "id": 4293,
                "name": "Gorenja Vas–Poljane Municipality",
                "state_code": "027"
            },
            {
                "id": 4210,
                "name": "Gorišnica Municipality",
                "state_code": "028"
            },
            {
                "id": 4284,
                "name": "Gorje Municipality",
                "state_code": "207"
            },
            {
                "id": 4343,
                "name": "Gornja Radgona Municipality",
                "state_code": "029"
            },
            {
                "id": 4339,
                "name": "Gornji Grad Municipality",
                "state_code": "030"
            },
            {
                "id": 4271,
                "name": "Gornji Petrovci Municipality",
                "state_code": "031"
            },
            {
                "id": 4217,
                "name": "Grad Municipality",
                "state_code": "158"
            },
            {
                "id": 4336,
                "name": "Grosuplje Municipality",
                "state_code": "032"
            },
            {
                "id": 4145,
                "name": "Hajdina Municipality",
                "state_code": "159"
            },
            {
                "id": 4175,
                "name": "Hoče–Slivnica Municipality",
                "state_code": "160"
            },
            {
                "id": 4327,
                "name": "Hodoš Municipality",
                "state_code": "161"
            },
            {
                "id": 4193,
                "name": "Horjul Municipality",
                "state_code": "162"
            },
            {
                "id": 4341,
                "name": "Hrastnik Municipality",
                "state_code": "034"
            },
            {
                "id": 4321,
                "name": "Hrpelje–Kozina Municipality",
                "state_code": "035"
            },
            {
                "id": 4152,
                "name": "Idrija Municipality",
                "state_code": "036"
            },
            {
                "id": 4286,
                "name": "Ig Municipality",
                "state_code": "037"
            },
            {
                "id": 4305,
                "name": "Ivančna Gorica Municipality",
                "state_code": "039"
            },
            {
                "id": 4322,
                "name": "Izola Municipality",
                "state_code": "040"
            },
            {
                "id": 4337,
                "name": "Jesenice Municipality",
                "state_code": "041"
            },
            {
                "id": 4203,
                "name": "Jezersko Municipality",
                "state_code": "163"
            },
            {
                "id": 4266,
                "name": "Juršinci Municipality",
                "state_code": "042"
            },
            {
                "id": 4180,
                "name": "Kamnik Municipality",
                "state_code": "043"
            },
            {
                "id": 4227,
                "name": "Kanal ob Soči Municipality",
                "state_code": "044"
            },
            {
                "id": 4150,
                "name": "Kidričevo Municipality",
                "state_code": "045"
            },
            {
                "id": 4243,
                "name": "Kobarid Municipality",
                "state_code": "046"
            },
            {
                "id": 4325,
                "name": "Kobilje Municipality",
                "state_code": "047"
            },
            {
                "id": 4335,
                "name": "Kočevje Municipality",
                "state_code": "048"
            },
            {
                "id": 4315,
                "name": "Komen Municipality",
                "state_code": "049"
            },
            {
                "id": 4283,
                "name": "Komenda Municipality",
                "state_code": "164"
            },
            {
                "id": 4319,
                "name": "Koper City Municipality",
                "state_code": "050"
            },
            {
                "id": 4254,
                "name": "Kostanjevica na Krki Municipality",
                "state_code": "197"
            },
            {
                "id": 4331,
                "name": "Kostel Municipality",
                "state_code": "165"
            },
            {
                "id": 4186,
                "name": "Kozje Municipality",
                "state_code": "051"
            },
            {
                "id": 4287,
                "name": "Kranj City Municipality",
                "state_code": "052"
            },
            {
                "id": 4340,
                "name": "Kranjska Gora Municipality",
                "state_code": "053"
            },
            {
                "id": 4238,
                "name": "Križevci Municipality",
                "state_code": "166"
            },
            {
                "id": 4197,
                "name": "Kungota",
                "state_code": "055"
            },
            {
                "id": 4211,
                "name": "Kuzma Municipality",
                "state_code": "056"
            },
            {
                "id": 4338,
                "name": "Laško Municipality",
                "state_code": "057"
            },
            {
                "id": 4142,
                "name": "Lenart Municipality",
                "state_code": "058"
            },
            {
                "id": 4225,
                "name": "Lendava Municipality",
                "state_code": "059"
            },
            {
                "id": 4347,
                "name": "Litija Municipality",
                "state_code": "060"
            },
            {
                "id": 4270,
                "name": "Ljubljana City Municipality",
                "state_code": "061"
            },
            {
                "id": 4294,
                "name": "Ljubno Municipality",
                "state_code": "062"
            },
            {
                "id": 4351,
                "name": "Ljutomer Municipality",
                "state_code": "063"
            },
            {
                "id": 4306,
                "name": "Log–Dragomer Municipality",
                "state_code": "208"
            },
            {
                "id": 4350,
                "name": "Logatec Municipality",
                "state_code": "064"
            },
            {
                "id": 4174,
                "name": "Loška Dolina Municipality",
                "state_code": "065"
            },
            {
                "id": 4158,
                "name": "Loški Potok Municipality",
                "state_code": "066"
            },
            {
                "id": 4156,
                "name": "Lovrenc na Pohorju Municipality",
                "state_code": "167"
            },
            {
                "id": 4219,
                "name": "Luče Municipality",
                "state_code": "067"
            },
            {
                "id": 4302,
                "name": "Lukovica Municipality",
                "state_code": "068"
            },
            {
                "id": 4157,
                "name": "Majšperk Municipality",
                "state_code": "069"
            },
            {
                "id": 4224,
                "name": "Makole Municipality",
                "state_code": "198"
            },
            {
                "id": 4242,
                "name": "Maribor City Municipality",
                "state_code": "070"
            },
            {
                "id": 4244,
                "name": "Markovci Municipality",
                "state_code": "168"
            },
            {
                "id": 4349,
                "name": "Medvode Municipality",
                "state_code": "071"
            },
            {
                "id": 4348,
                "name": "Mengeš Municipality",
                "state_code": "072"
            },
            {
                "id": 4323,
                "name": "Metlika Municipality",
                "state_code": "073"
            },
            {
                "id": 4265,
                "name": "Mežica Municipality",
                "state_code": "074"
            },
            {
                "id": 4223,
                "name": "Miklavž na Dravskem Polju Municipality",
                "state_code": "169"
            },
            {
                "id": 4220,
                "name": "Miren–Kostanjevica Municipality",
                "state_code": "075"
            },
            {
                "id": 4298,
                "name": "Mirna Municipality",
                "state_code": "212"
            },
            {
                "id": 4237,
                "name": "Mirna Peč Municipality",
                "state_code": "170"
            },
            {
                "id": 4212,
                "name": "Mislinja Municipality",
                "state_code": "076"
            },
            {
                "id": 4297,
                "name": "Mokronog–Trebelno Municipality",
                "state_code": "199"
            },
            {
                "id": 4168,
                "name": "Moravče Municipality",
                "state_code": "077"
            },
            {
                "id": 4218,
                "name": "Moravske Toplice Municipality",
                "state_code": "078"
            },
            {
                "id": 4190,
                "name": "Mozirje Municipality",
                "state_code": "079"
            },
            {
                "id": 4318,
                "name": "Municipality of Apače",
                "state_code": "195"
            },
            {
                "id": 4309,
                "name": "Municipality of Cirkulane",
                "state_code": "196"
            },
            {
                "id": 4344,
                "name": "Municipality of Ilirska Bistrica",
                "state_code": "038"
            },
            {
                "id": 4314,
                "name": "Municipality of Krško",
                "state_code": "054"
            },
            {
                "id": 4187,
                "name": "Municipality of Škofljica",
                "state_code": "123"
            },
            {
                "id": 4313,
                "name": "Murska Sobota City Municipality",
                "state_code": "080"
            },
            {
                "id": 4208,
                "name": "Muta Municipality",
                "state_code": "081"
            },
            {
                "id": 4177,
                "name": "Naklo Municipality",
                "state_code": "082"
            },
            {
                "id": 4329,
                "name": "Nazarje Municipality",
                "state_code": "083"
            },
            {
                "id": 4205,
                "name": "Nova Gorica City Municipality",
                "state_code": "084"
            },
            {
                "id": 4320,
                "name": "Odranci Municipality",
                "state_code": "086"
            },
            {
                "id": 4143,
                "name": "Oplotnica",
                "state_code": "171"
            },
            {
                "id": 4221,
                "name": "Ormož Municipality",
                "state_code": "087"
            },
            {
                "id": 4199,
                "name": "Osilnica Municipality",
                "state_code": "088"
            },
            {
                "id": 4172,
                "name": "Pesnica Municipality",
                "state_code": "089"
            },
            {
                "id": 4201,
                "name": "Piran Municipality",
                "state_code": "090"
            },
            {
                "id": 4184,
                "name": "Pivka Municipality",
                "state_code": "091"
            },
            {
                "id": 4146,
                "name": "Podčetrtek Municipality",
                "state_code": "092"
            },
            {
                "id": 4161,
                "name": "Podlehnik Municipality",
                "state_code": "172"
            },
            {
                "id": 4234,
                "name": "Podvelka Municipality",
                "state_code": "093"
            },
            {
                "id": 4239,
                "name": "Poljčane Municipality",
                "state_code": "200"
            },
            {
                "id": 4272,
                "name": "Polzela Municipality",
                "state_code": "173"
            },
            {
                "id": 4330,
                "name": "Postojna Municipality",
                "state_code": "094"
            },
            {
                "id": 4188,
                "name": "Prebold Municipality",
                "state_code": "174"
            },
            {
                "id": 4303,
                "name": "Preddvor Municipality",
                "state_code": "095"
            },
            {
                "id": 4274,
                "name": "Prevalje Municipality",
                "state_code": "175"
            },
            {
                "id": 4228,
                "name": "Ptuj City Municipality",
                "state_code": "096"
            },
            {
                "id": 4288,
                "name": "Puconci Municipality",
                "state_code": "097"
            },
            {
                "id": 4204,
                "name": "Rače–Fram Municipality",
                "state_code": "098"
            },
            {
                "id": 4195,
                "name": "Radeče Municipality",
                "state_code": "099"
            },
            {
                "id": 4292,
                "name": "Radenci Municipality",
                "state_code": "100"
            },
            {
                "id": 4275,
                "name": "Radlje ob Dravi Municipality",
                "state_code": "101"
            },
            {
                "id": 4231,
                "name": "Radovljica Municipality",
                "state_code": "102"
            },
            {
                "id": 4155,
                "name": "Ravne na Koroškem Municipality",
                "state_code": "103"
            },
            {
                "id": 4206,
                "name": "Razkrižje Municipality",
                "state_code": "176"
            },
            {
                "id": 4160,
                "name": "Rečica ob Savinji Municipality",
                "state_code": "209"
            },
            {
                "id": 4253,
                "name": "Renče–Vogrsko Municipality",
                "state_code": "201"
            },
            {
                "id": 4235,
                "name": "Ribnica Municipality",
                "state_code": "104"
            },
            {
                "id": 4207,
                "name": "Ribnica na Pohorju Municipality",
                "state_code": "177"
            },
            {
                "id": 4233,
                "name": "Rogaška Slatina Municipality",
                "state_code": "106"
            },
            {
                "id": 4264,
                "name": "Rogašovci Municipality",
                "state_code": "105"
            },
            {
                "id": 4209,
                "name": "Rogatec Municipality",
                "state_code": "107"
            },
            {
                "id": 4280,
                "name": "Ruše Municipality",
                "state_code": "108"
            },
            {
                "id": 4222,
                "name": "Šalovci Municipality",
                "state_code": "033"
            },
            {
                "id": 4230,
                "name": "Selnica ob Dravi Municipality",
                "state_code": "178"
            },
            {
                "id": 4346,
                "name": "Semič Municipality",
                "state_code": "109"
            },
            {
                "id": 4317,
                "name": "Šempeter–Vrtojba Municipality",
                "state_code": "183"
            },
            {
                "id": 4299,
                "name": "Šenčur Municipality",
                "state_code": "117"
            },
            {
                "id": 4324,
                "name": "Šentilj Municipality",
                "state_code": "118"
            },
            {
                "id": 4241,
                "name": "Šentjernej Municipality",
                "state_code": "119"
            },
            {
                "id": 4171,
                "name": "Šentjur Municipality",
                "state_code": "120"
            },
            {
                "id": 4311,
                "name": "Šentrupert Municipality",
                "state_code": "211"
            },
            {
                "id": 4268,
                "name": "Sevnica Municipality",
                "state_code": "110"
            },
            {
                "id": 4149,
                "name": "Sežana Municipality",
                "state_code": "111"
            },
            {
                "id": 4170,
                "name": "Škocjan Municipality",
                "state_code": "121"
            },
            {
                "id": 4316,
                "name": "Škofja Loka Municipality",
                "state_code": "122"
            },
            {
                "id": 4169,
                "name": "Slovenj Gradec City Municipality",
                "state_code": "112"
            },
            {
                "id": 4332,
                "name": "Slovenska Bistrica Municipality",
                "state_code": "113"
            },
            {
                "id": 4198,
                "name": "Slovenske Konjice Municipality",
                "state_code": "114"
            },
            {
                "id": 4285,
                "name": "Šmarje pri Jelšah Municipality",
                "state_code": "124"
            },
            {
                "id": 4289,
                "name": "Šmarješke Toplice Municipality",
                "state_code": "206"
            },
            {
                "id": 4296,
                "name": "Šmartno ob Paki Municipality",
                "state_code": "125"
            },
            {
                "id": 4279,
                "name": "Šmartno pri Litiji Municipality",
                "state_code": "194"
            },
            {
                "id": 4277,
                "name": "Sodražica Municipality",
                "state_code": "179"
            },
            {
                "id": 4261,
                "name": "Solčava Municipality",
                "state_code": "180"
            },
            {
                "id": 4248,
                "name": "Šoštanj Municipality",
                "state_code": "126"
            },
            {
                "id": 4263,
                "name": "Središče ob Dravi",
                "state_code": "202"
            },
            {
                "id": 4259,
                "name": "Starše Municipality",
                "state_code": "115"
            },
            {
                "id": 4185,
                "name": "Štore Municipality",
                "state_code": "127"
            },
            {
                "id": 4333,
                "name": "Straža Municipality",
                "state_code": "203"
            },
            {
                "id": 4164,
                "name": "Sveta Ana Municipality",
                "state_code": "181"
            },
            {
                "id": 4260,
                "name": "Sveta Trojica v Slovenskih Goricah Municipality",
                "state_code": "204"
            },
            {
                "id": 4229,
                "name": "Sveti Andraž v Slovenskih Goricah Municipality",
                "state_code": "182"
            },
            {
                "id": 4255,
                "name": "Sveti Jurij ob Ščavnici Municipality",
                "state_code": "116"
            },
            {
                "id": 4328,
                "name": "Sveti Jurij v Slovenskih Goricah Municipality",
                "state_code": "210"
            },
            {
                "id": 4273,
                "name": "Sveti Tomaž Municipality",
                "state_code": "205"
            },
            {
                "id": 4194,
                "name": "Tabor Municipality",
                "state_code": "184"
            },
            {
                "id": 4312,
                "name": "Tišina Municipality",
                "state_code": "010"
            },
            {
                "id": 4247,
                "name": "Tolmin Municipality",
                "state_code": "128"
            },
            {
                "id": 4246,
                "name": "Trbovlje Municipality",
                "state_code": "129"
            },
            {
                "id": 4214,
                "name": "Trebnje Municipality",
                "state_code": "130"
            },
            {
                "id": 4153,
                "name": "Trnovska Vas Municipality",
                "state_code": "185"
            },
            {
                "id": 4250,
                "name": "Tržič Municipality",
                "state_code": "131"
            },
            {
                "id": 4334,
                "name": "Trzin Municipality",
                "state_code": "186"
            },
            {
                "id": 4251,
                "name": "Turnišče Municipality",
                "state_code": "132"
            },
            {
                "id": 4267,
                "name": "Velika Polana Municipality",
                "state_code": "187"
            },
            {
                "id": 4144,
                "name": "Velike Lašče Municipality",
                "state_code": "134"
            },
            {
                "id": 4257,
                "name": "Veržej Municipality",
                "state_code": "188"
            },
            {
                "id": 4300,
                "name": "Videm Municipality",
                "state_code": "135"
            },
            {
                "id": 4196,
                "name": "Vipava Municipality",
                "state_code": "136"
            },
            {
                "id": 4148,
                "name": "Vitanje Municipality",
                "state_code": "137"
            },
            {
                "id": 4154,
                "name": "Vodice Municipality",
                "state_code": "138"
            },
            {
                "id": 4245,
                "name": "Vojnik Municipality",
                "state_code": "139"
            },
            {
                "id": 4163,
                "name": "Vransko Municipality",
                "state_code": "189"
            },
            {
                "id": 4262,
                "name": "Vrhnika Municipality",
                "state_code": "140"
            },
            {
                "id": 4226,
                "name": "Vuzenica Municipality",
                "state_code": "141"
            },
            {
                "id": 4269,
                "name": "Zagorje ob Savi Municipality",
                "state_code": "142"
            },
            {
                "id": 4258,
                "name": "Žalec Municipality",
                "state_code": "190"
            },
            {
                "id": 4182,
                "name": "Zavrč Municipality",
                "state_code": "143"
            },
            {
                "id": 4256,
                "name": "Železniki Municipality",
                "state_code": "146"
            },
            {
                "id": 4249,
                "name": "Žetale Municipality",
                "state_code": "191"
            },
            {
                "id": 4192,
                "name": "Žiri Municipality",
                "state_code": "147"
            },
            {
                "id": 4276,
                "name": "Žirovnica Municipality",
                "state_code": "192"
            },
            {
                "id": 4342,
                "name": "Zreče Municipality",
                "state_code": "144"
            },
            {
                "id": 4307,
                "name": "Žužemberk Municipality",
                "state_code": "193"
            }
        ]
    },
    {
        "id": 202,
        "states": [
            {
                "id": 4784,
                "name": "Central Province",
                "state_code": "CE"
            },
            {
                "id": 4781,
                "name": "Choiseul Province",
                "state_code": "CH"
            },
            {
                "id": 4785,
                "name": "Guadalcanal Province",
                "state_code": "GU"
            },
            {
                "id": 4778,
                "name": "Honiara",
                "state_code": "CT"
            },
            {
                "id": 4780,
                "name": "Isabel Province",
                "state_code": "IS"
            },
            {
                "id": 4782,
                "name": "Makira-Ulawa Province",
                "state_code": "MK"
            },
            {
                "id": 4783,
                "name": "Malaita Province",
                "state_code": "ML"
            },
            {
                "id": 4787,
                "name": "Rennell and Bellona Province",
                "state_code": "RB"
            },
            {
                "id": 4779,
                "name": "Temotu Province",
                "state_code": "TE"
            },
            {
                "id": 4786,
                "name": "Western Province",
                "state_code": "WE"
            }
        ]
    },
    {
        "id": 203,
        "states": [
            {
                "id": 925,
                "name": "Awdal Region",
                "state_code": "AW"
            },
            {
                "id": 917,
                "name": "Bakool",
                "state_code": "BK"
            },
            {
                "id": 927,
                "name": "Banaadir",
                "state_code": "BN"
            },
            {
                "id": 930,
                "name": "Bari",
                "state_code": "BR"
            },
            {
                "id": 926,
                "name": "Bay",
                "state_code": "BY"
            },
            {
                "id": 918,
                "name": "Galguduud",
                "state_code": "GA"
            },
            {
                "id": 928,
                "name": "Gedo",
                "state_code": "GE"
            },
            {
                "id": 915,
                "name": "Hiran",
                "state_code": "HI"
            },
            {
                "id": 924,
                "name": "Lower Juba",
                "state_code": "JH"
            },
            {
                "id": 921,
                "name": "Lower Shebelle",
                "state_code": "SH"
            },
            {
                "id": 922,
                "name": "Middle Juba",
                "state_code": "JD"
            },
            {
                "id": 923,
                "name": "Middle Shebelle",
                "state_code": "SD"
            },
            {
                "id": 916,
                "name": "Mudug",
                "state_code": "MU"
            },
            {
                "id": 920,
                "name": "Nugal",
                "state_code": "NU"
            },
            {
                "id": 919,
                "name": "Sanaag Region",
                "state_code": "SA"
            },
            {
                "id": 929,
                "name": "Togdheer Region",
                "state_code": "TO"
            }
        ]
    },
    {
        "id": 204,
        "states": [
            {
                "id": 938,
                "name": "Eastern Cape",
                "state_code": "EC"
            },
            {
                "id": 932,
                "name": "Free State",
                "state_code": "FS"
            },
            {
                "id": 936,
                "name": "Gauteng",
                "state_code": "GP"
            },
            {
                "id": 935,
                "name": "KwaZulu-Natal",
                "state_code": "KZN"
            },
            {
                "id": 933,
                "name": "Limpopo",
                "state_code": "LP"
            },
            {
                "id": 937,
                "name": "Mpumalanga",
                "state_code": "MP"
            },
            {
                "id": 934,
                "name": "North West",
                "state_code": "NW"
            },
            {
                "id": 931,
                "name": "Northern Cape",
                "state_code": "NC"
            },
            {
                "id": 939,
                "name": "Western Cape",
                "state_code": "WC"
            }
        ]
    },
    {
        "id": 205,
        "states": []
    },
    {
        "id": 116,
        "states": [
            {
                "id": 3860,
                "name": "Busan",
                "state_code": "26"
            },
            {
                "id": 3846,
                "name": "Daegu",
                "state_code": "27"
            },
            {
                "id": 3850,
                "name": "Daejeon",
                "state_code": "30"
            },
            {
                "id": 3862,
                "name": "Gangwon Province",
                "state_code": "42"
            },
            {
                "id": 3858,
                "name": "Gwangju",
                "state_code": "29"
            },
            {
                "id": 3847,
                "name": "Gyeonggi Province",
                "state_code": "41"
            },
            {
                "id": 3848,
                "name": "Incheon",
                "state_code": "28"
            },
            {
                "id": 3853,
                "name": "Jeju",
                "state_code": "49"
            },
            {
                "id": 3854,
                "name": "North Chungcheong Province",
                "state_code": "43"
            },
            {
                "id": 3855,
                "name": "North Gyeongsang Province",
                "state_code": "47"
            },
            {
                "id": 3851,
                "name": "North Jeolla Province",
                "state_code": "45"
            },
            {
                "id": 3861,
                "name": "Sejong City",
                "state_code": "50"
            },
            {
                "id": 3849,
                "name": "Seoul",
                "state_code": "11"
            },
            {
                "id": 3859,
                "name": "South Chungcheong Province",
                "state_code": "44"
            },
            {
                "id": 3857,
                "name": "South Gyeongsang Province",
                "state_code": "48"
            },
            {
                "id": 3856,
                "name": "South Jeolla Province",
                "state_code": "46"
            },
            {
                "id": 3852,
                "name": "Ulsan",
                "state_code": "31"
            }
        ]
    },
    {
        "id": 206,
        "states": [
            {
                "id": 2092,
                "name": "Central Equatoria",
                "state_code": "EC"
            },
            {
                "id": 2093,
                "name": "Eastern Equatoria",
                "state_code": "EE"
            },
            {
                "id": 2094,
                "name": "Jonglei State",
                "state_code": "JG"
            },
            {
                "id": 2090,
                "name": "Lakes",
                "state_code": "LK"
            },
            {
                "id": 2088,
                "name": "Northern Bahr el Ghazal",
                "state_code": "BN"
            },
            {
                "id": 2085,
                "name": "Unity",
                "state_code": "UY"
            },
            {
                "id": 2086,
                "name": "Upper Nile",
                "state_code": "NU"
            },
            {
                "id": 2087,
                "name": "Warrap",
                "state_code": "WR"
            },
            {
                "id": 2091,
                "name": "Western Bahr el Ghazal",
                "state_code": "BW"
            },
            {
                "id": 2089,
                "name": "Western Equatoria",
                "state_code": "EW"
            }
        ]
    },
    {
        "id": 207,
        "states": [
            {
                "id": 5089,
                "name": "A Coruña",
                "state_code": "C"
            },
            {
                "id": 5109,
                "name": "Albacete",
                "state_code": "AB"
            },
            {
                "id": 5108,
                "name": "Alicante",
                "state_code": "A"
            },
            {
                "id": 5095,
                "name": "Almeria",
                "state_code": "AL"
            },
            {
                "id": 5093,
                "name": "Araba",
                "state_code": "VI"
            },
            {
                "id": 1160,
                "name": "Asturias",
                "state_code": "O"
            },
            {
                "id": 1189,
                "name": "Ávila",
                "state_code": "AV"
            },
            {
                "id": 5092,
                "name": "Badajoz",
                "state_code": "BA"
            },
            {
                "id": 5102,
                "name": "Barcelona",
                "state_code": "B"
            },
            {
                "id": 5094,
                "name": "Bizkaia",
                "state_code": "BI"
            },
            {
                "id": 1146,
                "name": "Burgos",
                "state_code": "BU"
            },
            {
                "id": 1190,
                "name": "Caceres",
                "state_code": "CC"
            },
            {
                "id": 5096,
                "name": "Cádiz",
                "state_code": "CA"
            },
            {
                "id": 1170,
                "name": "Cantabria",
                "state_code": "S"
            },
            {
                "id": 5110,
                "name": "Castellón",
                "state_code": "CS"
            },
            {
                "id": 5105,
                "name": "Ciudad Real",
                "state_code": "CR"
            },
            {
                "id": 5097,
                "name": "Córdoba",
                "state_code": "CO"
            },
            {
                "id": 5106,
                "name": "Cuenca",
                "state_code": "CU"
            },
            {
                "id": 1191,
                "name": "Gipuzkoa",
                "state_code": "SS"
            },
            {
                "id": 5103,
                "name": "Girona",
                "state_code": "GI"
            },
            {
                "id": 5098,
                "name": "Granada",
                "state_code": "GR"
            },
            {
                "id": 5107,
                "name": "Guadalajara",
                "state_code": "GU"
            },
            {
                "id": 5099,
                "name": "Huelva",
                "state_code": "H"
            },
            {
                "id": 1177,
                "name": "Huesca",
                "state_code": "HU"
            },
            {
                "id": 1174,
                "name": "Islas Baleares",
                "state_code": "PM"
            },
            {
                "id": 5100,
                "name": "Jaén",
                "state_code": "J"
            },
            {
                "id": 1171,
                "name": "La Rioja",
                "state_code": "LO"
            },
            {
                "id": 1185,
                "name": "Las Palmas",
                "state_code": "GC"
            },
            {
                "id": 1200,
                "name": "Léon",
                "state_code": "LE"
            },
            {
                "id": 5104,
                "name": "Lleida",
                "state_code": "L"
            },
            {
                "id": 5090,
                "name": "Lugo",
                "state_code": "LU"
            },
            {
                "id": 1158,
                "name": "Madrid",
                "state_code": "M"
            },
            {
                "id": 5101,
                "name": "Málaga",
                "state_code": "MA"
            },
            {
                "id": 1176,
                "name": "Murcia",
                "state_code": "MU"
            },
            {
                "id": 1204,
                "name": "Navarra",
                "state_code": "NA"
            },
            {
                "id": 5091,
                "name": "Ourense",
                "state_code": "OR"
            },
            {
                "id": 1157,
                "name": "Palencia",
                "state_code": "P"
            },
            {
                "id": 1167,
                "name": "Pontevedra",
                "state_code": "PO"
            },
            {
                "id": 1147,
                "name": "Salamanca",
                "state_code": "SA"
            },
            {
                "id": 5112,
                "name": "Santa Cruz de Tenerife",
                "state_code": "TF"
            },
            {
                "id": 1192,
                "name": "Segovia",
                "state_code": "SG"
            },
            {
                "id": 1193,
                "name": "Sevilla",
                "state_code": "SE"
            },
            {
                "id": 1208,
                "name": "Soria",
                "state_code": "SO"
            },
            {
                "id": 1203,
                "name": "Tarragona",
                "state_code": "T"
            },
            {
                "id": 5111,
                "name": "Teruel",
                "state_code": "TE"
            },
            {
                "id": 1205,
                "name": "Toledo",
                "state_code": "TO"
            },
            {
                "id": 1175,
                "name": "Valencia",
                "state_code": "V"
            },
            {
                "id": 1183,
                "name": "Valladolid",
                "state_code": "VA"
            },
            {
                "id": 1161,
                "name": "Zamora",
                "state_code": "ZA"
            },
            {
                "id": 5113,
                "name": "Zaragoza",
                "state_code": "Z"
            }
        ]
    },
    {
        "id": 208,
        "states": [
            {
                "id": 2799,
                "name": "Ampara District",
                "state_code": "52"
            },
            {
                "id": 2816,
                "name": "Anuradhapura District",
                "state_code": "71"
            },
            {
                "id": 2790,
                "name": "Badulla District",
                "state_code": "81"
            },
            {
                "id": 2818,
                "name": "Batticaloa District",
                "state_code": "51"
            },
            {
                "id": 2798,
                "name": "Central Province",
                "state_code": "2"
            },
            {
                "id": 2815,
                "name": "Colombo District",
                "state_code": "11"
            },
            {
                "id": 2808,
                "name": "Eastern Province",
                "state_code": "5"
            },
            {
                "id": 2792,
                "name": "Galle District",
                "state_code": "31"
            },
            {
                "id": 2804,
                "name": "Gampaha District",
                "state_code": "12"
            },
            {
                "id": 2791,
                "name": "Hambantota District",
                "state_code": "33"
            },
            {
                "id": 2787,
                "name": "Jaffna District",
                "state_code": "41"
            },
            {
                "id": 2789,
                "name": "Kalutara District",
                "state_code": "13"
            },
            {
                "id": 2788,
                "name": "Kandy District",
                "state_code": "21"
            },
            {
                "id": 2797,
                "name": "Kegalle District",
                "state_code": "92"
            },
            {
                "id": 2793,
                "name": "Kilinochchi District",
                "state_code": "42"
            },
            {
                "id": 2805,
                "name": "Mannar District",
                "state_code": "43"
            },
            {
                "id": 2810,
                "name": "Matale District",
                "state_code": "22"
            },
            {
                "id": 2806,
                "name": "Matara District",
                "state_code": "32"
            },
            {
                "id": 2819,
                "name": "Monaragala District",
                "state_code": "82"
            },
            {
                "id": 2814,
                "name": "Mullaitivu District",
                "state_code": "45"
            },
            {
                "id": 2800,
                "name": "North Central Province",
                "state_code": "7"
            },
            {
                "id": 2817,
                "name": "North Western Province",
                "state_code": "6"
            },
            {
                "id": 2813,
                "name": "Northern Province",
                "state_code": "4"
            },
            {
                "id": 2794,
                "name": "Nuwara Eliya District",
                "state_code": "23"
            },
            {
                "id": 2812,
                "name": "Polonnaruwa District",
                "state_code": "72"
            },
            {
                "id": 2796,
                "name": "Puttalam District",
                "state_code": "62"
            },
            {
                "id": 2807,
                "name": "Ratnapura district",
                "state_code": "91"
            },
            {
                "id": 2803,
                "name": "Sabaragamuwa Province",
                "state_code": "9"
            },
            {
                "id": 2801,
                "name": "Southern Province",
                "state_code": "3"
            },
            {
                "id": 2795,
                "name": "Trincomalee District",
                "state_code": "53"
            },
            {
                "id": 2811,
                "name": "Uva Province",
                "state_code": "8"
            },
            {
                "id": 2809,
                "name": "Vavuniya District",
                "state_code": "44"
            },
            {
                "id": 2802,
                "name": "Western Province",
                "state_code": "1"
            }
        ]
    },
    {
        "id": 209,
        "states": [
            {
                "id": 885,
                "name": "Al Jazirah",
                "state_code": "GZ"
            },
            {
                "id": 886,
                "name": "Al Qadarif",
                "state_code": "GD"
            },
            {
                "id": 887,
                "name": "Blue Nile",
                "state_code": "NB"
            },
            {
                "id": 896,
                "name": "Central Darfur",
                "state_code": "DC"
            },
            {
                "id": 892,
                "name": "East Darfur",
                "state_code": "DE"
            },
            {
                "id": 884,
                "name": "Kassala",
                "state_code": "KA"
            },
            {
                "id": 881,
                "name": "Khartoum",
                "state_code": "KH"
            },
            {
                "id": 890,
                "name": "North Darfur",
                "state_code": "DN"
            },
            {
                "id": 893,
                "name": "North Kordofan",
                "state_code": "KN"
            },
            {
                "id": 895,
                "name": "Northern",
                "state_code": "NO"
            },
            {
                "id": 880,
                "name": "Red Sea",
                "state_code": "RS"
            },
            {
                "id": 891,
                "name": "River Nile",
                "state_code": "NR"
            },
            {
                "id": 882,
                "name": "Sennar",
                "state_code": "SI"
            },
            {
                "id": 894,
                "name": "South Darfur",
                "state_code": "DS"
            },
            {
                "id": 883,
                "name": "South Kordofan",
                "state_code": "KS"
            },
            {
                "id": 888,
                "name": "West Darfur",
                "state_code": "DW"
            },
            {
                "id": 889,
                "name": "West Kordofan",
                "state_code": "GK"
            },
            {
                "id": 879,
                "name": "White Nile",
                "state_code": "NW"
            }
        ]
    },
    {
        "id": 210,
        "states": [
            {
                "id": 2846,
                "name": "Brokopondo District",
                "state_code": "BR"
            },
            {
                "id": 2839,
                "name": "Commewijne District",
                "state_code": "CM"
            },
            {
                "id": 2842,
                "name": "Coronie District",
                "state_code": "CR"
            },
            {
                "id": 2845,
                "name": "Marowijne District",
                "state_code": "MA"
            },
            {
                "id": 2840,
                "name": "Nickerie District",
                "state_code": "NI"
            },
            {
                "id": 2841,
                "name": "Para District",
                "state_code": "PR"
            },
            {
                "id": 2843,
                "name": "Paramaribo District",
                "state_code": "PM"
            },
            {
                "id": 2848,
                "name": "Saramacca District",
                "state_code": "SA"
            },
            {
                "id": 2847,
                "name": "Sipaliwini District",
                "state_code": "SI"
            },
            {
                "id": 2844,
                "name": "Wanica District",
                "state_code": "WA"
            }
        ]
    },
    {
        "id": 211,
        "states": []
    },
    {
        "id": 212,
        "states": [
            {
                "id": 969,
                "name": "Hhohho District",
                "state_code": "HH"
            },
            {
                "id": 970,
                "name": "Lubombo District",
                "state_code": "LU"
            },
            {
                "id": 968,
                "name": "Manzini District",
                "state_code": "MA"
            },
            {
                "id": 971,
                "name": "Shiselweni District",
                "state_code": "SH"
            }
        ]
    },
    {
        "id": 213,
        "states": [
            {
                "id": 1537,
                "name": "Blekinge",
                "state_code": "K"
            },
            {
                "id": 1534,
                "name": "Dalarna County",
                "state_code": "W"
            },
            {
                "id": 1533,
                "name": "Gävleborg County",
                "state_code": "X"
            },
            {
                "id": 1546,
                "name": "Gotland County",
                "state_code": "I"
            },
            {
                "id": 1548,
                "name": "Halland County",
                "state_code": "N"
            },
            {
                "id": 1550,
                "name": "Jönköping County",
                "state_code": "F"
            },
            {
                "id": 1544,
                "name": "Kalmar County",
                "state_code": "H"
            },
            {
                "id": 1542,
                "name": "Kronoberg County",
                "state_code": "G"
            },
            {
                "id": 1538,
                "name": "Norrbotten County",
                "state_code": "BD"
            },
            {
                "id": 1539,
                "name": "Örebro County",
                "state_code": "T"
            },
            {
                "id": 1536,
                "name": "Östergötland County",
                "state_code": "E"
            },
            {
                "id": 1541,
                "name": "Skåne County",
                "state_code": "M"
            },
            {
                "id": 1540,
                "name": "Södermanland County",
                "state_code": "D"
            },
            {
                "id": 1551,
                "name": "Stockholm County",
                "state_code": "AB"
            },
            {
                "id": 1545,
                "name": "Uppsala County",
                "state_code": "C"
            },
            {
                "id": 1535,
                "name": "Värmland County",
                "state_code": "S"
            },
            {
                "id": 1543,
                "name": "Västerbotten County",
                "state_code": "AC"
            },
            {
                "id": 1552,
                "name": "Västernorrland County",
                "state_code": "Y"
            },
            {
                "id": 1549,
                "name": "Västmanland County",
                "state_code": "U"
            },
            {
                "id": 1547,
                "name": "Västra Götaland County",
                "state_code": "O"
            }
        ]
    },
    {
        "id": 214,
        "states": [
            {
                "id": 1639,
                "name": "Aargau",
                "state_code": "AG"
            },
            {
                "id": 1655,
                "name": "Appenzell Ausserrhoden",
                "state_code": "AR"
            },
            {
                "id": 1649,
                "name": "Appenzell Innerrhoden",
                "state_code": "AI"
            },
            {
                "id": 1641,
                "name": "Basel-Land",
                "state_code": "BL"
            },
            {
                "id": 4957,
                "name": "Basel-Stadt",
                "state_code": "BS"
            },
            {
                "id": 1645,
                "name": "Bern",
                "state_code": "BE"
            },
            {
                "id": 1640,
                "name": "Fribourg",
                "state_code": "FR"
            },
            {
                "id": 1647,
                "name": "Geneva",
                "state_code": "GE"
            },
            {
                "id": 1661,
                "name": "Glarus",
                "state_code": "GL"
            },
            {
                "id": 1660,
                "name": "Graubünden",
                "state_code": "GR"
            },
            {
                "id": 1658,
                "name": "Jura",
                "state_code": "JU"
            },
            {
                "id": 1663,
                "name": "Lucerne",
                "state_code": "LU"
            },
            {
                "id": 1659,
                "name": "Neuchâtel",
                "state_code": "NE"
            },
            {
                "id": 1652,
                "name": "Nidwalden",
                "state_code": "NW"
            },
            {
                "id": 1650,
                "name": "Obwalden",
                "state_code": "OW"
            },
            {
                "id": 1654,
                "name": "Schaffhausen",
                "state_code": "SH"
            },
            {
                "id": 1653,
                "name": "Schwyz",
                "state_code": "SZ"
            },
            {
                "id": 1662,
                "name": "Solothurn",
                "state_code": "SO"
            },
            {
                "id": 1644,
                "name": "St. Gallen",
                "state_code": "SG"
            },
            {
                "id": 1657,
                "name": "Thurgau",
                "state_code": "TG"
            },
            {
                "id": 1643,
                "name": "Ticino",
                "state_code": "TI"
            },
            {
                "id": 1642,
                "name": "Uri",
                "state_code": "UR"
            },
            {
                "id": 1648,
                "name": "Valais",
                "state_code": "VS"
            },
            {
                "id": 1651,
                "name": "Vaud",
                "state_code": "VD"
            },
            {
                "id": 1646,
                "name": "Zug",
                "state_code": "ZG"
            },
            {
                "id": 1656,
                "name": "Zürich",
                "state_code": "ZH"
            }
        ]
    },
    {
        "id": 215,
        "states": [
            {
                "id": 2941,
                "name": "Al-Hasakah",
                "state_code": "HA"
            },
            {
                "id": 2944,
                "name": "Al-Raqqah",
                "state_code": "RA"
            },
            {
                "id": 2946,
                "name": "Aleppo",
                "state_code": "HL"
            },
            {
                "id": 2936,
                "name": "As-Suwayda",
                "state_code": "SU"
            },
            {
                "id": 2939,
                "name": "Damascus",
                "state_code": "DI"
            },
            {
                "id": 2945,
                "name": "Daraa",
                "state_code": "DR"
            },
            {
                "id": 2937,
                "name": "Deir ez-Zor",
                "state_code": "DY"
            },
            {
                "id": 2934,
                "name": "Hama",
                "state_code": "HM"
            },
            {
                "id": 2942,
                "name": "Homs",
                "state_code": "HI"
            },
            {
                "id": 2940,
                "name": "Idlib",
                "state_code": "ID"
            },
            {
                "id": 2938,
                "name": "Latakia",
                "state_code": "LA"
            },
            {
                "id": 2943,
                "name": "Quneitra",
                "state_code": "QU"
            },
            {
                "id": 2935,
                "name": "Rif Dimashq",
                "state_code": "RD"
            },
            {
                "id": 2947,
                "name": "Tartus",
                "state_code": "TA"
            }
        ]
    },
    {
        "id": 216,
        "states": [
            {
                "id": 3404,
                "name": "Changhua",
                "state_code": "CHA"
            },
            {
                "id": 3408,
                "name": "Chiayi",
                "state_code": "CYI"
            },
            {
                "id": 3418,
                "name": "Chiayi",
                "state_code": "CYQ"
            },
            {
                "id": 3423,
                "name": "Hsinchu",
                "state_code": "HSQ"
            },
            {
                "id": 3417,
                "name": "Hsinchu",
                "state_code": "HSZ"
            },
            {
                "id": 3411,
                "name": "Hualien",
                "state_code": "HUA"
            },
            {
                "id": 3412,
                "name": "Kaohsiung",
                "state_code": "KHH"
            },
            {
                "id": 4965,
                "name": "Keelung",
                "state_code": "KEE"
            },
            {
                "id": 3415,
                "name": "Kinmen",
                "state_code": "KIN"
            },
            {
                "id": 3420,
                "name": "Lienchiang",
                "state_code": "LIE"
            },
            {
                "id": 3413,
                "name": "Miaoli",
                "state_code": "MIA"
            },
            {
                "id": 3407,
                "name": "Nantou",
                "state_code": "NAN"
            },
            {
                "id": 4966,
                "name": "New Taipei",
                "state_code": "NWT"
            },
            {
                "id": 3403,
                "name": "Penghu",
                "state_code": "PEN"
            },
            {
                "id": 3405,
                "name": "Pingtung",
                "state_code": "PIF"
            },
            {
                "id": 3406,
                "name": "Taichung",
                "state_code": "TXG"
            },
            {
                "id": 3421,
                "name": "Tainan",
                "state_code": "TNN"
            },
            {
                "id": 3422,
                "name": "Taipei",
                "state_code": "TPE"
            },
            {
                "id": 3410,
                "name": "Taitung",
                "state_code": "TTT"
            },
            {
                "id": 3419,
                "name": "Taoyuan",
                "state_code": "TAO"
            },
            {
                "id": 3402,
                "name": "Yilan",
                "state_code": "ILA"
            },
            {
                "id": 3416,
                "name": "Yunlin",
                "state_code": "YUN"
            }
        ]
    },
    {
        "id": 217,
        "states": [
            {
                "id": 3397,
                "name": "districts of Republican Subordination",
                "state_code": "RA"
            },
            {
                "id": 3399,
                "name": "Gorno-Badakhshan Autonomous Province",
                "state_code": "GB"
            },
            {
                "id": 3398,
                "name": "Khatlon Province",
                "state_code": "KT"
            },
            {
                "id": 3400,
                "name": "Sughd Province",
                "state_code": "SU"
            }
        ]
    },
    {
        "id": 218,
        "states": [
            {
                "id": 1491,
                "name": "Arusha",
                "state_code": "01"
            },
            {
                "id": 1490,
                "name": "Dar es Salaam",
                "state_code": "02"
            },
            {
                "id": 1466,
                "name": "Dodoma",
                "state_code": "03"
            },
            {
                "id": 1481,
                "name": "Geita",
                "state_code": "27"
            },
            {
                "id": 1489,
                "name": "Iringa",
                "state_code": "04"
            },
            {
                "id": 1465,
                "name": "Kagera",
                "state_code": "05"
            },
            {
                "id": 1482,
                "name": "Katavi",
                "state_code": "28"
            },
            {
                "id": 1478,
                "name": "Kigoma",
                "state_code": "08"
            },
            {
                "id": 1467,
                "name": "Kilimanjaro",
                "state_code": "09"
            },
            {
                "id": 1483,
                "name": "Lindi",
                "state_code": "12"
            },
            {
                "id": 1484,
                "name": "Manyara",
                "state_code": "26"
            },
            {
                "id": 1468,
                "name": "Mara",
                "state_code": "13"
            },
            {
                "id": 4955,
                "name": "Mbeya",
                "state_code": "14"
            },
            {
                "id": 1470,
                "name": "Morogoro",
                "state_code": "16"
            },
            {
                "id": 1476,
                "name": "Mtwara",
                "state_code": "17"
            },
            {
                "id": 1479,
                "name": "Mwanza",
                "state_code": "18"
            },
            {
                "id": 1480,
                "name": "Njombe",
                "state_code": "29"
            },
            {
                "id": 1488,
                "name": "Pemba North",
                "state_code": "06"
            },
            {
                "id": 1472,
                "name": "Pemba South",
                "state_code": "10"
            },
            {
                "id": 1485,
                "name": "Pwani",
                "state_code": "19"
            },
            {
                "id": 1477,
                "name": "Rukwa",
                "state_code": "20"
            },
            {
                "id": 1486,
                "name": "Ruvuma",
                "state_code": "21"
            },
            {
                "id": 1463,
                "name": "Shinyanga",
                "state_code": "22"
            },
            {
                "id": 1464,
                "name": "Simiyu",
                "state_code": "30"
            },
            {
                "id": 1474,
                "name": "Singida",
                "state_code": "23"
            },
            {
                "id": 4956,
                "name": "Songwe",
                "state_code": "31"
            },
            {
                "id": 1469,
                "name": "Tabora",
                "state_code": "24"
            },
            {
                "id": 1487,
                "name": "Tanga",
                "state_code": "25"
            },
            {
                "id": 1473,
                "name": "Zanzibar North",
                "state_code": "07"
            },
            {
                "id": 1471,
                "name": "Zanzibar South",
                "state_code": "11"
            },
            {
                "id": 1475,
                "name": "Zanzibar West",
                "state_code": "15"
            }
        ]
    },
    {
        "id": 219,
        "states": [
            {
                "id": 3523,
                "name": "Amnat Charoen",
                "state_code": "37"
            },
            {
                "id": 3519,
                "name": "Ang Thong",
                "state_code": "15"
            },
            {
                "id": 3554,
                "name": "Bangkok",
                "state_code": "10"
            },
            {
                "id": 3533,
                "name": "Bueng Kan",
                "state_code": "38"
            },
            {
                "id": 3534,
                "name": "Buri Ram",
                "state_code": "31"
            },
            {
                "id": 3552,
                "name": "Chachoengsao",
                "state_code": "24"
            },
            {
                "id": 3522,
                "name": "Chai Nat",
                "state_code": "18"
            },
            {
                "id": 4954,
                "name": "Chaiyaphum",
                "state_code": "36"
            },
            {
                "id": 3486,
                "name": "Chanthaburi",
                "state_code": "22"
            },
            {
                "id": 3491,
                "name": "Chiang Mai",
                "state_code": "50"
            },
            {
                "id": 3498,
                "name": "Chiang Rai",
                "state_code": "57"
            },
            {
                "id": 3513,
                "name": "Chon Buri",
                "state_code": "20"
            },
            {
                "id": 3526,
                "name": "Chumphon",
                "state_code": "86"
            },
            {
                "id": 3550,
                "name": "Kalasin",
                "state_code": "46"
            },
            {
                "id": 3516,
                "name": "Kamphaeng Phet",
                "state_code": "62"
            },
            {
                "id": 3511,
                "name": "Kanchanaburi",
                "state_code": "71"
            },
            {
                "id": 3485,
                "name": "Khon Kaen",
                "state_code": "40"
            },
            {
                "id": 3478,
                "name": "Krabi",
                "state_code": "81"
            },
            {
                "id": 3544,
                "name": "Lampang",
                "state_code": "52"
            },
            {
                "id": 3483,
                "name": "Lamphun",
                "state_code": "51"
            },
            {
                "id": 3509,
                "name": "Loei",
                "state_code": "42"
            },
            {
                "id": 3543,
                "name": "Lop Buri",
                "state_code": "16"
            },
            {
                "id": 3505,
                "name": "Mae Hong Son",
                "state_code": "58"
            },
            {
                "id": 3517,
                "name": "Maha Sarakham",
                "state_code": "44"
            },
            {
                "id": 3546,
                "name": "Mukdahan",
                "state_code": "49"
            },
            {
                "id": 3535,
                "name": "Nakhon Nayok",
                "state_code": "26"
            },
            {
                "id": 3503,
                "name": "Nakhon Pathom",
                "state_code": "73"
            },
            {
                "id": 3548,
                "name": "Nakhon Phanom",
                "state_code": "48"
            },
            {
                "id": 3497,
                "name": "Nakhon Ratchasima",
                "state_code": "30"
            },
            {
                "id": 3492,
                "name": "Nakhon Sawan",
                "state_code": "60"
            },
            {
                "id": 3520,
                "name": "Nakhon Si Thammarat",
                "state_code": "80"
            },
            {
                "id": 3530,
                "name": "Nan",
                "state_code": "55"
            },
            {
                "id": 3553,
                "name": "Narathiwat",
                "state_code": "96"
            },
            {
                "id": 3480,
                "name": "Nong Bua Lam Phu",
                "state_code": "39"
            },
            {
                "id": 3484,
                "name": "Nong Khai",
                "state_code": "43"
            },
            {
                "id": 3495,
                "name": "Nonthaburi",
                "state_code": "12"
            },
            {
                "id": 3500,
                "name": "Pathum Thani",
                "state_code": "13"
            },
            {
                "id": 3540,
                "name": "Pattani",
                "state_code": "94"
            },
            {
                "id": 3507,
                "name": "Pattaya",
                "state_code": "S"
            },
            {
                "id": 3549,
                "name": "Phangnga",
                "state_code": "82"
            },
            {
                "id": 3488,
                "name": "Phatthalung",
                "state_code": "93"
            },
            {
                "id": 3538,
                "name": "Phayao",
                "state_code": "56"
            },
            {
                "id": 3515,
                "name": "Phetchabun",
                "state_code": "67"
            },
            {
                "id": 3532,
                "name": "Phetchaburi",
                "state_code": "76"
            },
            {
                "id": 3514,
                "name": "Phichit",
                "state_code": "66"
            },
            {
                "id": 3506,
                "name": "Phitsanulok",
                "state_code": "65"
            },
            {
                "id": 3494,
                "name": "Phra Nakhon Si Ayutthaya",
                "state_code": "14"
            },
            {
                "id": 3528,
                "name": "Phrae",
                "state_code": "54"
            },
            {
                "id": 3536,
                "name": "Phuket",
                "state_code": "83"
            },
            {
                "id": 3542,
                "name": "Prachin Buri",
                "state_code": "25"
            },
            {
                "id": 3508,
                "name": "Prachuap Khiri Khan",
                "state_code": "77"
            },
            {
                "id": 3479,
                "name": "Ranong",
                "state_code": "85"
            },
            {
                "id": 3499,
                "name": "Ratchaburi",
                "state_code": "70"
            },
            {
                "id": 3518,
                "name": "Rayong",
                "state_code": "21"
            },
            {
                "id": 3510,
                "name": "Roi Et",
                "state_code": "45"
            },
            {
                "id": 3529,
                "name": "Sa Kaeo",
                "state_code": "27"
            },
            {
                "id": 3501,
                "name": "Sakon Nakhon",
                "state_code": "47"
            },
            {
                "id": 3481,
                "name": "Samut Prakan",
                "state_code": "11"
            },
            {
                "id": 3504,
                "name": "Samut Sakhon",
                "state_code": "74"
            },
            {
                "id": 3502,
                "name": "Samut Songkhram",
                "state_code": "75"
            },
            {
                "id": 3487,
                "name": "Saraburi",
                "state_code": "19"
            },
            {
                "id": 3537,
                "name": "Satun",
                "state_code": "91"
            },
            {
                "id": 3547,
                "name": "Si Sa Ket",
                "state_code": "33"
            },
            {
                "id": 3490,
                "name": "Sing Buri",
                "state_code": "17"
            },
            {
                "id": 3539,
                "name": "Songkhla",
                "state_code": "90"
            },
            {
                "id": 3545,
                "name": "Sukhothai",
                "state_code": "64"
            },
            {
                "id": 3524,
                "name": "Suphan Buri",
                "state_code": "72"
            },
            {
                "id": 3482,
                "name": "Surat Thani",
                "state_code": "84"
            },
            {
                "id": 3531,
                "name": "Surin",
                "state_code": "32"
            },
            {
                "id": 3525,
                "name": "Tak",
                "state_code": "63"
            },
            {
                "id": 3541,
                "name": "Trang",
                "state_code": "92"
            },
            {
                "id": 3496,
                "name": "Trat",
                "state_code": "23"
            },
            {
                "id": 3512,
                "name": "Ubon Ratchathani",
                "state_code": "34"
            },
            {
                "id": 3527,
                "name": "Udon Thani",
                "state_code": "41"
            },
            {
                "id": 3551,
                "name": "Uthai Thani",
                "state_code": "61"
            },
            {
                "id": 3489,
                "name": "Uttaradit",
                "state_code": "53"
            },
            {
                "id": 3493,
                "name": "Yala",
                "state_code": "95"
            },
            {
                "id": 3521,
                "name": "Yasothon",
                "state_code": "35"
            }
        ]
    },
    {
        "id": 17,
        "states": [
            {
                "id": 3601,
                "name": "Acklins",
                "state_code": "AK"
            },
            {
                "id": 3628,
                "name": "Acklins and Crooked Islands",
                "state_code": "AC"
            },
            {
                "id": 3593,
                "name": "Berry Islands",
                "state_code": "BY"
            },
            {
                "id": 3629,
                "name": "Bimini",
                "state_code": "BI"
            },
            {
                "id": 3605,
                "name": "Black Point",
                "state_code": "BP"
            },
            {
                "id": 3611,
                "name": "Cat Island",
                "state_code": "CI"
            },
            {
                "id": 3603,
                "name": "Central Abaco",
                "state_code": "CO"
            },
            {
                "id": 3631,
                "name": "Central Andros",
                "state_code": "CS"
            },
            {
                "id": 3596,
                "name": "Central Eleuthera",
                "state_code": "CE"
            },
            {
                "id": 3621,
                "name": "Crooked Island",
                "state_code": "CK"
            },
            {
                "id": 3614,
                "name": "East Grand Bahama",
                "state_code": "EG"
            },
            {
                "id": 3612,
                "name": "Exuma",
                "state_code": "EX"
            },
            {
                "id": 3626,
                "name": "Freeport",
                "state_code": "FP"
            },
            {
                "id": 3619,
                "name": "Fresh Creek",
                "state_code": "FC"
            },
            {
                "id": 3597,
                "name": "Governor's Harbour",
                "state_code": "GH"
            },
            {
                "id": 3632,
                "name": "Grand Cay",
                "state_code": "GC"
            },
            {
                "id": 3595,
                "name": "Green Turtle Cay",
                "state_code": "GT"
            },
            {
                "id": 3613,
                "name": "Harbour Island",
                "state_code": "HI"
            },
            {
                "id": 3598,
                "name": "High Rock",
                "state_code": "HR"
            },
            {
                "id": 3624,
                "name": "Hope Town",
                "state_code": "HT"
            },
            {
                "id": 3609,
                "name": "Inagua",
                "state_code": "IN"
            },
            {
                "id": 3618,
                "name": "Kemps Bay",
                "state_code": "KB"
            },
            {
                "id": 3610,
                "name": "Long Island",
                "state_code": "LI"
            },
            {
                "id": 3625,
                "name": "Mangrove Cay",
                "state_code": "MC"
            },
            {
                "id": 3604,
                "name": "Marsh Harbour",
                "state_code": "MH"
            },
            {
                "id": 3633,
                "name": "Mayaguana District",
                "state_code": "MG"
            },
            {
                "id": 4881,
                "name": "New Providence",
                "state_code": "NP"
            },
            {
                "id": 3594,
                "name": "Nichollstown and Berry Islands",
                "state_code": "NB"
            },
            {
                "id": 3616,
                "name": "North Abaco",
                "state_code": "NO"
            },
            {
                "id": 3617,
                "name": "North Andros",
                "state_code": "NS"
            },
            {
                "id": 3602,
                "name": "North Eleuthera",
                "state_code": "NE"
            },
            {
                "id": 3615,
                "name": "Ragged Island",
                "state_code": "RI"
            },
            {
                "id": 3623,
                "name": "Rock Sound",
                "state_code": "RS"
            },
            {
                "id": 3600,
                "name": "Rum Cay District",
                "state_code": "RC"
            },
            {
                "id": 3620,
                "name": "San Salvador and Rum Cay",
                "state_code": "SR"
            },
            {
                "id": 3627,
                "name": "San Salvador Island",
                "state_code": "SS"
            },
            {
                "id": 3606,
                "name": "Sandy Point",
                "state_code": "SP"
            },
            {
                "id": 3608,
                "name": "South Abaco",
                "state_code": "SO"
            },
            {
                "id": 3622,
                "name": "South Andros",
                "state_code": "SA"
            },
            {
                "id": 3607,
                "name": "South Eleuthera",
                "state_code": "SE"
            },
            {
                "id": 3630,
                "name": "Spanish Wells",
                "state_code": "SW"
            },
            {
                "id": 3599,
                "name": "West Grand Bahama",
                "state_code": "WG"
            }
        ]
    },
    {
        "id": 220,
        "states": [
            {
                "id": 2575,
                "name": "Centrale Region",
                "state_code": "C"
            },
            {
                "id": 2579,
                "name": "Kara Region",
                "state_code": "K"
            },
            {
                "id": 2576,
                "name": "Maritime",
                "state_code": "M"
            },
            {
                "id": 2577,
                "name": "Plateaux Region",
                "state_code": "P"
            },
            {
                "id": 2578,
                "name": "Savanes Region",
                "state_code": "S"
            }
        ]
    },
    {
        "id": 221,
        "states": []
    },
    {
        "id": 222,
        "states": [
            {
                "id": 3913,
                "name": "Haʻapai",
                "state_code": "02"
            },
            {
                "id": 3915,
                "name": "ʻEua",
                "state_code": "01"
            },
            {
                "id": 3914,
                "name": "Niuas",
                "state_code": "03"
            },
            {
                "id": 3912,
                "name": "Tongatapu",
                "state_code": "04"
            },
            {
                "id": 3911,
                "name": "Vavaʻu",
                "state_code": "05"
            }
        ]
    },
    {
        "id": 223,
        "states": [
            {
                "id": 3362,
                "name": "Arima",
                "state_code": "ARI"
            },
            {
                "id": 3366,
                "name": "Chaguanas",
                "state_code": "CHA"
            },
            {
                "id": 3354,
                "name": "Couva-Tabaquite-Talparo Regional Corporation",
                "state_code": "CTT"
            },
            {
                "id": 3367,
                "name": "Diego Martin Regional Corporation",
                "state_code": "DMN"
            },
            {
                "id": 3355,
                "name": "Eastern Tobago",
                "state_code": "ETO"
            },
            {
                "id": 3365,
                "name": "Penal-Debe Regional Corporation",
                "state_code": "PED"
            },
            {
                "id": 3360,
                "name": "Point Fortin",
                "state_code": "PTF"
            },
            {
                "id": 3363,
                "name": "Port of Spain",
                "state_code": "POS"
            },
            {
                "id": 3368,
                "name": "Princes Town Regional Corporation",
                "state_code": "PRT"
            },
            {
                "id": 3356,
                "name": "Rio Claro-Mayaro Regional Corporation",
                "state_code": "MRC"
            },
            {
                "id": 3359,
                "name": "San Fernando",
                "state_code": "SFO"
            },
            {
                "id": 3357,
                "name": "San Juan-Laventille Regional Corporation",
                "state_code": "SJL"
            },
            {
                "id": 3361,
                "name": "Sangre Grande Regional Corporation",
                "state_code": "SGE"
            },
            {
                "id": 3364,
                "name": "Siparia Regional Corporation",
                "state_code": "SIP"
            },
            {
                "id": 3358,
                "name": "Tunapuna-Piarco Regional Corporation",
                "state_code": "TUP"
            },
            {
                "id": 3353,
                "name": "Western Tobago",
                "state_code": "WTO"
            }
        ]
    },
    {
        "id": 224,
        "states": [
            {
                "id": 2550,
                "name": "Ariana",
                "state_code": "12"
            },
            {
                "id": 2566,
                "name": "Ben Arous",
                "state_code": "13"
            },
            {
                "id": 2551,
                "name": "Bizerte",
                "state_code": "23"
            },
            {
                "id": 2558,
                "name": "Gabès",
                "state_code": "81"
            },
            {
                "id": 2556,
                "name": "Gafsa",
                "state_code": "71"
            },
            {
                "id": 2552,
                "name": "Jendouba",
                "state_code": "32"
            },
            {
                "id": 2564,
                "name": "Kairouan",
                "state_code": "41"
            },
            {
                "id": 2570,
                "name": "Kasserine",
                "state_code": "42"
            },
            {
                "id": 2572,
                "name": "Kassrine",
                "state_code": "31"
            },
            {
                "id": 2562,
                "name": "Kebili",
                "state_code": "73"
            },
            {
                "id": 2561,
                "name": "Kef",
                "state_code": "33"
            },
            {
                "id": 2568,
                "name": "Mahdia",
                "state_code": "53"
            },
            {
                "id": 2555,
                "name": "Manouba",
                "state_code": "14"
            },
            {
                "id": 2560,
                "name": "Medenine",
                "state_code": "82"
            },
            {
                "id": 2553,
                "name": "Monastir",
                "state_code": "52"
            },
            {
                "id": 5116,
                "name": "Nabeul",
                "state_code": "21"
            },
            {
                "id": 2557,
                "name": "Sfax",
                "state_code": "61"
            },
            {
                "id": 2567,
                "name": "Sidi Bouzid",
                "state_code": "43"
            },
            {
                "id": 2563,
                "name": "Siliana",
                "state_code": "34"
            },
            {
                "id": 2571,
                "name": "Sousse",
                "state_code": "51"
            },
            {
                "id": 2559,
                "name": "Tataouine",
                "state_code": "83"
            },
            {
                "id": 2569,
                "name": "Tozeur",
                "state_code": "72"
            },
            {
                "id": 2554,
                "name": "Tunis",
                "state_code": "11"
            },
            {
                "id": 2565,
                "name": "Zaghouan",
                "state_code": "22"
            }
        ]
    },
    {
        "id": 225,
        "states": [
            {
                "id": 2212,
                "name": "Adana",
                "state_code": "01"
            },
            {
                "id": 2155,
                "name": "Adıyaman",
                "state_code": "02"
            },
            {
                "id": 2179,
                "name": "Afyonkarahisar",
                "state_code": "03"
            },
            {
                "id": 2193,
                "name": "Ağrı",
                "state_code": "04"
            },
            {
                "id": 2210,
                "name": "Aksaray",
                "state_code": "68"
            },
            {
                "id": 2161,
                "name": "Amasya",
                "state_code": "05"
            },
            {
                "id": 2217,
                "name": "Ankara",
                "state_code": "06"
            },
            {
                "id": 2169,
                "name": "Antalya",
                "state_code": "07"
            },
            {
                "id": 2185,
                "name": "Ardahan",
                "state_code": "75"
            },
            {
                "id": 2191,
                "name": "Artvin",
                "state_code": "08"
            },
            {
                "id": 2187,
                "name": "Aydın",
                "state_code": "09"
            },
            {
                "id": 2175,
                "name": "Balıkesir",
                "state_code": "10"
            },
            {
                "id": 2148,
                "name": "Bartın",
                "state_code": "74"
            },
            {
                "id": 2194,
                "name": "Batman",
                "state_code": "72"
            },
            {
                "id": 2177,
                "name": "Bayburt",
                "state_code": "69"
            },
            {
                "id": 2221,
                "name": "Bilecik",
                "state_code": "11"
            },
            {
                "id": 2153,
                "name": "Bingöl",
                "state_code": "12"
            },
            {
                "id": 2215,
                "name": "Bitlis",
                "state_code": "13"
            },
            {
                "id": 2172,
                "name": "Bolu",
                "state_code": "14"
            },
            {
                "id": 2209,
                "name": "Burdur",
                "state_code": "15"
            },
            {
                "id": 2163,
                "name": "Bursa",
                "state_code": "16"
            },
            {
                "id": 2216,
                "name": "Çanakkale",
                "state_code": "17"
            },
            {
                "id": 2168,
                "name": "Çankırı",
                "state_code": "18"
            },
            {
                "id": 2173,
                "name": "Çorum",
                "state_code": "19"
            },
            {
                "id": 2157,
                "name": "Denizli",
                "state_code": "20"
            },
            {
                "id": 2226,
                "name": "Diyarbakır",
                "state_code": "21"
            },
            {
                "id": 2202,
                "name": "Düzce",
                "state_code": "81"
            },
            {
                "id": 2151,
                "name": "Edirne",
                "state_code": "22"
            },
            {
                "id": 2159,
                "name": "Elazığ",
                "state_code": "23"
            },
            {
                "id": 2160,
                "name": "Erzincan",
                "state_code": "24"
            },
            {
                "id": 2165,
                "name": "Erzurum",
                "state_code": "25"
            },
            {
                "id": 2164,
                "name": "Eskişehir",
                "state_code": "26"
            },
            {
                "id": 2203,
                "name": "Gaziantep",
                "state_code": "27"
            },
            {
                "id": 2186,
                "name": "Giresun",
                "state_code": "28"
            },
            {
                "id": 2204,
                "name": "Gümüşhane",
                "state_code": "29"
            },
            {
                "id": 2190,
                "name": "Hakkâri",
                "state_code": "30"
            },
            {
                "id": 2211,
                "name": "Hatay",
                "state_code": "31"
            },
            {
                "id": 2166,
                "name": "Iğdır",
                "state_code": "76"
            },
            {
                "id": 2222,
                "name": "Isparta",
                "state_code": "32"
            },
            {
                "id": 2170,
                "name": "İstanbul",
                "state_code": "34"
            },
            {
                "id": 2205,
                "name": "İzmir",
                "state_code": "35"
            },
            {
                "id": 2227,
                "name": "Kahramanmaraş",
                "state_code": "46"
            },
            {
                "id": 2223,
                "name": "Karabük",
                "state_code": "78"
            },
            {
                "id": 2184,
                "name": "Karaman",
                "state_code": "70"
            },
            {
                "id": 2208,
                "name": "Kars",
                "state_code": "36"
            },
            {
                "id": 2197,
                "name": "Kastamonu",
                "state_code": "37"
            },
            {
                "id": 2200,
                "name": "Kayseri",
                "state_code": "38"
            },
            {
                "id": 2154,
                "name": "Kilis",
                "state_code": "79"
            },
            {
                "id": 2178,
                "name": "Kırıkkale",
                "state_code": "71"
            },
            {
                "id": 2176,
                "name": "Kırklareli",
                "state_code": "39"
            },
            {
                "id": 2180,
                "name": "Kırşehir",
                "state_code": "40"
            },
            {
                "id": 2195,
                "name": "Kocaeli",
                "state_code": "41"
            },
            {
                "id": 2171,
                "name": "Konya",
                "state_code": "42"
            },
            {
                "id": 2149,
                "name": "Kütahya",
                "state_code": "43"
            },
            {
                "id": 2158,
                "name": "Malatya",
                "state_code": "44"
            },
            {
                "id": 2198,
                "name": "Manisa",
                "state_code": "45"
            },
            {
                "id": 2224,
                "name": "Mardin",
                "state_code": "47"
            },
            {
                "id": 2156,
                "name": "Mersin",
                "state_code": "33"
            },
            {
                "id": 2182,
                "name": "Muğla",
                "state_code": "48"
            },
            {
                "id": 2162,
                "name": "Muş",
                "state_code": "49"
            },
            {
                "id": 2196,
                "name": "Nevşehir",
                "state_code": "50"
            },
            {
                "id": 2189,
                "name": "Niğde",
                "state_code": "51"
            },
            {
                "id": 2174,
                "name": "Ordu",
                "state_code": "52"
            },
            {
                "id": 2214,
                "name": "Osmaniye",
                "state_code": "80"
            },
            {
                "id": 2219,
                "name": "Rize",
                "state_code": "53"
            },
            {
                "id": 2150,
                "name": "Sakarya",
                "state_code": "54"
            },
            {
                "id": 2220,
                "name": "Samsun",
                "state_code": "55"
            },
            {
                "id": 2183,
                "name": "Şanlıurfa",
                "state_code": "63"
            },
            {
                "id": 2207,
                "name": "Siirt",
                "state_code": "56"
            },
            {
                "id": 4854,
                "name": "Sinop",
                "state_code": "57"
            },
            {
                "id": 2181,
                "name": "Sivas",
                "state_code": "58"
            },
            {
                "id": 2225,
                "name": "Şırnak",
                "state_code": "73"
            },
            {
                "id": 2167,
                "name": "Tekirdağ",
                "state_code": "59"
            },
            {
                "id": 2199,
                "name": "Tokat",
                "state_code": "60"
            },
            {
                "id": 2206,
                "name": "Trabzon",
                "state_code": "61"
            },
            {
                "id": 2192,
                "name": "Tunceli",
                "state_code": "62"
            },
            {
                "id": 2201,
                "name": "Uşak",
                "state_code": "64"
            },
            {
                "id": 2152,
                "name": "Van",
                "state_code": "65"
            },
            {
                "id": 2218,
                "name": "Yalova",
                "state_code": "77"
            },
            {
                "id": 2188,
                "name": "Yozgat",
                "state_code": "66"
            },
            {
                "id": 2213,
                "name": "Zonguldak",
                "state_code": "67"
            }
        ]
    },
    {
        "id": 226,
        "states": [
            {
                "id": 3374,
                "name": "Ahal Region",
                "state_code": "A"
            },
            {
                "id": 3371,
                "name": "Ashgabat",
                "state_code": "S"
            },
            {
                "id": 3372,
                "name": "Balkan Region",
                "state_code": "B"
            },
            {
                "id": 3373,
                "name": "Daşoguz Region",
                "state_code": "D"
            },
            {
                "id": 3370,
                "name": "Lebap Region",
                "state_code": "L"
            },
            {
                "id": 3369,
                "name": "Mary Region",
                "state_code": "M"
            }
        ]
    },
    {
        "id": 227,
        "states": []
    },
    {
        "id": 228,
        "states": [
            {
                "id": 3951,
                "name": "Funafuti",
                "state_code": "FUN"
            },
            {
                "id": 3947,
                "name": "Nanumanga",
                "state_code": "NMG"
            },
            {
                "id": 3949,
                "name": "Nanumea",
                "state_code": "NMA"
            },
            {
                "id": 3946,
                "name": "Niutao Island Council",
                "state_code": "NIT"
            },
            {
                "id": 3948,
                "name": "Nui",
                "state_code": "NUI"
            },
            {
                "id": 3952,
                "name": "Nukufetau",
                "state_code": "NKF"
            },
            {
                "id": 3953,
                "name": "Nukulaelae",
                "state_code": "NKL"
            },
            {
                "id": 3950,
                "name": "Vaitupu",
                "state_code": "VAI"
            }
        ]
    },
    {
        "id": 229,
        "states": [
            {
                "id": 329,
                "name": "Abim District",
                "state_code": "314"
            },
            {
                "id": 361,
                "name": "Adjumani District",
                "state_code": "301"
            },
            {
                "id": 392,
                "name": "Agago District",
                "state_code": "322"
            },
            {
                "id": 344,
                "name": "Alebtong District",
                "state_code": "323"
            },
            {
                "id": 416,
                "name": "Amolatar District",
                "state_code": "315"
            },
            {
                "id": 353,
                "name": "Amudat District",
                "state_code": "324"
            },
            {
                "id": 352,
                "name": "Amuria District",
                "state_code": "216"
            },
            {
                "id": 335,
                "name": "Amuru District",
                "state_code": "316"
            },
            {
                "id": 328,
                "name": "Apac District",
                "state_code": "302"
            },
            {
                "id": 447,
                "name": "Arua District",
                "state_code": "303"
            },
            {
                "id": 441,
                "name": "Budaka District",
                "state_code": "217"
            },
            {
                "id": 349,
                "name": "Bududa District",
                "state_code": "218"
            },
            {
                "id": 387,
                "name": "Bugiri District",
                "state_code": "201"
            },
            {
                "id": 391,
                "name": "Buhweju District",
                "state_code": "420"
            },
            {
                "id": 377,
                "name": "Buikwe District",
                "state_code": "117"
            },
            {
                "id": 343,
                "name": "Bukedea District",
                "state_code": "219"
            },
            {
                "id": 375,
                "name": "Bukomansimbi District",
                "state_code": "118"
            },
            {
                "id": 385,
                "name": "Bukwo District",
                "state_code": "220"
            },
            {
                "id": 428,
                "name": "Bulambuli District",
                "state_code": "225"
            },
            {
                "id": 389,
                "name": "Buliisa District",
                "state_code": "416"
            },
            {
                "id": 419,
                "name": "Bundibugyo District",
                "state_code": "401"
            },
            {
                "id": 381,
                "name": "Bunyangabu District",
                "state_code": "430"
            },
            {
                "id": 386,
                "name": "Bushenyi District",
                "state_code": "402"
            },
            {
                "id": 431,
                "name": "Busia District",
                "state_code": "202"
            },
            {
                "id": 365,
                "name": "Butaleja District",
                "state_code": "221"
            },
            {
                "id": 384,
                "name": "Butambala District",
                "state_code": "119"
            },
            {
                "id": 388,
                "name": "Butebo District",
                "state_code": "233"
            },
            {
                "id": 414,
                "name": "Buvuma District",
                "state_code": "120"
            },
            {
                "id": 380,
                "name": "Buyende District",
                "state_code": "226"
            },
            {
                "id": 396,
                "name": "Central Region",
                "state_code": "C"
            },
            {
                "id": 341,
                "name": "Dokolo District",
                "state_code": "317"
            },
            {
                "id": 372,
                "name": "Eastern Region",
                "state_code": "E"
            },
            {
                "id": 366,
                "name": "Gomba District",
                "state_code": "121"
            },
            {
                "id": 413,
                "name": "Gulu District",
                "state_code": "304"
            },
            {
                "id": 339,
                "name": "Ibanda District",
                "state_code": "417"
            },
            {
                "id": 340,
                "name": "Iganga District",
                "state_code": "203"
            },
            {
                "id": 383,
                "name": "Isingiro District",
                "state_code": "418"
            },
            {
                "id": 367,
                "name": "Jinja District",
                "state_code": "204"
            },
            {
                "id": 434,
                "name": "Kaabong District",
                "state_code": "318"
            },
            {
                "id": 426,
                "name": "Kabale District",
                "state_code": "404"
            },
            {
                "id": 326,
                "name": "Kabarole District",
                "state_code": "405"
            },
            {
                "id": 336,
                "name": "Kaberamaido District",
                "state_code": "213"
            },
            {
                "id": 403,
                "name": "Kagadi District",
                "state_code": "427"
            },
            {
                "id": 399,
                "name": "Kakumiro District",
                "state_code": "428"
            },
            {
                "id": 405,
                "name": "Kalangala District",
                "state_code": "101"
            },
            {
                "id": 398,
                "name": "Kaliro District",
                "state_code": "222"
            },
            {
                "id": 394,
                "name": "Kalungu District",
                "state_code": "122"
            },
            {
                "id": 382,
                "name": "Kampala District",
                "state_code": "102"
            },
            {
                "id": 334,
                "name": "Kamuli District",
                "state_code": "205"
            },
            {
                "id": 360,
                "name": "Kamwenge District",
                "state_code": "413"
            },
            {
                "id": 373,
                "name": "Kanungu District",
                "state_code": "414"
            },
            {
                "id": 432,
                "name": "Kapchorwa District",
                "state_code": "206"
            },
            {
                "id": 440,
                "name": "Kasese District",
                "state_code": "406"
            },
            {
                "id": 420,
                "name": "Katakwi District",
                "state_code": "207"
            },
            {
                "id": 368,
                "name": "Kayunga District",
                "state_code": "112"
            },
            {
                "id": 436,
                "name": "Kibaale District",
                "state_code": "407"
            },
            {
                "id": 347,
                "name": "Kiboga District",
                "state_code": "103"
            },
            {
                "id": 338,
                "name": "Kibuku District",
                "state_code": "227"
            },
            {
                "id": 355,
                "name": "Kiruhura District",
                "state_code": "419"
            },
            {
                "id": 346,
                "name": "Kiryandongo District",
                "state_code": "421"
            },
            {
                "id": 409,
                "name": "Kisoro District",
                "state_code": "408"
            },
            {
                "id": 348,
                "name": "Kitgum District",
                "state_code": "305"
            },
            {
                "id": 345,
                "name": "Koboko District",
                "state_code": "319"
            },
            {
                "id": 401,
                "name": "Kole District",
                "state_code": "325"
            },
            {
                "id": 443,
                "name": "Kotido District",
                "state_code": "306"
            },
            {
                "id": 425,
                "name": "Kumi District",
                "state_code": "208"
            },
            {
                "id": 369,
                "name": "Kween District",
                "state_code": "228"
            },
            {
                "id": 325,
                "name": "Kyankwanzi District",
                "state_code": "123"
            },
            {
                "id": 437,
                "name": "Kyegegwa District",
                "state_code": "422"
            },
            {
                "id": 402,
                "name": "Kyenjojo District",
                "state_code": "415"
            },
            {
                "id": 448,
                "name": "Kyotera District",
                "state_code": "125"
            },
            {
                "id": 411,
                "name": "Lamwo District",
                "state_code": "326"
            },
            {
                "id": 342,
                "name": "Lira District",
                "state_code": "307"
            },
            {
                "id": 445,
                "name": "Luuka District",
                "state_code": "229"
            },
            {
                "id": 433,
                "name": "Luwero District",
                "state_code": "104"
            },
            {
                "id": 417,
                "name": "Lwengo District",
                "state_code": "124"
            },
            {
                "id": 376,
                "name": "Lyantonde District",
                "state_code": "114"
            },
            {
                "id": 438,
                "name": "Manafwa District",
                "state_code": "223"
            },
            {
                "id": 421,
                "name": "Maracha District",
                "state_code": "320"
            },
            {
                "id": 356,
                "name": "Masaka District",
                "state_code": "105"
            },
            {
                "id": 354,
                "name": "Masindi District",
                "state_code": "409"
            },
            {
                "id": 418,
                "name": "Mayuge District",
                "state_code": "214"
            },
            {
                "id": 350,
                "name": "Mbale District",
                "state_code": "209"
            },
            {
                "id": 415,
                "name": "Mbarara District",
                "state_code": "410"
            },
            {
                "id": 435,
                "name": "Mitooma District",
                "state_code": "423"
            },
            {
                "id": 364,
                "name": "Mityana District",
                "state_code": "115"
            },
            {
                "id": 395,
                "name": "Moroto District",
                "state_code": "308"
            },
            {
                "id": 363,
                "name": "Moyo District",
                "state_code": "309"
            },
            {
                "id": 327,
                "name": "Mpigi District",
                "state_code": "106"
            },
            {
                "id": 371,
                "name": "Mubende District",
                "state_code": "107"
            },
            {
                "id": 410,
                "name": "Mukono District",
                "state_code": "108"
            },
            {
                "id": 393,
                "name": "Nakapiripirit District",
                "state_code": "311"
            },
            {
                "id": 423,
                "name": "Nakaseke District",
                "state_code": "116"
            },
            {
                "id": 406,
                "name": "Nakasongola District",
                "state_code": "109"
            },
            {
                "id": 351,
                "name": "Namayingo District",
                "state_code": "230"
            },
            {
                "id": 400,
                "name": "Namisindwa District",
                "state_code": "234"
            },
            {
                "id": 337,
                "name": "Namutumba District",
                "state_code": "224"
            },
            {
                "id": 430,
                "name": "Napak District",
                "state_code": "327"
            },
            {
                "id": 446,
                "name": "Nebbi District",
                "state_code": "310"
            },
            {
                "id": 424,
                "name": "Ngora District",
                "state_code": "231"
            },
            {
                "id": 332,
                "name": "Northern Region",
                "state_code": "N"
            },
            {
                "id": 422,
                "name": "Ntoroko District",
                "state_code": "424"
            },
            {
                "id": 404,
                "name": "Ntungamo District",
                "state_code": "411"
            },
            {
                "id": 378,
                "name": "Nwoya District",
                "state_code": "328"
            },
            {
                "id": 374,
                "name": "Omoro District",
                "state_code": "331"
            },
            {
                "id": 390,
                "name": "Otuke District",
                "state_code": "329"
            },
            {
                "id": 397,
                "name": "Oyam District",
                "state_code": "321"
            },
            {
                "id": 408,
                "name": "Pader District",
                "state_code": "312"
            },
            {
                "id": 357,
                "name": "Pakwach District",
                "state_code": "332"
            },
            {
                "id": 412,
                "name": "Pallisa District",
                "state_code": "210"
            },
            {
                "id": 439,
                "name": "Rakai District",
                "state_code": "110"
            },
            {
                "id": 358,
                "name": "Rubanda District",
                "state_code": "429"
            },
            {
                "id": 442,
                "name": "Rubirizi District",
                "state_code": "425"
            },
            {
                "id": 331,
                "name": "Rukiga District",
                "state_code": "431"
            },
            {
                "id": 324,
                "name": "Rukungiri District",
                "state_code": "412"
            },
            {
                "id": 427,
                "name": "Sembabule District",
                "state_code": "111"
            },
            {
                "id": 333,
                "name": "Serere District",
                "state_code": "232"
            },
            {
                "id": 407,
                "name": "Sheema District",
                "state_code": "426"
            },
            {
                "id": 429,
                "name": "Sironko District",
                "state_code": "215"
            },
            {
                "id": 444,
                "name": "Soroti District",
                "state_code": "211"
            },
            {
                "id": 359,
                "name": "Tororo District",
                "state_code": "212"
            },
            {
                "id": 362,
                "name": "Wakiso District",
                "state_code": "113"
            },
            {
                "id": 370,
                "name": "Western Region",
                "state_code": "W"
            },
            {
                "id": 330,
                "name": "Yumbe District",
                "state_code": "313"
            },
            {
                "id": 379,
                "name": "Zombo District",
                "state_code": "330"
            }
        ]
    },
    {
        "id": 230,
        "states": [
            {
                "id": 4689,
                "name": "Autonomous Republic of Crimea",
                "state_code": "43"
            },
            {
                "id": 4680,
                "name": "Cherkaska oblast",
                "state_code": "71"
            },
            {
                "id": 4692,
                "name": "Chernihivska oblast",
                "state_code": "74"
            },
            {
                "id": 4678,
                "name": "Chernivetska oblast",
                "state_code": "77"
            },
            {
                "id": 4675,
                "name": "Dnipropetrovska oblast",
                "state_code": "12"
            },
            {
                "id": 4691,
                "name": "Donetska oblast",
                "state_code": "14"
            },
            {
                "id": 4682,
                "name": "Ivano-Frankivska oblast",
                "state_code": "26"
            },
            {
                "id": 4686,
                "name": "Kharkivska oblast",
                "state_code": "63"
            },
            {
                "id": 4684,
                "name": "Khersonska oblast",
                "state_code": "65"
            },
            {
                "id": 4681,
                "name": "Khmelnytska oblast",
                "state_code": "68"
            },
            {
                "id": 4677,
                "name": "Kirovohradska oblast",
                "state_code": "35"
            },
            {
                "id": 4676,
                "name": "Kyiv",
                "state_code": "30"
            },
            {
                "id": 4671,
                "name": "Kyivska oblast",
                "state_code": "32"
            },
            {
                "id": 4673,
                "name": "Luhanska oblast",
                "state_code": "09"
            },
            {
                "id": 4672,
                "name": "Lvivska oblast",
                "state_code": "46"
            },
            {
                "id": 4679,
                "name": "Mykolaivska oblast",
                "state_code": "48"
            },
            {
                "id": 4688,
                "name": "Odeska oblast",
                "state_code": "51"
            },
            {
                "id": 5071,
                "name": "Poltavska oblast",
                "state_code": "53"
            },
            {
                "id": 4683,
                "name": "Rivnenska oblast",
                "state_code": "56"
            },
            {
                "id": 4685,
                "name": "Sumska oblast",
                "state_code": "59"
            },
            {
                "id": 4674,
                "name": "Ternopilska oblast",
                "state_code": "61"
            },
            {
                "id": 4669,
                "name": "Vinnytska oblast",
                "state_code": "05"
            },
            {
                "id": 4690,
                "name": "Volynska oblast",
                "state_code": "07"
            },
            {
                "id": 4670,
                "name": "Zakarpatska Oblast",
                "state_code": "21"
            },
            {
                "id": 4687,
                "name": "Zaporizka oblast",
                "state_code": "23"
            },
            {
                "id": 4668,
                "name": "Zhytomyrska oblast",
                "state_code": "18"
            }
        ]
    },
    {
        "id": 231,
        "states": [
            {
                "id": 3396,
                "name": "Abu Dhabi Emirate",
                "state_code": "AZ"
            },
            {
                "id": 3395,
                "name": "Ajman Emirate",
                "state_code": "AJ"
            },
            {
                "id": 3391,
                "name": "Dubai",
                "state_code": "DU"
            },
            {
                "id": 3393,
                "name": "Fujairah",
                "state_code": "FU"
            },
            {
                "id": 3394,
                "name": "Ras al-Khaimah",
                "state_code": "RK"
            },
            {
                "id": 3390,
                "name": "Sharjah Emirate",
                "state_code": "SH"
            },
            {
                "id": 3392,
                "name": "Umm al-Quwain",
                "state_code": "UQ"
            }
        ]
    },
    {
        "id": 232,
        "states": [
            {
                "id": 2463,
                "name": "Aberdeen",
                "state_code": "ABE"
            },
            {
                "id": 2401,
                "name": "Aberdeenshire",
                "state_code": "ABD"
            },
            {
                "id": 2387,
                "name": "Angus",
                "state_code": "ANS"
            },
            {
                "id": 2533,
                "name": "Antrim",
                "state_code": "ANT"
            },
            {
                "id": 2412,
                "name": "Antrim and Newtownabbey",
                "state_code": "ANN"
            },
            {
                "id": 2498,
                "name": "Ards",
                "state_code": "ARD"
            },
            {
                "id": 2523,
                "name": "Ards and North Down",
                "state_code": "AND"
            },
            {
                "id": 2392,
                "name": "Argyll and Bute",
                "state_code": "AGB"
            },
            {
                "id": 2331,
                "name": "Armagh City and District Council",
                "state_code": "ARM"
            },
            {
                "id": 2324,
                "name": "Armagh, Banbridge and Craigavon",
                "state_code": "ABC"
            },
            {
                "id": 2378,
                "name": "Ascension Island",
                "state_code": "SH-AC"
            },
            {
                "id": 2363,
                "name": "Ballymena Borough",
                "state_code": "BLA"
            },
            {
                "id": 2361,
                "name": "Ballymoney",
                "state_code": "BLY"
            },
            {
                "id": 2315,
                "name": "Banbridge",
                "state_code": "BNB"
            },
            {
                "id": 2499,
                "name": "Barnsley",
                "state_code": "BNS"
            },
            {
                "id": 2339,
                "name": "Bath and North East Somerset",
                "state_code": "BAS"
            },
            {
                "id": 2507,
                "name": "Bedford",
                "state_code": "BDF"
            },
            {
                "id": 2311,
                "name": "Belfast district",
                "state_code": "BFS"
            },
            {
                "id": 2425,
                "name": "Birmingham",
                "state_code": "BIR"
            },
            {
                "id": 2329,
                "name": "Blackburn with Darwen",
                "state_code": "BBD"
            },
            {
                "id": 2451,
                "name": "Blackpool",
                "state_code": "BPL"
            },
            {
                "id": 2530,
                "name": "Blaenau Gwent County Borough",
                "state_code": "BGW"
            },
            {
                "id": 2504,
                "name": "Bolton",
                "state_code": "BOL"
            },
            {
                "id": 2342,
                "name": "Bournemouth",
                "state_code": "BMH"
            },
            {
                "id": 2470,
                "name": "Bracknell Forest",
                "state_code": "BRC"
            },
            {
                "id": 2529,
                "name": "Bradford",
                "state_code": "BRD"
            },
            {
                "id": 2452,
                "name": "Bridgend County Borough",
                "state_code": "BGE"
            },
            {
                "id": 2395,
                "name": "Brighton and Hove",
                "state_code": "BNH"
            },
            {
                "id": 2405,
                "name": "Buckinghamshire",
                "state_code": "BKM"
            },
            {
                "id": 2459,
                "name": "Bury",
                "state_code": "BUR"
            },
            {
                "id": 2298,
                "name": "Caerphilly County Borough",
                "state_code": "CAY"
            },
            {
                "id": 2517,
                "name": "Calderdale",
                "state_code": "CLD"
            },
            {
                "id": 2423,
                "name": "Cambridgeshire",
                "state_code": "CAM"
            },
            {
                "id": 2484,
                "name": "Carmarthenshire",
                "state_code": "CMN"
            },
            {
                "id": 2439,
                "name": "Carrickfergus Borough Council",
                "state_code": "CKF"
            },
            {
                "id": 2525,
                "name": "Castlereagh",
                "state_code": "CSR"
            },
            {
                "id": 2316,
                "name": "Causeway Coast and Glens",
                "state_code": "CCG"
            },
            {
                "id": 2303,
                "name": "Central Bedfordshire",
                "state_code": "CBF"
            },
            {
                "id": 2509,
                "name": "Ceredigion",
                "state_code": "CGN"
            },
            {
                "id": 2444,
                "name": "Cheshire East",
                "state_code": "CHE"
            },
            {
                "id": 2442,
                "name": "Cheshire West and Chester",
                "state_code": "CHW"
            },
            {
                "id": 2528,
                "name": "City and County of Cardiff",
                "state_code": "CRF"
            },
            {
                "id": 2433,
                "name": "City and County of Swansea",
                "state_code": "SWA"
            },
            {
                "id": 2413,
                "name": "City of Bristol",
                "state_code": "BST"
            },
            {
                "id": 2485,
                "name": "City of Derby",
                "state_code": "DER"
            },
            {
                "id": 2475,
                "name": "City of Kingston upon Hull",
                "state_code": "KHL"
            },
            {
                "id": 2318,
                "name": "City of Leicester",
                "state_code": "LCE"
            },
            {
                "id": 2424,
                "name": "City of London",
                "state_code": "LND"
            },
            {
                "id": 2359,
                "name": "City of Nottingham",
                "state_code": "NGM"
            },
            {
                "id": 2297,
                "name": "City of Peterborough",
                "state_code": "PTE"
            },
            {
                "id": 2514,
                "name": "City of Plymouth",
                "state_code": "PLY"
            },
            {
                "id": 2305,
                "name": "City of Portsmouth",
                "state_code": "POR"
            },
            {
                "id": 2294,
                "name": "City of Southampton",
                "state_code": "STH"
            },
            {
                "id": 2506,
                "name": "City of Stoke-on-Trent",
                "state_code": "STE"
            },
            {
                "id": 2372,
                "name": "City of Sunderland",
                "state_code": "SND"
            },
            {
                "id": 2357,
                "name": "City of Westminster",
                "state_code": "WSM"
            },
            {
                "id": 2489,
                "name": "City of Wolverhampton",
                "state_code": "WLV"
            },
            {
                "id": 2426,
                "name": "City of York",
                "state_code": "YOR"
            },
            {
                "id": 2450,
                "name": "Clackmannanshire",
                "state_code": "CLK"
            },
            {
                "id": 2461,
                "name": "Coleraine Borough Council",
                "state_code": "CLR"
            },
            {
                "id": 2352,
                "name": "Conwy County Borough",
                "state_code": "CWY"
            },
            {
                "id": 2445,
                "name": "Cookstown District Council",
                "state_code": "CKT"
            },
            {
                "id": 2312,
                "name": "Cornwall",
                "state_code": "CON"
            },
            {
                "id": 2406,
                "name": "County Durham",
                "state_code": "DUR"
            },
            {
                "id": 2438,
                "name": "Coventry",
                "state_code": "COV"
            },
            {
                "id": 2449,
                "name": "Craigavon Borough Council",
                "state_code": "CGV"
            },
            {
                "id": 2334,
                "name": "Cumbria",
                "state_code": "CMA"
            },
            {
                "id": 2389,
                "name": "Darlington",
                "state_code": "DAL"
            },
            {
                "id": 2497,
                "name": "Denbighshire",
                "state_code": "DEN"
            },
            {
                "id": 2403,
                "name": "Derbyshire",
                "state_code": "DBY"
            },
            {
                "id": 2446,
                "name": "Derry City and Strabane",
                "state_code": "DRS"
            },
            {
                "id": 2417,
                "name": "Derry City Council",
                "state_code": "DRY"
            },
            {
                "id": 2491,
                "name": "Devon",
                "state_code": "DEV"
            },
            {
                "id": 2364,
                "name": "Doncaster",
                "state_code": "DNC"
            },
            {
                "id": 2345,
                "name": "Dorset",
                "state_code": "DOR"
            },
            {
                "id": 2304,
                "name": "Down District Council",
                "state_code": "DOW"
            },
            {
                "id": 2457,
                "name": "Dudley",
                "state_code": "DUD"
            },
            {
                "id": 2415,
                "name": "Dumfries and Galloway",
                "state_code": "DGY"
            },
            {
                "id": 2511,
                "name": "Dundee",
                "state_code": "DND"
            },
            {
                "id": 2508,
                "name": "Dungannon and South Tyrone Borough Council",
                "state_code": "DGN"
            },
            {
                "id": 2374,
                "name": "East Ayrshire",
                "state_code": "EAY"
            },
            {
                "id": 2454,
                "name": "East Dunbartonshire",
                "state_code": "EDU"
            },
            {
                "id": 2462,
                "name": "East Lothian",
                "state_code": "ELN"
            },
            {
                "id": 2333,
                "name": "East Renfrewshire",
                "state_code": "ERW"
            },
            {
                "id": 2370,
                "name": "East Riding of Yorkshire",
                "state_code": "ERY"
            },
            {
                "id": 2414,
                "name": "East Sussex",
                "state_code": "ESX"
            },
            {
                "id": 2428,
                "name": "Edinburgh",
                "state_code": "EDH"
            },
            {
                "id": 2336,
                "name": "England",
                "state_code": "ENG"
            },
            {
                "id": 2410,
                "name": "Essex",
                "state_code": "ESS"
            },
            {
                "id": 2344,
                "name": "Falkirk",
                "state_code": "FAL"
            },
            {
                "id": 2366,
                "name": "Fermanagh and Omagh",
                "state_code": "FMO"
            },
            {
                "id": 2531,
                "name": "Fermanagh District Council",
                "state_code": "FER"
            },
            {
                "id": 2479,
                "name": "Fife",
                "state_code": "FIF"
            },
            {
                "id": 2437,
                "name": "Flintshire",
                "state_code": "FLN"
            },
            {
                "id": 2431,
                "name": "Gateshead",
                "state_code": "GAT"
            },
            {
                "id": 2404,
                "name": "Glasgow",
                "state_code": "GLG"
            },
            {
                "id": 2373,
                "name": "Gloucestershire",
                "state_code": "GLS"
            },
            {
                "id": 2379,
                "name": "Gwynedd",
                "state_code": "GWN"
            },
            {
                "id": 2466,
                "name": "Halton",
                "state_code": "HAL"
            },
            {
                "id": 2435,
                "name": "Hampshire",
                "state_code": "HAM"
            },
            {
                "id": 2309,
                "name": "Hartlepool",
                "state_code": "HPL"
            },
            {
                "id": 2500,
                "name": "Herefordshire",
                "state_code": "HEF"
            },
            {
                "id": 2369,
                "name": "Hertfordshire",
                "state_code": "HRT"
            },
            {
                "id": 2383,
                "name": "Highland",
                "state_code": "HLD"
            },
            {
                "id": 2388,
                "name": "Inverclyde",
                "state_code": "IVC"
            },
            {
                "id": 2289,
                "name": "Isle of Wight",
                "state_code": "IOW"
            },
            {
                "id": 2343,
                "name": "Isles of Scilly",
                "state_code": "IOS"
            },
            {
                "id": 2464,
                "name": "Kent",
                "state_code": "KEN"
            },
            {
                "id": 2371,
                "name": "Kirklees",
                "state_code": "KIR"
            },
            {
                "id": 2330,
                "name": "Knowsley",
                "state_code": "KWL"
            },
            {
                "id": 2495,
                "name": "Lancashire",
                "state_code": "LAN"
            },
            {
                "id": 2515,
                "name": "Larne Borough Council",
                "state_code": "LRN"
            },
            {
                "id": 2503,
                "name": "Leeds",
                "state_code": "LDS"
            },
            {
                "id": 2516,
                "name": "Leicestershire",
                "state_code": "LEC"
            },
            {
                "id": 2382,
                "name": "Limavady Borough Council",
                "state_code": "LMV"
            },
            {
                "id": 2355,
                "name": "Lincolnshire",
                "state_code": "LIN"
            },
            {
                "id": 2460,
                "name": "Lisburn and Castlereagh",
                "state_code": "LBC"
            },
            {
                "id": 2494,
                "name": "Lisburn City Council",
                "state_code": "LSB"
            },
            {
                "id": 2340,
                "name": "Liverpool",
                "state_code": "LIV"
            },
            {
                "id": 2356,
                "name": "London Borough of Barking and Dagenham",
                "state_code": "BDG"
            },
            {
                "id": 2520,
                "name": "London Borough of Barnet",
                "state_code": "BNE"
            },
            {
                "id": 2307,
                "name": "London Borough of Bexley",
                "state_code": "BEX"
            },
            {
                "id": 2291,
                "name": "London Borough of Brent",
                "state_code": "BEN"
            },
            {
                "id": 2490,
                "name": "London Borough of Bromley",
                "state_code": "BRY"
            },
            {
                "id": 2349,
                "name": "London Borough of Camden",
                "state_code": "CMD"
            },
            {
                "id": 2512,
                "name": "London Borough of Croydon",
                "state_code": "CRY"
            },
            {
                "id": 2532,
                "name": "London Borough of Ealing",
                "state_code": "EAL"
            },
            {
                "id": 2476,
                "name": "London Borough of Enfield",
                "state_code": "ENF"
            },
            {
                "id": 2411,
                "name": "London Borough of Hackney",
                "state_code": "HCK"
            },
            {
                "id": 2448,
                "name": "London Borough of Hammersmith and Fulham",
                "state_code": "HMF"
            },
            {
                "id": 2306,
                "name": "London Borough of Haringey",
                "state_code": "HRY"
            },
            {
                "id": 2385,
                "name": "London Borough of Harrow",
                "state_code": "HRW"
            },
            {
                "id": 2347,
                "name": "London Borough of Havering",
                "state_code": "HAV"
            },
            {
                "id": 2376,
                "name": "London Borough of Hillingdon",
                "state_code": "HIL"
            },
            {
                "id": 2380,
                "name": "London Borough of Hounslow",
                "state_code": "HNS"
            },
            {
                "id": 2319,
                "name": "London Borough of Islington",
                "state_code": "ISL"
            },
            {
                "id": 2396,
                "name": "London Borough of Lambeth",
                "state_code": "LBH"
            },
            {
                "id": 2358,
                "name": "London Borough of Lewisham",
                "state_code": "LEW"
            },
            {
                "id": 2483,
                "name": "London Borough of Merton",
                "state_code": "MRT"
            },
            {
                "id": 2418,
                "name": "London Borough of Newham",
                "state_code": "NWM"
            },
            {
                "id": 2397,
                "name": "London Borough of Redbridge",
                "state_code": "RDB"
            },
            {
                "id": 2501,
                "name": "London Borough of Richmond upon Thames",
                "state_code": "RIC"
            },
            {
                "id": 2432,
                "name": "London Borough of Southwark",
                "state_code": "SWK"
            },
            {
                "id": 2313,
                "name": "London Borough of Sutton",
                "state_code": "STN"
            },
            {
                "id": 2390,
                "name": "London Borough of Tower Hamlets",
                "state_code": "TWH"
            },
            {
                "id": 2326,
                "name": "London Borough of Waltham Forest",
                "state_code": "WFT"
            },
            {
                "id": 2434,
                "name": "London Borough of Wandsworth",
                "state_code": "WND"
            },
            {
                "id": 2322,
                "name": "Magherafelt District Council",
                "state_code": "MFT"
            },
            {
                "id": 2398,
                "name": "Manchester",
                "state_code": "MAN"
            },
            {
                "id": 2381,
                "name": "Medway",
                "state_code": "MDW"
            },
            {
                "id": 2328,
                "name": "Merthyr Tydfil County Borough",
                "state_code": "MTY"
            },
            {
                "id": 2320,
                "name": "Metropolitan Borough of Wigan",
                "state_code": "WGN"
            },
            {
                "id": 2429,
                "name": "Mid and East Antrim",
                "state_code": "MEA"
            },
            {
                "id": 2399,
                "name": "Mid Ulster",
                "state_code": "MUL"
            },
            {
                "id": 2332,
                "name": "Middlesbrough",
                "state_code": "MDB"
            },
            {
                "id": 2519,
                "name": "Midlothian",
                "state_code": "MLN"
            },
            {
                "id": 2416,
                "name": "Milton Keynes",
                "state_code": "MIK"
            },
            {
                "id": 2402,
                "name": "Monmouthshire",
                "state_code": "MON"
            },
            {
                "id": 2360,
                "name": "Moray",
                "state_code": "MRY"
            },
            {
                "id": 2348,
                "name": "Moyle District Council",
                "state_code": "MYL"
            },
            {
                "id": 2351,
                "name": "Neath Port Talbot County Borough",
                "state_code": "NTL"
            },
            {
                "id": 2458,
                "name": "Newcastle upon Tyne",
                "state_code": "NET"
            },
            {
                "id": 2524,
                "name": "Newport",
                "state_code": "NWP"
            },
            {
                "id": 2350,
                "name": "Newry and Mourne District Council",
                "state_code": "NYM"
            },
            {
                "id": 2534,
                "name": "Newry, Mourne and Down",
                "state_code": "NMD"
            },
            {
                "id": 2317,
                "name": "Newtownabbey Borough Council",
                "state_code": "NTA"
            },
            {
                "id": 2473,
                "name": "Norfolk",
                "state_code": "NFK"
            },
            {
                "id": 2535,
                "name": "North Ayrshire",
                "state_code": "NAY"
            },
            {
                "id": 2513,
                "name": "North Down Borough Council",
                "state_code": "NDN"
            },
            {
                "id": 2384,
                "name": "North East Lincolnshire",
                "state_code": "NEL"
            },
            {
                "id": 2487,
                "name": "North Lanarkshire",
                "state_code": "NLK"
            },
            {
                "id": 2453,
                "name": "North Lincolnshire",
                "state_code": "NLN"
            },
            {
                "id": 2430,
                "name": "North Somerset",
                "state_code": "NSM"
            },
            {
                "id": 2521,
                "name": "North Tyneside",
                "state_code": "NTY"
            },
            {
                "id": 2522,
                "name": "North Yorkshire",
                "state_code": "NYK"
            },
            {
                "id": 2480,
                "name": "Northamptonshire",
                "state_code": "NTH"
            },
            {
                "id": 2337,
                "name": "Northern Ireland",
                "state_code": "NIR"
            },
            {
                "id": 2365,
                "name": "Northumberland",
                "state_code": "NBL"
            },
            {
                "id": 2456,
                "name": "Nottinghamshire",
                "state_code": "NTT"
            },
            {
                "id": 2477,
                "name": "Oldham",
                "state_code": "OLD"
            },
            {
                "id": 2314,
                "name": "Omagh District Council",
                "state_code": "OMH"
            },
            {
                "id": 2474,
                "name": "Orkney Islands",
                "state_code": "ORK"
            },
            {
                "id": 2353,
                "name": "Outer Hebrides",
                "state_code": "ELS"
            },
            {
                "id": 2321,
                "name": "Oxfordshire",
                "state_code": "OXF"
            },
            {
                "id": 2486,
                "name": "Pembrokeshire",
                "state_code": "PEM"
            },
            {
                "id": 2325,
                "name": "Perth and Kinross",
                "state_code": "PKN"
            },
            {
                "id": 2302,
                "name": "Poole",
                "state_code": "POL"
            },
            {
                "id": 2441,
                "name": "Powys",
                "state_code": "POW"
            },
            {
                "id": 2455,
                "name": "Reading",
                "state_code": "RDG"
            },
            {
                "id": 2527,
                "name": "Redcar and Cleveland",
                "state_code": "RCC"
            },
            {
                "id": 2443,
                "name": "Renfrewshire",
                "state_code": "RFW"
            },
            {
                "id": 2301,
                "name": "Rhondda Cynon Taf",
                "state_code": "RCT"
            },
            {
                "id": 2327,
                "name": "Rochdale",
                "state_code": "RCH"
            },
            {
                "id": 2308,
                "name": "Rotherham",
                "state_code": "ROT"
            },
            {
                "id": 2492,
                "name": "Royal Borough of Greenwich",
                "state_code": "GRE"
            },
            {
                "id": 2368,
                "name": "Royal Borough of Kensington and Chelsea",
                "state_code": "KEC"
            },
            {
                "id": 2481,
                "name": "Royal Borough of Kingston upon Thames",
                "state_code": "KTT"
            },
            {
                "id": 2472,
                "name": "Rutland",
                "state_code": "RUT"
            },
            {
                "id": 2502,
                "name": "Saint Helena",
                "state_code": "SH-HL"
            },
            {
                "id": 2493,
                "name": "Salford",
                "state_code": "SLF"
            },
            {
                "id": 2341,
                "name": "Sandwell",
                "state_code": "SAW"
            },
            {
                "id": 2335,
                "name": "Scotland",
                "state_code": "SCT"
            },
            {
                "id": 2346,
                "name": "Scottish Borders",
                "state_code": "SCB"
            },
            {
                "id": 2518,
                "name": "Sefton",
                "state_code": "SFT"
            },
            {
                "id": 2295,
                "name": "Sheffield",
                "state_code": "SHF"
            },
            {
                "id": 2300,
                "name": "Shetland Islands",
                "state_code": "ZET"
            },
            {
                "id": 2407,
                "name": "Shropshire",
                "state_code": "SHR"
            },
            {
                "id": 2427,
                "name": "Slough",
                "state_code": "SLG"
            },
            {
                "id": 2469,
                "name": "Solihull",
                "state_code": "SOL"
            },
            {
                "id": 2386,
                "name": "Somerset",
                "state_code": "SOM"
            },
            {
                "id": 2377,
                "name": "South Ayrshire",
                "state_code": "SAY"
            },
            {
                "id": 2400,
                "name": "South Gloucestershire",
                "state_code": "SGC"
            },
            {
                "id": 2362,
                "name": "South Lanarkshire",
                "state_code": "SLK"
            },
            {
                "id": 2409,
                "name": "South Tyneside",
                "state_code": "STY"
            },
            {
                "id": 2323,
                "name": "Southend-on-Sea",
                "state_code": "SOS"
            },
            {
                "id": 2290,
                "name": "St Helens",
                "state_code": "SHN"
            },
            {
                "id": 2447,
                "name": "Staffordshire",
                "state_code": "STS"
            },
            {
                "id": 2488,
                "name": "Stirling",
                "state_code": "STG"
            },
            {
                "id": 2394,
                "name": "Stockport",
                "state_code": "SKP"
            },
            {
                "id": 2421,
                "name": "Stockton-on-Tees",
                "state_code": "STT"
            },
            {
                "id": 2393,
                "name": "Strabane District Council",
                "state_code": "STB"
            },
            {
                "id": 2467,
                "name": "Suffolk",
                "state_code": "SFK"
            },
            {
                "id": 2526,
                "name": "Surrey",
                "state_code": "SRY"
            },
            {
                "id": 2422,
                "name": "Swindon",
                "state_code": "SWD"
            },
            {
                "id": 2367,
                "name": "Tameside",
                "state_code": "TAM"
            },
            {
                "id": 2310,
                "name": "Telford and Wrekin",
                "state_code": "TFW"
            },
            {
                "id": 2468,
                "name": "Thurrock",
                "state_code": "THR"
            },
            {
                "id": 2478,
                "name": "Torbay",
                "state_code": "TOB"
            },
            {
                "id": 2496,
                "name": "Torfaen",
                "state_code": "TOF"
            },
            {
                "id": 2293,
                "name": "Trafford",
                "state_code": "TRF"
            },
            {
                "id": 2375,
                "name": "United Kingdom",
                "state_code": "UKM"
            },
            {
                "id": 2299,
                "name": "Vale of Glamorgan",
                "state_code": "VGL"
            },
            {
                "id": 2465,
                "name": "Wakefield",
                "state_code": "WKF"
            },
            {
                "id": 2338,
                "name": "Wales",
                "state_code": "WLS"
            },
            {
                "id": 2292,
                "name": "Walsall",
                "state_code": "WLL"
            },
            {
                "id": 2420,
                "name": "Warrington",
                "state_code": "WRT"
            },
            {
                "id": 2505,
                "name": "Warwickshire",
                "state_code": "WAR"
            },
            {
                "id": 2471,
                "name": "West Berkshire",
                "state_code": "WBK"
            },
            {
                "id": 2440,
                "name": "West Dunbartonshire",
                "state_code": "WDU"
            },
            {
                "id": 2354,
                "name": "West Lothian",
                "state_code": "WLN"
            },
            {
                "id": 2296,
                "name": "West Sussex",
                "state_code": "WSX"
            },
            {
                "id": 2391,
                "name": "Wiltshire",
                "state_code": "WIL"
            },
            {
                "id": 2482,
                "name": "Windsor and Maidenhead",
                "state_code": "WNM"
            },
            {
                "id": 2408,
                "name": "Wirral",
                "state_code": "WRL"
            },
            {
                "id": 2419,
                "name": "Wokingham",
                "state_code": "WOK"
            },
            {
                "id": 2510,
                "name": "Worcestershire",
                "state_code": "WOR"
            },
            {
                "id": 2436,
                "name": "Wrexham County Borough",
                "state_code": "WRX"
            }
        ]
    },
    {
        "id": 233,
        "states": [
            {
                "id": 1456,
                "name": "Alabama",
                "state_code": "AL"
            },
            {
                "id": 1400,
                "name": "Alaska",
                "state_code": "AK"
            },
            {
                "id": 1424,
                "name": "American Samoa",
                "state_code": "AS"
            },
            {
                "id": 1434,
                "name": "Arizona",
                "state_code": "AZ"
            },
            {
                "id": 1444,
                "name": "Arkansas",
                "state_code": "AR"
            },
            {
                "id": 1402,
                "name": "Baker Island",
                "state_code": "UM-81"
            },
            {
                "id": 1416,
                "name": "California",
                "state_code": "CA"
            },
            {
                "id": 1450,
                "name": "Colorado",
                "state_code": "CO"
            },
            {
                "id": 1435,
                "name": "Connecticut",
                "state_code": "CT"
            },
            {
                "id": 1399,
                "name": "Delaware",
                "state_code": "DE"
            },
            {
                "id": 1437,
                "name": "District of Columbia",
                "state_code": "DC"
            },
            {
                "id": 1436,
                "name": "Florida",
                "state_code": "FL"
            },
            {
                "id": 1455,
                "name": "Georgia",
                "state_code": "GA"
            },
            {
                "id": 1412,
                "name": "Guam",
                "state_code": "GU"
            },
            {
                "id": 1411,
                "name": "Hawaii",
                "state_code": "HI"
            },
            {
                "id": 1398,
                "name": "Howland Island",
                "state_code": "UM-84"
            },
            {
                "id": 1460,
                "name": "Idaho",
                "state_code": "ID"
            },
            {
                "id": 1425,
                "name": "Illinois",
                "state_code": "IL"
            },
            {
                "id": 1440,
                "name": "Indiana",
                "state_code": "IN"
            },
            {
                "id": 1459,
                "name": "Iowa",
                "state_code": "IA"
            },
            {
                "id": 1410,
                "name": "Jarvis Island",
                "state_code": "UM-86"
            },
            {
                "id": 1428,
                "name": "Johnston Atoll",
                "state_code": "UM-67"
            },
            {
                "id": 1406,
                "name": "Kansas",
                "state_code": "KS"
            },
            {
                "id": 1419,
                "name": "Kentucky",
                "state_code": "KY"
            },
            {
                "id": 1403,
                "name": "Kingman Reef",
                "state_code": "UM-89"
            },
            {
                "id": 1457,
                "name": "Louisiana",
                "state_code": "LA"
            },
            {
                "id": 1453,
                "name": "Maine",
                "state_code": "ME"
            },
            {
                "id": 1401,
                "name": "Maryland",
                "state_code": "MD"
            },
            {
                "id": 1433,
                "name": "Massachusetts",
                "state_code": "MA"
            },
            {
                "id": 1426,
                "name": "Michigan",
                "state_code": "MI"
            },
            {
                "id": 1438,
                "name": "Midway Atoll",
                "state_code": "UM-71"
            },
            {
                "id": 1420,
                "name": "Minnesota",
                "state_code": "MN"
            },
            {
                "id": 1430,
                "name": "Mississippi",
                "state_code": "MS"
            },
            {
                "id": 1451,
                "name": "Missouri",
                "state_code": "MO"
            },
            {
                "id": 1446,
                "name": "Montana",
                "state_code": "MT"
            },
            {
                "id": 1439,
                "name": "Navassa Island",
                "state_code": "UM-76"
            },
            {
                "id": 1408,
                "name": "Nebraska",
                "state_code": "NE"
            },
            {
                "id": 1458,
                "name": "Nevada",
                "state_code": "NV"
            },
            {
                "id": 1404,
                "name": "New Hampshire",
                "state_code": "NH"
            },
            {
                "id": 1417,
                "name": "New Jersey",
                "state_code": "NJ"
            },
            {
                "id": 1423,
                "name": "New Mexico",
                "state_code": "NM"
            },
            {
                "id": 1452,
                "name": "New York",
                "state_code": "NY"
            },
            {
                "id": 1447,
                "name": "North Carolina",
                "state_code": "NC"
            },
            {
                "id": 1418,
                "name": "North Dakota",
                "state_code": "ND"
            },
            {
                "id": 1431,
                "name": "Northern Mariana Islands",
                "state_code": "MP"
            },
            {
                "id": 4851,
                "name": "Ohio",
                "state_code": "OH"
            },
            {
                "id": 1421,
                "name": "Oklahoma",
                "state_code": "OK"
            },
            {
                "id": 1415,
                "name": "Oregon",
                "state_code": "OR"
            },
            {
                "id": 1448,
                "name": "Palmyra Atoll",
                "state_code": "UM-95"
            },
            {
                "id": 1422,
                "name": "Pennsylvania",
                "state_code": "PA"
            },
            {
                "id": 1449,
                "name": "Puerto Rico",
                "state_code": "PR"
            },
            {
                "id": 1461,
                "name": "Rhode Island",
                "state_code": "RI"
            },
            {
                "id": 1443,
                "name": "South Carolina",
                "state_code": "SC"
            },
            {
                "id": 1445,
                "name": "South Dakota",
                "state_code": "SD"
            },
            {
                "id": 1454,
                "name": "Tennessee",
                "state_code": "TN"
            },
            {
                "id": 1407,
                "name": "Texas",
                "state_code": "TX"
            },
            {
                "id": 1432,
                "name": "United States Minor Outlying Islands",
                "state_code": "UM"
            },
            {
                "id": 1413,
                "name": "United States Virgin Islands",
                "state_code": "VI"
            },
            {
                "id": 1414,
                "name": "Utah",
                "state_code": "UT"
            },
            {
                "id": 1409,
                "name": "Vermont",
                "state_code": "VT"
            },
            {
                "id": 1427,
                "name": "Virginia",
                "state_code": "VA"
            },
            {
                "id": 1405,
                "name": "Wake Island",
                "state_code": "UM-79"
            },
            {
                "id": 1462,
                "name": "Washington",
                "state_code": "WA"
            },
            {
                "id": 1429,
                "name": "West Virginia",
                "state_code": "WV"
            },
            {
                "id": 1441,
                "name": "Wisconsin",
                "state_code": "WI"
            },
            {
                "id": 1442,
                "name": "Wyoming",
                "state_code": "WY"
            }
        ]
    },
    {
        "id": 234,
        "states": []
    },
    {
        "id": 235,
        "states": [
            {
                "id": 3205,
                "name": "Artigas Department",
                "state_code": "AR"
            },
            {
                "id": 3213,
                "name": "Canelones Department",
                "state_code": "CA"
            },
            {
                "id": 3211,
                "name": "Cerro Largo Department",
                "state_code": "CL"
            },
            {
                "id": 3208,
                "name": "Colonia Department",
                "state_code": "CO"
            },
            {
                "id": 3209,
                "name": "Durazno Department",
                "state_code": "DU"
            },
            {
                "id": 3203,
                "name": "Flores Department",
                "state_code": "FS"
            },
            {
                "id": 3217,
                "name": "Florida Department",
                "state_code": "FD"
            },
            {
                "id": 3215,
                "name": "Lavalleja Department",
                "state_code": "LA"
            },
            {
                "id": 3206,
                "name": "Maldonado Department",
                "state_code": "MA"
            },
            {
                "id": 3218,
                "name": "Montevideo Department",
                "state_code": "MO"
            },
            {
                "id": 3212,
                "name": "Paysandú Department",
                "state_code": "PA"
            },
            {
                "id": 3210,
                "name": "Río Negro Department",
                "state_code": "RN"
            },
            {
                "id": 3207,
                "name": "Rivera Department",
                "state_code": "RV"
            },
            {
                "id": 3216,
                "name": "Rocha Department",
                "state_code": "RO"
            },
            {
                "id": 3220,
                "name": "Salto Department",
                "state_code": "SA"
            },
            {
                "id": 3204,
                "name": "San José Department",
                "state_code": "SJ"
            },
            {
                "id": 3219,
                "name": "Soriano Department",
                "state_code": "SO"
            },
            {
                "id": 3221,
                "name": "Tacuarembó Department",
                "state_code": "TA"
            },
            {
                "id": 3214,
                "name": "Treinta y Tres Department",
                "state_code": "TT"
            }
        ]
    },
    {
        "id": 236,
        "states": [
            {
                "id": 2540,
                "name": "Andijan Region",
                "state_code": "AN"
            },
            {
                "id": 2541,
                "name": "Bukhara Region",
                "state_code": "BU"
            },
            {
                "id": 2538,
                "name": "Fergana Region",
                "state_code": "FA"
            },
            {
                "id": 2545,
                "name": "Jizzakh Region",
                "state_code": "JI"
            },
            {
                "id": 2548,
                "name": "Karakalpakstan",
                "state_code": "QR"
            },
            {
                "id": 2537,
                "name": "Namangan Region",
                "state_code": "NG"
            },
            {
                "id": 2542,
                "name": "Navoiy Region",
                "state_code": "NW"
            },
            {
                "id": 2543,
                "name": "Qashqadaryo Region",
                "state_code": "QA"
            },
            {
                "id": 2544,
                "name": "Samarqand Region",
                "state_code": "SA"
            },
            {
                "id": 2547,
                "name": "Sirdaryo Region",
                "state_code": "SI"
            },
            {
                "id": 2546,
                "name": "Surxondaryo Region",
                "state_code": "SU"
            },
            {
                "id": 2536,
                "name": "Tashkent",
                "state_code": "TK"
            },
            {
                "id": 2549,
                "name": "Tashkent Region",
                "state_code": "TO"
            },
            {
                "id": 2539,
                "name": "Xorazm Region",
                "state_code": "XO"
            }
        ]
    },
    {
        "id": 237,
        "states": [
            {
                "id": 4775,
                "name": "Malampa",
                "state_code": "MAP"
            },
            {
                "id": 4773,
                "name": "Penama",
                "state_code": "PAM"
            },
            {
                "id": 4776,
                "name": "Sanma",
                "state_code": "SAM"
            },
            {
                "id": 4774,
                "name": "Shefa",
                "state_code": "SEE"
            },
            {
                "id": 4777,
                "name": "Tafea",
                "state_code": "TAE"
            },
            {
                "id": 4772,
                "name": "Torba",
                "state_code": "TOB"
            }
        ]
    },
    {
        "id": 238,
        "states": []
    },
    {
        "id": 239,
        "states": [
            {
                "id": 2044,
                "name": "Amazonas",
                "state_code": "Z"
            },
            {
                "id": 2050,
                "name": "Anzoátegui",
                "state_code": "B"
            },
            {
                "id": 4856,
                "name": "Apure",
                "state_code": "C"
            },
            {
                "id": 2047,
                "name": "Aragua",
                "state_code": "D"
            },
            {
                "id": 2049,
                "name": "Barinas",
                "state_code": "E"
            },
            {
                "id": 2039,
                "name": "Bolívar",
                "state_code": "F"
            },
            {
                "id": 2040,
                "name": "Carabobo",
                "state_code": "G"
            },
            {
                "id": 2034,
                "name": "Cojedes",
                "state_code": "H"
            },
            {
                "id": 2051,
                "name": "Delta Amacuro",
                "state_code": "Y"
            },
            {
                "id": 4855,
                "name": "Distrito Capital",
                "state_code": "A"
            },
            {
                "id": 2035,
                "name": "Falcón",
                "state_code": "I"
            },
            {
                "id": 2046,
                "name": "Federal Dependencies of Venezuela",
                "state_code": "W"
            },
            {
                "id": 2045,
                "name": "Guárico",
                "state_code": "J"
            },
            {
                "id": 2055,
                "name": "La Guaira",
                "state_code": "X"
            },
            {
                "id": 2038,
                "name": "Lara",
                "state_code": "K"
            },
            {
                "id": 2053,
                "name": "Mérida",
                "state_code": "L"
            },
            {
                "id": 2037,
                "name": "Miranda",
                "state_code": "M"
            },
            {
                "id": 2054,
                "name": "Monagas",
                "state_code": "N"
            },
            {
                "id": 2052,
                "name": "Nueva Esparta",
                "state_code": "O"
            },
            {
                "id": 2036,
                "name": "Portuguesa",
                "state_code": "P"
            },
            {
                "id": 2056,
                "name": "Sucre",
                "state_code": "R"
            },
            {
                "id": 2048,
                "name": "Táchira",
                "state_code": "S"
            },
            {
                "id": 2043,
                "name": "Trujillo",
                "state_code": "T"
            },
            {
                "id": 2041,
                "name": "Yaracuy",
                "state_code": "U"
            },
            {
                "id": 2042,
                "name": "Zulia",
                "state_code": "V"
            }
        ]
    },
    {
        "id": 240,
        "states": [
            {
                "id": 3794,
                "name": "An Giang",
                "state_code": "44"
            },
            {
                "id": 3770,
                "name": "Bà Rịa-Vũng Tàu",
                "state_code": "43"
            },
            {
                "id": 3815,
                "name": "Bắc Giang",
                "state_code": "54"
            },
            {
                "id": 3822,
                "name": "Bắc Kạn",
                "state_code": "53"
            },
            {
                "id": 3804,
                "name": "Bạc Liêu",
                "state_code": "55"
            },
            {
                "id": 3791,
                "name": "Bắc Ninh",
                "state_code": "56"
            },
            {
                "id": 3796,
                "name": "Bến Tre",
                "state_code": "50"
            },
            {
                "id": 3785,
                "name": "Bình Dương",
                "state_code": "57"
            },
            {
                "id": 3830,
                "name": "Bình Định",
                "state_code": "31"
            },
            {
                "id": 3797,
                "name": "Bình Phước",
                "state_code": "58"
            },
            {
                "id": 3787,
                "name": "Bình Thuận",
                "state_code": "40"
            },
            {
                "id": 3778,
                "name": "Cà Mau",
                "state_code": "59"
            },
            {
                "id": 4925,
                "name": "Cần Thơ",
                "state_code": "CT"
            },
            {
                "id": 3782,
                "name": "Cao Bằng",
                "state_code": "04"
            },
            {
                "id": 3806,
                "name": "Đà Nẵng",
                "state_code": "DN"
            },
            {
                "id": 3829,
                "name": "Đắk Lắk",
                "state_code": "33"
            },
            {
                "id": 3823,
                "name": "Đắk Nông",
                "state_code": "72"
            },
            {
                "id": 3773,
                "name": "Điện Biên",
                "state_code": "71"
            },
            {
                "id": 3821,
                "name": "Đồng Nai",
                "state_code": "39"
            },
            {
                "id": 3769,
                "name": "Đồng Tháp",
                "state_code": "45"
            },
            {
                "id": 3813,
                "name": "Gia Lai",
                "state_code": "30"
            },
            {
                "id": 3779,
                "name": "Hà Giang",
                "state_code": "03"
            },
            {
                "id": 3802,
                "name": "Hà Nam",
                "state_code": "63"
            },
            {
                "id": 3810,
                "name": "Hà Nội",
                "state_code": "HN"
            },
            {
                "id": 3816,
                "name": "Hà Tĩnh",
                "state_code": "23"
            },
            {
                "id": 3827,
                "name": "Hải Dương",
                "state_code": "61"
            },
            {
                "id": 3783,
                "name": "Hải Phòng",
                "state_code": "HP"
            },
            {
                "id": 3777,
                "name": "Hậu Giang",
                "state_code": "73"
            },
            {
                "id": 3811,
                "name": "Hồ Chí Minh",
                "state_code": "SG"
            },
            {
                "id": 3799,
                "name": "Hòa Bình",
                "state_code": "14"
            },
            {
                "id": 3768,
                "name": "Hưng Yên",
                "state_code": "66"
            },
            {
                "id": 3793,
                "name": "Khánh Hòa",
                "state_code": "34"
            },
            {
                "id": 3800,
                "name": "Kiên Giang",
                "state_code": "47"
            },
            {
                "id": 3772,
                "name": "Kon Tum",
                "state_code": "28"
            },
            {
                "id": 3825,
                "name": "Lai Châu",
                "state_code": "01"
            },
            {
                "id": 3818,
                "name": "Lâm Đồng",
                "state_code": "35"
            },
            {
                "id": 3792,
                "name": "Lạng Sơn",
                "state_code": "09"
            },
            {
                "id": 3817,
                "name": "Lào Cai",
                "state_code": "02"
            },
            {
                "id": 3808,
                "name": "Long An",
                "state_code": "41"
            },
            {
                "id": 3789,
                "name": "Nam Định",
                "state_code": "67"
            },
            {
                "id": 3780,
                "name": "Nghệ An",
                "state_code": "22"
            },
            {
                "id": 3786,
                "name": "Ninh Bình",
                "state_code": "18"
            },
            {
                "id": 3788,
                "name": "Ninh Thuận",
                "state_code": "36"
            },
            {
                "id": 3801,
                "name": "Phú Thọ",
                "state_code": "68"
            },
            {
                "id": 3824,
                "name": "Phú Yên",
                "state_code": "32"
            },
            {
                "id": 3809,
                "name": "Quảng Bình",
                "state_code": "24"
            },
            {
                "id": 3776,
                "name": "Quảng Nam",
                "state_code": "27"
            },
            {
                "id": 3828,
                "name": "Quảng Ngãi",
                "state_code": "29"
            },
            {
                "id": 3814,
                "name": "Quảng Ninh",
                "state_code": "13"
            },
            {
                "id": 3803,
                "name": "Quảng Trị",
                "state_code": "25"
            },
            {
                "id": 3819,
                "name": "Sóc Trăng",
                "state_code": "52"
            },
            {
                "id": 3812,
                "name": "Sơn La",
                "state_code": "05"
            },
            {
                "id": 3826,
                "name": "Tây Ninh",
                "state_code": "37"
            },
            {
                "id": 3775,
                "name": "Thái Bình",
                "state_code": "20"
            },
            {
                "id": 3807,
                "name": "Thái Nguyên",
                "state_code": "69"
            },
            {
                "id": 3771,
                "name": "Thanh Hóa",
                "state_code": "21"
            },
            {
                "id": 3798,
                "name": "Thừa Thiên-Huế",
                "state_code": "26"
            },
            {
                "id": 3781,
                "name": "Tiền Giang",
                "state_code": "46"
            },
            {
                "id": 3805,
                "name": "Trà Vinh",
                "state_code": "51"
            },
            {
                "id": 3795,
                "name": "Tuyên Quang",
                "state_code": "07"
            },
            {
                "id": 3790,
                "name": "Vĩnh Long",
                "state_code": "49"
            },
            {
                "id": 3774,
                "name": "Vĩnh Phúc",
                "state_code": "70"
            },
            {
                "id": 3784,
                "name": "Yên Bái",
                "state_code": "06"
            }
        ]
    },
    {
        "id": 241,
        "states": []
    },
    {
        "id": 242,
        "states": [
            {
                "id": 5074,
                "name": "Saint Croix",
                "state_code": "SC"
            },
            {
                "id": 5073,
                "name": "Saint John",
                "state_code": "SJ"
            },
            {
                "id": 5072,
                "name": "Saint Thomas",
                "state_code": "ST"
            }
        ]
    },
    {
        "id": 243,
        "states": []
    },
    {
        "id": 244,
        "states": []
    },
    {
        "id": 245,
        "states": [
            {
                "id": 1242,
                "name": "'Adan",
                "state_code": "AD"
            },
            {
                "id": 1250,
                "name": "'Amran",
                "state_code": "AM"
            },
            {
                "id": 1237,
                "name": "Abyan",
                "state_code": "AB"
            },
            {
                "id": 1240,
                "name": "Al Bayda'",
                "state_code": "BA"
            },
            {
                "id": 1241,
                "name": "Al Hudaydah",
                "state_code": "HU"
            },
            {
                "id": 1243,
                "name": "Al Jawf",
                "state_code": "JA"
            },
            {
                "id": 1251,
                "name": "Al Mahrah",
                "state_code": "MR"
            },
            {
                "id": 1235,
                "name": "Al Mahwit",
                "state_code": "MW"
            },
            {
                "id": 1246,
                "name": "Dhamar",
                "state_code": "DH"
            },
            {
                "id": 1238,
                "name": "Hadhramaut",
                "state_code": "HD"
            },
            {
                "id": 1244,
                "name": "Hajjah",
                "state_code": "HJ"
            },
            {
                "id": 1233,
                "name": "Ibb",
                "state_code": "IB"
            },
            {
                "id": 1245,
                "name": "Lahij",
                "state_code": "LA"
            },
            {
                "id": 1234,
                "name": "Ma'rib",
                "state_code": "MA"
            },
            {
                "id": 1248,
                "name": "Raymah",
                "state_code": "RA"
            },
            {
                "id": 1249,
                "name": "Saada",
                "state_code": "SD"
            },
            {
                "id": 1236,
                "name": "Sana'a",
                "state_code": "SN"
            },
            {
                "id": 1232,
                "name": "Sana'a",
                "state_code": "SA"
            },
            {
                "id": 1247,
                "name": "Shabwah",
                "state_code": "SH"
            },
            {
                "id": 1239,
                "name": "Socotra",
                "state_code": "SU"
            },
            {
                "id": 1231,
                "name": "Ta'izz",
                "state_code": "TA"
            }
        ]
    },
    {
        "id": 246,
        "states": [
            {
                "id": 1986,
                "name": "Central Province",
                "state_code": "02"
            },
            {
                "id": 1984,
                "name": "Copperbelt Province",
                "state_code": "08"
            },
            {
                "id": 1991,
                "name": "Eastern Province",
                "state_code": "03"
            },
            {
                "id": 1987,
                "name": "Luapula Province",
                "state_code": "04"
            },
            {
                "id": 1988,
                "name": "Lusaka Province",
                "state_code": "09"
            },
            {
                "id": 1989,
                "name": "Muchinga Province",
                "state_code": "10"
            },
            {
                "id": 1982,
                "name": "Northern Province",
                "state_code": "05"
            },
            {
                "id": 1985,
                "name": "Northwestern Province",
                "state_code": "06"
            },
            {
                "id": 1990,
                "name": "Southern Province",
                "state_code": "07"
            },
            {
                "id": 1983,
                "name": "Western Province",
                "state_code": "01"
            }
        ]
    },
    {
        "id": 247,
        "states": [
            {
                "id": 1956,
                "name": "Bulawayo Province",
                "state_code": "BU"
            },
            {
                "id": 1958,
                "name": "Harare Province",
                "state_code": "HA"
            },
            {
                "id": 1959,
                "name": "Manicaland",
                "state_code": "MA"
            },
            {
                "id": 1955,
                "name": "Mashonaland Central Province",
                "state_code": "MC"
            },
            {
                "id": 1951,
                "name": "Mashonaland East Province",
                "state_code": "ME"
            },
            {
                "id": 1953,
                "name": "Mashonaland West Province",
                "state_code": "MW"
            },
            {
                "id": 1960,
                "name": "Masvingo Province",
                "state_code": "MV"
            },
            {
                "id": 1954,
                "name": "Matabeleland North Province",
                "state_code": "MN"
            },
            {
                "id": 1952,
                "name": "Matabeleland South Province",
                "state_code": "MS"
            },
            {
                "id": 1957,
                "name": "Midlands Province",
                "state_code": "MI"
            }
        ]
    }
]