import React, { useState } from "react";
import { Link } from "react-router-dom";
import { publicRouteLink } from "../constant/Routes";
import SignUp1 from "./SignUp1";
import Popover from "@mui/material/Popover";
import SignIn from "./SignIn";

const LandingNavbar = () => {
  let Links = [
    { name: "Marketing Plans", link: publicRouteLink.MARKETING_PLANS },
    { name: "Uploads", link: publicRouteLink.UPLOADS },
    { name: "Contact Us", link: publicRouteLink.CONTACT_US },
    { name: "About Us", link: publicRouteLink.ABOUT_US },
  ];

  let [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open1 = Boolean(anchorEl);
  const [anchorElSignup, setAnchorElSignup] = useState(null);
  const openSignup = Boolean(anchorElSignup);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickSignup = (event) => {
    setAnchorElSignup(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElSignup(null);
  };

  return (
    <>
      <div className="shadow-md w-full sticky top-0 left-0 z-10">
        <div className="md:flex items-center justify-between bg-black text-white py-2 md:px-10 px-6">
          <div className="font-bold text-2xl cursor-pointer flex items-center font-[Poppins] text-gray-800">
            <span className="mr-1">
              <a href={publicRouteLink.HOME}>
                <img src="\assets\HungamaLogo.png" alt="hungama-logo" />
              </a>
            </span>
          </div>

          <div
            onClick={() => setOpen(!open)}
            className="text-3xl absolute right-8 top-6 cursor-pointer md:hidden"
          >
            <ion-icon name={open ? "close" : "menu"}></ion-icon>
          </div>

          <ul
            className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-black text-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
              open ? "top-20 " : "top-[-490px]"
            }`}
          >
            {Links.map((link) => (
              <li
                key={link.name}
                className="md:ml-8 text-base font-medium md:my-0 my-7"
              >
                <Link to={link.link}>{link.name}</Link>
              </li>
            ))}

            <div className="w-64 flex overflow-hidden justify-center items-center rounded-3xl bg-white md:ml-8 text-base font-medium">
              <button
                onClick={handleClick}
                className="w-36 text-black bg-white rounded-3xl h-12"
              >
                Sign In
              </button>
              <Popover
                open={open1}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{ marginTop: "2%" }}
              >
                <SignIn onClose={handleClose} />
              </Popover>

              <button
                onClick={handleClickSignup}
                className="w-36 text-black bg-[#FEC961] rounded-3xl h-12"
              >
                Sign Up
              </button>
              <Popover
                open={openSignup}
                anchorEl={anchorElSignup}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{ marginTop: "2%" }}
              >
                <SignUp1 onClose={handleClose} />
              </Popover>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

export default LandingNavbar;
