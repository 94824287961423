import React, { useState, useEffect } from "react";
function ProfilePicture(props) {
  // const defaultImageUrl = "/haa-frontend/public/assets/profilrpicture.jpg";
  const [image, setImage] = useState();
  const [artistName, setArtistName] = useState("Your Name");
  const [imageError, setImageError] = useState("");

  useEffect(() => {
    //get api integration
    const savedImage = localStorage.getItem("uploadedImage");
    if (savedImage) {
      setImage(savedImage);
    }
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (validTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const imageUrl = reader.result;
          setImage(imageUrl);
          localStorage.setItem("uploadedImage", imageUrl);
          setImageError(""); // Clear any previous error message
        };
        reader.readAsDataURL(file);
      } else {
        setImageError("Only .jpeg, .jpg, and .png files are allowed.");
      }
    }
    // if (file) {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     // setImage(reader.result);
    //     const imageUrl = reader.result;
    //     setImage(imageUrl);
    //     localStorage.setItem("uploadedImage", imageUrl);
    //   };
    //   reader.readAsDataURL(file);
    // }
  };

  return (
    <div className="profile_bar">
      <div className="image-wrapper">
        <img src={image} className="profile-picture" />
        <div className="button-content">
          <label
            for="upload_ProfilePicture"
            className="custom-image-upload btn btn-primary"
            style={{
              backgroundColor: "#ff6c2f",
              width: "auto",
              height: "30px",
              marginRight: "4%",
            }}
          >
            <img
              src={"./assets/imgUpload.png"}
              alt=""
              className=""
              style={{
                width: "15px",
                height: "15px",
              }}
            />
            <h3
              style={{ fontSize: "small", fontWeight: 400, marginLeft: "4px" }}
            >
              Edit
            </h3>
          </label>
          <input
            className=""
            type="file"
            id="upload_ProfilePicture"
            name="file"
            accept=".jpeg,.jpg,.png"
            onChange={handleImageChange}
            hidden
          ></input>
        </div>
      </div>
      {imageError && (
        <span className="error-text" style={{ color: "red" }}>
          {imageError}
        </span>
      )}
      <div>
        <h2 className="font-[Poppins] text-[#FFFFFF] ml-[15%] text-[130%] mt-[5%]">
          {props.artistName}
        </h2>
      </div>
    </div>
  );
}

export default ProfilePicture;
