import React from 'react'
import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { publicRouteLink } from '../constant/Routes';
import "react-country-state-city/dist/react-country-state-city.css";
import { MainContext } from '../context/main';
import { country } from '../constant/country';
import { state } from '../constant/state';

const SignUp2 = () => {
    const [countryid, setCountryid] = useState(0);
    const [countryName, setCountryName] = useState('');
    const [stateid, setstateid] = useState(0);
    const [stateName, setStateName] = useState('');
    const [cityName, setCityName] = useState('');
    const [zipCode, setZipCode] = useState('');
    const { formData, handleFormData } = useContext(MainContext);

    const getStatesByCountry = () => {
        const selectState = state.filter(item => item.id == countryid);
        if (selectState.length === 0) {
            console.log('No states found for the selected country.');
            return [];
        }
        let states = selectState[0];
        states = states.states;
        return states;
    };

    return (
        <div className="flex items-center justify-center h-screen bg-[#1A1A1A]">
            <form className="bg-[#1A1A1A] text-white rounded-mdF w-96">
                <h1 className="text-3xl font-bold text-center">Complete your account</h1>

                <p className='text-[#6E6E6E] text-2xlfont-medium break-words mt-8'>Location</p>
                <div className='pt-3'>
                    <label className="block text-white text-sm font-normal" htmlFor="country">
                        Country/Region <span className='text-[#FF6C2F]'>*</span>
                    </label>
                    <select
                        name='country'
                        className="w-full outline-none bg-white text-black rounded-md h-12"
                        onChange={(e) => {
                            const selectedCountryId = e.target.value;
                            const selectedCountry = country.find(item => item.id == selectedCountryId);
                            setCountryid(selectedCountryId);
                            setCountryName(selectedCountry.name);
                            setstateid(0);
                            setStateName('');
                        }}
                    >
                        <option value="">Select Country</option>
                        {country.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className='pt-3'>
                    <label className="block text-white text-sm font-normal" htmlFor="state">
                        State <span className='text-[#FF6C2F]'>*</span>
                    </label>
                    <select
                        name='state'
                        className="w-full outline-none bg-white text-black rounded-md h-12"
                        onChange={(e) => {
                            const selectedStateId = e.target.value;
                            const selectedState = getStatesByCountry().find(item => item.id == selectedStateId);
                            setstateid(selectedStateId);
                            setStateName(selectedState.name);
                        }}
                    >
                        <option value="">Select State</option>
                        {getStatesByCountry().map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className='flex flex-row space-x-5'>
                    <div className='pt-3'>
                        <label className="block text-white text-sm font-normal" htmlFor="city">City
                            <span className='text-[#FF6C2F]'>*</span>
                        </label>
                        <input
                            className="w-full outline-none pl-1 bg-white text-black rounded-md h-12"
                            type="text"
                            name="city"
                            value={formData?.city}
                            onChange={(e) => {
                                handleFormData(e);
                                setCityName(e.target.value);
                            }}
                            required
                        />
                    </div>
                    <div className='pt-3'>
                        <label className="block text-white text-sm font-normal" htmlFor="zipCode">Zip Code
                            <span className='text-[#FF6C2F]'>*</span>
                        </label>
                        <input
                            className="w-full outline-none pl-1 bg-white text-black rounded-md h-12"
                            type="text"
                            name="zipCode"
                            value={formData?.zipCode}
                            onChange={(e) => {
                                handleFormData(e);
                                setZipCode(e.target.value);
                            }}
                            required
                        />
                    </div>
                </div>
                <Link to={publicRouteLink.COMPLETE_YOUR_PROFILE}>
                    <button className="w-full bg-[#FF6C2F] text-white h-12 rounded-md text-sm font-medium mt-3" type="submit">
                        Next
                    </button>
                </Link>
            </form >
        </div >
    )
}

export default SignUp2
