import React from 'react';
import { Link } from 'react-router-dom';
import { publicRouteLink } from '../constant/Routes';


const Footer = () => {
    return (
        <>
            <div className='bg-[#000]'>
                <div className='flex flex-col md:flex-row justify-center items-center space-x-30 gap-10 pb-5'>
                    <div className='flex flex-col justify-center items-center md:block'>
                        <div className="pt-11 flex flex-col justify-center items-center md:block">
                            <h2 className="text-[#FF6C2F] text-base font-semibold">COMPANY</h2>
                            <div className='text-white text-base font-medium md:pt-10 pt-5'><a href='https://www.hungamaartistaloud.com/about_us.php'>About Us</a></div>
                            <div className='text-white text-base font-medium'><a href='https://www.hungamaartistaloud.com/terms-conditions.php'>Terms & Conditions</a></div>
                            <div className='text-white text-base font-medium'><a href='https://www.hungamaartistaloud.com/privacy-policy.php'>Privacy Policy</a></div>
                            <div className='text-white text-base font-medium'><a href='https://www.hungamaartistaloud.com/privacy-policy.php'>Grievance Officer</a></div>
                        </div>

                        <div className="pt-7 flex flex-col justify-center items-center md:block">
                            <h2 className="text-[#FF6C2F] text-base font-semibold">CONTACT US</h2>
                            <div className='text-white text-base font-medium break-words pt-10 w-80 md:!text-left sm:!text-center'>
                                SF-B-07, Art Guild House, Phoenix Maket City, LBS Marg, Kurla (W), Mumbai Maharashtra 400070
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-col justify-center items-center space-y-6 md:pl-10 pl-0'>
                        <div className="text-white text-3xl font-bold sm:break-words sm:text-xl">HUNGAMA ARTIST ALOUD</div>
                        <img className='w-20 h-20' src='\assets\HungamaLogo.png' alt='logo' />
                        <div className=' text-white text-3xl font-medium break-words  sm:text-xl'>AN INITIATIVE BY</div>
                        <img className='' src='assets\hungama-logo.png' alt='logo' />
                    </div>

                    <div className='flex flex-col md:pl-52 pl-0'>
                        <div className="w-fit flex flex-col md:block items-center justify-center">
                            <h2 className="text-[#FF6C2F] text-base font-semibold">PARTNERS</h2>
                            <div className='text-white text-base font-medium md:pt-10 pt-5'><a href='https://www.hungama.com/'>Hungama</a></div>
                            <div className='text-white text-base font-medium'><a href='https://www.bollywoodhungama.com/'>Bollywood Hungama</a></div>
                            <div className='text-white text-base font-medium'><a href='https://www.hungamagames.com/'>Hungama Games</a></div>
                            <div className='text-white text-base font-medium'><a href='https://world.heftyverse.xyz/login'>Heftyverse</a></div>
                        </div>

                        <div className="pt-7">
                            <h2 className=" text-[#FF6C2F] text-base font-semibold">CONNECT WITH US</h2>
                            <div className="flex flex-row space-x-4 pt-10">
                                <img src='assets\Vector.svg' alt='twitter-logo' />
                                <img src='assets\instagram.png' alt='insta-logo' />
                                <img src='assets\facebook.png' alt='fb-logo' />
                                <img src='assets\youtube.png' alt='utube-logo' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=' text-center bg-black text-white pb-7'>
                <Link to={publicRouteLink.TERMS_AND_CONDITIONS}>
                    <span className='text-base font-medium underline'>Terms & Conditions</span>
                </Link>
                <span className='text-base font-medium'> | </span>
                <Link to={publicRouteLink.PRIVACY_POLICY}>
                    <span className='text-base font-medium underline' >Privacy policy<br /></span>
                </Link>
                <span className='text-base font-medium'>All rights reserved. Copyrights © 2022-2023 Hungama Digital Media Ent. Pvt. Ltd.</span>
            </div>
        </>
    )
};

export default Footer;