import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { publicRouteLink } from '../constant/Routes'
import { useState } from "react";

const LandingPage = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 10,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        fade: true,
        ease: 'liner'
    };

    const [data, setData] = useState([
        {
            id: 1,
            img: "assets/arman-malik.png",
            name: "Amaal Malik",
            bio: "Independent artists receive genuine support through Artist Aloud. Whenever I seek assistance, a real person promptly responds to my queries, ensuring that my concerns are addressed. I count on Artist Aloud to amplify my music's reach across the globe."
        },
        {
            id: 2,
            img: "assets/mame-khan.png",
            name: "Mame Khan",
            bio: "Crafting music brings me immense fulfillment and purpose; it serves as my connection to the world. I feel fortunate to have an audience spanning the globe, and I take joy in knowing that my music resonates with them. Thanks to Hungama Artist Aloud, I can effortlessly share my music with this diverse audience."
        },
        {
            id: 3,
            img: "assets/shekhar.png",
            name: "Shekhar ravjiani",
            bio: "Support and reliability serve as the bedrock for an artist's ability to explore, evolve, and share their artistry with the community. In this regard, Artist Aloud's consistent support and revenue collection model have consistently met our expectations and facilitated our creative journey"
        },
        {
            id: 4,
            img: "assets/amiway.png",
            name: "Emiway Bantai",
            bio: "As a musician, my ultimate aspiration is to make my music accessible to people worldwide. It's the dream. Thanks to HAA, this dream effortlessly transforms into reality. Wherever I may journey, I'm confident that my songs precede me, reaching listeners across the globe."
        }
    ]);

    const [bg, setBg] = useState([
        {
            id: 1,
            link: publicRouteLink.SIGNIN,
            img: "assets/bg-carousel-1.png",
            heading: "Create! Own! Publish!",
            text: "#BeOriginal #BeIndependent"
        },
        {
            id: 2,
            link: publicRouteLink.MARKETING_PLANS,
            img: "assets/bg-carousel-2.png",
            heading: "It's time to turn that dream into reality,",
            text: "Grow your fans through our marketing services"
        }
    ]);

    return (
        <>
            <section className="relative overflow-hidden">
                <div className="overflow-hidden">
                    <Slider {...settings}>
                        {bg.map((item, index) => (
                            <div key={index} className="w-full h-screen flex flex-shrink-0 flex-grow-0 transition-transform duration-600 ease-in-out relative">
                                <img className="w-full h-full object-cover" src={item.img} alt={`bg-carousel-${index}`} />
                                <div className="absolute inset-0 flex flex-col justify-center items-center text-center text-white font-poppins">
                                    <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold">{item.heading}</h1>
                                    <p className="text-lg md:text-xl font-bold mb-8">{item.text}</p>
                                    <Link to={item.link}>
                                        <button className="text-black no-underline bg-[#FEC961] w-52 h-14 rounded-full flex justify-center items-center">
                                            <span className="font-bold">Get Started</span>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>

            <section className="text-center pb-20 bg-black">
                <div className="text-5xl font-bold break-words text-white pt-4 pb-4">
                    <h1>Learn more about us!</h1>
                </div>
                <br />

                <div className="space-y-3 px-4 md:px-10 lg:px-20 xl:px-40">
                    <div className="flex flex-col md:flex-row md:space-x-4 lg:space-x-8">
                        <div className="bg-[#FF6C2F] text-white w-full md:w-80 lg:w-96 h-20 rounded-full flex justify-center items-center"><span className="text-xl md:text-2xl font-medium break-words">Audio Distribution</span></div>
                        <div className="bg-white text-black w-full md:w-80 lg:w-96 h-20 rounded-full flex justify-center items-center mt-3 md:mt-0"><span className="text-xl md:text-2xl font-medium break-words">Video Distribution</span></div>
                        <div className="bg-[#FF6C2F] text-white w-full md:w-96 lg:w-96 h-20 rounded-full flex justify-center items-center mt-3 md:mt-0"><span className="text-xl md:text-2xl font-medium break-words">Marketing Services</span></div>
                    </div>
                    <div className="flex flex-col md:flex-row md:space-x-4 lg:space-x-8">
                        <div className="bg-white text-black w-full md:w-80 lg:w-96 h-20 rounded-full flex justify-center items-center mt-3"><span className="text-xl md:text-2xl font-medium break-words">Dashboard Access</span></div>
                        <div className="bg-[#FF6C2F] text-white w-full md:w-80 lg:w-96 h-20 rounded-full flex justify-center items-center mt-3 md:mt-0"><span className="text-xl md:text-2xl font-medium break-words">Timely Royalty Payouts</span></div>
                        <div className="bg-white text-black w-full md:w-96 lg:w-96 h-20 rounded-full flex justify-center items-center mt-3 md:mt-0"><span className="text-xl md:text-2xl font-medium break-words">Content Push & Placements</span></div>
                    </div>
                </div>

                <div className="px-4 md:px-10 lg:px-20 xl:px-40">
                    <p className="text-white text-2xl md:text-3xl lg:text-4xl font-normal break-words md:px-24 pt-8 md:pt-32 pb-10 md:pb-20 lg:pb-24">
                        Get your music on Hungama, Spotify, Apple Music, Wynk, YouTube, JioSaavn and more.
                    </p>
                    <div className="flex flex-wrap justify-center items-center px-4 md:px-18 pb-12 md:pb-0 space-x-4 md:space-x-6 lg:space-x-8">
                        <img src="assets/hungama-logo.png" alt="hungama-logo" className="w-32 md:w-44 h-auto" />
                        <img src="assets/spotify-logo.png" alt="spotify-logo" className="w-28 md:w-36 h-auto" />
                        <img src="assets/wynk-logo.svg" alt="wynk-logo" className="w-40 md:w-60 h-auto" />
                        <img src="assets/tataPlay-logo.png" alt="tataplay-logo" className="w-44 md:w-64 h-auto" />
                        <img src="assets/savan-logo.png" alt="saven-logo" className="w-32 md:w-40 h-auto" />
                        <span className="text-white text-lg md:text-2xl font-normal">+ more</span>
                    </div>
                </div>
            </section>

            <section className="bg-[#FEC961]">
                <div className="flex flex-col md:flex-row justify-center items-center py-8 md:py-16 lg:py-32 px-4 md:px-10 lg:px-20 xl:px-40">
                    <img src="assets/artist-pic.png" alt="artist-pic" className="w-64 md:w-auto h-auto mb-8 md:mb-0" />
                    <div className="md:pl-16 md:pr-40">
                        <h1 className="text-2xl md:text-4xl lg:text-5xl font-bold pb-4 md:pb-8">Let us help you reach your music to the world.</h1>
                        <p className="text-xl md:text-3xl font-normal break-words pb-8 md:pb-16">Get your music playing over various digital stores and streaming services worldwide.</p>
                        <Link to={publicRouteLink.SIGNIN}>
                            <button className="text-white bg-[#FF6C2F] w-full md:w-80 h-20 rounded-full">
                                <span className="text-lg md:text-2xl font-medium break-words">Artist Dashboard</span>
                            </button>
                        </Link>
                    </div>
                </div>
            </section>

            <section className="bg-gradient-to-t from-[#FEC961] to-black min-h-screen">
                <div className="pt-16 md:pt-20 lg:pt-32 flex justify-center">
                    <h1 className="text-white text-4xl md:text-6xl lg:text-7xl font-bold break-words text-center max-w-3xl">
                        Want to get started?
                    </h1>
                </div>

                <div className="pt-8 md:pt-12 lg:pt-16 container mx-auto text-center">
                    <p className="text-white text-lg md:text-3xl lg:text-4xl font-medium break-words text-center items-center">
                        Welcome to the B2B website of Hungama Artist Aloud! This is the place where YOU as an artist can share with us your creativity for Acquisition and Distribution.
                    </p>
                </div>

                <div className="py-8 md:py-24 lg:py-32 px-6 md:px-0 flex flex-col md:flex-row justify-center items-center space-y-8 md:space-y-0 md:space-x-8 lg:space-x-12">
                    <div className="w-80 md:w-96 h-40 flex justify-center items-center bg-[#474135] rounded-3xl">
                        <span className="text-white text-xl md:text-3xl font-medium">Create Music</span>
                    </div>
                    <div className="w-80 md:w-96 h-40 flex justify-center items-center bg-[#474135] rounded-3xl">
                        <span className="text-white text-xl md:text-3xl font-medium">Distribute worldwide</span>
                    </div>
                    <div className="w-80 md:w-96 h-40 flex justify-center items-center bg-[#474135] rounded-3xl">
                        <span className="text-white text-xl md:text-3xl font-medium">Start Earning</span>
                    </div>
                </div>
            </section>

            <section className="bg-black text-white">
                <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold break-words text-center px-4 md:px-0 py-12 md:py-16 lg:py-20">
                    What you can expect
                </h1>

                <div className="flex flex-col md:flex-row justify-center items-center space-y-8 md:space-y-0 md:space-x-14 pb-16 md:pb-20 lg:pb-24">
                    <div className="w-full md:w-1/4">
                        <h3 className="text-2xl md:text-3xl font-bold break-words text-center">Targeted Promotion</h3>
                        <p className="text-lg md:text-xl font-normal text-center break-words">
                            Get your music in front of the right audience, ensuring it reaches those who'll appreciate it the most.
                        </p>
                    </div>

                    <div className="w-full md:w-1/4">
                        <h3 className="text-2xl md:text-3xl font-bold break-words text-center">Social Media Mastery</h3>
                        <p className="text-lg md:text-xl font-normal text-center break-words">
                            Harness the power of social media with engaging content and strategies that boost your online presence.
                        </p>
                    </div>

                    <div className="w-full md:w-1/4">
                        <h3 className="text-2xl md:text-3xl font-bold break-words text-center">Fan Engagement</h3>
                        <p className="text-lg md:text-xl font-normal text-center break-words">
                            Build a loyal fan base through meaningful connections and interactions.
                        </p>
                    </div>

                    <div className="w-full md:w-1/4">
                        <h3 className="text-2xl md:text-3xl font-bold break-words text-center">Data-Driven Insights</h3>
                        <p className="text-lg md:text-xl font-normal text-center break-words">
                            Stay informed with data analytics that help you make informed decisions for your artist career.
                        </p>
                    </div>
                </div>
            </section>


            <section className="bg-gradient-to-t from-[#FEC961] to-black text-white pt-10">
                <h1 className="text-6xl font-bold text-center">Artist Review</h1>
                <div className="flex justify-center">
                    <div className=" pt-16 pb-32 w-3/4">
                        <Slider {...settings}>
                            {data.map((item, index) => {
                                return <div className="" key={index}>
                                    <div className="bg-black flex items-center px-16 h-80 rounded-l-2xl rounded-r-2xl relative">
                                        <div className="w-2/5 flex justify-center items-center">
                                            <img src={item.img} alt={item.name} className="rounded-full" />
                                        </div>
                                        <div className=" flex flex-col justify-start items-start w-3/5">
                                            <h2 className="text-2xl font-medium break-words">{item.name}</h2>
                                            <img src="assets\quote.png" alt="quotes" className="" />
                                            <p className="text-lg font-medium">{item.bio}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </Slider>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LandingPage;
