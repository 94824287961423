import React, { useState, useRef } from "react";
import { FaIndianRupeeSign } from "react-icons/fa6";

export default function MarketingPlans() {
  const plansRef = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div
      style={{
        backgroundImage: "url('/assets/lines.png')",
        backgroundSize: "100vw",
        backgroundRepeat: "no-repeat",
        fontFamily: "Poppins, sans-serif",
      }}
    >
      <div className="flex">
        <h2
          className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          Marketing <span className="text-[#FEC961] ml-1">Services</span>
        </h2>
      </div>
      <div className="flex flex-col text-[#FFFFFF]">
        <div className="text-[3.5rem] mt-[6%] mb-[3%] ml-[12%] flex items-center justify-left">
          <p className="w-[70%]">
            {`Want your music to reach 
          your desired audience?`}
          </p>
        </div>
        <div className="flex gap-5 w-[70%] ml-[12%]">
          <div
            className="px-[50px] py-[5px] rounded-md cursor-pointer"
            style={{
              background: "#2f3132",
            }}
            onClick={() => scrollToSection(plansRef)}
          >
            <div className="font-semibold">Free Plan</div>
          </div>
          <div
            className=" px-[50px] py-[5px] rounded-md cursor-pointer"
            style={{
              background: "rgba(154, 154, 154, 0.43)",
            }}
            onClick={() => scrollToSection(plansRef)}
          >
            <div className="font-semibold">Silver Plan</div>
          </div>
          <div
            className="px-[50px] py-[5px] rounded-md cursor-pointer"
            style={{
              background: "rgba(255, 193, 71, 0.53)",
            }}
            onClick={() => scrollToSection(plansRef)}
          >
            <div className="font-semibold">Gold Plan</div>
          </div>

          <div
            className="px-[50px] py-[5px] rounded-md cursor-pointer"
            style={{
              background: "#8F00FF33",
            }}
            onClick={() => scrollToSection(plansRef)}
          >
            <div className="font-semibold">Platinum Plan</div>
          </div>
        </div>
        <div className="w-[80%] text-[0.75rem] mt-[3%] ml-[12%] flex items-center justify-center">
          <p>
            {`At Artist Aloud, transparency and simplicity are at the core of what we do. We believe in offering clear and fair pricing without any hidden surprises. Our goal is to provide you with the best value for our services/products while ensuring you understand exactly what you're getting. your desired audience?`}
          </p>
        </div>
        <div className="mt-[15%] mb-[3%] flex flex-col items-end">
          <div className="w-[40%]">
            <p className="text-[3.5rem]">
              {`Promote Your Music on Social Platforms`}
            </p>
            <div className="flex justify-start w-[100%]">
              <div
                className="px-[50px] py-[5px] rounded-md cursor-pointer"
                style={{
                  background: "#8F00FF33",
                }}
                onClick={() => scrollToSection(plansRef)}
              >
                <div className="font-semibold">Platinum Plan</div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[15%] mb-[3%] ml-[12%] flex flex-col items-start">
          <div className="w-[50%]">
            <p className="text-[3.5rem]">
              {`Get the benefits of Enhance distribution packages`}
            </p>
            <div className="flex justify-start w-[100%] gap-5">
              <div
                className="px-[50px] py-[5px] rounded-md cursor-pointer"
                style={{
                  background: "#8F00FF33",
                }}
                onClick={() => scrollToSection(plansRef)}
              >
                <div className="font-semibold">Platinum Plan</div>
              </div>
              <div
                className="px-[50px] py-[5px] rounded-md cursor-pointer"
                style={{
                  background: "rgba(255, 193, 71, 0.53)",
                }}
                onClick={() => scrollToSection(plansRef)}
              >
                <div className="font-semibold">Gold Plan</div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="flex flex-col items-center justify-center rounded-md ml-auto mr-auto w-[90%] mt-[5%] pb-[4%]"
          style={{
            background: `linear-gradient(0deg, #191919, #191919), 
                 linear-gradient(219.73deg, rgba(255, 255, 255, 0.2) -20.78%, rgba(0, 0, 0, 0) 77.31%),
                 linear-gradient(330.82deg, rgba(0, 0, 0, 0.4) -5.18%, rgba(161, 161, 161, 0.1) 92.84%)`,
          }}
          ref={plansRef}
        >
          <div className="pt-[20px] w-[90%] ml-[2%]">
            <p className="text-[3.5rem] w-[40%] ">
              Select the <span className="text-[#FF6C2F]">plan</span> that fits
              you
            </p>
          </div>
          <div className="flex flex-row gap-8 justify-center w-[100%] py-[20px] ">
            <div className="flex flex-col py-[20px] rounded-xl h-auto w-[20%] bg-[#2f3132] pl-[20px]">
              <div className="text-[1.3rem]"> Free Plan</div>
              <div className="flex flex-row items-center">
                <span className="text-[3rem] font-semibold">FREE</span>
              </div>
              <div className="flex flex-col gap-4 w-[90%] text-[0.7rem] mt-[28%]">
                <div>Unlimited Releases to all distribution Platforms</div>
                <div>1 announcement across all artist aloud social media</div>
                <div>Engage with ChatBot for information</div>
                <div>Artist Revenue Splits 50% artist - 50% Hungama</div>
                <div className="opacity-40">
                  Customer Service Response in 24 hours CTA in 5-7 working days
                </div>
                <div className="opacity-40">Enhanced Distribution Packages</div>
                <div className="opacity-40">Playlist Pitching Services</div>
                <div className="opacity-40">Social Media ad Campaigns</div>
                <div className="opacity-40">Store Maximizer</div>
                <div className="opacity-40">
                  Advanced Analytics Subscriptions
                </div>
              </div>
              <div className="">
                <button
                  className="text-white text-[1rem] font-semibold py-2 px-[50px] mx-4 mt-10 rounded-md transition-transform transform hover:scale-105"
                  style={{
                    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
                          linear-gradient(180deg, #FF6C2F 0%, rgba(255, 160, 47, 0.984375) 0.01%, rgba(255, 100, 0, 0.6) 100%)`,
                    backgroundBlendMode: "overlay",
                  }}
                >
                  Buy Now
                </button>
              </div>
            </div>
            <div
              className="pt-[20px] pb-[40px] rounded-xl  h-auto w-[20%] pl-[20px]"
              style={{ backgroundColor: "rgba(154, 154, 154, 0.43)" }}
            >
              <div className="text-[1.3rem] "> Silver Plan</div>
              <div className="flex flex-row items-center">
                <FaIndianRupeeSign size={18} />
                <span className="text-[3rem] font-semibold">
                  999<span className="text-[1rem]">/year</span>
                </span>
              </div>
              <div className="text-[0.7rem] opacity-80 mb-[20%]">
                + taxes /per song
              </div>
              <div className="flex flex-col gap-4 w-[90%] text-[0.7rem]">
                <div>Unlimited Releases to all distribution Platforms</div>
                <div>2 announcement across all artist aloud social media</div>
                <div>Engage with ChatBot for information</div>
                <div>Artist Revenue Splits 70% artist - 30% Hungama</div>
                <div>
                  Customer Service Response in 24 hours CTA in 5-7 working days
                </div>
                <div className="opacity-40">Enhanced Distribution Packages</div>
                <div className="opacity-40">Playlist Pitching Services</div>
                <div className="opacity-40">Social Media ad Campaigns</div>
                <div className="opacity-40">Store Maximizer</div>
                <div className="opacity-40">
                  Advanced Analytics Subscriptions
                </div>
              </div>
              <div className="">
                <button
                  className="text-white font-semibold py-2 px-[50px] mx-4 mt-10 rounded-md transition-transform transform hover:scale-105"
                  style={{
                    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
                          linear-gradient(180deg, #FF6C2F 0%, rgba(255, 160, 47, 0.984375) 0.01%, rgba(255, 100, 0, 0.6) 100%)`,
                    backgroundBlendMode: "overlay",
                  }}
                >
                  Buy Now
                </button>
              </div>
            </div>
            <div
              className="py-[20px] rounded-xl h-auto w-[20%] text-[1.5rem] pl-[20px]"
              style={{ background: "rgba(255, 193, 71, 0.53)" }}
            >
              <div className=" text-[1.3rem]">Gold Plan</div>
              <div className="flex flex-row items-center">
                <FaIndianRupeeSign size={18} />
                <span className="text-[3rem] font-semibold">
                  1,499<span className="text-[1rem]">/year</span>
                </span>
              </div>
              <div className="text-[0.7rem] opacity-80 mb-[20%]">
                + taxes /per song
              </div>
              <div className="flex flex-col gap-4 w-[90%] text-[0.7rem]">
                <div>Unlimited Releases to all distribution Platforms</div>
                <div>4 announcement across all artist aloud social media</div>
                <div>Engage with ChatBot for information</div>
                <div>Artist Revenue Splits 75% artist - 25% Hungama</div>
                <div>
                  Customer Service Response in 24 hours CTA in 3-5 working days
                </div>
                <div>Enhanced Distribution Packages</div>
                <div>Playlist Pitching Services</div>
                <div className="opacity-40">Social Media ad Campaigns</div>
                <div className="opacity-40">Store Maximizer</div>
                <div className="opacity-40">
                  Advanced Analytics Subscriptions
                </div>
              </div>
              <div className="">
                <button
                  className="text-white text-[1rem] font-semibold py-2 px-[50px] mx-4 mt-10 rounded-md transition-transform transform hover:scale-105"
                  style={{
                    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
                          linear-gradient(180deg, #FF6C2F 0%, rgba(255, 160, 47, 0.984375) 0.01%, rgba(255, 100, 0, 0.6) 100%)`,
                    backgroundBlendMode: "overlay",
                  }}
                >
                  Buy Now
                </button>
              </div>
            </div>
            <div className="py-[20px] rounded-xl bg-[#8F00FF33] h-auto w-[20%] text-[1.5rem] pl-[20px]">
              <div className=" text-[1.3rem]">Platinum Plan</div>
              <div className="flex flex-row items-center">
                <FaIndianRupeeSign size={18} />
                <span className="text-[3rem] font-semibold">
                  2,499<span className="text-[1rem]">/year</span>
                </span>
              </div>
              <div className="text-[0.7rem] opacity-80 mb-[20%]">
                + taxes /per song
              </div>
              <div className="flex flex-col gap-4 w-[90%] text-[0.7rem]">
                <div>Unlimited Releases to all distribution Platforms</div>
                <div>4 announcement across all artist aloud social media</div>
                <div>Engage with ChatBot for information</div>
                <div>Artist Revenue Splits 80% artist - 20% Hungama</div>
                <div>
                  Customer Service Response in 24 hours CTA in 1-2 working days
                </div>
                <div>Enhanced Distribution Packages</div>
                <div>Playlist Pitching Services</div>
                <div>Social Media ad Campaigns</div>
                <div>Store Maximizer</div>
                <div>Advanced Analytics Subscriptions</div>
              </div>
              <div className="">
                <button
                  className="text-white text-[1rem] font-semibold py-2 px-[50px] mx-4 mt-10 rounded-md transition-transform transform hover:scale-105"
                  style={{
                    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
                          linear-gradient(180deg, #FF6C2F 0%, rgba(255, 160, 47, 0.984375) 0.01%, rgba(255, 100, 0, 0.6) 100%)`,
                    backgroundBlendMode: "overlay",
                  }}
                >
                  Buy Now
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[90%] text-[0.9rem] mt-[3%] ml-[5%] flex flex-col mb-[12%]">
          <p>
            {`Please note that all prices listed above are valid for a one-time use only. Subsequent use or additional services may be subject to revised pricing. For further details on multiple-use pricing or extended services, kindly reach out to our customer service team.`}
          </p>
          {/* <span className="mt-[3%] opacity-80">customer support: artistaloud@hungama.com</span> */}
        </div>
      </div>
    </div>
  );
}
