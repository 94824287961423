import React, { useState, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useNavigate } from "react-router-dom";
import { useContent } from "../context/main.js";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Iprs() {
  const { contentType } = useContent();

  const today = new Date();
  const day = today.getDate();
  const month = today.toLocaleString("en-GB", { month: "short" });
  const year = today.getFullYear().toString().slice(-2);
  const formattedDate = `${day} ${month} ${year}`;

  const [hideContract, setHideContract] = useState(true);
  const sigCanvas = useRef({});
  const [signature, setSignature] = useState(null);

  const navigate = useNavigate();

  const handleYesClick = () => {
    navigate("/attach-audio");
  };

  const handleNoClick = () => {
    setHideContract(false);
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  const handleSubmitClick = () => {
    if (sigCanvas.current.isEmpty()) {
      alert("Please provide your digital signature before agreeing.");
    } else {
      const signatureDataURL = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      setSignature(signatureDataURL);
      // You can save the signature here
      console.log(signatureDataURL);
      alert("Submitted");
      toast.success("Submitted", { position: "top-center", autoClose: 5000 });
      navigate("/attach-audio");
    }
  };

  return (
    <div>
      <div className="flex">
        {contentType === "audio" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Audio</span>
          </h2>
        )}
        {contentType === "video" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Video</span>
          </h2>
        )}
      </div>
      <div
        className="container1"
        style={{ width: "auto", minHeight: "700px", height: "auto" }}
      >
        <div className="flex flex-col items-start profileBar w-[28%] bg-[#ffffff0d] rounded-md ">
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%] mt-[13%]">
            <Link to="/details-audio">Details</Link>
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/content-distribution">Content Distribution</Link>
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/terms-uploads">T&C</Link>
          </button>
          <br />
          <button className="text-[#FF6C2F] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/iprs">IPRS</Link>
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            {contentType === "audio" && (
              <Link to="/attach-audio">Attach Audio</Link>
            )}
            {contentType === "video" && (
              <Link to="/attach-audio">Attach Video</Link>
            )}
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/cover-image">Select Cover Image</Link>
          </button>
        </div>
        <div className="formIprs flex flex-col mt-[5%] w-[70%]">
          {hideContract ? (
            <div className="pl-[100px]">
              <div>
                <h2
                  className="text-[#FFFFFF] text-[1.1rem] mb-[5%]"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  Are you an IPRS member?
                </h2>
              </div>
              <div className="flex justify-start mt-[1%]">
                <button
                  className="w-[6rem] h-[3rem] bg-[#302F2F] rounded-md text-[#FFFFFF]  hover:bg-[#FF6C2F] active:bg-[#c75525]"
                  onClick={handleYesClick}
                >
                  Yes
                </button>
                <button
                  className="w-[6rem] h-[3rem] bg-[#302F2F] rounded-md text-[#FFFFFF] mx-[25px] hover:bg-[#484848] active:bg-[#484848]"
                  onClick={handleNoClick}
                >
                  No
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div
                className="overflow-y-scroll termsContent h-[auto] p-[30px]"
                // style={{ border: "1px solid #FFFFFF", borderRadius: "5px" }}
              >
                <div className="flex justify-center">
                  <h2
                    className="text-[#FFFFFF] text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                    }}
                  >
                    ON THE LETTER HEAD OF MUSIC PROVIDER
                  </h2>
                  <br />
                  <br />
                </div>
                <div className="flex justify-end">
                  <h2
                    className="text-[#FFFFFF] text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "300",
                    }}
                  >
                    Date:{" "}
                    <span
                      className="text-[#FFFFFF] text-[0.9rem]"
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "400",
                      }}
                    >
                      {formattedDate}
                    </span>
                  </h2>
                  <br />
                  <br />
                </div>
                <div>
                  <span
                    className="text-[#FFFFFF] text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "400",
                    }}
                  >
                    To,
                  </span>
                  <br />
                  <br />
                  <div className="flex justify-start">
                    <p
                      className="text-[#FFFFFF] text-[0.9rem]"
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "500",
                        width: "60%",
                      }}
                    >
                      Hungama Digital Media Entertainment Pvt. Ltd. Registered
                      office - SF-B-07, Art Guild House, Phoenix Market City,
                      LBS Marg, Kurla (W), Mumbai-400070, Maharashtra, India
                    </p>
                  </div>
                  <br />
                  <p
                    className="text-[#FFFFFF] text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "300",
                    }}
                  >
                    Dear Sir,
                  </p>
                  <br />
                  <p
                    className="text-[#FFFFFF] text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "300",
                    }}
                  >
                    This is in reference to the sound recordings (as defined
                    under the Copyright Act, 1957) owned and/or controlled by
                    us, the Exclusive and limited rights of which have been
                    licensed to you for exploitation on properties and services
                    owned by you (the “Content”).
                  </p>
                  <br />
                  <p
                    className="text-[#FFFFFF] text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "300",
                    }}
                  >
                    We hereby confirm that the publishing rights in and to the
                    Content i.e., the rights in and to the underlying literary
                    and musical works and performances incorporated in the
                    Content are solely and exclusively owned by us.
                  </p>
                  <br />
                  <p
                    className="text-[#FFFFFF] text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "300",
                    }}
                  >
                    We understand that as part of the properties and services
                    offered by you, you shall be utilizing both our sound
                    recordings as well as the underlying literary and musical
                    works and performances incorporated in such sound
                    recordings.
                  </p>
                  <br />
                  <p
                    className="text-[#FFFFFF] text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "300",
                    }}
                  >
                    We have granted a valid and subsisting license in our sound
                    recording rights to Hungama Artist Aloud, a division of
                    Hungama Digital Media Entertainment Private Limited and/or
                    its group companies (collectively “Hungama”), as the case
                    maybe, for the purposes of exercising the rights licensed by
                    us to Hungama for the entire duration of the term of rights
                    licensed including any extensions and/or renewals thereof
                    (“Term”) on the properties and services offered by you.
                  </p>
                  <br />
                  <p
                    className="text-[#FFFFFF] text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "300",
                    }}
                  >
                    During the interim period i.e., until we authorize IPRS to
                    administer publishing rights in and to the Content for and
                    on our behalf, we, as the owner of the sound recordings and
                    underlying musical and literary works and performances
                    incorporated in such Content, hereby authorize you to use
                    such underlying musical and literary works and performances
                    incorporated in such Content as part of the properties and
                    services offered by you.
                  </p>
                  <br />
                  <p
                    className="text-[#FFFFFF] text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "300",
                    }}
                  >
                    We hereby agreed to indemnify you, your directors and
                    employees for any issue arising out of the usage of such
                    underlying works and performances.
                  </p>
                  <br />
                  <br />
                  <br />
                  <p
                    className="text-[#FFFFFF] text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "300",
                    }}
                  >
                    Yours sincerely,
                  </p>
                  <br />
                  <p
                    className="text-[#FFFFFF] text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "300",
                    }}
                  >
                    For and on behalf of test
                  </p>
                  <br />
                  <br />
                </div>
              </div>
              <div className="m-[auto] mt-[4%] pl-[5%]">
                <h2 className="text-[#FFFFFF] text-[1rem] mb-[10px]">
                  Digital Signature:
                </h2>
                <SignatureCanvas
                  ref={sigCanvas}
                  canvasProps={{
                    width: 500,
                    height: 200,
                    className: "sigCanvas",
                  }}
                  penColor="#FFFFFF"
                />

                <div className="flex mt-[10px]">
                  <button
                    className="text-[#FFFFFF] w-[6rem] h-[2rem] bg-[#FF6C2F] rounded-[5px]"
                    onClick={clearSignature}
                  >
                    Clear
                  </button>
                </div>
              </div>
              <div
                className="flex justify-end mb-[5%] mt-[5%] pr-[5%]"
                style={{ flexGrow: "8" }}
              >
                <button
                  className="text-[#FFFFFF] w-[6rem] h-[3rem] bg-[#FF6C2F] rounded-md hover:bg-[#d95a25] active:bg-[#c15122]"
                  style={{
                    fontFamily: "Poppins, sanSerif",
                  }}
                  onClick={handleSubmitClick}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
