import { Outlet } from "react-router-dom";
import React from 'react'
import Navbar1 from "../components/Navbar1";
import Footer from "../components/Footer";

const PublicRoutes = () => {

    return (
        <>
            <Navbar1 />
            <Outlet />
            <Footer />
        </>)
}

export default PublicRoutes
