import React, { useState, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useContent } from "../context/main.js";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";


export default function TermsUpload() {
  const { contentType } = useContent();

  const [lincensorName, setLicensorName] = useState("");
  const [artistName, setArtistName] = useState("");
  const [albumName, setAlbumName] = useState("");
  const [trackName, setTrackName] = useState("");
  const [dateOfExecution, setDateOfExecution] = useState("");
  const [showContent, setShowContent] = useState(false);
  const sigCanvas = useRef({});
  const [signature, setSignature] = useState(null);

  const navigate = useNavigate();

  const handleAgreeClick = () => {
    if (sigCanvas.current.isEmpty()) {
      alert("Please provide your digital signature before agreeing.");
    } else {
      const signatureDataURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
      setSignature(signatureDataURL);
      // You can save the signature here
      console.log(signatureDataURL);
      navigate("/iprs");
    }
  };
  const handleDisagreeClick = () => {
    navigate("/upload");
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  const saveSignature = () => {
    const signature = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    console.log(signature);
  };

  const toggleContent = () => {
    setShowContent(!showContent);
  };

  return (
    <div>
      <div className="flex">
        {contentType === "audio" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Audio</span>
          </h2>
        )}
        {contentType === "video" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Video</span>
          </h2>
        )}
      </div>
      <div className="container1">
        <div className="flex flex-col items-start profileBar w-[28%]  bg-[#ffffff0d] rounded-md">
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%] mt-[13%]">
            <Link to="/details-audio">Details</Link>
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/content-distribution">Content Distribution</Link>
          </button>
          <br />
          <button className="text-[#FF6C2F] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/terms-uploads">T&C</Link>
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/iprs">IPRS</Link>
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            {contentType === "audio" && (
              <Link to="/attach-audio">Attach Audio</Link>
            )}
            {contentType === "video" && (
              <Link to="/attach-audio">Attach Video</Link>
            )}
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/cover-image">Select Cover Image</Link>
          </button>
        </div>
        <div className="mt-[4%] flex flex-col  max-w-[70%]">
          <div className="overflow-y-scroll termsContent">
            <div className="flex justify-center">
              <h2
                className="text-[#FFFFFF] text-[0.9rem]"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "300",
                }}
              >
                ANNEXURE TO ACKNOWLEDGE THE ADDITION OF THE TRACK TO THE
                AGREEMENT
              </h2>
            </div>
            <br />
            <br />
            <div className="flex flex-col justify-center ml-[12%]">
              <h2
                className="text-[#FFFFFF] text-[1rem]"
                style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400" }}
              >
                Licensor: <span>{lincensorName}</span>
              </h2>
              <br />
              <h2
                className="text-[#FFFFFF] text-[1rem]"
                style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400" }}
              >
                Artist Name: <span>{artistName}</span>
              </h2>
              <br />
              <h2
                className="text-[#FFFFFF] text-[1rem]"
                style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400" }}
              >
                Album Name: <span>{albumName}</span>
              </h2>
              <br />
              <h2
                className="text-[#FFFFFF] text-[1rem]"
                style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400" }}
              >
                Track Name: <span>{trackName}</span>
              </h2>
              <br />
              <h2
                className="text-[#FFFFFF] text-[1rem]"
                style={{ fontFamily: "Poppins, sans-serif", fontWeight: "400" }}
              >
                Date of Execution: <span>{dateOfExecution}</span>
              </h2>
              <br />
            </div>
            <div className="flex justify-center">
              <h2
                className="text-[#FFFFFF] text-[1rem]"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "300",
                  textDecorationLine: "underline",
                }}
              >
                To Whomsoever It May Concern
              </h2>
            </div>
            <br />
            <div
              className="text-[#FFFFFF] text-[0.9rem] mx-[10%] p-[10px]"
              style={{
                maxWidth: "100%",
                fontFamily: "Poppins, sans-serif",
                fontWeight: "200",
                textAlign: "justify",
                // backgroundColor: "#ffffff0d",
              }}
            >
              <h3>
                This is to certify that by virtue of arrangement between faf and
                we have granted Hungama the exclusive Rights to the Audio /
                Video Content mentioned inAnnexure A including future content
                during the term of agreement to this letter. The rights are
                licensed for period of on an auto renewal basis commencing
                from 10th July 2024 (‘Effective Date”) until terminated by
                either Party.
              </h3>
              <br />
              <h3>
                Hungama as an agent shall have the rights to sub-license,
                reformat, perform, publish, display, transmit, copy, store,
                stream, rent, hire, embed, retail, offer for subscription,
                downloading, streaming and/or marketing, exploiting &
                distributing the content (Licensed Content) on all digital
                platforms including Virtual Reality unless specifically excluded
                by agreement (which are not limited to Mobile, Internet, Hungama
                platform and its affiliates ) Hungama may exercise the licensed
                rights in respect of Licensed Content in the territory of
                the World. This annexure shall include existing as well as
                future Hungama platforms.
              </h3>
              <br />
              <h3>
                The Artist / Licensor has full right, title and interest in and
                to all Intellectual Property Rights in the Licensed Content and
                is legally authorized and entitled to provide the royalty free
                Licensed Content to the Licensee for exploiting the same as
                provided in this Agreement. Artist/ Licensor represents and
                warrants that the Licensee shall be entitled to exploit the
                Licensed Content without any obstruction or interference from
                any third party.
              </h3>
              <br />
              <h3>
                The Artist / Licensor indemnifies and hold harmless the Licensee
                from and against any loss, injury or damage caused to Licensee
                in consequence of any of the breach of warranties or arising out
                of any claim alleging that the use of the Content constitutes in
                any way a breach of any warranties or rights of other parties,
                including but not limited to alleged or actual infringement of
                intellectual property rights who may have claim over the said
                Content. The indemnification shall include any legal costs,
                punitive or compensatory awards or expenses and disbursements
                paid by Licensee on advice of its legal counsel to compromise or
                settle any claim.
              </h3>
              <br />
              <h3>
                This Annexure is an addition to the Agreement and all other
                terms and conditions of the Agreement shall remain in full force
                and effect.
              </h3>
            </div>
          </div>
          <div>
            <a
              className="text-[#FFFFFF] text-[0.9rem] p-[30px]"
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "300",
                textDecoration: "underline",
              }}
              onClick={toggleContent}
              clickable
            >
              {showContent ? "Hide Main Agreement" : "Show Main Agreement"}
            </a>
          </div>

          {showContent && (
            <div className="overflow-y-scroll termsContent flex flex-col p-[30px]">
              <div className="flex justify-center">
                <h1
                  className="text-[#FFFFFF] text-[1.8rem]"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "500",
                    textDecoration: "underline",
                  }}
                >
                  AGREEMENT
                </h1>
              </div>
              <div>
                <div>
                  <br />
                  <br />
                  <h2
                    className="text-[#FFFFFF] text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "400",
                    }}
                  >
                    This agreement is made on: 19th April 2024
                  </h2>
                  <br />
                  <br />
                  <table
                    style={{
                      border: "1px solid white",
                      width: "100%",
                      borderCollapse: "collapse",
                    }}
                  >
                    <tr style={{ border: "1px solid white" }}>
                      <th
                        className="text-[#FFFFFF] text-[0.9rem]"
                        style={{
                          border: "1px solid white",
                          borderCollapse: "collapse",
                        }}
                      >
                        Artist / Licensor
                      </th>
                      <td
                        className="text-[#FFFFFF] text-[0.9rem] w-[70%]"
                        style={{
                          border: "1px solid white",
                          width: "60%",
                          borderCollapse: "collapse",
                          textAlign: "center",
                        }}
                      >
                        test
                      </td>
                    </tr>
                    <tr style={{ border: "1px solid white" }}>
                      <th
                        className="text-[#FFFFFF] text-[0.9rem]"
                        style={{
                          border: "1px solid white",
                          borderCollapse: "collapse",
                        }}
                      >
                        Address
                      </th>
                      <td
                        className="text-[#FFFFFF] text-[0.9rem]"
                        style={{
                          border: "1px solid white",
                          width: "60%",
                          borderCollapse: "collapse",
                          textAlign: "center",
                        }}
                      >
                        test
                      </td>
                    </tr>
                    <tr style={{ border: "1px solid white" }}>
                      <th
                        className="text-[#FFFFFF] text-[0.9rem]"
                        style={{
                          border: "1px solid white",
                          borderCollapse: "collapse",
                        }}
                      >
                        Mobile No.
                      </th>
                      <td
                        className="text-[#FFFFFF] text-[0.9rem]"
                        style={{
                          border: "1px solid white",
                          width: "60%",
                          borderCollapse: "collapse",
                          textAlign: "center",
                        }}
                      >
                        test
                      </td>
                    </tr>
                    <tr style={{ border: "1px solid white" }}>
                      <th
                        className="text-[#FFFFFF] text-[0.9rem]"
                        style={{
                          border: "1px solid white",
                          borderCollapse: "collapse",
                        }}
                      >
                        Email
                      </th>
                      <td
                        className="text-[#FFFFFF] text-[0.9rem]"
                        style={{
                          border: "1px solid white",
                          width: "60%",
                          borderCollapse: "collapse",
                          textAlign: "center",
                        }}
                      >
                        test
                      </td>
                    </tr>
                    <tr style={{ border: "1px solid white" }}>
                      <th
                        className="text-[#FFFFFF] text-[0.9rem]"
                        style={{
                          border: "1px solid white",
                          borderCollapse: "collapse",
                        }}
                      >
                        PAN No.
                      </th>
                      <td
                        className="text-[#FFFFFF] text-[0.9rem]"
                        style={{
                          border: "1px solid white",
                          width: "60%",
                          borderCollapse: "collapse",
                          textAlign: "center",
                        }}
                      >
                        test
                      </td>
                    </tr>
                  </table>
                </div>
                <br />
                <div>
                  <h1
                    className="text-[#FFFFFF] text-[1rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                      textDecoration: "underline",
                    }}
                  >
                    Licensee: Hungama Digital Media Entertainment Pvt Ltd
                  </h1>
                  <br />
                  <h1
                    className="text-[#FFFFFF] text-[1rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                      textDecoration: "underline",
                    }}
                  >
                    Address: SF-B-07, Art Guild House, Phoenix Market City, LBS
                    Marg, Kurla (W), Mumbai, Maharashtra 400 070.
                  </h1>
                  <br />
                  <h1
                    className="text-[#FFFFFF] text-[1rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                      textDecoration: "underline",
                    }}
                  >
                    Term & Conditions
                  </h1>
                </div>
                <br />
                <div
                  className="text-[#FFFFFF] text-[0.9rem]"
                  style={{
                    maxWidth: "100%",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "200",
                    textAlign: "justify",
                    // backgroundColor: "#ffffff0d",
                  }}
                >
                  <h3 className="">
                    The Artist / Licensor hereby agrees to be bound by and
                    subject to the following terms & conditions which comprises
                    a legally binding agreement between the Artist / Licensor
                    and Hungama Digital Media Entertainment Pvt Ltd:
                  </h3>
                  <br />
                  <ul className=" mx-[5%]">
                    <li>
                      The Artist / Licensor represents and warrants that it is
                      the owner and / or has the necessary rights in the content
                      licensed (hereinafter “Licensed Content“) to Hungama
                      Digital Media Entertainment Pvt. Ltd. (hereinafter
                      referred to as the “Licensee“) to grant the said License.
                    </li>
                    <br />
                    <li>
                      The Artist / Licensor hereby grants an non-exclusive,
                      sub-licensable, irrevocable license to distribute, exploit
                      and market the License Content on the Distribution Channel
                      and make the same available to the end users for
                      monetization and offer the Licensed Content for
                      sub-licensing, download, streaming, renting, hiring,
                      retail, subscription in any form or manner now known or
                      hereafter devised and/ or invented in future. Hungama
                      shall distribute the Licensed Content exclusively on the
                      Distribution Channels that has been informed and further
                      shall be informed via email in case of future additions,
                      which shall form an integral part of this Agreement.
                    </li>
                    <br />
                    <li>
                      The 'Distribution Channel' shall mean the modes of
                      distribution of the Licensed Content through Hungama and
                      all its affiliates and other platforms including but not
                      limited to Mobile (including IVR and WAP), Application,
                      DTH, Linear TV, Virtual reality, WAP and Website, Podcast
                      services, OTT Services; In-flight Entertainment,
                      terrestrial radio etc. The Licensee shall also have the
                      right to exploit the Licensed Content through its
                      affiliates in the territory of World during the Term of
                      this Agreement. This agreement shall include existing as
                      well as future Hungama platforms.
                    </li>
                    <br />

                    <li>
                      Existing content of the Artist / Licensor as well as
                      future content produced by the Artist / Licensor shall be
                      covered within the ambit of this Agreement. Details of
                      such content shall be added with an email approval from
                      the Artist/ licensor and the same shall form integral part
                      of this Agreement.
                    </li>
                    <br />
                    <li>
                      The term shall be effective for a period of Three (3)
                      years commencing from 19th April 2024 (‘Effective Date”)
                      unless otherwise earlier terminated as per provisions of
                      this Agreement.
                    </li>
                    <br />
                    <li>
                      The licensor represents and warrants that the exploitation
                      of the rights herein and the underlying works forming part
                      of the Content shall not infringe the copyright (including
                      the moral rights of the authors of the underlying works),
                      right of privacy or any other rights whatsoever of any
                      person. Further, the Licensor agrees that the provisions
                      of Section 19(4), 19A and 30A of the Copyright Act, 1957
                      (as amended) shall not apply to this Agreement and the
                      Licensor hereby waives its rights under the aforesaid
                      provisions of the said Act.
                    </li>
                    <br />
                    <li>
                      The Licensee shall share 70% of Net Revenue generated from
                      the exploitation of the Licensed Content with the Artist /
                      Licensor. Net Revenue shall mean End User Price less
                      either Bandwidth Cost or Operator Share (as may be
                      applicable) after deduction of Taxes. For streaming of the
                      Licensed Content on Hungama Music, the Licensee shall pay
                      on a per stream basis and the same will be displayed in
                      the report sent to the Artist/Licensor.
                    </li>
                    <br />
                    <li>
                      The Artist/Licensor acknowledges that no royalty payment
                      and/or aggregation charges shall be paid by the Licensee
                      for the Licensed Content used for promotion purpose and
                      for the content which is streamed for free.
                    </li>
                    <br />
                    <li>
                      Licensee shall provide quarterly reports of exploitation
                      of Licensed Content the Artist / Licensor. Based on the
                      reports, the Artist / Licensor shall raise an invoice for
                      its share of Net Revenue on the Licensee. Net Revenue
                      shall be paid to the Licensor if such Net Revenue share of
                      Licensor exceeds minimum Rs.1,500/- (Rupees One Thousand
                      and Five Hundred Only) for Artist based in India and
                      minimum $1000 (USD One Thousand Only) for Artist based
                      outside of India. This payment clause will have exception
                      where the Agreement is terminated or expires, Hungama is
                      obliged to pay the implied outstanding amount to the
                      Licensor.
                    </li>
                    <br />
                    <li>
                      Artist / Licensor agrees that the Net Revenue share
                      received by it from the Licensee vide this Agreement is
                      inclusive of royalties and publishing fees. Licensee shall
                      not be liable to pay any royalties, fees or any other
                      amount to the Artist / Licensor or to any music composer,
                      lyricist, performer or any third party for exploiting the
                      Licensed Content. Any such payment to the third Party
                      shall be the sole and non-exclusive responsibility of the
                      Artist / Licensor.
                    </li>
                    <br />
                    <li>
                      The Artist / Licensor has full right, title and interest
                      in and to all Intellectual Property Rights in the Licensed
                      Content and is legally authorized and entitled to provide
                      the royalty free Licensed Content to the Licensee for
                      exploiting the same as provided in this Agreement. Artist/
                      Licensor represents and warrants that the Licensee shall
                      be entitled to exploit the Licensed Content without any
                      obstruction or interference from any third party.
                    </li>
                    <br />
                    <li>
                      The Artist/Licensor has obtained the necessary and valid
                      license and all other approvals, consents, permissions
                      etc.; which shall be kept in force and valid, and to
                      provide the Content contemplated herein which includes
                      compliance of statutory and non-statutory requirements and
                      obligations and all other digital management rights,
                      various issues and obligations to protect the Intellectual
                      Property Rights.
                    </li>
                    <br />
                    <li>
                      The Artist / Licensor indemnifies and hold harmless the
                      Licensee from and against any loss, injury or damage
                      caused to Licensee in consequence of any of the breach of
                      warranties or arising out of any claim alleging that the
                      use of the Content constitutes in any way a breach of any
                      warranties or rights of other parties, including but not
                      limited to alleged or actual infringement of intellectual
                      property rights who may have claim over the said Content.
                      The indemnification shall include any legal costs,
                      punitive or compensatory awards or expenses and
                      disbursements paid by Licensee on advice of its legal
                      counsel to compromise or settle any claim.
                    </li>
                    <br />
                    <li>
                      The Artist/Licensor warrants that the Content is fault
                      free and that service is uninterrupted. In the event of
                      any Content being unlawful, harmful, threatening, abusive,
                      harassing, tortuous, defamatory, vulgar, libellous,
                      invasive of another's privacy, hateful, or racially,
                      ethnically, or otherwise objectionable, or that expresses
                      cruelty, violence, or inhumanity and/or any Content
                      contains pornography, obscenity, or grossly insulting
                      message, or is vulgar and obscene the Licensee shall not
                      be held liable for the same.
                    </li>
                    <br />
                    <li>
                      Licensee shall be entitled to terminate this Agreement
                      without any reason by giving thirty (30) days prior notice
                      in writing to the Licensor. Either Party can terminate
                      this Agreement by giving written 30 days' notice to the
                      other party due to breach of any terms of this Agreement
                      by other Party. If such breach is not cured within thirty
                      (30) days of receipt of notice by the defaulting Party,
                      then the Party not in default may terminate this
                      Agreement.
                    </li>
                    <br />
                    <li>
                      Unless otherwise stated in writing sixty (60) days prior
                      to the expiry of the term, the Agreement will be deemed to
                      be automatically renewed on its expiry of the Term of this
                      Agreement and on same terms and condition as defined in
                      this Agreement.
                    </li>
                    <br />
                    <li>
                      The Parties will endeavor to settle amicably by mutual
                      discussions any disputes, differences, or claims
                      whatsoever related to this Agreement. The Hon’ble Courts
                      in Mumbai shall have the non-exclusive jurisdiction over
                      the disputes arising out of this Agreement between the
                      parties hereto.
                    </li>
                    <br />
                    <li>
                      Artist shall not be entitled to assign this
                      Agreement/Licensed Content without obtaining prior written
                      consent of the Licensee.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
          <div className="mx-[12%] my-[20px]">
            <h2 className="text-[#FFFFFF] text-[1rem]">Digital Signature:</h2>
            <SignatureCanvas
              ref={sigCanvas}
              canvasProps={{
                width: 500,
                height: 200,
                className: "sigCanvas",
              }}
              penColor="#FFFFFF"
            />

            <div className="flex mt-[10px]">
              <button
                className="text-[#FFFFFF] w-[6rem] h-[2rem] bg-[#FF6C2F] rounded-[5px]"
                onClick={clearSignature}
              >
                Clear
              </button>
            </div>
          </div>
          <div
            className="mb-[5%]"
            style={{
              flexGrow: "8",
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
            }}
          >
            {/* <div className="flex justify-start mt-[1%]"> */}
            <button
              className="w-[6rem] h-[3rem] bg-[#302F2F] rounded-md text-[#FFFFFF]  hover:bg-[#FF6C2F] active:bg-[#c75525]"
              onClick={handleAgreeClick}
            >
              Agree
            </button>
            <button
              className="w-[6rem] h-[3rem] bg-[#302F2F] rounded-md text-[#FFFFFF] mx-[20px] hover:bg-[#484848] active:bg-[#484848]"
              onClick={handleDisagreeClick}
            >
              Disagree
            </button>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
