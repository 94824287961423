import React, { useState } from "react";
import { useContent } from "../context/main.js";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

export default function RoyaltyReport() {
  const navigate = useNavigate();
  const { file, setFile } = useContent();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [isSelectingStart, setIsSelectingStart] = useState(true); // Track start or end selection
  const [entries, setEntries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  const handleDateChange = (date) => {
    if (isSelectingStart) {
      setStartDate(date);
      setIsSelectingStart(false); // Switch to end date selection
    } else {
      setEndDate(date);
      setShowCalendar(false); // Close calendar
    }
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "custom") {
      setShowCalendar(true); // Show calendar for custom date
    } else {
      setShowCalendar(false); // Hide calendar if other option is selected
      setStartDate(null);
      setEndDate(null); // Reset dates if switching to non-custom options
    }
  };

  const handleSubmitClick = () => {
    if (!file) {
      alert("Please upload an image before proceeding.");
      return;
    }
    navigate("/upload-submission");
  };

  const data = [
    {
      financialYear: "2023-2024",
      month: "September",
      contentName: "Song A",
      contentCategory: "Music",
      contentType: "Audio",
      vectorType: "Streaming",
      contentPartnerName: "Partner X",
      labelStatus: "Active",
      contentPartnerRevenuePercentage: "50%",
      operatorService: "Spotify",
      downloadsStreams: 100000,
      totalRevenue: "₹1,50,000",
      revenueForContentPartner: "₹75,000",
    },
    {
      financialYear: "2023-2024",
      month: "August",
      contentName: "Podcast B",
      contentCategory: "Podcast",
      contentType: "Audio",
      vectorType: "Download",
      contentPartnerName: "Partner Y",
      labelStatus: "Inactive",
      contentPartnerRevenuePercentage: "40%",
      operatorService: "Apple Music",
      downloadsStreams: 50000,
      totalRevenue: "₹80,000",
      revenueForContentPartner: "₹32,000",
    },
    {
      financialYear: "2022-2023",
      month: "July",
      contentName: "Video C",
      contentCategory: "Music Video",
      contentType: "Video",
      vectorType: "Streaming",
      contentPartnerName: "Partner Z",
      labelStatus: "Active",
      contentPartnerRevenuePercentage: "60%",
      operatorService: "YouTube",
      downloadsStreams: 200000,
      totalRevenue: "₹2,50,000",
      revenueForContentPartner: "₹1,50,000",
    },
    {
      financialYear: "2023-2024",
      month: "June",
      contentName: "Song D",
      contentCategory: "Music",
      contentType: "Audio",
      vectorType: "Streaming",
      contentPartnerName: "Partner X",
      labelStatus: "Active",
      contentPartnerRevenuePercentage: "50%",
      operatorService: "JioSaavn",
      downloadsStreams: 120000,
      totalRevenue: "₹1,20,000",
      revenueForContentPartner: "₹60,000",
    },
    {
      financialYear: "2022-2023",
      month: "May",
      contentName: "Podcast E",
      contentCategory: "Podcast",
      contentType: "Audio",
      vectorType: "Download",
      contentPartnerName: "Partner W",
      labelStatus: "Inactive",
      contentPartnerRevenuePercentage: "30%",
      operatorService: "Amazon Music",
      downloadsStreams: 30000,
      totalRevenue: "₹50,000",
      revenueForContentPartner: "₹15,000",
    },
  ];

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleEntriesPerPageChange = (event) => {
    setEntriesPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const renderPageNumbers = () => {
    const totalPages = Math.ceil(totalEntries / entriesPerPage);
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          className={`mx-1 ${i === currentPage ? "font-bold" : ""}`}
          onClick={() => setCurrentPage(i)}
        >
          {i < 10 ? `0${i}` : i}
        </button>
      );
    }
    return pages;
  };
  const totalEntries = data.length;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);
  const firstEntryIndex = (currentPage - 1) * entriesPerPage;
  const lastEntryIndex = Math.min(
    firstEntryIndex + entriesPerPage,
    totalEntries
  );

  return (
    <div>
      <div className="flex">
        <h2
          className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          Royalty <span className="text-[#FF6C2F] ml-1">Report</span>
        </h2>
      </div>
      <div
        className="container1"
        style={{
          width: "auto",
          minHeight: "400px",
          height: "auto",
          marginBottom: "2%",
        }}
      >
        <div className=" pl-[5%] pt-[4%] profileBar w-[28%] bg-[#ffffff0d] rounded-md ">
          <div className="flex flex-col items-start h-[70%]">
            <button className="text-[#FF6C2F] text-[1.3rem]">
              <Link to="/royalty-report">Generate Report</Link>
            </button>
            <br />
            <button className="text-[#FFFFFF] text-[1.3rem]">
              <Link to="/invoice">Invoice</Link>
            </button>
          </div>
          <div className="flex flex-col justify-center bg-[#ffffff0d] text-[#FFFFFF] pl-[15px] py-[10px] w-[75%] rounded-md">
            <h2 className="text-[0.9rem]">Payable Amount:</h2>
            <h1 className="text-[#FF6C2F] text-[1.2rem] font-semibold">
              Rs. {`10,234`} /-
            </h1>
          </div>
        </div>
        <div className=" mt-[4%] flex flex-col items-center justify-center w-[70%]">
          <div className="w-[35rem] rounded-md my-4">
            <div className="relative">
              <label className="text-[#FFFFFF]">Select Time Period</label>
              <select
                onChange={(e) =>
                  e.target.value === "custom" && setShowCalendar(true)
                }
                className="rounded-[5px] bg-[#302F2F] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem] pr-5 outline-none"
              >
                <option value="">Select</option>
                <option value="3months">Last 3 months</option>
                <option value="6months">Last 6 months</option>
                <option value="1year">Last 1 year</option>
                <option value="2years">Last 2 years</option>
                <option value="custom">Select Custom Date</option>
              </select>

              {startDate && endDate && (
                <p className="text-white mt-2">
                  Showing Data from: {startDate.toDateString()} to{" "}
                  {endDate.toDateString()}
                </p>
              )}

              {showCalendar && (
                <div className="absolute z-10 mt-2">
                  <Calendar onChange={handleDateChange} />
                </div>
              )}
            </div>
            <div
              className="text-[#e1dddd] text-[0.8rem] mt-[5%] w-[100%] flex flex-col gap-1"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              <div className="grid grid-cols-[1rem_auto] gap-x-1">
                <span>1.</span>
                <p>
                  The following report provides data in detail for your content
                  distributed through us.
                </p>
              </div>

              <div className="grid grid-cols-[1rem_auto] gap-x-1">
                <span>2.</span>
                <p>
                  The revenue report is in INR currency. If your payout is above
                  Rs.1500/-, please click on Invoice. Fill in the required
                  fields and upload the necessary documents.
                </p>
              </div>

              <div className="grid grid-cols-[1rem_auto] gap-x-1">
                <span>3.</span>
                <p>
                  For NRI or International Artists, please write to
                  artistaloud@hungama.com if your payout is above
                  <span className="text-[#FF6C2F]"> $1000</span> (INR
                  83,282.95/- approx.).
                </p>
              </div>

              <div className="grid grid-cols-[1rem_auto] gap-x-1">
                <span>4.</span>
                <p>
                  The 1st Royalty Report for a new Content Partner will begin
                  post
                  <span className="text-[#FF6C2F]"> 3 months</span> from the
                  date of the content being made live across distribution
                  platforms.
                </p>
              </div>
            </div>
          </div>
          <div
            className="mb-[5%] w-[80%]"
            style={{
              flexGrow: "8",
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
            }}
          ></div>
        </div>
      </div>
      <div className="flex flex-col w-full">
        <div className="w-[76%] overflow-x-auto ml-[12%] rounded-md">
          <table className="w-full table-auto border-collapse border border-gray-700 text-sm text-center">
            <thead className="bg-[#FEC961] text-black">
              <tr>
                <th className="border border-gray-700 px-4 py-2">No.</th>
                <th className="border border-gray-700 px-4 py-2">
                  Financial Year
                </th>
                <th className="border border-gray-700 px-4 py-2">Month</th>
                <th className="border border-gray-700 px-4 py-2">
                  Content Name
                </th>
                <th className="border border-gray-700 px-4 py-2">
                  Content Category
                </th>
                <th className="border border-gray-700 px-4 py-2">
                  Content Type
                </th>
                <th className="border border-gray-700 px-4 py-2">
                  Vector Type
                </th>
                <th className="border border-gray-700 px-4 py-2">
                  Content Partner Name
                </th>
                <th className="border border-gray-700 px-4 py-2">
                  Label Status
                </th>
                <th className="border border-gray-700 px-4 py-2">Revenue %</th>
                <th className="border border-gray-700 px-4 py-2">
                  Operator Service
                </th>
                <th className="border border-gray-700 px-4 py-2">
                  Streams / Downloads
                </th>
                <th className="border border-gray-700 px-4 py-2">
                  Total Revenue
                </th>
                <th className="border border-gray-700 px-4 py-2">
                  Partner Revenue
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-[#252525] text-white">
                <td className="border border-gray-700 px-4 py-2">1</td>
                <td className="border border-gray-700 px-4 py-2">2023-24</td>
                <td className="border border-gray-700 px-4 py-2">October</td>
                <td className="border border-gray-700 px-4 py-2">Song A</td>
                <td className="border border-gray-700 px-4 py-2">Music</td>
                <td className="border border-gray-700 px-4 py-2">Audio</td>
                <td className="border border-gray-700 px-4 py-2">Streaming</td>
                <td className="border border-gray-700 px-4 py-2">Partner X</td>
                <td className="border border-gray-700 px-4 py-2">Active</td>
                <td className="border border-gray-700 px-4 py-2">40%</td>
                <td className="border border-gray-700 px-4 py-2">Spotify</td>
                <td className="border border-gray-700 px-4 py-2">500K</td>
                <td className="border border-gray-700 px-4 py-2">$10,000</td>
                <td className="border border-gray-700 px-4 py-2">$4,000</td>
              </tr>
              <tr className="bg-[#252525] text-white">
                <td className="border border-gray-700 px-4 py-2">2</td>
                <td className="border border-gray-700 px-4 py-2">2023-24</td>
                <td className="border border-gray-700 px-4 py-2">September</td>
                <td className="border border-gray-700 px-4 py-2">Album B</td>
                <td className="border border-gray-700 px-4 py-2">Album</td>
                <td className="border border-gray-700 px-4 py-2">Audio</td>
                <td className="border border-gray-700 px-4 py-2">Download</td>
                <td className="border border-gray-700 px-4 py-2">Partner Y</td>
                <td className="border border-gray-700 px-4 py-2">Inactive</td>
                <td className="border border-gray-700 px-4 py-2">30%</td>
                <td className="border border-gray-700 px-4 py-2">
                  Apple Music
                </td>
                <td className="border border-gray-700 px-4 py-2">300K</td>
                <td className="border border-gray-700 px-4 py-2">$5,000</td>
                <td className="border border-gray-700 px-4 py-2">$1,500</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex justify-between items-center mt-1 mb-6 bg-[#252525] text-white text-sm rounded-md px-6 py-3 w-[76%] ml-[12%]">
          <div>
            Showing {firstEntryIndex + 1} to {lastEntryIndex} of {totalEntries}{" "}
            entries
          </div>
          <div className="flex items-center">
            <span>Show</span>
            <select
              className="mx-2 px-4 py-1 pr-8 border border-gray-600 rounded bg-[#252525] text-white outline-none appearance-none"
              value={entriesPerPage}
              onChange={handleEntriesPerPageChange}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <span>entries</span>
          </div>
          <div className="flex items-center">
            <button
              className="px-4 py-1 mx-2 border border-gray-600 rounded disabled:opacity-50"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              className="px-4 py-1 mx-2 border border-gray-600 rounded disabled:opacity-50"
              onClick={goToNextPage}
              disabled={
                currentPage === Math.ceil(totalEntries / entriesPerPage)
              }
            >
              Next
            </button>
          </div>
        </div>
        <div className="flex justify-end mr-[12%] mb-[10%]">
          <button
            className="text-[#FFFFFF] py-2 px-4 bg-[#FF6C2F] text-sm rounded-md hover:bg-[#d95a25] active:bg-[#c15122]"
            style={{
              fontFamily: "Poppins, sanSerif",
            }}
            onClick={handleSubmitClick}
          >
            Export Full Report
          </button>
        </div>
      </div>
    </div>
  );
}
