import React from "react";
import { useContent } from "../context/main.js";

export default function UploadSubmission() {
  const { contentType } = useContent();
  const { file } = useContent();

  const imageUrl = file ? URL.createObjectURL(file) : "";
  return (
    <div>
      <div className="flex">
        {contentType === "audio" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Audio</span>
          </h2>
        )}
        {contentType === "video" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Video</span>
          </h2>
        )}
      </div>
      <div
        className=""
        style={{
          width: "auto",
          minHeight: "300px",
          height: "auto",
          marginLeft: "12%",
          marginRight: "12%",
          borderRadius: "5px",
          backgroundColor: "#ffffff0d",
          display: "flex",
          borderRadius: "10px",
          marginBottom: "10%",
        }}
      >
        <div className="flex items-center justify-center profileBar w-[28%] bg-[#ffffff0d] rounded-md ">
          {imageUrl && (
            <img
              src={imageUrl}
              alt="Uploaded"
              style={{ width: "95%", height: "95%", borderRadius: "5px" }}
            />
          )}
        </div>
        <div className="flex flex-col items-start justify-center w-[70%]">
          <div className="ml-[5%]">
            {contentType === "audio" && (
              <h1
                className="text-[#FFFFFF] text-[1.5rem]"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Your track has been submitted!
              </h1>
            )}
            {contentType === "video" && (
              <h1
                className="text-[#FFFFFF] text-[1.5rem]"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Your Video has been submitted!
              </h1>
            )}
            <br />
            <br />
            <h2
              className="text-[#FFFFFF] text-[1rem]"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              track name
            </h2>
            <br />
            <h2
              className="text-[#FFFFFF] text-[1rem]"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Release Date
            </h2>
            <br />
            <br />
            <p className="text-[#FFFFFF] text-[0.9rem] max-w-[60%]">
              It will take 2-3 business days for your song to get reviewed and
              approved, We will notify you on email{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
