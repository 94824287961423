import React, { useState, useEffect } from "react";
import { publicRouteLink } from "../constant/Routes";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-hot-toast";
import axios from "axios";
import { useContext } from "react";
import { MainContext } from "../context/main";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";

const SignInForm = ({ onClose }) => {
  const [ph, setPh] = useState("");
  const [isPhoneExists, setIsPhoneExists] = useState(true);
  const { countryCode, setCountryCode, mob, setMob, updateButtonInfo } =
    useContext(MainContext);
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState("");
  const navigate = useNavigate();

  const handleClick = async (event) => {
    await checkPhoneNumber(mob);
  };

  const checkPhoneNumber = async () => {
    try {
      const response = await fetch(`https://haartistaloud-backend.kollywoodhungama.com/v1/CheckPhoneNo/${mob}`);
      const respData = await response.json();

      if (respData.status === 409) {
        await sendOTP();
      } else if (respData.status === 200) {
        setIsPhoneExists(false);
        await sendOTP();
        return;
      }
    } catch (err) {
      toast.error("Something went wrong. Please try again!");
      console.error("Error from checkPhoneNumber function:", err.message);
      if (err.response) {
        console.error("Error response:", err.response.data);
      }
    }
  };

  const handlePhChange = (phone, country) => {
    let newPh = phone.split(country.dialCode);
    newPh = newPh[1];
    setPh(phone);
    setMob(newPh);
    setCountryCode(country.dialCode);
  };

  const sendOTP = async () => {
    onClose && onClose();
    updateButtonInfo("Send OTP", "SignIn");
    try {
      const url = "https://haartistaloud-backend.kollywoodhungama.com/v2/send-otp";

      const resp = await axios({
        url: url,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        data: {
          mob: mob,
          countryCode: "+" + countryCode,
        },
      });

      if (resp.data.statusCode === 200) {
        toast.success("OTP sent successfully!");
        navigate(publicRouteLink.OTP_VERIFICATION);
        return;
      } else {
        toast.error("OTP not sent. Please try again!");
        return;
      }
    } catch (error) {
      console.log("Error from sendOTP function:", error);
      return;
    }
  };

  const login = useGoogleLogin({
    
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
      navigate(publicRouteLink.DASHBOARD);
      console.log(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    console.log(process.env);
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  return (
    <div className="flex items-center justify-center bg-[#1A1A1A] px-16 py-12">
      <div className="bg-[#1A1A1A] text-white  rounded-mdF w-96">
        <h1 className="text-3xl font-bold text-center">
          Sign in to your account
        </h1>
        <div className="pt-5">
          <label
            className="block text-white text-sm font-medium"
            htmlFor="phoneNumber"
          >
            Mobile Number
          </label>
          {/* <PhoneInput inputClass="w-full" country={"in"} value={ph} onChange={handlePhChange} required className="text-black pl-[40px]" /> */}
          <div className="flex items-center">
            <PhoneInput
              country={"in"}
              value={ph}
              onChange={handlePhChange}
              inputClass="w-full pl-12 text-black"
              buttonClass="absolute"
              containerClass="relative w-full"
            />
          </div>
        </div>
        <button
          className="w-full bg-[#FF6C2F] text-white h-12 rounded-md text-sm font-medium mt-3"
          type="submit"
          onClick={handleClick}
        >
          Send OTP
        </button>

        <div className="my-3 text-center">or</div>
        <button
          className="w-full bg-[#262626] text-white h-12 rounded-md text-sm font-medium mt-3"
          type="button"
          // onClick={() => login()}
        >
          <span className="flex flex-row justify-center items-center">
            <img
              src="assets\google-icon.svg"
              alt="google-icon"
              className="pr-2"
            />
            Sign in with Google
          </span>
        </button>

        {!isPhoneExists && (
          <p className="text-[#FA3235]">
            You dont have an account. Please create an account!
          </p>
        )}

        <div className="flex flex-row items-center justify-end mt-10 space-x-4">
          <p className="">Don’t have an account?</p>
          <a
            className="bg-[#262626] text-[#FF6C2F] w-28 h-9 flex items-center justify-center ml-3 rounded-md"
            href={publicRouteLink.SIGNUP}
          >
            Create Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default SignInForm;
