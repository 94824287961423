import React, { useState } from 'react';

const ProfileDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            {isOpen && (
                <div className="absolute mt-3 w-24 text-center bg-[#252525] rounded-md shadow-sm">
                    <ul className="flex flex-col space-y-2 p-2">
                        <li className="bg-[#313131] text-white font-light"
                            onClick={() => { toggleDropdown() }}>
                            Abhishek Aji
                            abhishek.aji@hungama.com
                        </li>
                        <li className="bg-[#313131] text-white font-light"
                            onClick={() => { toggleDropdown() }}>Dashboard
                        </li>
                        <li className="bg-[#313131] text-white font-light"
                            onClick={() => { toggleDropdown() }}>Profile
                        </li>
                        <li className="bg-[#313131] text-white font-light"
                            onClick={() => { toggleDropdown() }}>Logout
                        </li>
                    </ul>
                </div>
            )}
        </>
    )
}

export default ProfileDropdown;