import axios from 'axios';
import React from 'react'
import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { publicRouteLink } from '../constant/Routes';
import { MainContext } from '../context/main';
import { useNavigate } from 'react-router-dom';

const SignUp3 = () => {
    const [instaLink, setInstaLink] = useState('');
    const [fbLink, setFbLink] = useState('');
    const [youtubeLink, setYoutubeLink] = useState('');
    const [twitterLink, setTwitterLink] = useState('');
    const { formData, handleFormData } = useContext(MainContext);
    const navigate = useNavigate();

    const createUser = async () => {
        try {
            const resp = await axios({
                url: 'https://haartistaloud-backend.kollywoodhungama.com/v2/addUser',
                method: "POST",
                headers: {
                    "Content-type": "application/json"
                },
                data: {
                    "phone": formData.phone,
                    "fullName": formData.fullName,
                    "countryCode": formData.country_code,
                    "email": formData.email,
                    "country": formData.country,
                    "state": formData.state,
                    "city": formData.city,
                    "zipcode": formData.zipCode,
                    "instaLink": instaLink,
                    "fbLink": fbLink,
                    "youtubeLink": youtubeLink,
                    "twitterLink": twitterLink
                }
            });

            if (resp.data.status == 200) {
                navigate(publicRouteLink.DASHBOARD);
            }
        }
        catch (err) {
            console.log("Error from createUser function:", err);
            return err;
        }
    }

    return (
        <div className="flex items-center justify-center h-screen bg-[#1A1A1A]">
            <form className="bg-[#1A1A1A] text-white  rounded-mdF w-96">
                <h1 className="text-3xl font-bold text-center">Complete your account</h1>

                <p className='text-[#6E6E6E] text-2xlfont-medium break-words mt-8'>Profiles</p>
                <div className='pt-3'>
                    <label className="block text-white text-sm font-normal" htmlFor="instaLink">Instagram Link
                    </label>
                    <input
                        className="w-full outline-none bg-white text-black rounded-md h-12"
                        type="text"
                        name="instaLink"
                        value={formData?.instaLink}
                        onChange={(e) => {
                            handleFormData(e);
                            setInstaLink(e.target.value)
                        }}
                    />
                </div>

                <div className='pt-3'>
                    <label className="block text-white text-sm font-normal" htmlFor="fbLink">Facebook Link
                    </label>
                    <input
                        className="w-full outline-none bg-white text-black rounded-md h-12"
                        type="text"
                        name="fbLink"
                        value={formData?.fbLink}
                        onChange={(e) => {
                            handleFormData(e);
                            setFbLink(e.target.value)
                        }}
                    />
                </div>

                <div className='pt-3'>
                    <label className="block text-white text-sm font-normal" htmlFor="youtubeLink">Youtube Link
                    </label>
                    <input
                        className="w-full outline-none bg-white text-black rounded-md h-12"
                        type="text"
                        name="youtubeLink"
                        value={formData?.youtubeLink}
                        onChange={(e) => {
                            handleFormData(e);
                            setYoutubeLink(e.target.value)
                        }}
                    />
                </div>

                <div className='pt-3'>
                    <label className="block text-white text-sm font-normal" htmlFor="twitterLink">Twitter Link
                    </label>
                    <input
                        className="w-full outline-none bg-white text-black rounded-md h-12"
                        type="text"
                        name="twitterLink"
                        value={formData?.twitterLink}
                        onChange={(e) => {
                            handleFormData(e);
                            setTwitterLink(e.target.value)
                        }}
                    />
                </div>

                <Link to={publicRouteLink.DASHBOARD}>
                    <button className="w-full bg-[#FF6C2F] text-white h-12 rounded-md text-sm font-medium mt-5"
                        type="submit"
                        onClick={createUser}
                    >
                        Submit
                    </button>
                </Link>

            </form>
        </div>
    )
}

export default SignUp3
