import React, { useState, useEffect } from "react";

const NumberPopup = ({ onClose }) => {
  const [showOtpField, setShowOtpField] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let interval = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handlePhoneNumberChange = (value) => {
    if (/^\d*$/.test(value)) {
      setPhoneNumber(value);
    }
  };

  const handleOtpChange = (value) => {
    if (/^\d*$/.test(value) && value.length <= 4) {
      setOtp(value);
    }
  };

  const handleSendOtpClick = () => {
    setShowOtpField(true);
    setTimer(59);
  };

  const handleChangeNumberClick = () => {
    setShowOtpField(false);
    setTimer(0);
  };

  const handleSendCodeAgainClick = () => {
    setTimer(59);
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content" style={{ width: "30%" }}>
        <span className="close-button" onClick={onClose}>
          &times;
        </span>
        {!showOtpField ? (
          <>
            <div>
              <h1
                style={{
                  fontWeight: "400",
                  fontSize: "25px",
                  marginBottom: "2%",
                }}
              >
                Enter Phone Number
              </h1>
            </div>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => handlePhoneNumberChange(e.target.value)}
              className="rounded-[5px] bg-[#4c4c4c] w-[auto] h-[2.7rem] mt-[0%] text-[#FFFFFF] font-[Poppins]"
              maxLength="10"
            />
            <button
              className="button-popup"
              onClick={handleSendOtpClick}
              style={{
                marginTop: "7%",
                marginBottom: "4%",
                padding: "8px 16px",
                border: "none",
                width: "28%",
                borderRadius: "4px",
                cursor: "pointer",
                fontSize: "13px",
                backgroundColor: "#ff6c2f",
                color: "white",
                marginLeft: "35%",
              }}
            >
              {timer > 0 ? `Resend OTP in ${timer}s` : "Send OTP"}
            </button>
          </>
        ) : (
          <>
            <h1
              style={{
                fontWeight: "400",
                fontSize: "25px",
                marginBottom: "2%",
              }}
            >
              Enter OTP
            </h1>
            <input
              type="text"
              value={otp}
              onChange={(e) => handleOtpChange(e.target.value)}
              className="rounded-[5px] bg-[#4c4c4c] w-[auto] h-[2.7rem] mt-[0%] text-[#FFFFFF] font-[Poppins]"
              maxLength="4"
            />
            <button
              className="button-popup"
              style={{
                marginTop: "5%",
                marginBottom: "5%",
                padding: "8px 16px",
                border: "none",
                width: "20%",
                borderRadius: "4px",
                cursor: "pointer",
                fontSize: "13px",
                backgroundColor: "#ff6c2f",
                color: "white",
                marginLeft: "40%",
              }}
            >
              Verify
            </button>

            {timer > 0 ? (
              <p
                className="change-number"
                // onClick={handleChangeNumberClick}
                style={{
                  fontSize: "13px",
                  textAlign: "center",
                  marginBottom: "2%",
                }}
              >
                <p>Resend OTP in {timer}s</p>
              </p>
            ) : (
              <p
                className="change-number"
                onClick={handleChangeNumberClick}
                style={{
                  fontSize: "13px",
                  textAlign: "center",
                  textDecorationLine: "underline",
                  marginBottom: "3%",
                }}
              >
                <button
                  className="send-code-again"
                  onClick={handleSendCodeAgainClick}
                >
                  Send code again
                </button>
              </p>
            )}
            <p
              className="change-number"
              onClick={handleChangeNumberClick}
              style={{
                fontSize: "13px",
                textAlign: "center",
                textDecorationLine: "underline",
              }}
            >
              <button>Change Number</button>
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default NumberPopup;
