import React, { useState, useEffect } from "react";
import {ToastContainer, toast} from 'react-toastify';
import axios from "axios";

const AddressPopup = ({ onClose, onSave }) => {
  const [address, setAddress] = useState("");
  const [countryValue, setCountryValue] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [pincodeValue, setPincodeValue] = useState();
  const [isValid, setIsValid] = useState(true);

  const [addressError, setAddressError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");

  const handleAddressChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9 ',+-]*$/;

    if (regex.test(value) && value.length <= 200) {
      setAddress(value);
      setAddressError("");
    } else if (!regex.test(value)) {
      setAddressError("Only alphanumeric values are allowed.");
    } else {
      setAddressError("The input must be exactly 200 characters.");
    }
  };

  const handleCityChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z\s]*$/;

    if (regex.test(value) && value.length <= 30) {
      setCityValue(value);
      setCityError("");
    } else {
      setCityError("Name must be less than 30 characters");
    }
  };

  const handleStateChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z.,A-Z\s]*$/;

    if (regex.test(value) && value.length <= 20) {
      setStateValue(value);
      setStateError("");
    } else {
      setStateError("Name must be less than 20 characters");
    }
  };
  

  const handleCountryChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z\s]*$/;

    if (regex.test(value) && value.length <= 50) {
      setCountryValue(value);
      setCountryError("");
    } else {
      setCountryError("Name must be less than 50 characters");
    }
  };

  const handlePincodeChange = (e) => {
    const value = e.target.value;
    const isValidInput = /^\d{4,12}$/.test(value);
    setPincodeValue(value);
    setIsValid(isValidInput);
  };

  const handleKeyPress = (event) => {
    const value = event.target.value;
    const key = event.key;

    if (!/\d/.test(key) || value.length >= 12) {
      event.preventDefault();
    }
  };

  const handleSaveClick = () => {
    onSave(address, cityValue, stateValue, countryValue, pincodeValue);
    toast.success("Address saved successfully!")
  };

  //-----API Integration-----
  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const response = await axios.get(
          "https://haartistaloud-backend.kollywoodhungama.com/v1/userById/400f6036b07146eab9d2eab150fa5f"
        );
        const userData = response.data.data[0];

        console.log("API response data:", userData);

        setAddress(userData.business_address || "");
        setCityValue(userData.city || "");
        setStateValue(userData.state || "");
        setCountryValue(userData.country || "");
        setPincodeValue(userData.zipcode || "");
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    getUserInfo();
  }, []);

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <span className="close-button" onClick={onClose}>
          &times;
        </span>
        <div>
          <h1
            style={{ fontWeight: "400", fontSize: "25px", marginBottom: "6%" }}
          >
            Add Address
          </h1>
        </div>
        <div className="labelHeading">
          <label
            htmlFor=""
            className="font-[Poppins] text-[#FFFFFF] mt-[4%] w-[80%]"
          >
            Business Address
          </label>
        </div>
        <div>
          <input
            type="text"
            className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.7rem] mt-[0%] text-[#FFFFFF] font-[Poppins]"
            placeholder="Address"
            value={address}
            onChange={handleAddressChange}
            required
            style={{ marginBottom: "4%", marginTop: "1.5%" }}
          />
        </div>
        <div className="flex flex-column justify-between">
          <div className="flex-column">
            <div className="labelHeading">
              <label htmlFor="" className="text-[#FFFFFF] flex-column">
                City
              </label>
            </div>
            <div>
              <input
                type="text"
                className="rounded-[5px] bg-[#4c4c4c] w-[auto] h-[2.7rem] text-[#FFFFFF] font-[Poppins] flex-column"
                placeholder="City"
                value={cityValue}
                onChange={handleCityChange}
                required
                style={{ marginBottom: "8%", marginTop: "2%" }}
              />
            </div>
          </div>
          <div className="flex-column">
            <div className="labelHeading">
              <label htmlFor="" className="text-[#FFFFFF] flex-column">
                State
              </label>
            </div>
            <div>
              <input
                type="text"
                className="rounded-[5px] bg-[#4c4c4c] w-[auto] h-[2.7rem] mt-[1%] mb-[35%] text-[#FFFFFF] font-[Poppins] flex-column"
                placeholder="State"
                value={stateValue}
                onChange={handleStateChange}
                required
                style={{ marginBottom: "8%", marginTop: "2%" }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-column justify-between">
          <div className="flex-column">
            <div className="labelHeading">
              <label
                htmlFor=""
                className="font-[Poppins] text-[#FFFFFF] mt-[4%] w-[80%] flex-column"
              >
                Pin code
              </label>
            </div>
            <div>
              <input
                type="text"
                className="rounded-[5px] bg-[#4c4c4c] w-[100%] h-[2.7rem] mt-[1%] mb-[35%] text-[#FFFFFF] font-[Poppins] flex-column"
                placeholder="Pin code"
                value={pincodeValue}
                onChange={handlePincodeChange}
                onKeyPress={handleKeyPress}
                required
                style={{ marginTop: "2%", maxWidth: "100%" }}
              />
            </div>
          </div>
          <div className="flex-column">
            <div className="labelHeading">
              <label
                htmlFor=""
                className="font-[Poppins] text-[#FFFFFF] mt-[4%] w-[80%] flex-column"
              >
                Country
              </label>
            </div>
            <div>
              <input
                type="text"
                className="rounded-[5px] bg-[#4c4c4c] w-[auto] h-[2.7rem] mt-[1%] mb-[35%] text-[#FFFFFF] font-[Poppins] flex-column"
                placeholder="Country"
                value={countryValue}
                onChange={handleCountryChange}
                required
                style={{ marginTop: "2%" }}
              />
            </div>
          </div>
        </div>
        {!isValid && (
          <p className="" style={{ fontSize: "11px", maxWidth: "52%" }}>
            Please enter a valid Pincode (minimum 4 maximum 12 digits)
          </p>
        )}
        <div className="button-group">
          <button onClick={handleSaveClick}>Save</button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddressPopup;
