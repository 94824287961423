import React, { useState, useEffect } from "react";
import { useContent } from "../context/main.js";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function ContentDistribution() {
  const { contentType } = useContent();

  const [isClickedJio, setIsClickedJio] = useState(false);
  const [isClickedAmazon, setIsClickedAmazon] = useState(false);
  const [isClickedSpotify, setIsClickedSpotify] = useState(false);
  const [isClickedWynk, setIsClickedWynk] = useState(false);
  const [isClickedMeta, setIsClickedMeta] = useState(false);
  const [isClickedItunes, setIsClickedItunes] = useState(false);
  const [selectedValue, setSelectedValue] = useState("Exclusive");

  const navigate = useNavigate();

  const handleNextClick = () => {
    navigate("/terms-uploads");
  };

  const handleClickJio = () => {
    const newValue = !isClickedJio;
    setIsClickedJio(newValue);
    checkAllPlatforms(
      newValue,
      isClickedAmazon,
      isClickedSpotify,
      isClickedWynk,
      isClickedMeta,
      isClickedItunes
    );
  };

  const handleClickAmazon = () => {
    const newValue = !isClickedAmazon;
    setIsClickedAmazon(newValue);
    checkAllPlatforms(
      isClickedJio,
      newValue,
      isClickedSpotify,
      isClickedWynk,
      isClickedMeta,
      isClickedItunes
    );
  };

  const handleClickSpotify = () => {
    const newValue = !isClickedSpotify;
    setIsClickedSpotify(newValue);
    checkAllPlatforms(
      isClickedJio,
      isClickedAmazon,
      newValue,
      isClickedWynk,
      isClickedMeta,
      isClickedItunes
    );
  };

  const handleClickWynk = () => {
    const newValue = !isClickedWynk;
    setIsClickedWynk(newValue);
    checkAllPlatforms(
      isClickedJio,
      isClickedAmazon,
      isClickedSpotify,
      newValue,
      isClickedMeta,
      isClickedItunes
    );
  };

  const handleClickMeta = () => {
    const newValue = !isClickedMeta;
    setIsClickedMeta(newValue);
    checkAllPlatforms(
      isClickedJio,
      isClickedAmazon,
      isClickedSpotify,
      isClickedWynk,
      newValue,
      isClickedItunes
    );
  };

  const handleClickItunes = () => {
    const newValue = !isClickedItunes;
    setIsClickedItunes(newValue);
    checkAllPlatforms(
      isClickedJio,
      isClickedAmazon,
      isClickedSpotify,
      isClickedWynk,
      isClickedMeta,
      newValue
    );
  };

  const checkAllPlatforms = (jio, amazon, spotify, wynk, meta, itunes) => {
    if (jio && amazon && spotify && wynk && meta && itunes) {
      setSelectedValue("Exclusive");
    } else {
      setSelectedValue("Non-Exclusive");
    }
  };

  const handleClickExclusive = (event) => {
    const { value } = event.target;
    setSelectedValue(value);
    setIsClickedJio(true);
    setIsClickedAmazon(true);
    setIsClickedSpotify(true);
    setIsClickedWynk(true);
    setIsClickedMeta(true);
    setIsClickedItunes(true);
  };

  const handleClickNonExclusive = (event) => {
    const { value } = event.target;
    setSelectedValue(value);
    setIsClickedJio(false);
    setIsClickedAmazon(false);
    setIsClickedSpotify(false);
    setIsClickedWynk(false);
    setIsClickedMeta(false);
    setIsClickedItunes(false);
  };

  // const handleChange = (event) => {
  //   const { value } = event.target;
  //   setSelectedValue(value);
  // };

  useEffect(() => {
    handleClickExclusive({ target: { value: "Exclusive" } });
  }, []);

  return (
    <div>
      <div className="flex">
        {contentType === "audio" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Audio</span>
          </h2>
        )}
        {contentType === "video" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Video</span>
          </h2>
        )}
      </div>
      <div
        className=""
        style={{
          width: "auto",
          minHeight: "300px",
          height: "auto",
          marginLeft: "12%",
          marginRight: "12%",
          borderRadius: "5px",
          backgroundColor: "#ffffff0d",
          display: "flex",
          borderRadius: "10px",
          marginBottom: "8%",
        }}
      >
        <div className="flex flex-col items-start profileBar w-[28%] bg-[#ffffff0d] rounded-md ">
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%] mt-[13%]">
            <Link to="/details-audio">Details</Link>
          </button>
          <br />
          <button className="text-[#FF6C2F] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/content-distribution">Content Distribution</Link>
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/terms-uploads">T&C</Link>
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/iprs">IPRS</Link>
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            {contentType === "audio" && (
              <Link to="/attach-audio">Attach Audio</Link>
            )}
            {contentType === "video" && (
              <Link to="/attach-audio">Attach Video</Link>
            )}
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/cover-image">Select Cover Image</Link>
          </button>
        </div>
        <div className="ml-[auto] mr-[auto] mt-[6%] flex flex-col max-w-[70%] min-h-[700px]">
          {contentType === "audio" && (
            <>
              <div className="flex items-center justify-between mb-[10%]">
                <h2
                  className="text-[#FFFFFF] text-[0.9rem]"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  Platforms:
                </h2>
                <div>
                  <input
                    type="radio"
                    value="Exclusive"
                    id="Exclusive"
                    checked={selectedValue === "Exclusive"}
                    onChange={handleClickExclusive}
                    style={{
                      width: "15px",
                      height: "15px",
                      appearance: "none",
                      backgroundColor:
                        selectedValue === "Exclusive" ? "#FF6C2F" : "#242424",
                      borderRadius: "50%",
                      border: "2px solid #fff",
                      cursor: "pointer",
                      outline: "none",
                      transition: "background-color 0.3s ease-in-out",
                    }}
                    onMouseOver={(e) =>
                      (e.target.style.backgroundColor =
                        selectedValue === "Exclusive" ? "#FF6C2F" : "")
                    }
                    onMouseOut={(e) =>
                      (e.target.style.backgroundColor =
                        selectedValue === "Exclusive" ? "#FF6C2F" : "")
                    }
                  />
                  <label
                    for="Exclusive"
                    className="text-[#FFFFFF] text-[0.9rem] ml-[10px] mr-[25px]"
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  >
                    Exclusive
                  </label>
                  <input
                    type="radio"
                    value="Non-Exclusive"
                    id="Non-Exclusive"
                    checked={selectedValue === "Non-Exclusive"}
                    onChange={handleClickNonExclusive}
                    style={{
                      width: "15px",
                      height: "15px",
                      appearance: "none",
                      backgroundColor:
                        selectedValue === "Non-Exclusive"
                          ? "#FF6C2F"
                          : "#242424",
                      borderRadius: "50%",
                      border: "2px solid #fff",
                      cursor: "pointer",
                      outline: "none",
                      transition: "background-color 0.3s ease-in-out",
                    }}
                    onMouseOver={(e) =>
                      (e.target.style.backgroundColor =
                        selectedValue === "Non-Exclusive" ? "#FF6C2F" : "")
                    }
                    onMouseOut={(e) =>
                      (e.target.style.backgroundColor =
                        selectedValue === "Non-Exclusive" ? "#FF6C2F" : "")
                    }
                  />
                  <label
                    for="Non-Exclusive"
                    className="text-[#FFFFFF] text-[0.9rem] ml-[10px]"
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  >
                    Non-Exclusive
                  </label>
                </div>
              </div>

              <div className="flex mb-[8%]">
                <button
                  disabled
                  style={{
                    backgroundColor: "#100e0e",
                    color: "white",
                    width: "8rem",
                    height: "3rem",
                    border: "none",
                    borderRadius: "40px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #FF6C2F",
                  }}
                >
                  <img
                    src={"./assets/hungamalogo1.png"}
                    alt="Button Icon"
                    style={{
                      width: "100px",
                    }}
                  />
                </button>
                <button
                  disabled
                  style={{
                    backgroundColor: "#100e0e",
                    color: "white",
                    width: "8rem",
                    height: "3rem",
                    border: "none",
                    borderRadius: "40px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #FF6C2F",
                    marginLeft: "50px",
                  }}
                >
                  <img
                    src={"./assets/heftyverse.png"}
                    alt="Button Icon"
                    style={{
                      width: "100px",
                    }}
                  />
                </button>
                <button
                  onClick={handleClickJio}
                  style={{
                    backgroundColor: isClickedJio ? "#100e0e" : "#ffffff0d",
                    color: "white",
                    width: "8rem",
                    height: "3rem",
                    border: "none",
                    borderRadius: "40px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: isClickedJio ? "1px solid #FF6C2F" : "",
                    marginLeft: "50px",
                  }}
                >
                  <img
                    src={"./assets/jiosaavanlogo.png"}
                    alt="Button Icon"
                    style={{
                      width: "140px",
                    }}
                  />
                </button>
              </div>

              <div className="flex mb-[8%]">
                <button
                  onClick={handleClickAmazon}
                  style={{
                    backgroundColor: isClickedAmazon ? "#100e0e" : "#ffffff0d",
                    color: "white",
                    width: "8rem",
                    height: "3rem",
                    border: "none",
                    borderRadius: "40px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: isClickedAmazon ? "1px solid #FF6C2F" : "",
                  }}
                >
                  <img
                    src={"./assets/amazonmusic.png"}
                    alt="Button Icon"
                    style={{
                      width: "100px",
                    }}
                  />
                </button>

                <button
                  onClick={handleClickSpotify}
                  style={{
                    backgroundColor: isClickedSpotify ? "#100e0e" : "#ffffff0d",
                    color: "white",
                    width: "8rem",
                    height: "3rem",
                    border: "none",
                    borderRadius: "40px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: isClickedSpotify ? "1px solid #FF6C2F" : "",
                    marginLeft: "50px",
                  }}
                >
                  <img
                    src={"./assets/spotifylogo.png"}
                    alt="Button Icon"
                    style={{
                      width: "100px",
                    }}
                  />
                </button>

                <button
                  onClick={handleClickWynk}
                  style={{
                    backgroundColor: isClickedWynk ? "#100e0e" : "#ffffff0d",
                    color: "white",
                    width: "8rem",
                    height: "3rem",
                    border: "none",
                    borderRadius: "40px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: isClickedWynk ? "1px solid #FF6C2F" : "",
                    marginLeft: "50px",
                  }}
                >
                  <img
                    src={"./assets/wynkmusic.png"}
                    alt="Button Icon"
                    style={{
                      width: "140px",
                    }}
                  />
                </button>
              </div>
              <div className="flex justify-evenly mb-[10%]">
                <button
                  onClick={handleClickMeta}
                  style={{
                    backgroundColor: isClickedMeta ? "#100e0e" : "#ffffff0d",
                    color: "white",
                    width: "8rem",
                    height: "3rem",
                    border: "none",
                    borderRadius: "40px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: isClickedMeta ? "1px solid #FF6C2F" : "",
                  }}
                >
                  <img
                    src={"./assets/metalogo.png"}
                    alt="Button Icon"
                    style={{
                      width: "90px",
                    }}
                  />
                </button>
                <button
                  onClick={handleClickItunes}
                  style={{
                    backgroundColor: isClickedItunes ? "#100e0e" : "#ffffff0d",
                    color: "white",
                    width: "8rem",
                    height: "3rem",
                    border: "none",
                    borderRadius: "40px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: isClickedItunes ? "1px solid #FF6C2F" : "",
                  }}
                >
                  <img
                    src={"./assets/ituneslogo.png"}
                    alt="Button Icon"
                    style={{
                      width: "80px",
                    }}
                  />
                </button>
              </div>
            </>
          )}

          {contentType === "video" && (
            <>
              <div className="flex items-center justify-between mb-[10%]">
                <h2
                  className="text-[#FFFFFF] text-[0.9rem]"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  Platforms:
                </h2>
                <div>
                  <input
                    type="radio"
                    value="Exclusive"
                    id="Exclusive"
                    // checked={selectedValue === "Exclusive"}
                    disabled
                    style={{
                      width: "15px",
                      height: "15px",
                      appearance: "none",
                      backgroundColor: "#FF6C2F",
                      borderRadius: "50%",
                      border: "2px solid #fff",
                      cursor: "pointer",
                      outline: "none",
                      transition: "background-color 0.3s ease-in-out",
                    }}
                  />
                  <label
                    for="Exclusive"
                    className="text-[#FFFFFF] text-[0.9rem] ml-[10px] mr-[25px]"
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  >
                    Exclusive
                  </label>
                </div>
              </div>
              <div className="flex mb-[8%]">
                <button
                  disabled
                  style={{
                    backgroundColor: "#100e0e",
                    color: "white",
                    width: "8rem",
                    height: "3rem",
                    border: "none",
                    borderRadius: "40px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #FF6C2F",
                  }}
                >
                  <img
                    src={"./assets/hungamalogo1.png"}
                    alt="Button Icon"
                    style={{
                      width: "100px",
                    }}
                  />
                </button>
                <button
                  disabled
                  style={{
                    backgroundColor: "#100e0e",
                    color: "white",
                    width: "8rem",
                    height: "3rem",
                    border: "none",
                    borderRadius: "40px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #FF6C2F",
                    marginLeft: "50px",
                  }}
                >
                  <img
                    src={"./assets/heftyverse.png"}
                    alt="Button Icon"
                    style={{
                      width: "100px",
                    }}
                  />
                </button>
                <button
                  disabled
                  style={{
                    backgroundColor: "#100e0e",
                    color: "white",
                    width: "8rem",
                    height: "3rem",
                    border: "none",
                    borderRadius: "40px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #FF6C2F",
                    marginLeft: "50px",
                  }}
                >
                  <img
                    src={"./assets/oneplustv.png"}
                    alt="Button Icon"
                    style={{
                      width: "140px",
                    }}
                  />
                </button>
              </div>

              <div className="flex mb-[8%]">
                <button
                  disabled
                  style={{
                    backgroundColor: "#100e0e",
                    color: "white",
                    width: "8rem",
                    height: "3rem",
                    border: "none",
                    borderRadius: "40px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #FF6C2F",
                  }}
                >
                  <img
                    src={"./assets/airtel.png"}
                    alt="Button Icon"
                    style={{
                      width: "200px",
                    }}
                  />
                </button>

                <button
                  disabled
                  style={{
                    backgroundColor: "#100e0e",
                    color: "white",
                    width: "8rem",
                    height: "3rem",
                    border: "none",
                    borderRadius: "40px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #FF6C2F",
                    marginLeft: "50px",
                  }}
                >
                  <img
                    src={"./assets/mi.png"}
                    alt="Button Icon"
                    style={{
                      width: "200px",
                    }}
                  />
                </button>

                <button
                  disabled
                  style={{
                    backgroundColor: "#100e0e",
                    color: "white",
                    width: "8rem",
                    height: "3rem",
                    border: "none",
                    borderRadius: "40px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #FF6C2F",
                    marginLeft: "50px",
                  }}
                >
                  <img
                    src={"./assets/fire.png"}
                    alt="Button Icon"
                    style={{
                      width: "140px",
                    }}
                  />
                </button>
              </div>
              <div className="flex justify-evenly mb-[10%]">
                <button
                  disabled
                  style={{
                    backgroundColor: "#100e0e",
                    color: "white",
                    width: "8rem",
                    height: "3rem",
                    border: "none",
                    borderRadius: "40px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #FF6C2F",
                  }}
                >
                  <img
                    src={"./assets/tata.png"}
                    alt="Button Icon"
                    style={{
                      width: "120px",
                    }}
                  />
                </button>
              </div>
            </>
          )}
          <div
            className="mb-[10%] w-[100%]"
            style={{
              flexGrow: "1",
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
            }}
          >
            <button
              className="text-[#FFFFFF] w-[6rem] h-[3rem] bg-[#FF6C2F] rounded-md hover:bg-[#d95a25] active:bg-[#c15122]"
              style={{
                fontFamily: "Poppins, sanSerif",
              }}
              onClick={handleNextClick}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
