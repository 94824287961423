import React, { useState } from "react";
import { useContent } from "../context/main.js";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function AttachFile() {
  const { contentType } = useContent();
  const navigate = useNavigate();
  const [fileName, setFileName] = useState("");
  const [fileError, setFileError] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      let isValid = false;
      let errorMessage = "";

      if (contentType === "audio") {
        if (file.size > 100 * 1024 * 1024) {
          errorMessage = "Audio file size exceeds 100 MB.";
        } else if (!file.name.endsWith(".wav")) {
          errorMessage = "Only .wav files are allowed for audio.";
        } else {
          isValid = true;
        }
      } else if (contentType === "video") {
        if (file.size > 500 * 1024 * 1024) {
          errorMessage = "Video file size exceeds 500 MB.";
        } else if (!file.name.endsWith(".mp4") && !file.name.endsWith(".mov")) {
          errorMessage = "Only .mp4 and .mov files are allowed for video.";
        } else {
          isValid = true;
        }
      }

      if (isValid) {
        setFileName(file.name);
        setFileError("");
      } else {
        setFileName("");
        setFileError(errorMessage);
      }
    } else {
      setFileName("");
      setFileError("");
    }
  };

  const handleNextClick = () => {
    if (!fileName) {
      alert("Please upload an image before proceeding.");
      return;
    }
    navigate("/cover-image");
  };
  return (
    <div>
      <div className="flex">
        {contentType === "audio" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Audio</span>
          </h2>
        )}
        {contentType === "video" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Video</span>
          </h2>
        )}
      </div>
      <div
        className="container1"
        style={{ width: "auto", minHeight: "700px", height: "auto" }}
      >
        <div className="flex flex-col items-start profileBar w-[28%]  bg-[#ffffff0d] rounded-md ">
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%] mt-[13%]">
            <Link to="/details-audio">Details</Link>
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/content-distribution">Content Distribution</Link>
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/terms-uploads">T&C</Link>
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/iprs">IPRS</Link>
          </button>
          <br />
          <button className="text-[#FF6C2F] text-[1.3rem] w-auto h-auto ml-[17%]">
            {contentType === "audio" && (
              <Link to="/attach-audio">Attach Audio</Link>
            )}
            {contentType === "video" && (
              <Link to="/attach-audio">Attach Video</Link>
            )}
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/cover-image">Select Cover Image</Link>
          </button>
        </div>
        <div className="mt-[4%] flex flex-col items-center justify-center w-[70%]">
          <div className="w-[35rem] bg-[#ffffff0d] rounded-md p-[5%]">
            <div>
              {contentType === "audio" && (
                <h2
                  className="text-[#FFFFFF] text-[0.9rem] mb-[2%]"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  Upload Audio
                </h2>
              )}
              {contentType === "video" && (
                <h2
                  className="text-[#FFFFFF] text-[0.9rem] mb-[2%]"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  Upload Video
                </h2>
              )}
            </div>
            <div>
              <button className="w-[8rem] h-[2.3rem] text-[#FFFFFF] bg-[#FF6C2F] rounded-md hover:bg-[#d95a25] active:bg-[#c15122] mb-[7%]">
                <label for="file-upload">Choose File</label>
              </button>
              <input
                id="file-upload"
                type="file"
                hidden
                accept={contentType === "audio" ? ".wav" : ".mp4, .mov"}
                onChange={handleFileChange}
              />
            </div>
            <div className="w-[100%] h-[2.6rem] bg-[#ffffff0d] rounded-md text-[#FFFFFF] pl-[25px] pt-[8px] overflow-hidden text-ellipsis whitespace-nowrap">
              <h2 className="opacity-[.67]">{fileName || "Preview"}</h2>{" "}
            </div>
            {fileError && (
              <div className="text-[#FF6C2F] mt-2">
                <p>{fileError}</p>
              </div>
            )}
          </div>

          <div className="w-[35rem] rounded-md my-4">
            {contentType === "audio" && (
              <div
                className="text-[#e1dddd] text-[0.8rem] mt-[2%] w-[100%] flex flex-row gap-4"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                <div className="flex flex-col w-[50%] gap-4">
                  <div className="flex flex-row gap-6">
                    <div className="flex flex-col text-center rounded-md bg-[#121111] px-[14px] py-[10px]">
                      <span>Maximum Limit</span>
                      <span className="text-[#FF6C2F] text-[1.4rem] font-bold">
                        100 mb
                      </span>
                    </div>
                    <div className=" flex flex-col text-center bg-[#121111] rounded-md p-[10px]">
                      <span>File should be in</span>{" "}
                      <span className="text-[#FF6C2F] text-[1.4rem] font-bold">
                        .wav
                      </span>
                    </div>
                  </div>
                  <div className=" flex flex-col items-center justify-center text-center bg-[#121111] rounded-md p-[10px] gap-2">
                    <span>
                      Please ensure the audio isn't muted during the track.
                    </span>
                    <img
                      src="./assets/Frame561.png"
                      alt=""
                      className="w-[214px] h-[58px] text-center"
                    />
                  </div>
                  <div className=" flex flex-col text-center bg-[#121111] rounded-md p-[10px]">
                    <span>Please ensure that the track begins in</span>
                    <span className="text-[#FF6C2F] text-[1.4rem]">
                      {" "}
                      Maximum 5 seconds
                    </span>
                  </div>
                  <div className=" flex flex-col text-center bg-[#121111] rounded-md p-[10px]">
                    <span>
                      Please ensure that the track is properly trimmed
                      <span className="text-[#FF6C2F] text-[0.8rem]">
                        {" "}
                        particularly towards the end.
                      </span>
                    </span>
                  </div>
                </div>
                <div className="flex flex-col w-[50%] gap-4">
                  <div className=" flex flex-col text-center bg-[#121111] rounded-md px-[10px] py-[17px]">
                    <span>
                      <span className="text-[#FF2F2F] text-[0.8rem]">
                        Please don't mention{" "}
                      </span>
                      any music label, recording studios during the track.
                    </span>
                  </div>
                  <div className=" flex flex-col text-center bg-[#121111] rounded-md p-[10px]">
                    <span>
                      <span className="text-[#FF2F2F] text-[0.8rem]">
                        Please don't upload
                      </span>{" "}
                      any cover songs.
                    </span>
                  </div>
                  <div className=" flex flex-col items-center justify-center text-center bg-[#121111] rounded-md p-[10px] gap-2">
                    <span>
                      <span className="text-[#FF2F2F] text-[0.8rem]">
                        Please don't upload{" "}
                      </span>{" "}
                      any poem or podcast content.
                    </span>
                    <img
                      src="./assets/Frame563.png"
                      alt=""
                      className="w-[135px] h-[48px]"
                    />
                  </div>
                </div>
              </div>
            )}
            {contentType === "video" && (
              <div
                className="text-[#e1dddd] text-[0.8rem] mt-[2%] w-[100%] flex flex-row gap-4"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                <div className="flex flex-col w-[50%] gap-4">
                  <div className="flex flex-row gap-6">
                    <div className="flex flex-col text-center rounded-md bg-[#121111] px-[14px] py-[10px]">
                      <span>Maximum Limit</span>
                      <span className="text-[#FF6C2F] text-[1.2rem] font-bold">
                        500 mb
                      </span>
                    </div>
                    <div className=" flex flex-col text-center bg-[#121111] rounded-md px-[10px] py-[10px] gap-0.5">
                      <span>File should be in</span>{" "}
                      <span className="text-[#FF6C2F] text-[0.9rem] font-bold">
                        .mp4 <span className="text-slate-200">&</span>{" "}
                        <span className="text-[#FF6C2F] text-[0.9rem] font-bold">
                          .mov
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className=" flex flex-col items-center justify-center text-center bg-[#121111] rounded-md p-[10px] gap-2">
                    <span>
                      Please ensure the audio isn't muted during the track.
                    </span>
                    <img
                      src="./assets/Frame561.png"
                      alt=""
                      className="w-[214px] h-[58px] text-center"
                    />
                  </div>
                  <div className=" flex flex-col text-center bg-[#121111] rounded-md p-[10px]">
                    <span>Please ensure there are no </span>
                    <span className="text-[#FF2F2F] text-[0.8rem]">
                      social media handles{" "}
                      <span className="text-slate-200">, </span>music platforms{" "}
                      <span className="text-slate-200"> or </span> brand logos
                    </span>{" "}
                    <span>during and after the video ends.</span>
                  </div>
                  <div className=" flex flex-col text-center bg-[#121111] rounded-md p-[10px]">
                    <span>
                      Please ensure there is{" "}
                      <span className="text-[#FF2F2F] text-[0.8rem]">no</span>{" "}
                      upcoming track teaser
                      <span className="text-[#FF6C2F] text-[0.8rem]">
                        {" "}
                        at the end of video.
                      </span>
                    </span>
                  </div>
                </div>
                <div className="flex flex-col w-[50%] gap-4">
                  <div className=" flex flex-col text-center bg-[#121111] rounded-md px-[10px] py-[17px]">
                    <span>
                      <span className="text-[#FF2F2F] text-[0.8rem]">
                        Please don't mention{" "}
                      </span>
                      any music label, recording studios during the track.
                    </span>
                  </div>
                  <div className=" flex flex-col text-center bg-[#121111] rounded-md p-[10px]">
                    <span>
                      <span className="text-[#FF2F2F] text-[0.8rem]">
                        Please don't upload
                      </span>{" "}
                      any cover songs.
                    </span>
                  </div>
                  <div className=" flex flex-col items-center justify-center text-center bg-[#121111] rounded-md p-[10px] gap-2">
                    <span>
                      <span className="text-[#FF2F2F] text-[0.8rem]">
                        Please don't upload{" "}
                      </span>{" "}
                      any poem or commentary videos.
                    </span>
                    <img
                      src="./assets/Frame563.png"
                      alt=""
                      className="w-[135px] h-[48px]"
                    />
                  </div>
                  <div className=" flex flex-col text-center bg-[#121111] rounded-md p-[10px]">
                    <span>
                      <span className="text-[#FF2F2F] text-[0.8rem]">No</span>{" "}
                      jukebox videos.
                    </span>
                  </div>
                  <div className=" flex flex-col text-center bg-[#121111] rounded-md p-[10px]">
                    <span>
                      Please{" "}
                      <span className="text-[#FF2F2F] text-[0.8rem]">
                        don't
                      </span>{" "}
                      upload any short film or movie videos.
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className="mb-[5%] w-[80%]"
            style={{
              flexGrow: "8",
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
            }}
          >
            <button
              className="text-[#FFFFFF] w-[6rem] h-[3rem] bg-[#FF6C2F] rounded-md hover:bg-[#d95a25] active:bg-[#c15122]"
              style={{
                fontFamily: "Poppins, sanSerif",
              }}
              onClick={handleNextClick}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
